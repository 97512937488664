import React from 'react'

import { IconProps } from '@/types/general'

export const DocumentOutlineIcon = ({
  size = 14,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08398 2.33329C3.76182 2.33329 3.50065 2.59446 3.50065 2.91663V11.0833C3.50065 11.4055 3.76182 11.6666 4.08398 11.6666H9.91732C10.2395 11.6666 10.5007 11.4055 10.5007 11.0833L10.5007 5.49158L7.34236 2.33329H4.08398ZM2.33398 2.91663C2.33398 1.95013 3.11749 1.16663 4.08398 1.16663H7.34236C7.65178 1.16663 7.94853 1.28954 8.16732 1.50833L11.3256 4.66663C11.5444 4.88542 11.6673 5.18217 11.6673 5.49158V11.0833C11.6673 12.0498 10.8838 12.8333 9.91732 12.8333H4.08398C3.11749 12.8333 2.33398 12.0498 2.33398 11.0833V2.91663Z"
        fill={color}
      />
      <path d="M4.5 4.5H8" stroke={color} strokeLinecap="round" />
      <path d="M4.5 6.5H9" stroke={color} strokeLinecap="round" />
    </svg>
  )
}
