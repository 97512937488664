import React from 'react'

import classNames from 'classnames'

import { IconProps } from '@/types/general'

import baseIconClasses from '../Icons.module.less'

const GlobeAlt = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  wrapperClassName,
  border = false,
  ...rest
}: IconProps): JSX.Element => {
  const { inline, defaultWrapper, defaultBorder } = baseIconClasses
  const wrapperClassNames = classNames(inline, wrapperClassName, {
    [defaultWrapper]: border,
    [defaultBorder]: border,
  })
  return (
    <div className={wrapperClassNames}>
      <svg
        width={width}
        height={height}
        {...rest}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 7C13 10.3137 10.3137 13 7 13M13 7C13 3.68629 10.3137 1 7 1M13 7H1M7 13C3.68629 13 1 10.3137 1 7M7 13C8.10457 13 9 10.3137 9 7C9 3.68629 8.10457 1 7 1M7 13C5.89543 13 5 10.3137 5 7C5 3.68629 5.89543 1 7 1M1 7C1 3.68629 3.68629 1 7 1"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default GlobeAlt
