import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import moment from 'moment-timezone'

import 'moment/dist/locale/de'

import intervalPlural from 'i18next-intervalplural-postprocessor'

import { REDUX_STORAGE_KEY } from '@/constants/localStorage'

import { AuthState } from './redux/auth/slice'
import common_de from './translations/de/common.json'
import common_en from './translations/en/common.json'
import common_jp from './translations/ja/common.json'
import { config } from './utils/config'
import { getStoredItem } from './utils/localStorage'

export type SupportedLocale = 'en' | 'de' | 'ja'

// List of supported locales
export const LOCALES: SupportedLocale[] = ['en', 'de', 'ja']

export const isSupportedLocale = (locale: string): locale is SupportedLocale =>
  LOCALES.includes(locale as SupportedLocale)

const getUserLocale = (): string | undefined => {
  const persistedAppSettings = getStoredItem(REDUX_STORAGE_KEY) as undefined | { auth: string }

  if (persistedAppSettings?.auth) {
    const authState = JSON.parse(persistedAppSettings.auth)

    if (!authState) {
      return
    }

    return (authState as AuthState).user?.locale
  }
}

const getBrowserLocale = (): string => {
  return window.navigator.language.split('-')[0].toLowerCase()
}

const getDefaultLocale = (): string | undefined => {
  const locale = getUserLocale() || getBrowserLocale()
  if (isSupportedLocale(locale)) {
    return locale
  }
  return LOCALES[0]
}

moment.tz.setDefault(moment.tz.guess() || 'UTC')
moment.updateLocale('en', {
  monthsShort: [
    'Jan.',
    'Feb.',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ],
})
moment.updateLocale('de', {
  monthsShort: [
    'Jan.',
    'Feb.',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'Aug.',
    'Sept.',
    'Okt.',
    'Nov.',
    'Dez.',
  ],
})

// // Set the moment locale to the current lng
i18n.on('initialized', ({ lng }) => moment.locale(lng))
i18n.on('languageChanged', (lng) => moment.locale(lng))

i18n
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getDefaultLocale(),
    resources: {
      en: { common: common_en },
      de: { common: common_de },
      ja: { common: common_jp },
    },
    supportedLngs: config.languages.map((lang) => lang.id),
    fallbackNS: 'common',
    interpolation: {
      format: (value, format) => {
        if (format === 'bold') {
          return `<strong>${value}</strong>`
        }
        return value
      },
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler: (key) => {
      const message = `Missing translation key: ${key}`

      if (config.NODE_ENV !== 'production') {
        throw new Error(message)
      }

      return key
    },
  })
export default i18n
