import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { message } from 'antd/es'
import { useForm } from 'antd/lib/form/Form'

import moment from 'moment'

import { EmissionFactor, EmissionFactorWithIncludes } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Alert from '@/atoms/Alert'
import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { MAX_VALUE } from '@/constants/numericPrecision'
import { useAppContext } from '@/contexts/app'

import classes from './classes.module.less'

interface FactorProps {
  selectedFactor?: EmissionFactorWithIncludes
  updateCustomFactor: (id: number, values: Partial<EmissionFactorWithIncludes>) => Promise<void>
  createCustomFactor: (
    factor: EmissionFactor,
    values: Partial<EmissionFactorWithIncludes>,
  ) => Promise<void>
  isVisible: boolean
  onClose: () => void
}

function CustomFactorModal({
  selectedFactor,
  updateCustomFactor,
  isVisible,
  onClose,
  createCustomFactor,
}: FactorProps): JSX.Element {
  const { t } = useTranslation('common')
  const [form] = useForm()
  const { getSuppliers, suppliers } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const [emissionFactorValue, setEmissionFactorValue] = useState<number>(0)

  useEffect(() => {
    if (isVisible) {
      getSuppliers()
    }
    form.resetFields()
  }, [isVisible])

  useEffect(() => {
    form.setFieldsValue({
      ...selectedFactor,
      ...(selectedFactor?.validityStartDate
        ? { validityStartDate: moment(selectedFactor?.validityStartDate) }
        : moment()),
      ...(selectedFactor?.usageEndDate
        ? { usageEndDate: moment(selectedFactor?.usageEndDate) }
        : {}),
    })
  }, [selectedFactor])

  async function submitModal(values: Partial<EmissionFactor>): Promise<void> {
    try {
      setIsLoading(true)
      if (selectedFactor?.organizationId) {
        await updateCustomFactor(selectedFactor.id, values)
      } else if (selectedFactor) {
        await createCustomFactor(selectedFactor, values)
      }
      message.warning(t('factors.save-factor.success'))
    } catch (e) {
      message.error(t('factors.save-factor.error'))
    } finally {
      setIsLoading(false)
    }
  }

  const updateEfValue = useCallback((event: FormEvent<HTMLFormElement>): void => {
    setEmissionFactorValue(parseInt((event.target as HTMLInputElement).value))
  }, [])

  return (
    <Modal
      title={t('factors.modal-title')}
      visible={isVisible}
      onCancel={() => {
        form.resetFields()
        onClose()
      }}
      onOk={() => submitModal(form.getFieldsValue())}
      confirmLoading={isLoading}
    >
      <Alert>{t('factors.update-info')}</Alert>
      <Form
        form={form}
        onChange={updateEfValue}
        category={AnalyticsCategories.FACTORS}
        layout={'vertical'}
        initialValues={{
          ...selectedFactor,
          ...(selectedFactor?.validityStartDate
            ? { validityStartDate: moment(selectedFactor?.validityStartDate) }
            : moment()),
          ...(selectedFactor?.usageEndDate
            ? { usageEndDate: moment(selectedFactor?.usageEndDate) }
            : {}),
        }}
        onFinish={async (values) => submitModal(values)}
      >
        <Form.Item label={t('factors.description')} name={'description'} wrapperCol={{ span: 24 }}>
          <InputField />
        </Form.Item>
        <Form.Item label={t('factors.value')} name={'value'} wrapperCol={{ span: 24 }}>
          <InputField max={MAX_VALUE} type={'number'} style={{ width: '100%' }} />
        </Form.Item>
        {form.getFieldValue('value') < 0 && (
          <Alert type={'info'} className={classes.alert}>
            {<Text>{t('log.create.negative-input-warning')}</Text>}
          </Alert>
        )}
        <Form.Item
          label={
            <div className={classes.validityTooltipRoot}>
              <div>{t('factors.startDate')}</div>
              <Tooltip trigger={['hover']} title={t('factors.startDateTooltip')}>
                <HiOutlineInformationCircle />
              </Tooltip>
            </div>
          }
          name="validityStartDate"
          wrapperCol={{ span: 24 }}
        >
          <DatePicker
            format="YYYY"
            picker="year"
            defaultValue={moment()}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={
            <div className={classes.validityTooltipRoot}>
              <div>{t('factors.endDate')}</div>
              <Tooltip trigger={['hover']} title={t('factors.endDateTooltip')}>
                <HiOutlineInformationCircle />
              </Tooltip>
            </div>
          }
          name="usageEndDate"
          wrapperCol={{ span: 24 }}
        >
          <DatePicker
            style={{ width: '100%' }}
            picker="year"
            defaultValue={
              (selectedFactor?.usageEndDate && moment(selectedFactor.usageEndDate)) || undefined
            }
          />
        </Form.Item>
        <Form.Item name="supplierId" label={t('layout.suppliers')} wrapperCol={{ span: 24 }}>
          <Select
            style={{ width: '100%' }}
            showSearch
            dropdownMatchSelectWidth={false}
            size="middle"
            filterOption={(input, option) =>
              (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            options={[
              {
                label: t('none'),
                value: '',
              },
              ...suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.id,
              })),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CustomFactorModal
