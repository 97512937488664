import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd'
import { Image, Typography } from 'antd/es'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'

import { ProductTag, TableViewProductLog } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import useLog from '@/hooks/useLog'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'
import useImpactedProducts from './hooks/useImpactedProducts'

const OrganisationImpactedProductsTable = (): ReactElement => {
  const {
    products,
    setLogPage,
    setProductsPageSize,
    productsPage,
    productsPageSize,
    loadingProducts,
  } = useImpactedProducts()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getProductTags } = useLog()
  const [productTags, setProductTags] = useState<Map<number, ProductTag>>(new Map())

  useEffect(() => {
    getProductTags('').then((productTagsResult) => {
      const newMap = new Map<number, ProductTag>()
      productTagsResult.forEach((tag) => {
        newMap.set(tag.id, tag)
      })

      setProductTags(newMap)
    })
  }, [])

  const changeTable = (pagination: TablePaginationConfig): void => {
    if (pagination.current && pagination.current !== productsPage) {
      setLogPage(pagination.current)
    }
  }

  const navigateToProduct = useCallback(
    (row: TableViewProductLog) => {
      navigate(
        routes.log.carbonFootprint.products.productDetails.replace(':productId', row.id.toString()),
      )
    },
    [navigate],
  )

  const columns: ColumnsType<TableViewProductLog> = [
    {
      title: () => <span className={classes.columnTitle}>{t('product.productName')}</span>,
      dataIndex: 'product.name',
      key: 'product',
      sorter: false,
      render(_, product) {
        return (
          <span className={classes.row}>
            {product.image?.url && (
              <Image
                width={30}
                height={30}
                src={product.image?.url}
                preview={false}
                className={classes.icon}
              />
            )}
            <Typography.Text className={classes.tableContent}>
              {truncate(product.name as string, 20)}
            </Typography.Text>
          </span>
        )
      },
    },
    {
      title: () => <span className={classes.columnTitle}>{t('product.product-code')}</span>,
      dataIndex: 'product.externalId',
      key: 'productCode',
      sorter: false,
      render(_, product) {
        return (
          <span className={classes.row}>
            {product.externalId && (
              <Tag truncateText size="sm" className={classes.tableContent}>
                {product.externalId as string}
              </Tag>
            )}
          </span>
        )
      },
    },
    {
      title: () => <span className={classes.columnTitle}>{t('product.productType')}</span>,
      dataIndex: 'product.productType',
      key: 'productType',
      sorter: false,
      render(_, product) {
        return (
          <span className={classes.row}>
            {product.productType?.name && (
              <Tag className={classes.tableContent} truncateText size="sm">
                {product.productType?.name as string}
              </Tag>
            )}
          </span>
        )
      },
    },
    {
      title: () => <span className={classes.columnTitle}>{t('product.productTag')}</span>,
      dataIndex: 'tags',
      key: 'productTag',
      sorter: false,
      render(_, product) {
        if (!product.productTagIds?.length) {
          return <></>
        }
        return (
          <span className={classes.row}>
            {product.productTagIds.slice(0, 3).map((id: number) => (
              <Tag className={classes.tag} truncateText size="xs" key={id}>
                {productTags.get(id)?.name}
              </Tag>
            ))}
            {product.productTagIds.length > 3 && (
              <Tag className={classes.tag} truncateText size="xs">
                +{product.productTagIds.length - 3}
              </Tag>
            )}
          </span>
        )
      },
    },
    {
      title: () => <span className={classes.columnTitle}>{t('log.emissions-per-unit')}</span>,
      dataIndex: 'emissionsPerUnit',
      sorter: false,
      key: 'emissionsPerUnit',
      render(value) {
        return (
          <span>
            <Typography.Text className={classes.emissions}>
              {formatNumber(convertTonnesToGrams((value as number) ?? 0))}
            </Typography.Text>
          </span>
        )
      },
    },
    {
      title: () => <span className={classes.columnTitle}>{t('log.total-emissions')}</span>,
      dataIndex: 'totalEmissions',
      sorter: false,
      fixed: 'right',
      key: 'totalEmissions',
      render(value) {
        return <span className={classes.emissions}>{formatNumber((value as number) ?? 0)}</span>
      },
    },
  ]

  if (!products || products?.total === 0) {
    return <></>
  }

  return (
    <Row className={classes.tableWrapper}>
      <Text className={classes.tableHeader} fontWeight="medium" size="lg" color="secondary">
        {t('calculation-changes.impact-modal.organisation-products-title')}
      </Text>
      <Col span={24}>
        <Table
          dataSource={products?.data ?? []}
          loading={loadingProducts}
          rowKey="id"
          columns={columns}
          scroll={{ x: 'max-content' }}
          rowClassName={classes.tableRow}
          showWrapper={false}
          onChange={changeTable}
          onRow={(row) => ({
            onClick: () => navigateToProduct(row),
          })}
          pagination={{
            current: productsPage,
            defaultPageSize: productsPageSize,
            pageSize: productsPageSize,
            total: products?.total,
            onShowSizeChange: (_current, size) => {
              setProductsPageSize(size)
            },
          }}
        />
      </Col>
    </Row>
  )
}

export default OrganisationImpactedProductsTable
