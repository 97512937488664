import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { ChangelogAffectedLog, ClosedPeriod } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  selectCalculationChangelogRecord,
  useFindChangelogClosedPeriodsQuery,
  useFindChangelogLogsQuery,
} from '@/redux/methodologyChangelogs'

interface IUseImpactedLogs {
  mappedLogs: ILogTableContent[]
  closedPeriods: ClosedPeriod[]
  logsPage: number
  logsPageSize: number
  setLogPage: Dispatch<SetStateAction<number>>
  setLogsPageSize: Dispatch<SetStateAction<number>>
  closedPeriodsPage: number
  closedPeriodsPageSize: number
  setClosedPeriodsPage: Dispatch<SetStateAction<number>>
  setClosedPeriodsPageSize: Dispatch<SetStateAction<number>>
  loadingLogs: boolean
  loadingClosedPeriods: boolean
  totalLogs: number
  totalClosedPeriods: number
}

export interface ILogTableContent {
  id: number
  categoryName: string
  activityDataSources: string[]
  description: string | null
  locationName: string
  businessUnitName: string
  startDate: Date | null
  endDate: Date | null
  affectedEmissionSum: number | undefined
}

const useImpactedLogs = (): IUseImpactedLogs => {
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const [logsPage, setLogsPage] = useState(1)
  const [logsPageSize, setLogsPageSize] = useState(10)
  const [closedPeriodsPage, setClosedPeriodsPage] = useState(1)
  const [closedPeriodsPageSize, setClosedPeriodsPageSize] = useState(10)
  const { data: { data: logs = [], total: totalLogs = 0 } = {}, isFetching: loadingLogs } =
    useFindChangelogLogsQuery(
      {
        businessUnitId: selectedBusinessUnit!.id,
        globalChangelogId: selectedCalculationChangelogRecord?.methodologyChangelogGlobalId,
        subcategoryId: selectedCalculationChangelogRecord?.subcategoryId,
        page: logsPage,
        pageSize: logsPageSize,
      },
      {
        skip:
          !selectedBusinessUnit ||
          !selectedCalculationChangelogRecord?.methodologyChangelogGlobalId ||
          !selectedCalculationChangelogRecord?.subcategoryId,
      },
    )

  const {
    data: { docs: closedPeriods, totalRecords: totalClosedPeriods } = { docs: [], totalRecords: 0 },
    isFetching: loadingClosedPeriods,
  } = useFindChangelogClosedPeriodsQuery(
    {
      businessUnitId: selectedBusinessUnit!.id,
      globalChangelogId: selectedCalculationChangelogRecord?.methodologyChangelogGlobalId,
      subcategoryId: selectedCalculationChangelogRecord?.subcategoryId,
      page: logsPage,
      pageSize: logsPageSize,
    },
    {
      skip:
        !selectedBusinessUnit ||
        !selectedCalculationChangelogRecord?.methodologyChangelogGlobalId ||
        !selectedCalculationChangelogRecord?.subcategoryId,
    },
  )

  const mappedLogs: ILogTableContent[] = useMemo(() => {
    return (
      (logs.map((log: ChangelogAffectedLog) => {
        const { category, description, location, startDate, endDate, affectedEmissionSum, id } = log
        const businessUnitName = log.businessUnit?.title ?? ''
        const locationName = location?.name ?? ''
        return {
          id,
          categoryName: category.name,
          description,
          locationName,
          businessUnitName,
          startDate,
          endDate,
          affectedEmissionSum,
          activityDataSources: log.activityDataSources?.map((x) => x.name.toString()) ?? [],
        }
      }) as ILogTableContent[]) || []
    )
  }, [logs])

  return {
    mappedLogs,
    logsPage,
    logsPageSize,
    setLogPage: setLogsPage,
    setLogsPageSize,
    loadingLogs,
    loadingClosedPeriods,
    closedPeriods,
    setClosedPeriodsPage,
    setClosedPeriodsPageSize,
    closedPeriodsPage,
    closedPeriodsPageSize,
    totalLogs,
    totalClosedPeriods,
  }
}

export default useImpactedLogs
