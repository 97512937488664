import { CreatePresetFilterDto, PresetFilterDto } from '@cozero/dtos'
import { reportsApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import {
  DELETE_PRESET_FILTER,
  GET_PRESET_FILTERS,
  SAVE_PRESET_FILTER,
  TAG_PRESET_FILTERS,
  UPDATE_PRESET_FILTER,
} from './constants'

export type PartialPresetFilterWithId = Partial<PresetFilterDto> & Pick<PresetFilterDto, 'id'>

const presetFilterSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_PRESET_FILTERS]: builder.query<PresetFilterDto[], void>({
      providesTags: [TAG_PRESET_FILTERS],
      query: () => ({
        url: reportsApiGatewayClient.presetFilter.GET_MANY,
        method: 'GET',
      }),
    }),
    [SAVE_PRESET_FILTER]: builder.mutation<PresetFilterDto, CreatePresetFilterDto>({
      invalidatesTags: [TAG_PRESET_FILTERS],
      query: (data) => ({
        url: reportsApiGatewayClient.presetFilter.CREATE,
        method: 'POST',
        data,
      }),
    }),
    [DELETE_PRESET_FILTER]: builder.mutation<void, number>({
      invalidatesTags: [TAG_PRESET_FILTERS],
      query: (id) => ({
        url: reportsApiGatewayClient.presetFilter.DELETE_ONE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
    }),
    [UPDATE_PRESET_FILTER]: builder.mutation<PresetFilterDto, PartialPresetFilterWithId>({
      invalidatesTags: [TAG_PRESET_FILTERS],
      query: (data) => ({
        url: reportsApiGatewayClient.presetFilter.UPDATE_ONE.replace(':id', data.id.toString()),
        method: 'PUT',
        data,
      }),
    }),
  }),
})

export const {
  useGetPresetFiltersQuery,
  useDeletePresetFilterMutation,
  useSavePresetFilterMutation,
  useUpdatePresetFilterMutation,
} = presetFilterSlice

export default presetFilterSlice
