import React, { useMemo } from 'react'
import { HiOutlineCalendar } from 'react-icons/hi'

import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd/es'
import { SizeType } from 'antd/es/config-provider/SizeContext'

import classnames from 'classnames'
import moment, { Moment } from 'moment'

import { CINDER_BLUE_50 } from '@/styles/variables'

import DateRangePicker from '../DateRangePicker'

import classes from './DatePicker.module.less'

export interface IDatePickerProps {
  value?: (Moment | null | undefined) | ([Moment | null, Moment | null] | undefined)
  className?: string
  format?: string
  disabled?: boolean
  size?: SizeType
  onChange?: (date: moment.Moment | null, dateString: string) => void
  picker:
    | 'date'
    | 'month'
    | 'year'
    | 'year-range'
    | 'date-range'
    | 'month-range'
    | 'date-time-range'
  showTime?: boolean
  placeholder?: string
  disabledDate?: (date: Moment) => boolean
  defaultOpen?: boolean
  style?: React.CSSProperties
}

export type PickerProps = IDatePickerProps & Omit<DatePickerProps, 'picker'>

const DatePicker = ({
  picker,
  value,
  className,
  format,
  size = 'middle',
  disabled,
  placeholder,
  showTime,
  onChange,
  style,
  disabledDate,
  ...props
}: PickerProps): JSX.Element => {
  const classNames = useMemo(
    () => classnames([classes.DatePickerInput, className], { [classes[size]]: !!size }),
    [size, className],
  )

  const renderCustomDatePicker = useMemo(() => {
    switch (picker) {
      case 'date-time-range':
        return (
          <DateRangePicker
            format={format}
            style={style}
            showTime
            className={className}
            value={value as [Moment | null, Moment | null]}
          />
        )
      case 'year-range':
        return (
          <DateRangePicker
            picker="year"
            format={format}
            style={style}
            className={className}
            value={value as [Moment | null, Moment | null]}
          />
        )
      case 'month-range':
        return (
          <DateRangePicker
            picker="month"
            format={format}
            style={style}
            className={className}
            value={value as [Moment | null, Moment | null]}
          />
        )
      default:
        return (
          <DateRangePicker
            picker="year"
            format={format}
            style={style}
            value={value as [Moment | null, Moment | null]}
            className={className}
          />
        )
    }
  }, [picker])
  if (
    picker === 'year-range' ||
    picker === 'date-range' ||
    picker === 'month-range' ||
    picker === 'date-time-range'
  ) {
    return <>{renderCustomDatePicker}</>
  } else {
    return (
      <AntdDatePicker
        picker={picker}
        value={value}
        className={classNames}
        format={format}
        disabled={disabled}
        size={size}
        onChange={onChange}
        showTime={showTime}
        placeholder={placeholder}
        disabledDate={disabledDate}
        style={style}
        suffixIcon={<HiOutlineCalendar size="1.3em" color={CINDER_BLUE_50} />}
        {...props}
      />
    )
  }
}

export default DatePicker
