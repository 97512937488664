import _ from 'lodash'

import { GroupedMethodologyChangelog } from '@cozero/models'
import { CalculationEntryType } from '@cozero/services'

import { formatNumber } from './number'

interface ChangeOfEmissions {
  oldEmissions: number
  newEmissions: number
  percentageChange: number
  years: number[]
}

export function getChangeOfEmissions(
  changelog: GroupedMethodologyChangelog,
  emissionsType: CalculationEntryType,
): ChangeOfEmissions {
  const oldEmissions =
    emissionsType === 'organization'
      ? changelog.oldLogCarbonFootprint
      : changelog.oldProductCarbonFootprint
  const newEmissions =
    emissionsType === 'organization'
      ? changelog.logCarbonFootprint
      : changelog.productCarbonFootprint
  const years = _.uniq(_.flatMap(changelog?.affectedYears))
  const percentageChange = ((newEmissions - oldEmissions) / oldEmissions) * 100 ?? 0

  return {
    oldEmissions,
    newEmissions,
    percentageChange,
    years,
  }
}

export const formatChangePercentage = ({
  affectedLogCount,
  affectedProductCount,
  changePercentage,
}: {
  affectedLogCount: number
  affectedProductCount: number
  changePercentage: number
}): string => {
  // No changes on the emission values, but there might be changes on the scope, impact model, etc...
  let percentageChangeToPresent = `< 0.01%`
  if (changePercentage === 0) {
    // No affected entries
    if (!affectedLogCount && !affectedProductCount) {
      percentageChangeToPresent = '---'
    }
    // Changes on the emission values
  } else {
    percentageChangeToPresent = `${formatNumber(Math.abs(changePercentage))}%`
  }

  return percentageChangeToPresent
}

export function calculateEmissionValue(
  mass?: number | null,
  value?: number | null,
): number | null | undefined {
  return value && mass ? value / mass : value
}
