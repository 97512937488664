/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

import { RowProps, Space } from 'antd/es'

import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { keyBy } from 'lodash-es'

import { LogEntriesTableColumnKey } from '@cozero/models'

import Pill from '@/atoms/Pill'
import Text from '@/atoms/Text'

import { useTableColumns } from '../../hooks/useTableColumns'

import classes from './ColumnManagerModal.module.less'
import { TableColumnItem } from './TableColumnItem'

export type ManagedColumns = {
  visibleColumns: LogEntriesTableColumnKey[]
  hiddenColumns: LogEntriesTableColumnKey[]
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ManagedColumn = ({
  id,
  columnKeys,
  title,
  fixFirstAndLast,
}: {
  id: keyof ManagedColumns
  columnKeys: LogEntriesTableColumnKey[]
  justify?: RowProps['justify']
  title?: string
  fixFirstAndLast?: boolean
}) => {
  const { tableColumns } = useTableColumns()
  const { setNodeRef } = useDroppable({ id })

  const tableColumnsDict = React.useMemo(() => keyBy(tableColumns, 'key'), [tableColumns])

  return (
    <div ref={setNodeRef} style={{ width: '100%', minWidth: 160, height: '100%', flex: 1 }}>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Space>
          <Text size="xl" fontWeight="medium">
            {title}
          </Text>
          <Pill className={classes.countPill}>
            <Text className={classes.countPillText} fontWeight="medium">
              {columnKeys.length}
            </Text>
          </Pill>
        </Space>
        {fixFirstAndLast && (
          <TableColumnItem
            tableColumnKey={tableColumnsDict[columnKeys[0]].key}
            tableColumnTitle={tableColumnsDict[columnKeys[0]].title}
            disabled
          />
        )}
        <SortableContext id={id} items={columnKeys} strategy={verticalListSortingStrategy}>
          <Space direction="vertical" size={16} style={{ width: '100%', height: '100%' }}>
            {(fixFirstAndLast ? columnKeys.slice(1, -1) : columnKeys).map((key) => (
              <TableColumnItem
                key={key}
                tableColumnKey={tableColumnsDict[key].key}
                tableColumnTitle={
                  tableColumnsDict[key].columnManagerTitle ?? tableColumnsDict[key].title
                }
              />
            ))}
          </Space>
        </SortableContext>
        {fixFirstAndLast && (
          <TableColumnItem
            tableColumnKey={tableColumnsDict[columnKeys[columnKeys.length - 1]].key}
            tableColumnTitle={tableColumnsDict[columnKeys[columnKeys.length - 1]].title}
            disabled
          />
        )}
      </Space>
    </div>
  )
}
