import { Category } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_CATEGORIES } from './tags'

const GET_CATEGORIES = 'getCategories'
const GET_CATEGORIES_FOR_LOG_CREATION = 'getCategoriesForLogCreation'
const GET_CATEGORIES_WITH_LOGS = 'getCategoriesWithLogs'
const GET_CATEGORY = 'getCategory'
const GET_MODELED_IMPACT_CATEGORIES = 'getModeledImpactCategories'

const categoriesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_CATEGORIES]: builder.query<Category[], void>({
      query: () => ({
        url: logApiGatewayClient.categories.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
    [GET_CATEGORIES_FOR_LOG_CREATION]: builder.query<
      Category[],
      { onlyCategoriesWithLogs?: boolean; businessUnitId?: number }
    >({
      query: ({ onlyCategoriesWithLogs, businessUnitId }) => ({
        url: logApiGatewayClient.categories.GET_FOR_LOG_CREATION,
        method: 'GET',
        params: {
          onlyCategoriesWithLogs,
          businessUnitId,
        },
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
    [GET_CATEGORIES_WITH_LOGS]: builder.query<
      Category[],
      {
        onlyWithLogsAssociated?: boolean
        businessUnitId?: number
      }
    >({
      query: ({ onlyWithLogsAssociated, businessUnitId }) => ({
        url: logApiGatewayClient.categories.GET_MANY_WITH_LOGS,
        method: 'GET',
        params: {
          onlyWithLogsAssociated,
          businessUnitId,
        },
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
    [GET_CATEGORY]: builder.query<Category, number>({
      query: (id) => ({
        url: logApiGatewayClient.categories.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: TAG_CATEGORIES, id }],
    }),
    [GET_MODELED_IMPACT_CATEGORIES]: builder.query<number[], number>({
      query: (subcategoryId) => ({
        url: logApiGatewayClient.categories.GET_MODELED_IMPACT_CATEGORIES,
        method: 'GET',
        params: {
          subcategoryId,
        },
      }),
    }),
  }),
})

export const {
  useGetCategoriesQuery,
  useGetCategoriesForLogCreationQuery,
  useGetCategoriesWithLogsQuery,
  useGetCategoryQuery,
  useGetModeledImpactCategoriesQuery,
} = categoriesSlice

export default categoriesSlice
