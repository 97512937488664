import React from 'react'
import { useTranslation } from 'react-i18next'

import Dot from '@/atoms/Dot'
import Pill from '@/atoms/Pill'
import Tooltip from '@/atoms/Tooltip'

import { useLastUpdatedTime } from '../hooks/useLastUpdateTime'

import classes from './LastUpdatePill.module.less'

export const LastUpdatePill = (): JSX.Element | null => {
  const { t } = useTranslation('common')

  const time = useLastUpdatedTime()

  if (!time) {
    return null
  }

  return (
    <Tooltip showArrow content={t('log.log-entries-overview.last-update.tooltip')}>
      <Pill color="cinder-blue-10" className={classes.pill}>
        <div className={classes.content}>
          <Dot className={classes.dot} type="info" />
          <div>{t('log.log-entries-overview.last-update.time', { time })}</div>
        </div>
      </Pill>
    </Tooltip>
  )
}
