/* eslint react-hooks/exhaustive-deps: 2 */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'antd'

import Modal, { ModalProps } from '@/molecules/Modal'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useDeleteManyLogEntriesMutation } from '@/redux/logEntries'

import { RowSelectionResult } from '../../../hooks/useRowsSelection'

import classes from './LogEntryDeletionModal.module.less'
import { LogEntryDeletionModalType } from './LogEntryDeletionModal.types'
import { LogEntryDeletionModalContent } from './LogEntryDeletionModalContent'

type LogEntryDeletionModalProps = {
  open: boolean
  onSuccess: () => void
  onCancel: () => void
  selectedLogEntriesIds: RowSelectionResult['selectedLogEntriesIds']
}

export const LogEntryDeletionModal = ({
  selectedLogEntriesIds = [],
  open,
  onSuccess,
  onCancel,
}: LogEntryDeletionModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const { trackAction } = useMixpanel()

  const [deleteManyLogEntries, { isLoading, data, reset }] = useDeleteManyLogEntriesMutation()

  const [modalType, setModalType] = React.useState<LogEntryDeletionModalType>(
    LogEntryDeletionModalType.CONFIRMATION,
  )
  const [logEntriesCount, setLogEntriesCount] = React.useState<number>(0)

  const deleteLogEntries = React.useCallback(
    async (logEntries: number[] = []): Promise<void> => {
      trackAction(
        AnalyticsCategories.LOG_ENTRY_OVERVIEW,
        logEntries.length
          ? 'log-entry-overview-bulk-delete-partial-CP-limitations'
          : 'log-entry-overview-bulk-delete',
      )
      const logEntriesIds = logEntries.length ? logEntries : selectedLogEntriesIds
      const response = await deleteManyLogEntries({ logEntriesIds })
      if ('error' in response) {
        message.warning(t('log.log-entries-overview.actions.delete-selection.error-message'))
      }

      if ('data' in response) {
        if (response.data.success) {
          message.success(
            t('log.log-entries-overview.actions.delete-selection.success-message', {
              count: logEntriesIds.length,
            }),
          )
          onSuccess()
        } else {
          if (response.data.deletableLogEntriesIds?.length) {
            setLogEntriesCount(response.data.deletableLogEntriesIds?.length)
          }
          setModalType(
            response.data.deletableLogEntriesIds?.length
              ? LogEntryDeletionModalType.PARTIAL_CONFIRMATION
              : LogEntryDeletionModalType.CANNOT_DELETE_SELECTION,
          )
        }
      }
    },
    [trackAction, selectedLogEntriesIds, deleteManyLogEntries, t, onSuccess],
  )

  const validateLogEntriesDeletion = React.useCallback(async () => {
    const response = await deleteManyLogEntries({
      logEntriesIds: selectedLogEntriesIds,
      onlyValidate: true,
    })
    if ('data' in response) {
      if (response.data.success) {
        // do nothing
        return
      } else {
        if (response.data.deletableLogEntriesIds?.length) {
          setLogEntriesCount(response.data.deletableLogEntriesIds?.length)
        }
        setModalType(
          response.data.deletableLogEntriesIds?.length
            ? LogEntryDeletionModalType.PARTIAL_CONFIRMATION
            : LogEntryDeletionModalType.CANNOT_DELETE_SELECTION,
        )
      }
    } else {
      message.warning(t('log.log-entries-overview.actions.delete-selection.error-message'))
      onCancel()
    }
  }, [deleteManyLogEntries, onCancel, selectedLogEntriesIds, t])

  const firstRender = React.useRef<boolean>(true)
  // Reset modal state when closed
  React.useEffect(() => {
    if (!open) {
      setModalType(LogEntryDeletionModalType.CONFIRMATION)
      reset()
      firstRender.current = true
    } else {
      if (firstRender.current) {
        validateLogEntriesDeletion()
        firstRender.current = false
      }
    }
  }, [deleteLogEntries, reset, open, validateLogEntriesDeletion])

  // Set total log entries on props change
  React.useEffect(() => {
    setLogEntriesCount(selectedLogEntriesIds?.length ?? 0)
  }, [selectedLogEntriesIds])

  const modalTypeVariantProps: Partial<ModalProps> = useMemo(() => {
    if (modalType === LogEntryDeletionModalType.CANNOT_DELETE_SELECTION) {
      return {
        title: t('log.log-entries-overview.actions.delete-selection.deletion-not-possible'),
        cancelText: t('log.log-entries-overview.actions.delete-selection.go-back'),
        okButtonProps: {
          color: 'blue',
        },
      }
    } else {
      return {
        title: t('log.log-entries-overview.actions.delete-selection.confirm-selection'),
        onOk: () => deleteLogEntries(data?.deletableLogEntriesIds),
        okButtonProps: {
          color: 'danger',
        },
      }
    }
  }, [data?.deletableLogEntriesIds, deleteLogEntries, modalType, t])

  return (
    <Modal
      className={classes.modal}
      noBodyPadding
      open={open}
      centered
      onCancel={onCancel}
      okText={t('log.log-entries-overview.actions.delete-selection.ok-text')}
      confirmLoading={isLoading}
      {...modalTypeVariantProps}
    >
      <div className={classes.wrapper}>
        <LogEntryDeletionModalContent logEntriesCount={logEntriesCount} modalType={modalType} />
      </div>
    </Modal>
  )
}
