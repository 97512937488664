/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useSelector } from 'react-redux'

import { uniqBy } from 'lodash-es'

import { User } from '@cozero/models'

import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'

import { SearchHookProps } from './types'

const validateSearch = (item: User, search: string): boolean => {
  if (!search?.length) {
    // defaults to true if no search value (show all)
    return true
  }

  // Ensure the value exists and is a string before proceeding
  return `${item.firstName} ${item.lastName ?? ''}`.toLowerCase().includes(search.toLowerCase())
}

export const useGetOrganizationUsersSearch = (): SearchHookProps => {
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { data, isFetching } = useGetOrganizationUsersQuery({
    businessUnitId: selectedBusinessUnit?.id ?? -1,
  })

  const [search, setSearch] = React.useState<string>('')

  const options = React.useMemo(
    () =>
      uniqBy(data, 'id')
        .filter((item) => validateSearch(item, search))
        .map((item) => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName ?? ''}`,
        })),
    [data, search],
  )

  return {
    onSearch: setSearch,
    options,
    isFetching,
  }
}
