import { RootState } from '..'
import { createSelector } from '@reduxjs/toolkit'

import { StrategyBuilderState } from './slice'

export const selectStrategyBuilderState = (state: RootState): StrategyBuilderState =>
  state.strategyBuilder

export const selectForecastDrawerOpen = createSelector(
  [selectStrategyBuilderState],
  (state) => state.forecastDrawerOpen,
)

export const selectIsAlertHidden = createSelector(
  [selectStrategyBuilderState],
  (state) => state.isAlertHidden,
)
