import React from 'react'
import { useParams } from 'react-router-dom'

import { Skeleton } from 'antd'

import { useFindFactorRequestByIdQuery } from '@/redux/factors-requests'

export const SupplierRequestCrumb = (): React.ReactElement => {
  const { id } = useParams()
  const { data: factorRequest, isLoading } = useFindFactorRequestByIdQuery(id as string, {
    skip: !id,
  })

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }

  return <>{factorRequest?.activityDataSource?.name}</>
}
