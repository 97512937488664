import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FacetOptions } from '@antv/g2plot'
import { ChartPivotRow, SeriesNamesColumn } from '@cubejs-client/core'
import { FacetChart } from '@opd/g2plot-react'
import truncate from 'lodash/truncate'
import moment from 'moment'

import { ReportType } from '@cozero/models'

import { useGetGraphMeasureUnitTitle } from '@/hooks/useGetGraphMeasureUnitTitle'
import useGraphs from '@/hooks/useGraphs'
import {
  axisTextStyle,
  columnStyles,
  legendStyles,
  xAxisStyles,
  yAxisStyles,
} from '@/styles/graphs'
import { formatNumber } from '@/utils/number'

interface DataEntry {
  split: string
  x: string
  value: number
  type: string
}

function prefillMissingData(data: DataEntry[]): DataEntry[] {
  function unique<T extends keyof DataEntry>(prop: T): Array<DataEntry[T]> {
    return Array.from(new Set(data.map((item) => item[prop])))
  }

  // Extract unique splits, dates, and types
  const splits = unique('split')
  const dates = unique('x')
  const types = unique('type')

  // Create a map to manage entries and fill missing ones
  const dataMap = new Map<string, DataEntry>()
  data.forEach((item) => {
    const key = `${item.split}-${item.x}-${item.type}`
    dataMap.set(key, item)
  })

  // Ensure all combinations are represented in the map
  for (const split of splits) {
    for (const date of dates) {
      for (const type of types) {
        const key = `${split}-${date}-${type}`
        if (!dataMap.has(key)) {
          dataMap.set(key, { split, x: date, type, value: 0 })
        }
      }
    }
  }

  // Convert map values to an array and sort by date
  const result = Array.from(dataMap.values())
  if (dates.every((date) => moment(date).isValid())) {
    result.sort((a, b) => moment(a.x).diff(moment(b.x)))
  }

  return result
}

const FacetStackedBar = React.memo(
  ({
    height,
    width,
    chartData,
    keys,
    visible,
    switchAxis,
    dimensionType,
    showLegend,
  }: {
    height: number | null
    width: number | null
    chartData: ChartPivotRow[]
    keys: SeriesNamesColumn[]
    visible: boolean
    switchAxis: boolean
    dimensionType?: string
    showLegend: boolean
  }): ReactElement | null => {
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.FACET_STACKED_BAR,
      columns: keys,
      dimensionType,
    })
    const [facetProps, setFacetProps] = useState<FacetOptions>()
    const { t, i18n } = useTranslation('common')
    const { getGraphMeasureUnitTitle } = useGetGraphMeasureUnitTitle()
    const mappedChartData = useMemo(() => {
      const result: DataEntry[] = chartData
        .map((datum) =>
          keys
            .filter(
              (x) =>
                x.key.split(',').includes(`${datum.xValues[1]}`) &&
                x.key.split(',').includes(`${datum.xValues[2]}`),
            )
            .map((key) => ({
              split: key.yValues[0], // REgion
              x: datum.xValues.at(0) || '', // DAte
              value: datum[key.yValues.at(-1) ?? ''], // number
              type: datum.xValues.at(-1) || '', // SCOPE
            })),
        )
        .flat()
      return prefillMissingData(result)
    }, [chartData, keys])

    useEffect(() => {
      const props: FacetOptions = {
        eachView: (innerView, facet) => {
          return {
            type: 'column',
            options: {
              yField: 'value',
              autoFit: true,
              ...graphStyles,
              data: facet?.data ?? [],
              xField: switchAxis ? 'type' : 'x',
              seriesField: switchAxis ? 'x' : 'type',
              isStack: true,
              columnStyle: columnStyles,
              xAxis: {
                label: {
                  formatter: (item) =>
                    moment(item).isValid() && item.match(/^\d/)
                      ? moment(item).format('YYYY')
                      : truncate(item, { length: 24 }),
                  autoHide: true,
                  autoRotate: false,
                  style: {
                    ...xAxisStyles.label?.style,
                  },
                },
              },
              yAxis: {
                ...yAxisStyles,
                title: {
                  style: yAxisStyles.title?.style,
                  text: getGraphMeasureUnitTitle(keys?.[0].key),
                },
              },
            },
          }
        },
        appendPadding: [20, 20, 10, 60],
        scrollbar: true,
        slider: true,
        meta: {
          x: {
            sync: true,
          },
          type: {
            sync: true,
          },
          value: {
            sync: true,
          },
        },
        data: mappedChartData,
        legend: showLegend
          ? {
              ...legendStyles,
              slidable: true,
              label: {
                formatter: (item: string | number | null) => {
                  return `${
                    moment(item, moment.ISO_8601, true).isValid() ? moment(item).year() : item
                  }`
                },
              },
              itemName: {
                style: legendStyles.itemName?.style,
                formatter: (item: string | number | null) => {
                  return `${
                    moment(item, moment.ISO_8601, true).isValid() ? moment(item).year() : item
                  }`
                },
              },
            }
          : false,
        columnTitle: {
          style: axisTextStyle,
          formatter: (title) => truncate(title, { length: 15 }),
        },
        tooltip: {
          domStyles: customTooltip.domStyles,
          title: (title) => {
            return `${
              moment(title, moment.ISO_8601, true).isValid() ? moment(title).year() : title
            }`
          },
          customItems: (items) => {
            return items.map(({ data, ...rest }) => {
              let name = data.type || data.x
              if (switchAxis) {
                name = data.x
              }
              return {
                ...rest,
                data,
                name: `${
                  moment(name, moment.ISO_8601, true).isValid() ? moment(name).year() : name
                }`,
                value: formatNumber(data.value),
              }
            })
          },
          customContent: customTooltip.customContent,
          formatter: (item) => {
            let x = item.x
            let name = item.type || item.x
            if (switchAxis) {
              x = item.type
              name = item.x
            }
            return {
              name: `${moment(name, moment.ISO_8601, true).isValid() ? moment(name).year() : name}`,
              value: item.value,
              title: `${moment(x, moment.ISO_8601, true).isValid() ? moment(x).year() : x}`,
            }
          },
        },
        type: 'rect',
        fields: ['split'],
      }
      setFacetProps(props)
    }, [chartData, switchAxis, dimensionType, showLegend, graphStyles])

    if (!height) {
      return null
    }
    if (!facetProps) {
      return <div />
    }

    const minWidth = Array.from(new Set(keys.flatMap((key) => key.yValues[0]))).length * 200

    return (
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <div style={{ width: minWidth > (width ?? 0) ? minWidth : width ?? '100%' }}>
          <FacetChart
            height={height}
            {...(facetProps as FacetOptions)}
            style={{ display: visible ? 'block' : 'none' }}
            renderer={'canvas'}
          />
          <FacetChart
            height={height}
            {...(facetProps as FacetOptions)}
            style={{ display: 'none' }}
            renderer={'svg'}
            width={1000}
          />
        </div>
      </div>
    )
  },
)

FacetStackedBar.displayName = 'FacetStackedBar'

export default FacetStackedBar
