import React from 'react'
import { Provider } from 'react-redux'

import { PersistGate } from 'reduxjs-toolkit-persist/lib/integration/react'
import { SWRConfig } from 'swr'

import { useMutedLogs } from './App.useMutedLogs'
import { BaseLayout } from './BaseLayout'
import AntDesignConfigProvider from './contexts/ant-design'
import store, { persistedStore } from './redux'
import ErrorBoundary from './templates/ErrorBoundary'
import { AppErrorFallback } from './templates/ErrorFallback/AppErrorFallback'
import fetcher from './utils/swr-fetcher'

export const App = (): JSX.Element => {
  useMutedLogs()

  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <SWRConfig
            value={{
              fetcher,
              revalidateOnFocus: false,
            }}
          >
            <AntDesignConfigProvider>
              <BaseLayout />
            </AntDesignConfigProvider>
          </SWRConfig>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
}
