import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'

import LOCATION_COMPLETE from '@/assets/location-complete.svg'
import LOG_COMPLETE from '@/assets/log-complete.svg'
import ONBOARDING_COMPLETE from '@/assets/onboarding-complete.svg'
import USER_COMPLETE from '@/assets/user-complete.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { config } from '@/utils/config'

import classes from './OnboardingStepModal.module.less'

export const onboardingModalStyle = {
  icon: null,
  maskClosable: false,
  bodyStyle: {
    padding: 0,
    margin: 0,
  },
  okButtonProps: {
    style: {
      display: 'none',
    },
  },
}

const OnboardingStepModal = ({ stepKey }: { stepKey: string }): ReactElement => {
  const { t } = useTranslation('common')
  const Image = useMemo(() => {
    switch (stepKey) {
      case 'users':
        return <img src={USER_COMPLETE} />
      case 'locations':
        return <img src={LOCATION_COMPLETE} />
      case 'logs':
        return <img src={LOG_COMPLETE} />
      case 'final':
        return <img src={ONBOARDING_COMPLETE} />
    }
  }, [stepKey])

  return (
    <div className={classes.container}>
      {Image}
      <span className={classes.title}>
        {stepKey !== 'final'
          ? t('onboarding.success.titleWithItem', {
              item: t(`onboarding.success.${stepKey}`),
            })
          : t('onboarding.success.final')}
      </span>
      <div className={classes.buttonContainer}>
        <Button
          type="secondary"
          category={AnalyticsCategories.ONBOARDING}
          action="go-back-to-onboarding"
          onClick={() => (location.href = routes.onboarding)}
          data-cy="go-back-to-onboarding"
          htmlType="submit"
        >
          {t('onboarding.go-back-to-onboarding')}
        </Button>
        <Button
          type="primary"
          category={AnalyticsCategories.ONBOARDING}
          action="close modal"
          onClick={() => Modal.destroyAll()}
          data-cy="success-button"
        >
          {t('actions.continue')}
        </Button>
      </div>
    </div>
  )
}

export default OnboardingStepModal
