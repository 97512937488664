import React from 'react'
import { useLocation } from 'react-router-dom'

import { config } from './config'

export function getUrl(path: string): URL {
  return new URL(`${config.API_URL}${path}`)
}

export function appendSearchParam(route: string, key: string, value: string): string {
  const keyValuePair = `${key}=${value}`,
    delimiter = route.includes('?') ? '&' : '?'

  return `${route}${delimiter}${keyValuePair}`
}

export const areURLSearchParamsDistinct = (a: URLSearchParams, b: URLSearchParams): boolean => {
  const aSortedEntries = Array.from(a.entries()).sort()
  const bSortedEntries = Array.from(b.entries()).sort()

  return JSON.stringify(aSortedEntries) !== JSON.stringify(bSortedEntries)
}

export const useQueryParams = <T>(): T => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const params = new URLSearchParams(search)
    return Object.fromEntries(params.entries())
  }, [search]) as unknown as T
}
