import moment from 'moment'

type DateRangeValue = [moment.Moment | null, moment.Moment | null]
export type DateRanges = Record<string, DateRangeValue>

const DEFAULT_YEARS_TO_SHOW = 5
export const getPredefinedDateRanges = (
  value: DateRangeValue | null,
  numberOfYearsToShow = DEFAULT_YEARS_TO_SHOW,
): DateRanges => {
  const predefinedDateRanges: DateRanges = {}
  for (let i = 0; i < numberOfYearsToShow; i++) {
    const yearMoment = moment().subtract(i, 'years')
    const yearLabel = yearMoment.format('YYYY')
    predefinedDateRanges[yearLabel] = [
      yearMoment.clone().startOf('year'),
      yearMoment.clone().endOf('year'),
    ]
  }
  if (value) {
    predefinedDateRanges.Clear = [null, null]
  }
  return predefinedDateRanges
}

export const getDefaultDateRanges = (): DateRanges => {
  const rangesAux: DateRanges = {}
  rangesAux['Year-to-Date'] = [moment().utc().startOf('year'), moment().utc()]
  rangesAux['Last 12 months'] = [moment().utc().subtract(12, 'months'), moment().utc()]
  for (let i = 3; i >= 0; i--) {
    rangesAux[moment().subtract(i, 'year').year()] = [
      moment().utc().subtract(i, 'year').startOf('year').utc(),
      moment().utc().subtract(i, 'year').endOf('year').utc(),
    ]
  }
  return rangesAux
}
