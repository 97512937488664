import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import { ErrorBoundaryProps, ErrorBoundaryState, FallbackErrorType } from './types'
import { withRouter } from './withRouter'

const initialState: ErrorBoundaryState = {
  error: null,
  type: FallbackErrorType.GENERAL,
}

const isDynamicImportError = (error: Error): boolean => {
  return (
    error.message.includes('Failed to fetch dynamically imported module') || // WebKit
    error.message.includes('error loading dynamically imported module') || // Firefox
    error.message.includes('Importing a module script failed') // Safari
  )
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state = initialState

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    if (isDynamicImportError(error)) {
      // return an error that can be used in the render method to rendern a specific error message
      return { error, type: FallbackErrorType.UPDATE }
    }

    return { error, type: FallbackErrorType.GENERAL }
  }

  public componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
    const { error } = this.state

    // If the user navigates, reset the error state
    if (
      error !== null &&
      prevState.error !== null &&
      this.props.location &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.setState({ error: null })
    }
  }

  public render(): ReactNode {
    if (this.state.error !== null) {
      const { FallbackComponent, renderFallbackUi } = this.props

      if (typeof renderFallbackUi === 'function') {
        return renderFallbackUi({ error: this.state.error, type: this.state.type })
      }

      if (FallbackComponent) {
        return <FallbackComponent error={this.state.error} type={this.state.type} />
      }

      throw new Error('No fallback component or renderFallbackUi function provided')
    }

    return this.props.children
  }
}

export default withTranslation()(withRouter(ErrorBoundary))
