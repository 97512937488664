import moment from 'moment'

/**
 * Calculates the estimated time of the last cron job update
 * @param date The reference date to calculate from
 * @param options
 * @param options.executionTime The estimated execution time in minutes
 * @param options.frequency The frequency of the cron job in minutes
 * @returns The estimated time of the last cron update
 */
export const getEstimatedLastCronUpdate = (
  date: Date,
  {
    executionTime,
    frequency,
  }: {
    executionTime: number
    frequency: number
  },
): Date => {
  const origMoment = moment(date)

  const targetTime = origMoment
    // cap to cronjob frequency
    .minutes(Math.floor(origMoment.minutes() / frequency) * frequency)
    .add(executionTime, 'minutes')

  while (targetTime.isAfter(date)) {
    targetTime.subtract(10, 'minutes')
  }

  return targetTime.toDate()
}

export const formatTime = (date: Date, locale?: string): string => {
  return date.toLocaleTimeString(locale || 'en', {
    hour: '2-digit',
    minute: '2-digit',
  })
}
