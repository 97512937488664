import { kebabCase } from 'lodash-es'

import i18n from '../i18n'

export function capitalize(string: string): string {
  if (!string) {
    return ''
  }
  return string.replace(/^\w/, (c: string) => c.toUpperCase())
}

export function truncate(string: string, maxLength = 20): string {
  if (!string) {
    return ''
  }
  if (string.length <= maxLength) {
    return string
  }
  return `${string.substring(0, maxLength)}...`
}

export function randomString(length: number): string {
  return [...Array(length)].map(() => Math.random().toString(36)[2]).join('')
}

export function generateObjectId(): string {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16)
      })
      .toLowerCase()
  )
}

/**
 * A helper to slugify any string
 * @param text the text to be slugified
 * @returns a slugified string
 */
export function slugify(text: string): string {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

/**
 * A helper method to test if a url is valid.
 * @param str the url to test
 * @returns {bolean} if the url is valid
 */
export function isValidURL(str: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator

  return !!pattern.test(str)
}

/**
 * A helper to create grammatically correct sentences of multiple strings
 * @param arr the array of the string to join
 * @returns a full grammatically correct sentence of strings
 */
export const arrayToSentence = (arr: string[], andChar?: string): string => {
  return arr
    ?.reduce(function (a, b, c) {
      return a + b + (c === arr.length - 2 ? ` ${andChar ?? i18n.t('and')} ` : ', ')
    }, '')
    .slice(0, -2)
}

/**
 * Removes spaces and symbols at the beginning and end of a string (e.g "!!   United Kingdom! !" -> "United Kingdom")
 * @param text
 * @returns trimmedText
 */
export function removeSpacesAndSymbols(text: string): string {
  return text.replace(/^[^\w\d]+|[^\w\d]+$/g, '')
}

export function getDataCy(title: string | React.ReactElement, fallback: string): string {
  if (typeof title === 'string') {
    return kebabCase(title)
  }
  return kebabCase(fallback)
}
