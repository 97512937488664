import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { OrganizationCarboonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import { DEFAULT_STATE, SLICE_NAME, SelectedRows } from './constants'

const logEntriesOverviewSlice = createSlice({
  name: SLICE_NAME,
  initialState: DEFAULT_STATE,
  reducers: {
    setLastUpdateDate: (state, action: PayloadAction<Date | undefined>) => {
      state.lastUpdateDate = action.payload
    },
    setOrganizationEmissionsFilters: (
      state,
      action: PayloadAction<OrganizationCarboonFootprintFormValues>,
    ) => {
      if (state.filters) {
        state.filters = action.payload
      }
    },
    resetOrganizationEmissionsFilters: (state) => {
      state.filters = DEFAULT_STATE.filters
    },
    setSelectedRows: (state, action: PayloadAction<SelectedRows>) => {
      state.selectedRows = action.payload
      return state
    },
    resetSelectedRows: (state) => {
      state.selectedRows = DEFAULT_STATE.selectedRows
      return state
    },
  },
})

export const {
  setLastUpdateDate,
  setOrganizationEmissionsFilters,
  resetOrganizationEmissionsFilters,
  setSelectedRows,
  resetSelectedRows,
} = logEntriesOverviewSlice.actions

export default logEntriesOverviewSlice
