import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ActGraphs } from '@/types/act'

import { SLICE_NAME_ACT_GRAPHS } from './constants'

type ClimateActionsState = {
  strategyBuilderSelectedGraph: ActGraphs
}

const initialState: ClimateActionsState = {
  strategyBuilderSelectedGraph: ActGraphs.CONSOLIDATED_GRAPH,
}

const actGraphsSlice = createSlice({
  name: SLICE_NAME_ACT_GRAPHS,
  initialState,
  reducers: {
    setStrategyBuilderSelectedGraph: (state, action: PayloadAction<ActGraphs>) => {
      state.strategyBuilderSelectedGraph = action.payload
    },
  },
})

export const { setStrategyBuilderSelectedGraph } = actGraphsSlice.actions

export default actGraphsSlice
