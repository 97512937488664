/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useMemo } from 'react'

import { TablePaginationConfig } from 'antd/es'

import { SearchParamsArgs } from './types'

const DEFAULT_PAGE_SIZE = 15
const DEFAULT_PAGE = 1

type UsePaginationArgs = SearchParamsArgs

type UsePaginationReturn = TablePaginationConfig & {
  onChange: (pagination: TablePaginationConfig) => void
}

export const usePagination = ({
  searchParams,
  setSearchParams,
}: UsePaginationArgs): UsePaginationReturn => {
  const { pageSize, currentPage } = useMemo(
    () => ({
      pageSize: Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE,
      currentPage: Number(searchParams.get('currentPage')) || DEFAULT_PAGE,
    }),
    [searchParams],
  )

  const onChange = React.useCallback(
    (pagination: TablePaginationConfig): void => {
      if (pagination.current && pagination.current !== currentPage) {
        setSearchParams((prev) => {
          prev.set('currentPage', String(pagination.current))
          return prev
        })
      }
    },
    [currentPage, setSearchParams],
  )

  React.useEffect(() => {
    const searchParamsProperties = Array.from(searchParams.entries())

    if (!searchParamsProperties.some(([key]) => key === 'currentPage')) {
      setSearchParams((prev) => {
        prev.set('currentPage', String(DEFAULT_PAGE))
        return prev
      })
    }
    if (!searchParamsProperties.some(([key]) => key === 'pageSize')) {
      setSearchParams((prev) => {
        prev.set('pageSize', String(DEFAULT_PAGE_SIZE))
        return prev
      })
    }
  }, [searchParams, setSearchParams])

  return {
    onChange,
    current: currentPage,
    pageSize,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    hideOnSinglePage: true,
    onShowSizeChange: (_current, size) => {
      setSearchParams((prev) => {
        prev.set('pageSize', String(size))
        return prev
      })
    },
  }
}
