import React from 'react'
import { useTranslation } from 'react-i18next'

import { Spin } from 'antd/es'
import { DefaultOptionType } from 'antd/lib/select'

import { debounce } from 'lodash-es'

import Select, { SelectOptionsProperties, SelectProps } from '@/atoms/Select'

import classes from './FiltersDrawer.module.less'

type Props = SelectProps & {
  multipleAllowed?: boolean
  /**
   * The `SearchSelect` component operates in two different modes:
   *
   * 1. **Dynamic Search Mode**: When the user types, a search is triggered, and the options are dynamically fetched based on the input value.
   *
   * 2. **Preloaded Options Mode**: All available options are fetched and loaded at the start. When the user types, the component filters through the preloaded options instead of making a new search request.
   *
   * The `preloadedOptionsMode` flag controls this behavior. If set to `true`, all options are loaded when the component is initialized, otherwise, they are fetched during typing.
   */
  preloadedOptionsMode?: boolean
  onSearch?: (value: string) => void
  options?:
    | (SelectOptionsProperties & { sublabel?: string })[]
    | (DefaultOptionType & { sublabel?: string })[]
}
export const SearchSelect = ({
  multipleAllowed,
  preloadedOptionsMode = false,
  value,
  onChange,
  onSearch,
  loading,
  options,
  ...props
}: Props): React.ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Select
      {...props}
      showSearch
      className={classes.select}
      mode={multipleAllowed ? 'multiple' : undefined}
      placeholder={props.placeholder || t('log.filter.search')}
      value={value}
      onChange={(value, option) => {
        onSearch?.('')
        onChange?.(value, option)
      }}
      onSearch={debounce((term) => onSearch?.(term), 500)}
      dropdownRender={(menu) => {
        if (loading) {
          return (
            <span className={classes.dropdownContainer}>
              <Spin />
            </span>
          )
        }
        return options?.length || preloadedOptionsMode ? (
          menu
        ) : (
          <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
        )
      }}
      options={options}
    />
  )
}
