import { RcFile } from 'antd/es/upload'

import {
  CreatePcfBulkImportDto,
  RequestPcfBulkImportTemplateDto,
  UpdatePcfBulkImportDto,
} from '@cozero/dtos'
import { PcfBulkImportTableData, Product, PutSignedUrl } from '@cozero/models'
import { BulkImportSorter } from '@cozero/models'
import { Page } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { ProductConfigurationValues } from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import { providesList } from '@/utils/redux'

import { UploadProductConfigurationParams } from '../../hooks/useProducts'
import axios from '../../utils/axios'
import apiSlice from '../api'
import { LIST } from '../types'

import {
  CREATE_PCF_BULK_IMPORT,
  CREATE_PRODUCT,
  DELETE_ONE_BULK_IMPORT,
  GET_ACTIVE_PRODUCTS,
  GET_ONE_PCF_BULK_IMPORT,
  GET_ONE_PCF_BULK_IMPORT_FILES_URLS,
  GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS,
  GET_PCF_BULK_IMPORTS,
  GET_PCF_SIGNED_URL,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_COUNT,
  REQUEST_PCF_TEMPLATE,
  TAG_ACTIVE_PRODUCTS,
  TAG_PCF_BULK_IMPORTS,
  TAG_PRODUCT,
  UPDATE_ONE_PCF_BULK_IMPORT,
  UPLOAD_BI_TO_SIGNED_URL,
} from './constants'

const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_PRODUCT]: builder.query<Product | undefined, number | string>({
      query: (id) => ({
        url: centralApiGatewayClient.products.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_PRODUCT, id: result?.id }],
    }),
    [GET_PCF_BULK_IMPORTS]: builder.query<
      Page<PcfBulkImportTableData>,
      { sorters: BulkImportSorter[]; page: number; pageSize: number }
    >({
      query: ({ sorters, page, pageSize }) => {
        return {
          url: centralApiGatewayClient.pcfBulkImports.GET_BULK_IMPORTS,
          method: 'GET',
          params: {
            sorters,
            page,
            pageSize,
          },
        }
      },
      providesTags: (result) =>
        providesList<PcfBulkImportTableData[], typeof TAG_PCF_BULK_IMPORTS>(
          result?.data,
          TAG_PCF_BULK_IMPORTS,
        ),
    }),
    [GET_ONE_PCF_BULK_IMPORT]: builder.query<PcfBulkImportTableData, number | string>({
      query: (id) => {
        return {
          url: centralApiGatewayClient.pcfBulkImports.GET_ONE_BULK_IMPORT.replace(
            ':id',
            id.toString(),
          ),
          method: 'GET',
        }
      },
      providesTags: (result) => [{ type: TAG_PCF_BULK_IMPORTS, id: result?.id }],
    }),
    [GET_ONE_PCF_BULK_IMPORT_FILES_URLS]: builder.query<PutSignedUrl[], number | string>({
      query: (id) => {
        return {
          url: centralApiGatewayClient.pcfBulkImports.GET_UPLOADED_FILES_SIGNED_URL.replace(
            ':id',
            id.toString(),
          ),
          method: 'GET',
        }
      },
    }),
    [CREATE_PRODUCT]: builder.query<Product | undefined, ProductConfigurationValues>({
      query: (data) => ({
        url: centralApiGatewayClient.products.CREATE,
        method: 'POST',
        params: {
          data,
          ...(data.photo ? { photo: (data.photo as UploadProductConfigurationParams).path } : {}),
        },
      }),
    }),
    [REQUEST_PCF_TEMPLATE]: builder.mutation<undefined, RequestPcfBulkImportTemplateDto>({
      query: (data) => ({
        url: centralApiGatewayClient.pcfBulkImports.REQUEST_TEMPLATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: TAG_PCF_BULK_IMPORTS, id: LIST }],
    }),
    [CREATE_PCF_BULK_IMPORT]: builder.mutation<PcfBulkImportTableData, CreatePcfBulkImportDto>({
      query: (data) => ({
        url: centralApiGatewayClient.pcfBulkImports.CREATE_BULK_IMPORT,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: TAG_PCF_BULK_IMPORTS, id: LIST }],
    }),
    [GET_ACTIVE_PRODUCTS]: builder.query<
      Product[] | undefined,
      { businessUnitKey: string; search?: string }
    >({
      query: ({ businessUnitKey, search }) => ({
        url: centralApiGatewayClient.products.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter: true,
        },
      }),
      providesTags: (result) =>
        providesList<Product[], typeof TAG_ACTIVE_PRODUCTS>(result, TAG_ACTIVE_PRODUCTS),
    }),
    [GET_PRODUCTS]: builder.query<
      Product[] | undefined,
      { businessUnitKey: string; activeFilter?: boolean; search?: string; root?: boolean }
    >({
      query: ({ businessUnitKey, search, activeFilter, root }) => ({
        url: centralApiGatewayClient.products.SEARCH,
        method: 'POST',
        params: {
          businessUnitKey,
          search,
          activeFilter,
          root,
        },
      }),
      providesTags: (result) =>
        providesList<Product[], typeof TAG_ACTIVE_PRODUCTS>(result, TAG_ACTIVE_PRODUCTS),
    }),
    [GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS]: builder.query<number, void>({
      query: () => ({
        url: centralApiGatewayClient.products.ORGANIZATION_TOTAL_PRODUCT_EMISSIONS,
        method: 'GET',
      }),
    }),
    [GET_PRODUCT_COUNT]: builder.query<number, void>({
      query: () => ({
        url: centralApiGatewayClient.products.COUNT,
        method: 'GET',
      }),
    }),
    [GET_PCF_SIGNED_URL]: builder.mutation<PutSignedUrl[], string[]>({
      query: (fileNames) => ({
        url: centralApiGatewayClient.pcfBulkImports.GET_BI_SIGNED_FILES_URLS_PUT_ACTION,
        method: 'GET',
        params: {
          fileNames: JSON.stringify(fileNames),
        },
      }),
    }),

    [UPDATE_ONE_PCF_BULK_IMPORT]: builder.mutation<
      PcfBulkImportTableData,
      { id: number | string; data: UpdatePcfBulkImportDto }
    >({
      query: ({ id, data }) => {
        return {
          url: centralApiGatewayClient.pcfBulkImports.UPDATE_ONE_BULK_IMPORT.replace(
            ':id',
            id.toString(),
          ),
          method: 'PATCH',
          data,
        }
      },
      invalidatesTags: (result) => [
        { type: TAG_PCF_BULK_IMPORTS, id: LIST },
        { type: TAG_PCF_BULK_IMPORTS, id: result?.id },
      ],
    }),
    [DELETE_ONE_BULK_IMPORT]: builder.mutation<PcfBulkImportTableData, { id: number | string }>({
      query: ({ id }) => {
        return {
          url: centralApiGatewayClient.pcfBulkImports.DELETE_ONE_BULK_IMPORT.replace(
            ':id',
            id.toString(),
          ),
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => [
        { type: TAG_PCF_BULK_IMPORTS, id: LIST },
        { type: TAG_PCF_BULK_IMPORTS, id: result?.id },
      ],
    }),
    [UPLOAD_BI_TO_SIGNED_URL]: builder.mutation<
      { path: string },
      { setProgress: (progress: number) => void; fileObj?: RcFile; signedUrl: string; path: string }
    >({
      queryFn: ({ signedUrl, fileObj, setProgress }) => {
        return axios.put(signedUrl, fileObj, {
          headers: {
            'Content-Type': fileObj?.type as string,
            'Content-Length': fileObj?.size?.toString() as string,
          },
          onUploadProgress: (ev) =>
            setProgress(Math.round((ev.loaded * 100) / ((ev.total as number) ?? fileObj?.length))),
        })
      },
    }),
  }),
})

export const {
  useGetProductQuery,
  useGetActiveProductsQuery,
  useLazyGetActiveProductsQuery,
  useGetProductsQuery,
  useLazyGetProductsQuery,
  usePrefetch,
  useGetOrganizationTotalProductEmissionsQuery,
  useGetProductCountQuery,
  useLazyGetProductCountQuery,
  useGetPcfSignedUrlMutation,
  useUploadBIFileToSignedUrlMutation,
  useCreatePcfBulkImportMutation,
  useGetPcfBulkImportsQuery,
  useRequestPcfTemplateMutation,
  useGetOnePcfBulkImportQuery,
  useUpdateOnePcfBulkImportMutation,
  useGetOnePcfBulkImportFilesUrlsQuery,
  useDeleteOnePcfBulkImportMutation,
} = productsApiSlice

export default productsApiSlice
