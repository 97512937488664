/* eslint react-hooks/exhaustive-deps: 2 */
import { useCallback, useEffect, useState } from 'react'

import { LogEntriesTableSortingKey, SortingDirection } from '@cozero/models'

/** Sorting State */
export type ColumnSorting = {
  /** Sorted column identifier */
  key: LogEntriesTableSortingKey
  /** Column sorting direction */
  direction: SortingDirection
} | null

/** Local storage keys to store sorted column and sorting direction */
enum LOG_COLUMN_LOCAL_STORAGE_KEYS {
  SORTING_KEY = 'logColumnSortingKey',
  SORTING_DIRECTION = 'logColumnSortingDirection',
}

/** Get current local storage sorting state */
const getSortingFromLocalStorage = (): ColumnSorting => {
  const key = localStorage.getItem(LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_KEY)
  const direction = localStorage.getItem(LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_DIRECTION)
  if (direction && key) {
    return {
      direction: direction as SortingDirection,
      key: key as LogEntriesTableSortingKey,
    }
  }
  return null
}

/** Stores column sorting state in local storage
 * TODO: Adapt it later when storing state in database
 */
export const useSorting = (): {
  currentSorting?: ColumnSorting
  setSorting: (sorting: ColumnSorting) => void
} => {
  const [currentSorting, setSortState] = useState(getSortingFromLocalStorage())

  const setSorting = useCallback((sort: ColumnSorting): void => {
    setSortState(sort)
  }, [])

  useEffect(() => {
    if (!currentSorting) {
      localStorage.removeItem(LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_KEY)
      localStorage.removeItem(LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_DIRECTION)
    } else {
      localStorage.setItem(LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_KEY, currentSorting.key)
      localStorage.setItem(
        LOG_COLUMN_LOCAL_STORAGE_KEYS.SORTING_DIRECTION,
        currentSorting.direction,
      )
    }
  }, [currentSorting])

  return {
    currentSorting,
    setSorting,
  }
}
