import {
  FactorRequestTableRowDto,
  FactorRequestWithResponsibleUserDto,
  PaginatedQueryResult,
  PaginationDto,
} from '@cozero/dtos'
import { FactorRequest, FactorRequestDetailsView } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import { ParsedFormValues } from '@/organisms/FactorRequestForm/types'

import apiSlice from '../api'

import {
  CREATE_ONE_FACTOR_REQUEST,
  DELETE_ONE_FACTOR_REQUEST,
  FIND_FACTOR_REQUEST,
  FIND_FACTOR_REQUEST_BY_ID,
  FIND_FACTOR_REQUEST_SUPPLIER,
  GET_MANY_INCOMING_REQUESTS,
  GET_MANY_OUTGOING_REQUESTS,
  TAG_MANY_FACTOR_REQUESTS,
  TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
  TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
  TAG_ONE_FACTOR_REQUESTS,
  UPDATE_ONE_FACTOR_REQUEST,
} from './constants'

const factorsRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_FACTOR_REQUEST]: builder.query<FactorRequest[], string>({
      query: (status) => ({
        url: logApiGatewayClient.factorRequests.GET_MANY,
        method: 'GET',
        params: {
          status,
        },
      }),
      providesTags: () => [{ type: TAG_MANY_FACTOR_REQUESTS }],
    }),
    [FIND_FACTOR_REQUEST_SUPPLIER]: builder.query<FactorRequest[], string>({
      query: (email) => ({
        url: centralApiGatewayClient.suppliers.GET_FACTOR_REQUESTS,
        method: 'GET',
        params: {
          supplier: email,
        },
      }),
    }),
    [FIND_FACTOR_REQUEST_BY_ID]: builder.query<FactorRequestDetailsView, string>({
      query: (id) => ({
        url: logApiGatewayClient.factorRequests.GET_ONE.replace(':id', id),
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_ONE_FACTOR_REQUESTS, id: result?.id }],
    }),
    [GET_MANY_INCOMING_REQUESTS]: builder.query<
      PaginatedQueryResult<FactorRequestTableRowDto[]>,
      PaginationDto
    >({
      query: (params) => ({
        url: logApiGatewayClient.factorRequests.GET_MANY_INCOMING,
        method: 'GET',
        params,
      }),
      providesTags: (_result, _err, params) => [
        { type: TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS, id: JSON.stringify(params) },
      ],
    }),
    [GET_MANY_OUTGOING_REQUESTS]: builder.query<
      PaginatedQueryResult<FactorRequestTableRowDto[]>,
      PaginationDto
    >({
      query: (params) => ({
        url: logApiGatewayClient.factorRequests.GET_MANY_OUTGOING,
        method: 'GET',
        params,
      }),
      providesTags: (_result, _err, params) => [
        { type: TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS, id: JSON.stringify(params) },
      ],
    }),
    [DELETE_ONE_FACTOR_REQUEST]: builder.mutation<FactorRequestDetailsView, number>({
      query: (id) => ({
        url: logApiGatewayClient.factorRequests.DELETE_ONE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
        { type: TAG_ONE_FACTOR_REQUESTS, id: result?.id },
      ],
    }),
    [UPDATE_ONE_FACTOR_REQUEST]: builder.mutation<
      FactorRequestDetailsView,
      Partial<FactorRequest> & { id: number; denominatorUnitId: number }
    >({
      query: ({ id, ...request }) => ({
        url: logApiGatewayClient.factorRequests.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: (result) => [
        TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
        TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
        { type: TAG_ONE_FACTOR_REQUESTS, id: result?.id },
      ],
    }),
    [CREATE_ONE_FACTOR_REQUEST]: builder.mutation<
      FactorRequestWithResponsibleUserDto,
      ParsedFormValues & { businessUnitId: number }
    >({
      query: (input) => ({
        url: logApiGatewayClient.factorRequests.CREATE,
        method: 'POST',
        data: input,
      }),
      invalidatesTags: () => [TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS],
    }),
  }),
})

export const {
  useLazyFindFactorRequestQuery,
  useLazyFindFactorRequestSupplierQuery,
  useFindFactorRequestByIdQuery,
  useGetManyIncomingRequestsQuery,
  useGetManyOutgoingRequestsQuery,
  useDeleteOneFactorRequestMutation,
  useUpdateOneFactorRequestMutation,
  useCreateOneFactorRequestMutation,
  usePrefetch,
} = factorsRequestsApiSlice

export default factorsRequestsApiSlice
