import { Component, ReactNode } from 'react'
import { WithTranslation } from 'react-i18next'
import { NavigateFunction } from 'react-router'

export enum FallbackErrorType {
  GENERAL = 'GENERAL',
  UPDATE = 'UPDATE',
}

export interface FallbackProps {
  error: Error
  type: FallbackErrorType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WithRouterProps<Params = any, State = any> {
  location?: State
  navigate?: NavigateFunction
  params?: Params
}

export declare function FallbackRender(
  props: FallbackProps,
): React.ReactElement<unknown, string | React.FunctionComponent | typeof Component> | null

export interface ErrorBoundaryProps extends WithTranslation, WithRouterProps {
  children?: ReactNode
  FallbackComponent?: React.ComponentType<FallbackProps>
  renderFallbackUi?: (props: FallbackProps) => ReactNode
}

export interface ErrorBoundaryState {
  error: Error | null
  type: FallbackErrorType
}
