import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Menu, Switch } from 'antd/es'

import { ConfigurationView } from '@cozero/models'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './EditViewMenu.module.less'

const EditViewMenu = ({
  onChange,
  onSubmit,
  view,
  userIsAdmin,
  onCancel,
}: {
  onChange: Dispatch<SetStateAction<Partial<ConfigurationView> | undefined>>
  onSubmit: () => void
  view: Partial<ConfigurationView>
  userIsAdmin: boolean
  onCancel: () => void
}): JSX.Element | null => {
  const { t } = useTranslation()

  return (
    <Menu className={classes.menu}>
      <div className={classes.edit}>
        <div className={classes.content}>
          <div className={classes.inputGroup}>
            <Label>{t('views.name.label')}</Label>
            <InputField
              placeholder={t('views.name.placeholder')}
              className={classes.editInput}
              value={view.title || ''}
              onChange={(event) => onChange({ ...view, title: event.target.value })}
              type="text"
            />
          </div>
          {userIsAdmin && (
            <div className={classes.inputGroup}>
              <Label className={classes.inputLabel}>{t('views.share-view')}</Label>
              <Switch
                className={classes.editSwitch}
                checked={view?.shared || false}
                onClick={(val) => onChange({ ...view, shared: val })}
              />
            </div>
          )}
        </div>
        <div className={classes.buttonGroup}>
          <Button
            type="ghost"
            onClick={onCancel}
            action="cancel"
            category={AnalyticsCategories.VIEW}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onSubmit}
            action="edit"
            category={AnalyticsCategories.VIEW}
          >
            {view.id ? t('views.update.title') : t('views.save.title')}
          </Button>
        </div>
      </div>
    </Menu>
  )
}

export default EditViewMenu
