import { Supplier } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_SUPPLIERS } from './tags'

const suppliersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuppliers: builder.query<Supplier[], { businessUnitId: number }>({
      query: (params) => ({
        method: 'GET',
        url: centralApiGatewayClient.suppliers.GET_MANY,
        params,
      }),
      providesTags: (result) =>
        providesList<Supplier[], typeof TAG_SUPPLIERS>(result, TAG_SUPPLIERS),
    }),
  }),
})

export const { useGetSuppliersQuery } = suppliersSlice

export default suppliersSlice
