import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ConfigProvider, Empty, Row } from 'antd/es'
import { TablePaginationConfig } from 'antd/es/table'

import { GroupedMethodologyChangelog, Page } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Text from '@/atoms/Text'

import emptyTable from '@/assets/empty-table.svg'
import { setSelectedCalculationChangelogRecord } from '@/redux/methodologyChangelogs/slice'

import classes from './classes.module.less'
import {
  MethodologyChangesTableColumnKeys,
  useChangelogsTableColumns,
} from './useChangelogsTableColumns'

interface Props {
  methodologyChangelogs: Page<GroupedMethodologyChangelog>
  page: number
  pageSize: number
  setPageSize: (size: number) => void
  setPage: (page: number) => void
  /* Columns to be shown in the table. If not provided, all columns will be shown */
  shownColumns?: MethodologyChangesTableColumnKeys[]
}

function ChangelogsTable({
  methodologyChangelogs,
  page,
  pageSize,
  setPageSize,
  setPage,
  shownColumns,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { columns } = useChangelogsTableColumns({
    methodologyChangelogTotal: methodologyChangelogs.total,
    shownColumns,
  })

  const goToChangelog = useCallback((record: GroupedMethodologyChangelog): void => {
    dispatch(setSelectedCalculationChangelogRecord(record))
    const subcategoryId = record.subcategoryId.toString()
    navigate(
      {
        pathname: routes.log.factors.calculations.viewCalculation.replace(
          ':id',
          record.methodologyChangelogGlobalId.toString(),
        ),
        search: `?subcategoryId=${subcategoryId} `,
      },
      { replace: false },
    )
  }, [])

  const changeTable = (pagination: TablePaginationConfig): void => {
    if (pagination.current && pagination.current !== page) {
      setPage(pagination.current)
    }
  }
  const CustomEmpty = (): ReactElement => (
    <Empty
      image={emptyTable}
      imageStyle={{ height: 108 }}
      description={
        <div>
          <Row justify={'center'}>
            <Text size="xl" mode="text" fontWeight="semibold" color="secondary">
              {t('calculation-changes.impact-modal.no-changes')}
            </Text>
          </Row>
          <Row justify={'center'}>
            <Text size="md" color="secondary">
              {t('calculation-changes.impact-modal.no-action')}
            </Text>
          </Row>
        </div>
      }
    ></Empty>
  )

  return (
    <ConfigProvider renderEmpty={CustomEmpty}>
      <Table
        dataSource={methodologyChangelogs.data}
        rowKey="id"
        columns={columns}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: () => goToChangelog(record),
        })}
        rowClassName={classes.tableRow}
        showWrapper={false}
        onChange={changeTable}
        pagination={{
          current: page,
          defaultPageSize: pageSize,
          pageSize,
          total: methodologyChangelogs.total,
          hideOnSinglePage: true,
          onShowSizeChange: (_current, size) => {
            setPageSize(size)
          },
        }}
        emptyTitle={t('calculation-changes.impact-modal.no-changes')}
        emptyDescription={t('calculation-changes.impact-modal.no-action')}
      />
    </ConfigProvider>
  )
}

export default ChangelogsTable
