import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight } from 'react-icons/hi'

import { Col, DatePicker, Row, Spin } from 'antd/es'

import { Datum } from '@antv/g2plot'
import { MixChart } from '@opd/g2plot-react'
import { random } from 'lodash-es'
import moment, { Moment } from 'moment'

import { Report, Target } from '@cozero/models'

import { BORDER_RADIUS_BASE, GRAY_LIGHT, GRAY_LIGHTEST } from '@/styles/variables'

import classes from './TargetMixedGraph.module.less'

interface Props {
  targets: Target[]
  report: Report
}

const Legend = ({ targets }: { targets: Target[] }): ReactElement | null => {
  if (!targets) {
    null
  }
  return (
    <div className={classes.legend}>
      {targets.map((t) => (
        <div key={t.id} className={classes.legendTitle}>
          <div className={classes.legendDot} />
          <span>{t.title}</span>
        </div>
      ))}
    </div>
  )
}

const getYears = (start: Moment, end: Moment): Moment[] =>
  Array.from({ length: end.diff(start, 'year') + 1 }).map((_, index) =>
    moment(start).add(index, 'year'),
  )

const TargetMixedGraph = ({ targets, report }: Props): ReactElement => {
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment(), moment('2030')])
  const [internalLoading, setInternalLoading] = useState<boolean>(false)
  const [barChartData, setBarChartData] = useState<
    { x: string; value: number; forecasted: boolean }[]
  >([])
  const [lineData, setLineData] = useState<{ x: string; value: number }[]>([])

  // Set bar chart data when date range changes
  useEffect(() => {
    setInternalLoading(true)
    setBarChartData(
      report?.data?.series
        ?.map((datum) => [
          ...datum.series.map((seriesItem) => {
            return {
              ...seriesItem,
              x: moment(seriesItem.x).format('YYYY'),
              forecasted: false,
            }
          }),
          ...getYears(dateRange[0], moment(dateRange[1]).endOf('year')).map((date) => {
            return {
              x: date.format('YYYY'),
              value: (datum.series[0]?.value ?? 0) * random(0.8, 1.2),
              forecasted: true,
            }
          }),
        ])
        ?.flat()
        .filter(Boolean) ?? [],
    )
    setInternalLoading(false)
  }, [setDateRange, targets, report?.data, dateRange])

  // set line chart data when targets change
  useEffect(() => {
    setInternalLoading(true)

    if (barChartData?.length) {
      const result = targets
        .map((target) => {
          const startYear = moment(target.createdAt).year()
          const targetYear = moment(target.targetYear)
          const emission =
            barChartData.find((datum) => datum.x === targetYear.format('YYYY'))?.value ?? 0
          let reduction = target?.targetReductions[0].reductionValue

          /**
           * The following calculation relies on the "Equation for the line with two known points"
           * We know that this is a linear graph, we can create two points in the graph
           * point one is the first emission (x, y) = (startYear, emission)
           * point two is the last emission (x1, y1) = (targetYear, |emission - reduction|)
           * We can then solve for the yearly reduction by (y1-y)/(x1-x)
           * In our case the difference of x and x1 will always be the difference in years
           */
          const yearDiff = moment(targetYear).diff(startYear, 'year')

          // If our reduction is larger
          // than our emission the reduction should be negative
          reduction = reduction > emission ? -reduction : reduction

          // To find our y1 here value we have to find the
          // absolute value of the reduction aka. the given percentage of the total emissions
          const yearlyReduction: number =
            (reduction / emission) * 100 - emission - emission / yearDiff

          return getYears(dateRange[0], moment(target.targetYear)).map((date, index) => {
            // Our current years reduction
            const reductionForYear = yearlyReduction * index
            // Our current year emissions
            const emissionForYear = emission + reductionForYear

            return {
              x: date.format('YYYY'),
              value: emissionForYear >= 0 ? emissionForYear : 0,
              targetTitle: target.title,
            }
          })
        })
        .flat()
        .filter(Boolean)

      setLineData(result ?? [])

      setInternalLoading(false)
    }
  }, [targets?.length, barChartData])

  return (
    <Row className={classes.wrapper}>
      <Col xs={24}>
        <Row justify="end">
          <Col>
            <Legend targets={targets} />
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <DatePicker.RangePicker
              onChange={(_range, strings) => setDateRange([moment(strings[0]), moment(strings[1])])}
              picker={'year'}
              value={dateRange}
              className={classes.datePicker}
              size="large"
              separator={<HiArrowRight />}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Spin
              spinning={internalLoading}
              wrapperClassName={internalLoading ? `${classes.chartLoading} ${classes.pulse}` : ''}
            >
              <MixChart
                className={classes.chart}
                syncViewPadding
                plots={[
                  {
                    type: 'column',
                    options: {
                      data: barChartData,
                      xField: 'x',
                      yField: 'value',
                      meta: {
                        x: {
                          sync: true,
                        },
                        value: {
                          sync: true,
                        },
                      },
                      color: ({ x }: Datum): string => {
                        const { forecasted } = barChartData.find((datum) => datum.x === x) ?? {}
                        if (forecasted) {
                          return GRAY_LIGHTEST as string
                        }
                        return GRAY_LIGHT as string
                      },
                      columnStyle: {},
                    },
                  },
                  {
                    type: 'line',
                    options: {
                      data: lineData,
                      xField: 'x',
                      yField: 'value',
                      seriesField: 'targetTitle',
                      xAxis: false,
                      yAxis: false,
                      color: (_, defaultValue): string => {
                        return defaultValue as string
                      },
                      meta: {
                        x: {
                          sync: true,
                        },
                        value: {
                          sync: true,
                        },
                      },
                    },
                  },
                ]}
                tooltip={{
                  shared: true,
                  domStyles: {
                    'g2-tooltip': {
                      padding: 'none',
                      borderRadius: BORDER_RADIUS_BASE,
                    },
                  },
                  customContent: (title, data) => {
                    const rows = data.map((datum) => {
                      const value = parseFloat(datum?.value).toFixed(2)
                      return `
                        <div class="${classes.tooltipRow}">
                          <div class="${classes.tooltipTitle}" >
                            <div class="${classes.tooltipDot}" style="background-color:${
                        datum?.mappingData.color
                      };"></div>
                            <span>${datum?.name}</span>
                          </div>
                          <span class="${classes.tooltipValue}">${
                        datum.data.forecasted
                          ? t('act.targets.forecasted')
                          : `${value} ${t('co2-tonnes')}`
                      } </span>
                        </div>`
                    })
                    return `<div class="${classes.tooltip}"><span class="${
                      classes.tooltipHeader
                    }">${title}</span>${rows.join('')}</div>`
                  },
                }}
              />
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TargetMixedGraph
