/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'

import { some, uniqBy } from 'lodash-es'

import { useGetCategoriesQuery } from '@/redux/categories'

import { SearchHookProps } from './types'
import { checkIfSearchMatchesItemProperty } from './utils'

export const useCategoriesSearch = ({
  selectedCategoriesIDs = [],
  selectedSubCategoriesIDs = [],
}: {
  selectedCategoriesIDs?: number[]
  selectedSubCategoriesIDs?: number[]
}): {
  categories: SearchHookProps
  subcategories: SearchHookProps
} => {
  const { data, isFetching } = useGetCategoriesQuery()
  const [searchCategory, setSearchCategory] = React.useState<string>('')
  const [searchSubCategory, setSearchSubCategory] = React.useState<string>('')

  const categoryOptions = React.useMemo(
    () =>
      uniqBy(data, 'id')
        .filter((item) => checkIfSearchMatchesItemProperty(item, 'name', searchCategory))
        .filter(
          (category) =>
            !selectedSubCategoriesIDs?.length ||
            some(category.subcategories, (subCategory) =>
              selectedSubCategoriesIDs.includes(subCategory.id),
            ),
        )
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
    [data, searchCategory, selectedSubCategoriesIDs],
  )

  const subCategoriesOptions: SearchHookProps['options'] = React.useMemo(() => {
    if (!data?.length) {
      return []
    }

    const validCategories = selectedCategoriesIDs?.length
      ? data.filter((category) => selectedCategoriesIDs.includes(category.id))
      : uniqBy(data, 'id')

    const subCategories = validCategories.reduce<{ name: string; id: number }[]>(
      (acc, category) => {
        return [...acc, ...(category.subcategories ?? [])]
      },
      [],
    )

    return subCategories
      .filter((item) => checkIfSearchMatchesItemProperty(item, 'name', searchSubCategory))
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
  }, [data, selectedCategoriesIDs, searchSubCategory])

  return {
    categories: {
      onSearch: setSearchCategory,
      options: categoryOptions,
      isFetching,
    },
    subcategories: {
      onSearch: setSearchSubCategory,
      options: subCategoriesOptions,
      isFetching,
    },
  }
}
