import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

import { notification } from 'antd'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import classes from './ForbiddenAccessModal.module.less'

export const CONFLICT_MODAL_EVENT_TYPE = 'conflict-modal'

export const useForbiddenAccessModal = (): void => {
  const { t } = useTranslation('common')

  useEffect(() => {
    const handleStorageChange = (): void => {
      notification.info({
        key: 'conflict',
        message: (
          <Title className={classes.title} size="xs">
            {t('forbidden-modal.title')}
          </Title>
        ),
        description: <Text size="xl">{t('forbidden-modal.message')}</Text>,
        placement: 'top',
        className: classes.notification,
        duration: 0, // should never close
        icon: <HiOutlineExclamationCircle className={classes.icon} />,
      })
    }

    window.addEventListener(CONFLICT_MODAL_EVENT_TYPE, handleStorageChange)

    return () => {
      window.removeEventListener(CONFLICT_MODAL_EVENT_TYPE, handleStorageChange)
    }
  }, [t])
}
