import { FactorOrigin } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

const factorOriginsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFactorOrigins: builder.query<FactorOrigin[], void>({
      query: () => ({
        url: logApiGatewayClient.factorOrigins.GET_MANY,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetFactorOriginsQuery } = factorOriginsSlice

export default factorOriginsSlice
