import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Col, Divider, Row, Spin, Switch, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { ApiOutlined, SnippetsOutlined } from '@ant-design/icons'
import omit from 'lodash/omit'

import { ManualImportModal } from '@/organisms/ManualImportModal'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import {
  useCheckIntegrationConnectionMutation,
  useConfigureIntegrationMutation,
  useGetIntegrationsQuery,
} from '@/redux/apiIntegrations/api'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetLocationsQuery } from '@/redux/locations'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { WHITE_NEUTRAL } from '@/styles/variables'
import { truncate } from '@/utils/string'

import classes from './classes.module.less'

function ConfigureIntegration(): JSX.Element {
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const [form] = Form.useForm()
  const { t } = useTranslation('common')

  const { data: apiIntegrations, isLoading: isloadingApiIntegrations } = useGetIntegrationsQuery()
  const [configureApiIntegration, { isLoading: isLoadingConfigureApiIntegration }] =
    useConfigureIntegrationMutation()
  const [checkConnection, { isLoading: isLoadingCheckConnection }] =
    useCheckIntegrationConnectionMutation()

  const loading =
    isloadingApiIntegrations || isLoadingConfigureApiIntegration || isLoadingCheckConnection

  const { data: users = [] } = useGetOrganizationUsersQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit?.id },
  )
  const { data: locations } = useGetLocationsQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit?.id },
  )
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)

  const { id } = useParams()

  const selectedIntegration = useMemo(() => {
    if (apiIntegrations?.length && id) {
      return apiIntegrations.find((integration) => integration.id === parseInt(id, 10))
    }
    return undefined
  }, [id, apiIntegrations])

  const submitForm = async (fields: Store): Promise<void> => {
    const configuration = omit(fields, ['active', 'interval', 'userId', 'locationId'])
    if (selectedIntegration) {
      try {
        await configureApiIntegration({
          integrationId: selectedIntegration?.id.toString(),
          data: {
            active: fields.active,
            interval: fields.interval,
            locationId: parseInt(fields.locationId),
            userId: parseInt(fields.userId),
            configuration,
          },
        }).unwrap()
        message.success(t('settings.integrations.configure.success'))
      } catch (err) {
        message.error(t('settings.integrations.configure.error'))
      }
    }
  }

  const check = async (): Promise<void> => {
    if (selectedIntegration?.id) {
      try {
        const success = await checkConnection({
          integrationId: selectedIntegration.id.toString(),
          values: form.getFieldsValue(),
        }).unwrap()
        if (success) {
          message.success(t('settings.integrations.transactions.success'))
        } else {
          message.error(t('settings.integrations.transactions.error'))
        }
      } catch (err) {
        message.error(t('settings.integrations.transactions.request-error'))
      }
    }
  }

  useEffect(() => {
    if (selectedIntegration && selectedIntegration?.configurations[0]) {
      const fieldValues = JSON.parse(
        selectedIntegration?.configurations[0]?.configuration?.toString() ?? '{}',
      )
      form.setFieldsValue({
        active: selectedIntegration?.configurations[0]?.active,
        interval: selectedIntegration?.configurations[0]?.interval,
        locationId: selectedIntegration.configurations[0]?.locationId,
        userId: selectedIntegration.configurations[0]?.userId,
        ...fieldValues,
      })
    }
  }, [selectedIntegration, locations?.length, users?.length])

  return (
    <Row justify="center" className={classes.tabSection}>
      <ManualImportModal
        selectedIntegration={selectedIntegration}
        visible={modalIsVisible}
        handleCancel={() => setModalIsVisible(false)}
      />
      <Col span={20}>
        <Row justify={'center'} gutter={16}>
          <Col span={4} style={{ marginTop: 5 }}>
            <div
              style={{
                background: selectedIntegration?.logoBg ?? WHITE_NEUTRAL,
                borderRadius: 5,
                width: 150,
                margin: '0 auto',
                display: 'block',
              }}
            >
              <img src={selectedIntegration?.logoUrl ?? ''} width={100} style={{ margin: 25 }} />
            </div>
          </Col>
          <Col span={19} style={{ marginLeft: 25 }}>
            <Col span={22}>
              <Title size="sm">{`${selectedIntegration?.name} ${t(
                'settings.integrations.single',
              )}`}</Title>
            </Col>
            <Col span={22}>
              <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                <>{selectedIntegration?.description}</>
              </Text>
            </Col>
          </Col>
        </Row>
      </Col>
      <Divider />
      <Spin spinning={loading}>
        <Col span={24} style={{ marginTop: 20 }}>
          <Form
            form={form}
            layout={'vertical'}
            onFinish={submitForm}
            category={AnalyticsCategories.API_INTEGRATIONS}
          >
            <Row justify={'center'} gutter={16}>
              <Col span={10}>
                <Title as="h5">{t('settings.integrations.headlines.general')}</Title>
                <Form.Item
                  key="active"
                  name={['active']}
                  label={t('settings.integrations.fields.active.title')}
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Switch defaultChecked />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.active.description')}
                </Text>
                <Form.Item
                  key="interval"
                  name={['interval']}
                  label={t('settings.integrations.fields.interval.title')}
                  initialValue={'end-of-month'}
                >
                  <Select
                    style={{ width: '50%' }}
                    defaultValue={'end-of-month'}
                    options={[
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-day'),
                        value: 'end-of-day',
                      },
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-week'),
                        value: 'end-of-week',
                      },
                      {
                        label: t('settings.integrations.fields.interval.options.end-of-month'),
                        value: 'end-of-month',
                      },
                    ]}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.interval.description')}
                </Text>
                <Form.Item
                  key="userId"
                  name={['userId']}
                  label={t('settings.integrations.fields.user.title')}
                >
                  <Select
                    style={{ width: '50%' }}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (!option || !option.label) {
                        return false
                      }
                      return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={users?.map((user) => ({
                      key: user.id,
                      value: user.id,
                      label: truncate(
                        user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user.email,
                        15,
                      ),
                    }))}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.user.description')}
                </Text>
                <Form.Item
                  key="locationId"
                  name={['locationId']}
                  label={t('settings.integrations.fields.location.title')}
                >
                  <Select
                    style={{ width: '50%' }}
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (!option || !option.label) {
                        return false
                      }
                      return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={locations?.map((location) => ({
                      key: location.id,
                      value: location.id,
                      label: truncate(location.name, 15),
                    }))}
                  />
                </Form.Item>
                <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                  {t('settings.integrations.fields.location.description')}
                </Text>

                <Button
                  prefixIcon={<SnippetsOutlined />}
                  action={'manually-import-data'}
                  onClick={() => setModalIsVisible(true)}
                  category={AnalyticsCategories.API_INTEGRATIONS}
                  style={{ marginRight: 25 }}
                  disabled={!selectedIntegration?.configurations?.[0]}
                >
                  {t('settings.integrations.actions.import')}
                </Button>
              </Col>
              <Col span={10}>
                <Title as="h5">{t('settings.integrations.headlines.integration')}</Title>
                {(
                  selectedIntegration?.metadata as {
                    fields: {
                      key: string
                      label: string
                      description: string
                      type:
                        | 'number'
                        | 'textarea'
                        | 'text'
                        | 'email'
                        | 'password'
                        | 'checkbox'
                        | undefined
                      default: string
                    }[]
                  }
                )?.fields?.map((field) => (
                  <Fragment key={field.key}>
                    <Form.Item
                      initialValue={field.default}
                      key={field.key}
                      name={[field.key]}
                      label={field.label}
                      valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
                    >
                      <InputField defaultValue={field.default} type={field.type} />
                    </Form.Item>
                    <Text size="xl" color="secondary" className={classes.integrationsSubtitle}>
                      {field.description}
                    </Text>
                  </Fragment>
                ))}
                <Button
                  prefixIcon={<ApiOutlined />}
                  action={'check-connection'}
                  category={AnalyticsCategories.API_INTEGRATIONS}
                  onClick={() => check()}
                >
                  {t('settings.integrations.actions.check-connection')}
                </Button>

                <Form.Item>
                  <Button
                    style={{ marginTop: 25 }}
                    type="primary"
                    htmlType="submit"
                    action={'submit'}
                    category={AnalyticsCategories.API_INTEGRATIONS}
                  >
                    {t('settings.integrations.actions.submit')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Spin>
    </Row>
  )
}

export default ConfigureIntegration
