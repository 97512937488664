import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cascader } from 'antd/es'

import ActivityDataSourceCascader from '@/organisms/ActivityDataSourcesCascader'

import { LifecycleStepCardProps } from '@/molecules/LifecycleStepCard'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'

import distributionAndStorage from '@/assets/lifecycle-steps/distribution-and-storage.svg'
import endOfLife from '@/assets/lifecycle-steps/end-of-life.svg'
import packaging from '@/assets/lifecycle-steps/packaging.svg'
import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import production from '@/assets/lifecycle-steps/production.svg'
import quantity from '@/assets/lifecycle-steps/quantity.svg'
import rawMaterialsAcquisition from '@/assets/lifecycle-steps/raw-materials-acquisition.svg'
import usage from '@/assets/lifecycle-steps/usage.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'

export enum LifecycleStepsKey {
  'PRODUCT_CONFIGURATION' = 'product-configuration',
  'RAW_MATERIALS_ACQUISITION' = 'raw-materials-acquisition',
  'PRODUCTION' = 'production',
  'PACKAGING' = 'packaging',
  'DISTRIBUTION_AND_STORAGE' = 'distribution-and-storage',
  'USAGE' = 'usage',
  'QUANTITIES' = 'quantities',
  'END_OF_LIFE' = 'end-of-life',
}

export enum LifecycleStepsID {
  'product-configuration' = 1,
  'raw-materials-acquisition' = 2,
  'production' = 3,
  'packaging' = 4,
  'distribution-and-storage' = 5,
  'usage' = 6,
  'end-of-life' = 7,
  'quantities' = 8,
}

export enum GenericFieldKey {
  'TITLE' = 'title',
  'LOG_CATEGORY' = 'categoryId',
  'LOG_SUBCATEGORY' = 'subcategoryId',
  'CALCULATION_METHOD' = 'calculationMethodId',
  'ACTIVITY_DATA_SOURCE' = 'activityDataSourceId',
  'SUPPLIER' = 'supplierId',
  'INPUTS' = 'inputs',
  'TERRITORY' = 'territoryId',
}

export interface Step extends Omit<LifecycleStepCardProps, 'index'> {
  path?: string
  stepKey: LifecycleStepsKey
  isVisible: boolean
}

interface StepList {
  productIdToEdit?: number
  selectedSteps: LifecycleStepsKey[]
  childRef: React.MutableRefObject<{
    onNext: () => void
    onReturn: () => void
    onClearData: () => void
  }>
  handleLifecycleChange: (value: boolean, index: number, isActive: boolean) => void
  mandatorySupplierStepKeys?: LifecycleStepsKey[]
  hideOptionalSteps?: boolean
}

export interface Field {
  key: GenericFieldKey
  label?: string
  tooltip?: string
  component: JSX.Element
  additionalComponent?: JSX.Element
}

type OptionalField = Field & { position: number }

interface StepContext {
  stepList: ({ handleLifecycleChange, selectedSteps }: StepList) => Step[]
  generateFields: (optionalFields?: OptionalField[]) => Field[]
  rawMaterialsAcquisitionOptionalFields: OptionalField[]
  productionOptionalFields: OptionalField[]
  packagingOptionalFields: OptionalField[]
  distributionAndStorageOptionalFields: OptionalField[]
  endOfLifeOptionalFields: OptionalField[]
  genericSteps: LifecycleStepsKey[]
  mandatoryGenericFields: Field[]
}

interface GetStepInitialValueArgs {
  mandatorySupplierStepKeys: LifecycleStepsKey[]
  selectedSteps: LifecycleStepsKey[]
  stepKey: LifecycleStepsKey
}
const getStepInitialValue = ({
  mandatorySupplierStepKeys,
  selectedSteps,
  stepKey,
}: GetStepInitialValueArgs): Pick<Step, 'initialValue' | 'isDisabled'> => {
  if (mandatorySupplierStepKeys.some((k) => k === stepKey)) {
    return {
      isDisabled: true,
      initialValue: true,
    }
  }

  return {
    isDisabled: false,
    initialValue: selectedSteps.some((step) => step === stepKey),
  }
}

export const useStep = (): StepContext => {
  const { t } = useTranslation('common')

  const stepList = ({
    handleLifecycleChange,
    selectedSteps,
    hideOptionalSteps = false,
    mandatorySupplierStepKeys = [],
  }: StepList): Step[] => {
    return [
      {
        imageSrc: productConfiguration,
        path: 'configuration',
        title: t('product.lifecycle-steps.configuration.title'),
        description: t('product.lifecycle-steps.configuration.description'),
        selectedLifecycle: handleLifecycleChange,
        isDisabled: true,
        initialValue: true,
        stepKey: LifecycleStepsKey.PRODUCT_CONFIGURATION,
        isVisible: true,
      },
      {
        imageSrc: rawMaterialsAcquisition,
        title: t('product.lifecycle-steps.raw-materials-acquisition.title'),
        description: t('product.lifecycle-steps.raw-materials-acquisition.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.RAW_MATERIALS_ACQUISITION,
        path: 'raw-materials-acquisition',
        isVisible: true,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.RAW_MATERIALS_ACQUISITION,
        }),
      },
      {
        imageSrc: production,
        title: t('product.lifecycle-steps.production.title'),
        description: t('product.lifecycle-steps.production.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.PRODUCTION,
        path: 'production',
        isVisible: true,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.PRODUCTION,
        }),
      },
      {
        imageSrc: packaging,
        title: t('product.lifecycle-steps.packaging.title'),
        description: t('product.lifecycle-steps.packaging.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.PACKAGING,
        path: 'packaging',
        isVisible: true,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.PACKAGING,
        }),
      },
      {
        imageSrc: distributionAndStorage,
        title: t('product.lifecycle-steps.distribution-and-storage.title'),
        description: t('product.lifecycle-steps.distribution-and-storage.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
        path: 'distribution-and-storage',
        isVisible: true,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
        }),
      },
      {
        imageSrc: usage,
        title: t('product.lifecycle-steps.usage.title'),
        description: t('product.lifecycle-steps.usage.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.USAGE,
        path: 'usage',
        isVisible: !hideOptionalSteps,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.USAGE,
        }),
      },
      {
        imageSrc: endOfLife,
        title: t('product.lifecycle-steps.end-of-life.title'),
        description: t('product.lifecycle-steps.end-of-life.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.END_OF_LIFE,
        path: 'end-of-life',
        isVisible: !hideOptionalSteps,
        ...getStepInitialValue({
          mandatorySupplierStepKeys,
          selectedSteps,
          stepKey: LifecycleStepsKey.END_OF_LIFE,
        }),
      },
      {
        imageSrc: quantity,
        title: t('product.lifecycle-steps.quantities.title'),
        description: t('product.lifecycle-steps.quantities.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.QUANTITIES,
        path: 'quantities',
        initialValue: !hideOptionalSteps,
        isDisabled: true,
        isVisible: !hideOptionalSteps,
      },
    ]
  }

  const genericSteps: LifecycleStepsKey[] = [
    LifecycleStepsKey.RAW_MATERIALS_ACQUISITION,
    LifecycleStepsKey.PRODUCTION,
    LifecycleStepsKey.PACKAGING,
    LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
    LifecycleStepsKey.USAGE,
    LifecycleStepsKey.END_OF_LIFE,
  ]

  const generateFields = (optionalFields?: OptionalField[]): Field[] => {
    const fields = [...mandatoryGenericFields]
    if (!optionalFields) {
      return fields
    }
    optionalFields.forEach(({ position, component, key, label, additionalComponent, tooltip }) => {
      fields.splice(position, 0, { key, component, label, additionalComponent, tooltip })
    })
    return fields
  }

  const mandatoryGenericFields: Field[] = [
    {
      key: GenericFieldKey.TITLE,
      label: t('product.lifecycle-steps.generic-fields.name.label'),
      component: <InputField></InputField>,
    },
    {
      key: GenericFieldKey.LOG_SUBCATEGORY,
      label: t('product.lifecycle-steps.generic-fields.subcategory.label'),
      component: (
        <Select
          filterOption={true}
          showSearch
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.subcategory.placeholder')}
        ></Select>
      ),
    },
    {
      key: GenericFieldKey.CALCULATION_METHOD,
      label: t('product.lifecycle-steps.generic-fields.type-of-data.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.type-of-data.tooltip'),
      component: (
        <Select
          filterOption={true}
          optionFilterProp="label"
          showSearch
          placeholder={t('product.lifecycle-steps.generic-fields.type-of-data.placeholder')}
        ></Select>
      ),
    },
    {
      key: GenericFieldKey.ACTIVITY_DATA_SOURCE,
      label: t('product.lifecycle-steps.generic-fields.activity.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.activity.tooltip'),
      component: (
        <ActivityDataSourceCascader
          size="middle"
          allowClear={false}
          placeholder={t('product.lifecycle-steps.generic-fields.activity.placeholder')}
        />
      ),
    },
    {
      key: GenericFieldKey.TERRITORY,
      label: t('product.lifecycle-steps.generic-fields.activity-origin.label'),
      component: (
        <Cascader
          showSearch
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
          placeholder={t('product.lifecycle-steps.generic-fields.activity-origin.placeholder')}
        />
      ),
    },
  ]

  const rawMaterialsAcquisitionOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.category.label'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.category.placeholder')}
        ></Select>
      ),
      position: 1,
    },
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.supplier.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.supplier.tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category={AnalyticsCategories.SUPPLIER} action="add" type="text" color="blue">
          {t('suppliers.add-new')}
        </Button>
      ),
      position: 6,
    },
  ]

  const productionOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.category.label'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.category.placeholder')}
        ></Select>
      ),
      position: 1,
    },
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.supplier.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.supplier.tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category={AnalyticsCategories.SUPPLIER} action="add" type="text" color="blue">
          {t('suppliers.add-new')}
        </Button>
      ),
      position: 6,
    },
  ]

  const distributionAndStorageOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.category.label'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.category.placeholder')}
        ></Select>
      ),
      position: 1,
    },
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.supplier.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.supplier.tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category={AnalyticsCategories.SUPPLIER} action="add" type="text" color="blue">
          {t('suppliers.add-new')}
        </Button>
      ),
      position: 6,
    },
  ]

  const packagingOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.supplier.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.supplier.tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category={AnalyticsCategories.SUPPLIER} action="add" type="text" color="blue">
          {t('suppliers.add-new')}
        </Button>
      ),
      position: 5,
    },
  ]

  const endOfLifeOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.supplier.label'),
      tooltip: t('product.lifecycle-steps.generic-fields.supplier.tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category={AnalyticsCategories.SUPPLIER} action="add" type="text" color="blue">
          {t('suppliers.add-new')}
        </Button>
      ),
      position: 5,
    },
  ]

  return {
    stepList,
    generateFields,
    rawMaterialsAcquisitionOptionalFields,
    productionOptionalFields,
    packagingOptionalFields,
    distributionAndStorageOptionalFields,
    endOfLifeOptionalFields,
    genericSteps,
    mandatoryGenericFields,
  }
}
