import React from 'react'

import classNames from 'classnames'

import Dot from '@/atoms/Dot'

import classes from './Shape.module.less'

export type Shapes = 'line' | 'dashed' | 'dot'

const ShapeComponent = ({
  type = 'dot',
  color,
  className,
}: {
  type?: Shapes
  color: string
  className?: string
}): React.ReactElement => {
  if (type === 'line') {
    return <div className={classNames(classes.shape, classes.line, className)} style={{ color }} />
  }

  if (type === 'dashed') {
    return (
      <div className={classNames(classes.shape, classes.dashed, className)} style={{ color }} />
    )
  }

  return <Dot className={className} color={color} />
}

export const Shape = React.memo(ShapeComponent)
