import React, { forwardRef, useImperativeHandle } from 'react'

import { useForm } from 'antd/lib/form/Form'

import { useProductOutletContext } from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import useTerritories from '@/hooks/useTerritories'

import UpsertProduct from './UpsertProduct'

const ProductConfiguration = (): JSX.Element => {
  const [form] = useForm()

  const { product, childRef, STEPS, updateProduct, createProductConfiguration, onHasFinished } =
    useProductOutletContext()

  const { territories } = useTerritories()

  useImperativeHandle(childRef, () => ({
    onClearData: () => {
      form.resetFields()
    },
    onNext: async () => {
      try {
        const valid = await form.validateFields()
        if (valid) {
          form.submit()
        }
      } catch (error) {
        return
      }
    },
    onReturn: () => undefined,
  }))

  return (
    <UpsertProduct
      STEPS={STEPS}
      territories={territories || []}
      product={product}
      form={form}
      onSubmitNewProductData={async (data) => {
        if (product?.id) {
          await updateProduct(product?.id?.toString(), data)
        } else {
          await createProductConfiguration(data)
        }
        onHasFinished()
      }}
    />
  )
}

export default React.memo(forwardRef(ProductConfiguration))
