import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineMail } from 'react-icons/hi'

import { Row, Space } from 'antd'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import requestFactorSuccess from '@/assets/request-factor-success.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './requestFactorSuccessModal.module.less'

const RequestFactorSuccessModal = ({
  visible,
  onSendPersonalEmail,
  onSendRequest,
  afterClose,
}: {
  visible: boolean
  onSendPersonalEmail: () => void
  onSendRequest: () => void
  afterClose: () => void
}): ReactElement => {
  const { t } = useTranslation()
  return (
    <Modal
      bodyStyle={{ overflowY: 'unset' }}
      visible={visible}
      className={classes.modal}
      afterClose={afterClose}
    >
      <Row justify="center">
        <div className={classes.section}>
          <img src={requestFactorSuccess} alt="request factor success" />
        </div>
        <div className={classes.section}>
          <Title size="sm" className={classes.title}>
            {t('suppliers.factor.request-modal.title')}
          </Title>
        </div>
        <div className={classes.section}>
          <Text color="secondary" size="xl" className={classes.description}>
            {t('suppliers.factor.request-modal.description')}
          </Text>
        </div>
        <div className={classes.section}>
          <Space size="middle">
            <Button
              className={classes.button}
              category={AnalyticsCategories.LOGS}
              action={'request-factor-send-personal-email'}
              data-cy="request-factor-send-personal-email"
              prefixIcon={<HiOutlineMail />}
              size="lg"
              onClick={onSendPersonalEmail}
            >
              {t('suppliers.factor.request-modal.button-send-personal-email')}
            </Button>
            <Button
              className={classes.button}
              category={AnalyticsCategories.LOGS}
              action={'request-factor-send-request'}
              type="primary"
              data-cy="request-factor-send-request"
              prefixIcon={<HiOutlineMail />}
              size="lg"
              onClick={onSendRequest}
            >
              {t('suppliers.factor.request-modal.button-send-request')}
            </Button>
          </Space>
        </div>
      </Row>
    </Modal>
  )
}

export default RequestFactorSuccessModal
