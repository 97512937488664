import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { useForm } from 'antd/es/form/Form'

import _ from 'lodash'

import { ActivityDataSource, CalculationMethodWithIncludes } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Select, { SelectOptionsProperties } from '@/atoms/Select'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import useLog from '@/hooks/useLog'
import { useGetCategoriesQuery } from '@/redux/categories'
import { GRAY_400 } from '@/styles/variables'

import ActivityDataSourceLazyLoadedCascader from '../ActivityDataSourceLazyLoadedCascader'
import { CreateProductFormValues, ParsedProductFormValues } from '../FactorRequestForm/types'

interface AddProductProps {
  isVisible: boolean
  onClose: (activityDataSource?: ActivityDataSource) => void
}

const AddProductModal = ({ isVisible, onClose }: AddProductProps): JSX.Element => {
  const { t } = useTranslation('common')
  const [isCreatingProduct, setIsCreatingProduct] = useState(false)
  const {
    getSubcategories,
    subcategories,
    getCalculationMethods,
    createScopedActivityDataSource,
    getActivityDataSourcesWithoutStructure,
  } = useLog()
  const { data: categories } = useGetCategoriesQuery()
  const [form] = useForm()
  const [calculationMethods, setCalculationMethods] = useState<CalculationMethodWithIncludes[]>([])
  const [isFormValid, setIsFormValid] = useState(false)
  const [activityDataSources, setActivityDataSources] = useState<ActivityDataSource[]>([])

  const parseFormValues = (values: CreateProductFormValues): ParsedProductFormValues => {
    return {
      ..._.cloneDeep(values),
      activityDataSourceId: values.activityDataSourceId.at(-1) as number,
    }
  }

  const onSubmit = async (values: CreateProductFormValues): Promise<void> => {
    setIsCreatingProduct(true)
    const createdActivityDataSource = await createScopedActivityDataSource(parseFormValues(values))
    form.resetFields()
    setIsCreatingProduct(false)
    onClose(createdActivityDataSource)
  }

  const fetchCalculationMethods = async (
    subcategoryId: number,
    activityDataSourceId?: number,
  ): Promise<void> => {
    const apiCalculationMethods = await getCalculationMethods(subcategoryId, activityDataSourceId)
    if (apiCalculationMethods) {
      setCalculationMethods(apiCalculationMethods)
    }
  }

  const formValueChanges = (
    changedValues: Partial<CreateProductFormValues>,
    allValues: CreateProductFormValues,
  ): void => {
    const { subcategoryId, calculationMethodId, categoryId } = changedValues

    if (categoryId) {
      getSubcategories(categoryId)
      form.resetFields(['subcategoryId'])
    }

    if (subcategoryId) {
      fetchCalculationMethods(subcategoryId)
      form.resetFields(['calculationMethodId'])
    }

    if (calculationMethodId && allValues.subcategoryId) {
      fetchActivityDataSources(calculationMethodId, allValues.subcategoryId)
      form.resetFields(['activityDataSourceId'])
    }
    const formKeys = Object.keys(allValues)
    const areFieldsTouched = formKeys.every((key) => form.isFieldTouched(key))
    const isValid = areFieldsTouched && Object.values(allValues).every((value) => !!value)
    setIsFormValid(isValid)
  }

  const fetchActivityDataSources = async (
    calculationMethodId?: number,
    subcategoryId?: number,
  ): Promise<void> => {
    const ads = await getActivityDataSourcesWithoutStructure(subcategoryId, calculationMethodId)
    if (ads) {
      setActivityDataSources(ads)
    }
  }

  const subcategoriesOptions = useCallback((): SelectOptionsProperties[] => {
    if (!subcategories || !subcategories.length) {
      return []
    }
    return subcategories.map(({ id: value, name: label }) => ({ value, label }))
  }, [subcategories])

  const categoriesOptions = useCallback((): SelectOptionsProperties[] => {
    if (!categories || !categories.length) {
      return []
    }
    return categories.map(({ id: value, name: label }) => ({ value, label }))
  }, [categories])

  const calculationMethodsOptions = useCallback((): SelectOptionsProperties[] => {
    if (!calculationMethods.length) {
      return []
    }
    return calculationMethods.map(({ id: value, name: label }) => ({ value, label }))
  }, [calculationMethods])

  return (
    <Modal
      title={t('product.create-btn')}
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={() => onClose()}
      okText={t('product.save-product')}
      okButtonProps={{ disabled: !isFormValid }}
      confirmLoading={isCreatingProduct}
    >
      <Form
        category={AnalyticsCategories.FACTOR_REQUESTS}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onValuesChange={formValueChanges}
      >
        <Form.Item
          label={t('product.lifecycle-steps.configuration.product-name.label')}
          name="name"
          rules={[{ required: true, message: t('product.validations.required-name') }]}
        >
          <InputField />
        </Form.Item>
        <Form.Item
          label={t('product.category')}
          name="categoryId"
          rules={[{ required: true, message: t('product.validations.required-category') }]}
          tooltip={{
            title: t('product.category-description'),
            icon: <HiOutlineInformationCircle color={GRAY_400} />,
          }}
        >
          <Select
            placeholder={t('suppliers.factor.option-placeholder')}
            options={categoriesOptions()}
            showSearch={true}
          />
        </Form.Item>
        <Form.Item
          label={t('product.subcategory')}
          name="subcategoryId"
          rules={[{ required: true, message: t('product.validations.required-subcategory') }]}
          tooltip={{
            title: t('product.subcategory-description'),
            icon: <HiOutlineInformationCircle color={GRAY_400} />,
          }}
        >
          <Select
            placeholder={t('suppliers.factor.option-placeholder')}
            options={subcategoriesOptions()}
            showSearch={true}
            disabled={!form.getFieldValue('categoryId')}
          />
        </Form.Item>
        <Form.Item
          label={t('calculation-methods.all')}
          name="calculationMethodId"
          rules={[
            { required: true, message: t('product.validations.required-calculation-method') },
          ]}
        >
          <Select
            placeholder={t('calculation-methods.select')}
            options={calculationMethodsOptions()}
            disabled={!form.getFieldValue('subcategoryId')}
          />
        </Form.Item>
        <Form.Item
          label={t('product.parent')}
          name="activityDataSourceId"
          rules={[{ required: true, message: t('product.validations.required-parent') }]}
          tooltip={{
            title: t('product.parent-description'),
            icon: <HiOutlineInformationCircle color={GRAY_400} />,
          }}
        >
          <ActivityDataSourceLazyLoadedCascader
            disabled={
              !form.getFieldValue('subcategoryId') || !form.getFieldValue('calculationMethodId')
            }
            activityDataSources={activityDataSources}
            placeholder={t('suppliers.factor.option-placeholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddProductModal
