import { ModeledImpact } from '@prisma/client'

import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { TAG_MODELED_IMPACTS } from './constants'

const modeledImpactSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getModeledImpacts: builder.query<ModeledImpact[], void>({
      providesTags: [TAG_MODELED_IMPACTS],
      query: () => ({
        url: logApiGatewayClient.modeledImpacts.GET_MANY,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetModeledImpactsQuery } = modeledImpactSlice

export default modeledImpactSlice
