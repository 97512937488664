/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { LogSorter, PageFilter } from '@cozero/models'

import useFiltersHandler from '../hooks/useFilters'
import { SearchFilters } from '../types/general'

export interface FiltersContextInterface {
  filters: PageFilter[]
  sorters: LogSorter[]
  saveFilters: (filters: PageFilter[]) => void
  saveSorters: (sorting: LogSorter[]) => void
  pageNumber: number
  savePageNumber: (currentPage: number) => void
  resetSearch: () => void
  saveConfig: ({ filters, sorters }: { filters?: PageFilter[]; sorters?: LogSorter[] }) => void
}
const encodeFilters = (data: SearchFilters): string => {
  try {
    return window.btoa(encodeURIComponent(JSON.stringify(data)))
  } catch (error) {
    return JSON.stringify({})
  }
}

const decodeFilters = (str: string): SearchFilters => {
  try {
    return JSON.parse(decodeURIComponent(window.atob(str)))
  } catch (error) {
    return {
      pageNumber: 1,
    }
  }
}

export const settingsContext = React.createContext<FiltersContextInterface | undefined>(undefined)

const { Provider } = settingsContext

export function useFiltersContext(): FiltersContextInterface {
  const contextValue = useContext(settingsContext)

  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
  saveQueryString?: boolean
}

const FiltersProvider: React.FC<ProviderProps> = ({
  children,
  saveQueryString = true,
}: ProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const previousSearchStr = React.useRef(searchParams.get('search'))
  const previousSearch = React.useMemo(
    () =>
      previousSearchStr.current ? decodeFilters(previousSearchStr.current) : { pageNumber: 1 },
    [],
  )

  const filtersAccessors = useFiltersHandler(previousSearch)

  const { filters, sorters, pageNumber } = filtersAccessors

  const data: SearchFilters = React.useMemo(
    () => ({
      filters,
      sorters,
      pageNumber,
    }),
    [filters, pageNumber, sorters],
  )

  useEffect(() => {
    if (saveQueryString) {
      setSearchParams(
        (queryParams) => {
          // Merge search params
          queryParams.set('search', encodeFilters(data))
          return queryParams
        },
        { replace: true },
      )
    }
  }, [data, saveQueryString, searchParams, setSearchParams])

  return <Provider value={filtersAccessors}>{children}</Provider>
}

export default FiltersProvider
