import React, { memo, useId, useMemo } from 'react'

import { Input, InputNumber, Skeleton } from 'antd/es'
import { valueType } from 'antd/es/statistic/utils'

import classnames from 'classnames'

import i18n from '@/i18n'
import { parseLocaleNumber } from '@/utils/number'

import DatePicker from '../DatePicker'
import Label from '../Label'
import Tag from '../Tag'
import WikiIcon from '../WikiIcon'

import classes from './InputField.module.less'
import { InputFieldProps } from './types'

const InputField = ({
  className = '',
  size = 'middle',
  label,
  optional,
  ...rest
}: InputFieldProps): JSX.Element => {
  const id = useId()
  const classNames = classnames(
    classes.inputField,
    rest.type === 'number' && classes.numberField,
    className,
  )

  const LabelComponent = useMemo(() => {
    const htmlFor = rest.id ? rest.id : rest.name ? rest.name : id

    if (label && typeof label === 'string') {
      return <Label htmlFor={htmlFor}>{label}</Label>
    }

    if (label && typeof label === 'object') {
      return (
        <Label htmlFor={htmlFor}>
          {label.text}
          <WikiIcon title={label.tooltipText} url={label.url} />
        </Label>
      )
    }
    return null
  }, [label, id, rest.id, rest.name])

  const InputTag = useMemo(() => {
    if (rest.type === 'search') {
      return <Input.Search id={id} className={classNames} size={size} {...rest} />
    }

    if (rest.type === 'textarea') {
      return <Input.TextArea id={id} className={classNames} size={size} {...rest} />
    }

    if (rest.type === 'date') {
      return <DatePicker id={id} className={classNames} size={size} {...rest} />
    }

    if (rest.type === 'number') {
      const { suffix, format, ...props } = rest

      const locale = localStorage.getItem('i18nextLng') ?? i18n.language
      const formatter = (value: valueType | undefined): string =>
        value
          ? new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(Number(value))
          : ''
      const parser = (localizedValue: string | undefined): string | number =>
        localizedValue ? parseLocaleNumber(localizedValue, locale) : ''

      return (
        <InputNumber
          id={id}
          className={classNames}
          addonAfter={suffix}
          size={size}
          controls={false}
          formatter={format ? formatter : undefined}
          parser={format ? parser : undefined}
          {...props}
          // InputNumber type needs to be "text", otherwise it messes up its internal logic
          type="text"
        />
      )
    }

    if (rest.type === 'loading') {
      const inputLoaderPaddingMapping: Record<typeof size, string> = {
        small: '-sm',
        middle: '',
        large: '-lg',
      }
      return (
        <div className={classes.inputLoadingWrapper}>
          <Input className={classNames} size={size} {...rest} />
          <div
            className={classnames(
              `ant-input${inputLoaderPaddingMapping[size]}`,
              classes.inputLoaderContainer,
            )}
          >
            <Skeleton.Input className={classes.inputLoader} active />
          </div>
        </div>
      )
    }

    return <Input className={classNames} size={size} {...rest} />
  }, [classNames, rest, id, size])

  return (
    <>
      {LabelComponent}
      {optional && <Tag className={classes.optionalTag}>Optional</Tag>}
      {InputTag}
    </>
  )
}

export default memo(InputField)
