import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Result, Spin } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useSubscriptionContext } from '@/contexts/subscription'
import { useLazyGetUserDataQuery } from '@/redux/auth'

const PaymentSuccess = (): ReactElement => {
  const { t } = useTranslation('common')
  const { loading, getCheckoutSession, checkoutSession } = useSubscriptionContext()
  const [getMe] = useLazyGetUserDataQuery()
  const query = new URLSearchParams(useLocation().search)
  const sessionId = query.get('session_id')

  useEffect(() => {
    if (sessionId) {
      getCheckoutSession(sessionId)
    }
  }, [sessionId])

  useEffect(() => {
    if (checkoutSession) {
      getMe()
    }
  }, [checkoutSession])

  return (
    <Spin spinning={loading} tip={t('loading')}>
      {checkoutSession && (
        <Result
          status="success"
          title={t('subscription.success.title')}
          subTitle={t('subscription.success.subtitle')}
          extra={[
            <a href={routes.overview.dashboard} key="1">
              <Button category={AnalyticsCategories.PAYMENT} action={'success'}>
                {t('subscription.success.button')}
              </Button>
            </a>,
          ]}
        />
      )}
    </Spin>
  )
}

export default PaymentSuccess
