export const getStoredItem = (key: string): unknown => {
  const item = window.localStorage.getItem(key)
  if (item) {
    try {
      return JSON.parse(item)
    } catch {
      return undefined
    }
  }
  return undefined
}

export const CCF_BI_VERSION = 'CCF_BI_VERSION'
export const setCCFBiVersion = (version: 'v1' | 'v2'): void => {
  window.localStorage.setItem(CCF_BI_VERSION, version)
}

export const getCCFBiVersion = (): 'v1' | 'v2' | null => {
  return window.localStorage.getItem(CCF_BI_VERSION) as 'v1' | 'v2' | null
}

const DESTINATION = 'COZERO_DESTINATION'

export const setDestination = (): void => {
  window.localStorage.setItem(DESTINATION, window.location.pathname)
}

export const getDestination = (): string | null => {
  const destination = window.localStorage.getItem(DESTINATION)
  if (destination) {
    window.localStorage.removeItem(DESTINATION)
  }
  return destination
}
