import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { Log, Page, TableViewProductLog } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  selectCalculationChangelogRecord,
  useFindChangelogProductsQuery,
} from '@/redux/methodologyChangelogs'

interface IUseImpactedLogs {
  products?: Page<TableViewProductLog>
  productsPage: number
  productsPageSize: number
  setLogPage: Dispatch<SetStateAction<number>>
  setProductsPageSize: Dispatch<SetStateAction<number>>
  loadingProducts: boolean
}

const useImpactedLogs = (): IUseImpactedLogs => {
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const [productsPage, setProductsPage] = useState(1)
  const [productsPageSize, setProductsPageSize] = useState(5)
  const { data: products, isFetching: loadingProducts } = useFindChangelogProductsQuery(
    {
      globalChangelogId: selectedCalculationChangelogRecord?.methodologyChangelogGlobalId,
      subcategoryId: selectedCalculationChangelogRecord?.subcategoryId,
      page: productsPage,
      pageSize: productsPageSize,
      businessUnitId: selectedBusinessUnit!.id,
    },
    {
      skip:
        !selectedBusinessUnit ||
        !selectedCalculationChangelogRecord?.methodologyChangelogGlobalId ||
        !selectedCalculationChangelogRecord?.subcategoryId,
    },
  )

  return {
    products,
    productsPage,
    productsPageSize,
    setLogPage: setProductsPage,
    setProductsPageSize,
    loadingProducts,
  }
}

export default useImpactedLogs
