import { CreateLogDto, SearchLogsResultDto, UpdateLogDto } from '@cozero/dtos'
import { Log, LogSorter, PageFilter, TableViewLog } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { TAG_PAGINATED_LOG_ENTRIES_EMISSIONS } from '../logEntries/tags'

import { TAG_LOG, TAG_PAGINATED_LOGS } from './tags'

export const GET_LOG = 'getLog'
export const CREATE_LOG = 'createLog'
export const EDIT_LOG = 'editLog'
export const HAS_LOG_ENTRIES = 'hasLogEntries'
export const DELETE_LOG = 'deleteLog'
export const GET_PAGINATED_LOGS = 'getPaginatedLogs'
export const GET_FULL_LOG_RANGE = 'getFullLogRange'

const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_LOG]: builder.query<Log | undefined, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logs.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG, id: result?.id }],
    }),
    [CREATE_LOG]: builder.mutation<Log, Partial<CreateLogDto>>({
      query: (log) => ({
        url: logApiGatewayClient.logs.CREATE,
        method: 'POST',
        data: log,
      }),
      invalidatesTags: (result) => [
        { type: TAG_LOG, id: result?.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
      ],
    }),
    [GET_FULL_LOG_RANGE]: builder.query<
      { startDate?: Date; endDate?: Date },
      { selectedBusinessUnitId: number }
    >({
      query: (params) => ({
        url: logApiGatewayClient.logs.GET_RANGE,
        method: 'GET',
        params,
      }),
    }),
    [EDIT_LOG]: builder.mutation<Log, { id: number; log: Partial<UpdateLogDto> }>({
      query: ({ id, log }) => ({
        url: logApiGatewayClient.logs.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PUT',
        data: log,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_LOG, id: arg.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
      ],
    }),
    [DELETE_LOG]: builder.mutation<{ count: number } | undefined, Log>({
      query: (log) => ({
        url: logApiGatewayClient.logs.DELETE_ONE.replace(':id', log.id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: TAG_LOG, id: arg.id },
        { type: TAG_PAGINATED_LOGS, id: PARTIAL_LIST },
        TAG_PAGINATED_LOG_ENTRIES_EMISSIONS,
      ],
    }),
    [GET_PAGINATED_LOGS]: builder.query<
      SearchLogsResultDto,
      {
        query: {
          type: string
          page: number
          pageSize: number
          filters?: Omit<PageFilter, 'options'>[]
        }
        businessUnitId?: number
        sort?: LogSorter[]
      }
    >({
      query: (log) => ({
        url: logApiGatewayClient.logs.SEARCH,
        method: 'POST',
        data: log,
      }),
      providesTags: (result) =>
        providesList<TableViewLog[], typeof TAG_PAGINATED_LOGS>(
          result?.logs,
          TAG_PAGINATED_LOGS,
          PARTIAL_LIST,
        ),
    }),
    [HAS_LOG_ENTRIES]: builder.query<
      boolean,
      { businessUnitId?: number; startDate?: Date; endDate?: Date }
    >({
      query: (params) => ({
        url: logApiGatewayClient.logEntries.EXISTS,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetLogQuery,
  useLazyGetLogQuery,
  useCreateLogMutation,
  useHasLogEntriesQuery,
  useLazyHasLogEntriesQuery,
  useEditLogMutation,
  useDeleteLogMutation,
  useGetPaginatedLogsQuery,
  useGetFullLogRangeQuery,
  useLazyGetFullLogRangeQuery,
} = logsApiSlice

export default logsApiSlice
