import { ForecastStatus } from '@prisma/client'

import {
  GetForecastStatusDto,
  StrategyBuilderOverviewValuesData,
  StrategyBuilderOverviewValuesDto,
  UpsertForecastDto,
} from '@cozero/dtos'
import {
  ForecastRegion,
  ForecastScenario,
  ForecastUnit,
  StrategyBuilderState,
} from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { FormValues } from '@/molecules/ForecastOnboardingSettings/ForecastOnboardingSettings'

import { providesList } from '@/utils/redux'

import { TAG_ACT_CLOSED_PERIOD } from '../actClosedPeriods/api'
import { TAG_CONSOLIDATED_GRAPH, TAG_WATERFALL_GRAPH } from '../actGraphs'
import apiSlice from '../api'

const GET_STRATEGY_BUILDER_STATE = 'getStrategyBuilderState'
const GET_FORECAST_REGIONS = 'getForecastRegions'
const GET_FORECAST_SCENARIOS = 'getForecastScenarios'

const SETUP_STRATEGY_BUILDER = 'setupStrategyBuilder'
const CREATE_FORECAST = 'createForecast'
const GET_FORECAST_STATUS = 'getForecastStatus'
const GET_FORECAST_UNITS = 'getForecastUnits'
const GET_STRATEGY_BUILDER_OVERVIEW_VALUES = 'getStrategyBuilderOverviewValues'

export const TAG_GET_STRATEGY_BUILDER_STATE = 'TAG_GET_STRATEGY_BUILDER_STATE'
export const TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES = 'TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES'
export const TAG_FORECAST_STATUS = 'TAG_FORECAST_STATUS'
const TAG_FORECAST_REGIONS = 'TAG_FORECAST_REGIONS'
const TAG_FORECAST_SCENARIOS = 'TAG_FORECAST_SCENARIOS'
const TAG_FORECAST_UNITS = 'TAG_FORECAST_UNITS'

const strategyBuilderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_STRATEGY_BUILDER_STATE]: builder.query<StrategyBuilderState, { businessUnitId: number }>({
      query: ({ businessUnitId }) => ({
        url: '/act/strategy-builder',
        method: 'GET',
        params: { businessUnitId },
      }),
      providesTags: () => [{ type: TAG_GET_STRATEGY_BUILDER_STATE }],
    }),

    [SETUP_STRATEGY_BUILDER]: builder.mutation<
      void,
      FormValues & {
        closedPeriodId: number
      }
    >({
      query: (data) => {
        return {
          url: '/act/strategy-builder/setup',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: () => [
        { type: TAG_GET_STRATEGY_BUILDER_STATE },
        { type: TAG_FORECAST_STATUS },
        { type: TAG_ACT_CLOSED_PERIOD },
        { type: TAG_WATERFALL_GRAPH },
        { type: TAG_CONSOLIDATED_GRAPH },
        { type: TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES },
      ],
    }),

    [CREATE_FORECAST]: builder.mutation<{ id: number }, UpsertForecastDto>({
      query: (data) => ({
        url: actApiGatewayClient.forecasts.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [
        { type: TAG_GET_STRATEGY_BUILDER_STATE },
        { type: TAG_FORECAST_STATUS },
        { type: TAG_ACT_CLOSED_PERIOD },
      ],
    }),

    [GET_FORECAST_STATUS]: builder.query<ForecastStatus, GetForecastStatusDto>({
      query: ({ businessUnitId }) => ({
        url: actApiGatewayClient.graphs.GET_FORECAST_STATUS,
        method: 'GET',
        params: {
          businessUnitId,
        },
      }),
      providesTags: () => [{ type: TAG_FORECAST_STATUS }],
    }),

    [GET_FORECAST_SCENARIOS]: builder.query<ForecastScenario[], void>({
      query: () => ({
        url: actApiGatewayClient.forecasts.GET_SCENARIOS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastScenario[], typeof TAG_FORECAST_SCENARIOS>(
          res,
          TAG_FORECAST_SCENARIOS,
        ),
    }),
    [GET_FORECAST_REGIONS]: builder.query<ForecastRegion[], void>({
      query: () => ({
        url: actApiGatewayClient.forecasts.GET_REGIONS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastRegion[], typeof TAG_FORECAST_REGIONS>(res, TAG_FORECAST_REGIONS),
    }),
    [GET_FORECAST_UNITS]: builder.query<ForecastUnit[], void>({
      query: () => ({
        url: actApiGatewayClient.forecasts.GET_FORECAST_UNITS,
        method: 'GET',
      }),
      providesTags: (res) =>
        providesList<ForecastUnit[], typeof TAG_FORECAST_UNITS>(res, TAG_FORECAST_UNITS),
    }),
    [GET_STRATEGY_BUILDER_OVERVIEW_VALUES]: builder.query<
      StrategyBuilderOverviewValuesData,
      StrategyBuilderOverviewValuesDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.overviewValues.GET_VALUES,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_GET_STRATEGY_BUILDER_OVERVIEW_VALUES }],
    }),
  }),
})

export const {
  useGetStrategyBuilderStateQuery,
  useLazyGetStrategyBuilderStateQuery,
  useGetForecastRegionsQuery,
  useGetForecastScenariosQuery,
  useSetupStrategyBuilderMutation,
  useCreateForecastMutation,
  useGetForecastStatusQuery,
  useGetForecastUnitsQuery,
  useGetStrategyBuilderOverviewValuesQuery,
  useLazyGetStrategyBuilderOverviewValuesQuery,
} = strategyBuilderApiSlice

export default strategyBuilderApiSlice
