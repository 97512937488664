import React from 'react'

import { IconProps } from '@/types/general'

const Bookmark = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V19C16 19.3466 15.8205 19.6684 15.5257 19.8507C15.2309 20.0329 14.8628 20.0494 14.5528 19.8944L8 16.618L1.44721 19.8944C1.13723 20.0494 0.769085 20.0329 0.474269 19.8507C0.179452 19.6684 0 19.3466 0 19V3ZM3 2C2.44772 2 2 2.44772 2 3V17.382L7.55279 14.6056C7.83431 14.4648 8.16569 14.4648 8.44721 14.6056L14 17.382V3C14 2.44772 13.5523 2 13 2H3Z"
        fill={color}
      />
    </svg>
  )
}

export default Bookmark
