import { useMemo } from 'react'

import { FactorRequestDetailsView } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'

export const useRequestIsTargetedAtMe = (
  factorRequest: FactorRequestDetailsView | undefined,
): boolean => {
  const user = useAppSelector(selectUser)

  return useMemo(
    () =>
      user?.organizationId === factorRequest?.targetOrganizationId &&
      user?.organizationId !== factorRequest?.organizationId,
    [user, factorRequest],
  )
}
