/*
 * StepShare.tsx
 *
 * Code for the second step of the supplier onboarding process. This step is where the supplier
 * shares the product lifecycle steps and the emissions associated with each step.
 */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Input, Row, Switch, UploadProps } from 'antd'
import { Upload } from 'antd/es'
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

import PaperClipIcon from '@heroicons/react/20/solid/PaperClipIcon'
import classNames from 'classnames'

import { FactorRequest, Product } from '@cozero/models'

import EmissionTag from '@/molecules/EmissionTag'

import Button from '@/atoms/Button'
import LazyLoadImage from '@/atoms/LazyLoadImage'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import placeholderImage from '@/assets/placeholder-image.png'
import { AnalyticsCategories } from '@/constants/analyticsCategories'

import ActionCard from '../ActionCard'
import { IProductLifecycleEmission } from '../hooks/useSupplierOnboarding'

import classes from './StepShare.module.less'

interface IStepShare {
  step: 'STEP_1' | 'STEP_2'
  endedOnboarding: boolean
  onSubmit: () => Promise<void>
  shareProductLifecycleSteps: boolean
  setShareProductLifecycleSteps: (value: boolean) => void
  showShareModal: boolean
  totalProductEmissions: number | undefined
  mass: number | null | undefined
  selectedProduct: Product | undefined
  productLifecycleEmissions: IProductLifecycleEmission[] | undefined
  factorRequest: FactorRequest | undefined | null
  comment: string
  setComment: (value: string) => void
  fileList: UploadFile[]
  onUpload: ({ fileList }: UploadChangeParam<UploadFile<unknown>>) => void
  isSubmitting: boolean
}

const StepShare = ({
  step,
  endedOnboarding,
  onSubmit,
  shareProductLifecycleSteps,
  setShareProductLifecycleSteps,
  totalProductEmissions,
  mass,
  selectedProduct,
  productLifecycleEmissions,
  factorRequest,
  comment,
  setComment,
  fileList,
  onUpload,
  isSubmitting,
}: IStepShare): JSX.Element => {
  const { t } = useTranslation('common')

  // To circumvent default behavior of Upload from antd
  const dummyRequest: UploadProps['customRequest'] = ({ file, onSuccess }) => {
    // Sending to the end of the stack to make sure the component state is already up to date
    setTimeout(() => {
      onSuccess && onSuccess(file)
    })
  }

  const disabled = useMemo(() => step === 'STEP_1', [step])

  const lifecycleStepsClassname = classNames(classes.lifecycleStepsSection, {
    [classes.lifecycleStepsSectionDisabled]: !shareProductLifecycleSteps,
  })

  return (
    <Col span={24}>
      <Row>
        <Col span={24}>
          <ActionCard
            disabled={disabled}
            title={t('onboarding.suppliers.steps.share.title')}
            text={t('onboarding.suppliers.steps.share.text')}
            status={disabled ? t('onboarding.suppliers.waiting') : t('onboarding.suppliers.todo')}
            stepNumber={2}
          >
            <>
              <Col span={23} offset={1} className={classes.singleRow}>
                <Row>
                  <Row className={classes.productDetailsSection} gutter={36}>
                    <Col span={8}>
                      <LazyLoadImage
                        src={selectedProduct?.image?.url ?? placeholderImage}
                        className={classes.image}
                      />
                    </Col>
                    <Col span={14} className={classes.productValuesSection}>
                      <Row justify="center" align="bottom" gutter={[0, 28]}>
                        <Col span={12}>
                          <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                            {t('onboarding.suppliers.steps.share.fields.product')}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Tag size="sm" type="default">
                            {selectedProduct?.name ??
                              factorRequest?.activityDataSource?.name?.toString() ??
                              '--'}
                          </Tag>
                        </Col>
                        <Col span={12}>
                          <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                            {t('onboarding.suppliers.steps.share.fields.emissions')}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <EmissionTag
                            mass={mass}
                            value={totalProductEmissions}
                            factorRequest={factorRequest}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col span={23} offset={1} className={classes.singleRow}>
                <Row gutter={36}>
                  <Col span={12}>
                    <Row>
                      <Col span={18}>
                        {t('onboarding.suppliers.steps.share.fields.shareLifecycleSteps')}
                      </Col>
                      <Col span={6}>
                        <Row justify="end">
                          <Switch
                            checked={!disabled && shareProductLifecycleSteps}
                            onClick={() =>
                              setShareProductLifecycleSteps(!shareProductLifecycleSteps)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <hr className={classes.line}></hr>
                      </Col>
                    </Row>

                    <Row className={lifecycleStepsClassname}>
                      <Col span={24}>
                        {productLifecycleEmissions?.map((step) => (
                          <Row key={step.key + step.value} className={classes.lifecycleStep}>
                            <Col span={12}>
                              <Row justify="start">
                                <Text
                                  color="secondary"
                                  fontWeight="regular"
                                  mode="paragraph"
                                  size="xl"
                                >
                                  {t(
                                    'product.lifecycle-steps.' +
                                      (step.key.replace('product-', '') ??
                                        'product-configuration') +
                                      '.title',
                                  )}
                                </Text>
                              </Row>
                            </Col>
                            <Col span={12}>
                              <Row justify="end">
                                <EmissionTag
                                  mass={selectedProduct?.mass}
                                  value={step.value}
                                  factorRequest={factorRequest}
                                />
                              </Row>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[8, 8]} align="middle" justify="start">
                      <Col span={24}>
                        <Text color="default" fontWeight="regular" mode="paragraph" size="xl">
                          {t('onboarding.suppliers.steps.share.moreInfo')}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text color="secondary" fontWeight="regular" mode="paragraph" size="md">
                          {t('onboarding.suppliers.steps.share.moreInfoSubtitle')}
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Input.TextArea
                          disabled={disabled || endedOnboarding}
                          defaultValue={''}
                          placeholder={t('onboarding.suppliers.steps.share.commentPlaceholder')}
                          rows={1}
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                          autoSize
                        />
                      </Col>
                      <Col span={24}>
                        <div className={classes.uploadWrapper}>
                          <Upload
                            accept=".png,.jpeg,.jpg,.pdf,.xlsx"
                            fileList={fileList}
                            onChange={onUpload}
                            name="files"
                            multiple
                            customRequest={dummyRequest}
                            showUploadList
                          >
                            <Button
                              category={AnalyticsCategories.SUPPLIER}
                              action="file-upload"
                              prefixIcon={<PaperClipIcon width={16} height={16} />}
                            >
                              {t('onboarding.suppliers.steps.share.uploadFile')}
                            </Button>
                          </Upload>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Row justify="end" className={classes.singleRow}>
                <Col>
                  <Button
                    category={AnalyticsCategories.SUPPLIER}
                    action="supplierToPcf"
                    type="primary"
                    key="createPcf"
                    disabled={disabled || endedOnboarding}
                    onClick={onSubmit}
                    loading={isSubmitting}
                  >
                    {t('onboarding.suppliers.steps.share.button')}
                  </Button>
                </Col>
              </Row>
            </>
          </ActionCard>
        </Col>
      </Row>
    </Col>
  )
}

export default StepShare
