import React from 'react'

import { IconProps } from '@/types/general'

const Supplier = ({
  size = 20,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2903 16.1652H18.0471C17.692 15.5161 17.0999 14.9852 16.3897 14.8082L16.3902 5.898C16.3902 5.48475 16.0351 5.13079 15.6205 5.13079H4.96616V1.76721C4.96616 1.35397 4.61108 1 4.19655 1H1.76961C1.35507 1 1 1.35398 1 1.76721C1 2.18046 1.35508 2.53442 1.76961 2.53442H3.48594V14.8078C2.3612 15.1029 1.53263 16.1059 1.53263 17.3448C1.53263 18.82 2.71635 20 4.19613 20C5.55741 20 6.68215 18.9969 6.85962 17.6987H12.8972C13.0748 18.9969 14.1994 20 15.5607 20C16.922 20 18.0467 18.9969 18.2242 17.6987H19.2304C19.6449 17.6987 20 17.3448 20 16.9315C20.0004 16.5191 19.6449 16.1652 19.2898 16.1652L19.2903 16.1652ZM15.5616 18.4665C14.9105 18.4665 14.3779 17.9355 14.3779 17.2864C14.3779 16.6374 14.9105 16.1064 15.5616 16.1064C16.2127 16.1064 16.7453 16.6374 16.7453 17.2864C16.7453 17.9351 16.2717 18.4665 15.5616 18.4665ZM10.8853 6.60618V8.49459H8.93199V6.60618H10.8853ZM7.45222 6.60618V9.20257C7.45222 9.61581 7.8073 9.96977 8.22183 9.96977H11.6549C12.0694 9.96977 12.4245 9.6158 12.4245 9.20257V6.60618H14.9105V14.8081C14.2004 15.0439 13.5493 15.516 13.2532 16.1651H6.74197C6.38688 15.516 5.79481 14.9851 5.08462 14.8081V6.60618H7.45222ZM4.25611 18.4665C3.60499 18.4665 3.07239 17.9355 3.07239 17.2864C3.07239 16.6374 3.60502 16.1064 4.25611 16.1064C4.9072 16.1064 5.43983 16.6374 5.43983 17.2864C5.43983 17.9351 4.9072 18.4665 4.25611 18.4665Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Supplier
