import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Options, Plot, SunburstOptions } from '@antv/g2plot'
import { ChartPivotRow } from '@cubejs-client/core'
import { SunburstChart } from '@opd/g2plot-react'

import { ReportType } from '@cozero/models'

import useGraphs from '@/hooks/useGraphs'
import { breadcrumbsTextStyle, legendStyles } from '@/styles/graphs'

const Sunburst = React.memo(
  ({
    height,
    chartData,
    valueKey,
    chartRef,
    svgChartRef,
    visible,
    dimensionType,
  }: {
    height: number | null
    chartData: ChartPivotRow[]
    valueKey: string
    chartRef?: MutableRefObject<Plot<Options> | null>
    svgChartRef?: MutableRefObject<Plot<Options> | null>
    visible: boolean
    dimensionType?: string
  }): ReactElement | null => {
    const firstLevel = Array.from(new Set(chartData.map((datum) => datum.xValues[0]).flat()))
    const { customTooltip, graphStyles, graphDataDepth } = useGraphs({
      graphType: ReportType.SUNBURST,
      dimensionType,
      graphData: chartData,
      valueUnitKey: valueKey,
    })
    const { t } = useTranslation('common')
    const [pieProps, setPieProps] = useState<SunburstOptions>({ data: [] })

    useEffect(() => {
      const props: SunburstOptions = {
        ...graphStyles,
        sunburstStyle: {
          stroke: 'white',
          lineWidth: 1,
        },
        innerRadius: 0.3,
        tooltip: {
          customItems: (items) =>
            items.map((item) => {
              return {
                ...item,
                name: item.data.path,
                value: item.data.value,
              }
            }),
          ...customTooltip,
        },
        data: {
          label: `${t('reports.company-emissions')}`,
          children: firstLevel.map((level) => ({
            label: level,
            children: Array.from(
              new Set(
                chartData
                  .filter((datum) => datum.xValues[0] === level)
                  .map((datum) => datum.xValues[1])
                  .flat(),
              ),
            ).map((level2) => ({
              label: level2,
              children: Array.from(
                new Set(
                  chartData
                    .filter((datum) => datum.xValues[1] === level2 && datum.xValues[0] === level)
                    .map((datum) => ({ label: datum.xValues[2], value: datum[valueKey] }))

                    .flat(),
                ),
              ).map((level3) => ({
                label: level3.label,
                sum: level3.value,
              })),
            })),
          })),
        },
        hierarchyConfig: {
          field: 'sum',
          activeDepth: graphDataDepth,
        },
        legend: legendStyles,
        drilldown: {
          breadCrumb: {
            position: 'top-left',
            textStyle: breadcrumbsTextStyle,
          },
        },
      }
      setPieProps(props)
    }, [dimensionType, chartData, graphStyles])

    if (!height) {
      return null
    }

    return (
      <div>
        <SunburstChart
          height={height}
          {...pieProps}
          style={{ display: visible ? 'block' : 'none' }}
          renderer={'canvas'}
          chartRef={(ref) => {
            if (ref && chartRef) {
              chartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
        <SunburstChart
          height={height}
          {...pieProps}
          style={{ display: 'none' }}
          renderer={'svg'}
          width={1000}
          chartRef={(ref) => {
            if (ref && svgChartRef) {
              svgChartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
      </div>
    )
  },
)

Sunburst.displayName = 'Sunburst'

export default Sunburst
