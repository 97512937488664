import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight } from 'react-icons/hi'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { Col, Row, Spin, message } from 'antd/es'
import { RuleObject } from 'antd/es/form'
import { Store } from 'antd/es/form/interface'

import jwtDecode from 'jwt-decode'

import { routes } from '@cozero/utils'

import ErrorBoundary from '@/templates/ErrorBoundary'
import { AppErrorFallback } from '@/templates/ErrorFallback/AppErrorFallback'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'

import logo from '@/assets/COZERO_LOGO_TYPE.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import i18n from '@/i18n'
import { useLazyActivateUserQuery } from '@/redux/auth'

import authClasses from '../Auth.module.less'

import classes from './Activate.module.less'

const Activate = (): ReactElement => {
  const [form] = Form.useForm()
  const { t } = useTranslation('common')
  const [activate, { isLoading: loading, error }] = useLazyActivateUserQuery()
  const [tokenError, setTokenError] = useState<boolean>(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const onFinish = useCallback(
    async (values: Store): Promise<void> => {
      try {
        setTokenError(false)
        if (token) {
          const { email, password } = form.getFieldsValue(['email', 'password'])
          await activate({
            token,
            email,
            password,
            locale: i18n.resolvedLanguage ?? 'en',
          }).unwrap()

          return navigate(`${routes.login.finish}?email=${values.email}`)
        }
      } catch (e) {
        message.error(t('general-errors.app-error'))
      }
    },
    [form],
  )

  // Set the email field value automatically from the JWT token
  useEffect(() => {
    if (token) {
      try {
        const content = jwtDecode<{ email: string }>(token)
        form.setFieldsValue({ email: content?.email ?? '' })
        setTokenError(false)
      } catch {
        setTokenError(true)
      }
    } else {
      setTokenError(true)
    }
  }, [token])

  if (!token) {
    return <Navigate to={routes.login.start} />
  }

  if (tokenError) {
    return <>{t('activate.error')}</>
  }

  return (
    <ErrorBoundary FallbackComponent={AppErrorFallback}>
      <div className={classes.container}>
        <Row className={classes.linkWrapper} justify="center">
          <img src={logo} className={classes.logo} alt="logo" />
        </Row>
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col xs={18} lg={12} xl={8} xxl={6}>
            <Spin tip={t('loading')} spinning={loading}>
              <Form
                form={form}
                category={AnalyticsCategories.SIGNUP}
                name="login"
                validateTrigger="onSubmit"
                onFinish={onFinish}
              >
                <Row gutter={[16, 8]}>
                  <Col xs={24}>
                    <Row justify="center">
                      <Col>
                        <div className={`${authClasses.fadedLabel} ${authClasses.orange}`}>
                          <span>{t('register.profile.tag')}</span>
                        </div>
                      </Col>
                      <Col xs={24}>
                        <h1 className={`${authClasses.title} ${authClasses.center}`}>
                          {t('register.profile.create')}
                        </h1>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <p className={`${authClasses.description} ${authClasses.center}`}>
                        {t('register.profile.description')}
                      </p>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="email"
                      rules={[{ type: 'email', required: true, message: t('email-msg') }]}
                    >
                      <InputField
                        className={`${authClasses.input}`}
                        placeholder="Email"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: t('login.password-msg') },
                        { min: 8, message: t('password.too-short') },
                      ]}
                    >
                      <InputField
                        className={authClasses.input}
                        type="password"
                        placeholder={t('password.placeholder')}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="confirm-password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: t('password.confirm'),
                        },
                        ({ getFieldValue }): RuleObject => ({
                          validator(_rule, value): Promise<void> {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(t('password.no-match'))
                          },
                        }),
                      ]}
                    >
                      <InputField
                        className={authClasses.input}
                        type="password"
                        placeholder={t('password.placeholder-confirm')}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Row justify="center">
                      <Button
                        category={AnalyticsCategories.SIGNUP}
                        action={'activate account'}
                        type="primary"
                        htmlType="submit"
                        className={`${authClasses.submitBtn} ${authClasses.orange}`}
                      >
                        {t('actions.next')}
                        <HiArrowRight />
                      </Button>
                    </Row>
                    <Row justify="center">
                      <Col xs={24}>{error && <Alert message={error.message} type="danger" />}</Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Col>
        </Row>
      </div>
    </ErrorBoundary>
  )
}

export default Activate
