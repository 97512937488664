import React from 'react'

import useSWR from 'swr'

import { Category } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import axios from '../utils/axios'

type UseCategories = {
  logCreationCategories?: Category[]
  mutate: (categories: Category[]) => void
  error: Error
  loading: boolean
}

/**
 * Hook to fetch categories from the API
 * @returns {UseCategories}
 */
const useLogCreationCategories = (hideExperimental = false): UseCategories => {
  const { data, mutate, error, isValidating } = useSWR<Category[]>(
    logApiGatewayClient.categories.GET_FOR_LOG_CREATION,
    async (uri) => {
      const { data } = await axios.get<Category[]>(uri)
      return data
    },
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  const logCreationCategories = React.useMemo(() => {
    let categories = data
    if (hideExperimental && data) {
      categories = data.filter((category) => !category.experimental)
    }
    return categories
  }, [data, hideExperimental])

  return {
    logCreationCategories,
    mutate,
    error,
    loading: !error && isValidating,
  }
}
export default useLogCreationCategories
