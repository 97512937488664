import React, { memo, useMemo } from 'react'

import { UserAvatarDto } from '@cozero/dtos'
import { User } from '@cozero/models'

import Text from '@/atoms/Text'

import classes from './Avatar.module.less'
import { AvatarContainer } from './AvatarContainer'
import { AvatarPicture } from './AvatarPicture'

export interface AvatarPictureProps {
  image?: Partial<User['photo']>
  size?: 'xs' | 'sm' | 'md'
  outlined?: boolean
  className?: string
  firstName?: User['firstName']
  lastName?: User['lastName']
}

export interface AvatarProps extends AvatarPictureProps {
  type?: 'icon' | 'full'
  user?: UserAvatarDto
  showTooltip?: boolean
}

const getPictureAttributesFromProps = ({
  user,
  firstName,
  lastName,
  image,
  ...props
}: AvatarProps): AvatarPictureProps => {
  if (!user) {
    return {
      ...props,
      firstName,
      lastName,
      image,
    }
  }

  return {
    ...props,
    firstName: user.firstName,
    lastName: user.lastName,
    image: user.photo,
  }
}

const getFullNameFromProps = ({ user, firstName, lastName }: AvatarProps): string => {
  if (!user) {
    return `${firstName} ${lastName}`
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }

  if (user.username) {
    return user.username
  }

  return user.email
}

const _Avatar = ({
  type = 'icon',
  showTooltip = true,
  ...props
}: AvatarProps): React.ReactElement => {
  const fullName = useMemo(
    () =>
      getFullNameFromProps({
        user: props.user,
        firstName: props.firstName,
        lastName: props.lastName,
      }),
    [props.lastName, props.firstName, props.user],
  )

  const renderContent = (): React.ReactElement => {
    if (type === 'full') {
      return (
        <>
          <AvatarPicture {...getPictureAttributesFromProps(props)} />
          <Text className={classes.avatarText}>{fullName}</Text>
        </>
      )
    }

    return <AvatarPicture {...getPictureAttributesFromProps(props)} />
  }

  return (
    <AvatarContainer tooltipTitle={fullName} showTooltip={showTooltip}>
      {renderContent()}
    </AvatarContainer>
  )
}

type AvatarSubComponents = {
  Picture: typeof AvatarPicture
  Container: typeof AvatarContainer
}

const memoizedAvatar = memo(_Avatar) as React.MemoExoticComponent<React.FC<AvatarProps>> &
  AvatarSubComponents

memoizedAvatar.Picture = AvatarPicture
memoizedAvatar.Container = AvatarContainer

/**
 * Props for the AvatarPicture component, which is a wrapper around the older Avatar component.
 *
 * **Key Improvements:**
 * - Can now work directly with a `UserAvatarDto` object, which encapsulates all necessary user details (such as avatar, name, etc.).
 * - Can display the user's full name next to the avatar picture when the `type` prop is set to `'full'`.
 * - Handles errors in loading the user's picture, allowing fallback handling when the image cannot be displayed.
 *
 *
 * @interface AvatarPictureProps
 * @property {'icon' | 'full'} [type] - Determines whether to display just the user's picture (`'picture'`), or the user's name along with the picture (`'full'`).
 */
export const Avatar = memoizedAvatar
