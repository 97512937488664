import React, { MutableRefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiDownload,
  HiOutlineDotsHorizontal,
  HiOutlineEye,
  HiOutlineTrash,
  HiSwitchVertical,
} from 'react-icons/hi'

import { Dropdown, Menu } from 'antd/es'
import { ItemType } from 'antd/es/menu/hooks/useItems'

import { ColumnOptions, MixOptions, Options, Plot } from '@antv/g2plot'
import { saveAs } from 'file-saver'
import { json2csv } from 'json-2-csv'

import { Report, ReportType } from '@cozero/models'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly } from '@/redux/auth'
import { CINDER_BLUE_50, CINDER_BLUE_80, RED_80 } from '@/styles/variables'
import { downloadImage } from '@/utils/graphs'
import { slugify } from '@/utils/string'
import { renderCSVRow } from '@/utils/table'

import classes from './GraphExportDropdown.module.less'
import graphAllowedOptions from './graphAllowedOptions'

type GraphRefs =
  | MutableRefObject<Plot<Options> | null>
  | MutableRefObject<Plot<MixOptions> | null>
  | MutableRefObject<Plot<ColumnOptions> | null>

interface DropdownOptionsProps {
  view: string
  report: Report
  graphDataAvailable: boolean
  disableDelete?: boolean
  onDeleteClick?: () => void
  switchAxis?: () => void
  toggleLegend?: () => void
  chartRef: GraphRefs
  svgChartRef: GraphRefs
  secondaryChartRef: MutableRefObject<Plot<Options> | null>
  secondarySvgChartRef: MutableRefObject<Plot<Options> | null>
}

const DropdownOptions = ({
  disableDelete,
  view,
  report,
  graphDataAvailable,
  onDeleteClick,
  switchAxis,
  toggleLegend,
  chartRef,
  svgChartRef,
  secondaryChartRef,
  secondarySvgChartRef,
}: DropdownOptionsProps): JSX.Element => {
  const { t, i18n } = useTranslation('translations')
  const { title, type, data } = report
  const [dropdownOptions, setDropdownOptions] = useState<ItemType[]>([])
  const isReadOnlyUser = useAppSelector(getIsUserReadOnly)
  const { trackAction } = useMixpanel()

  useEffect(() => {
    const options = [
      ...constructExportOptions(),
      includeCsvExportOption(),
      includeSwitchAxisOption(),
      includeShowLegendOption(),
    ]
    if (!disableDelete) {
      options.push(includeDeleteGraphOption())
    }
    setDropdownOptions(options)
  }, [view, report, graphDataAvailable, chartRef, svgChartRef, disableDelete])

  const constructExportOptions = (): ItemType[] => {
    if (!graphDataAvailable) {
      return []
    }

    const options = graphAllowedOptions.find((graph) => graph.type === report.type)
    const exportOptions = ['png', 'svg']

    return exportOptions.map((exportType) => {
      if (exportType === 'png' && options?.export.image) {
        return {
          key: exportType,
          label: `${t(`reports.download.${exportType}`, {
            type: t(`reports.menu.${report.type}`),
          })}`,
          icon: <HiDownload color={CINDER_BLUE_80} size={20} />,
          onClick: () => {
            trackAction(AnalyticsCategories.REPORTS, 'export', { key: report.key, format: 'png' })
            downloadImage(
              view === 'primary' && chartRef
                ? chartRef.current?.chart
                : secondaryChartRef.current?.chart
                ? secondaryChartRef.current?.chart
                : undefined,
              slugify(title || 'Report'),
            )
          },
          className: classes.menuItem,
        }
      } else if (exportType === 'svg' && options?.export.image) {
        return {
          key: exportType,
          label: `${t(`reports.download.${exportType}`, {
            type: t(`reports.menu.${report.type}`),
          })}`,
          icon: <HiDownload color={CINDER_BLUE_80} size={20} />,
          onClick: () => {
            trackAction(AnalyticsCategories.REPORTS, 'export', { key: report.key, format: 'svg' })
            return downloadImage(
              view === 'primary' && svgChartRef
                ? svgChartRef.current?.chart
                : secondarySvgChartRef
                ? secondarySvgChartRef.current?.chart
                : undefined,
              slugify(title || 'Report'),
            )
          },
          className: classes.menuItem,
        }
      }
      return null
    })
  }

  const includeSwitchAxisOption = (): ItemType => {
    if (graphDataAvailable && report && report.data && report.switchAxis && switchAxis) {
      return {
        key: 'switch-axis',
        label: t('reports.switch-axis'),
        icon: <HiSwitchVertical color={CINDER_BLUE_80} size={20} />,
        onClick: switchAxis,
        className: classes.menuItem,
      }
    } else {
      return null
    }
  }

  const includeCsvExportOption = (): ItemType => {
    const graphOptions = graphAllowedOptions.find((graph) => graph.type === report.type)

    if (report.data?.tablePivot?.length > 0 && graphOptions?.export.csv) {
      return {
        key: 'csv',
        label: `${t('reports.download.csv', { type: t(`reports.menu.${type}`) })}`,
        icon: <HiDownload color={CINDER_BLUE_80} size={20} />,
        className: classes.menuItem,
        onClick: () => {
          json2csv(
            data?.tablePivot.map((row) => renderCSVRow({ row, t, i18n })) ?? [],
            (error, data) =>
              data
                ? saveAs(
                    new Blob([data], {
                      type: 'text/csv',
                    }),
                    `${slugify(report.title || 'Report')}.csv`,
                  )
                : undefined,
          )
          trackAction(AnalyticsCategories.REPORTS, 'export', { key: report.key, format: 'csv' })
        },
      }
    } else {
      return null
    }
  }

  const includeShowLegendOption = (): ItemType => {
    const graphOptions = graphAllowedOptions.find((graph) => graph.type === report.type)

    if (
      graphDataAvailable &&
      report?.data &&
      graphOptions &&
      graphOptions.toggleLegend &&
      report?.type !== ReportType.TABLE &&
      toggleLegend
    ) {
      return {
        key: 'description',
        label: `${t('share.reports.show-description')}`,
        icon: <HiOutlineEye color={CINDER_BLUE_80} size={20} />,
        className: classes.menuItem,
        onClick: toggleLegend,
      }
    } else {
      return null
    }
  }

  const includeDeleteGraphOption = (): ItemType => {
    const graphOptions = graphAllowedOptions.find((graph) => graph.type === report.type)
    if (graphOptions && graphOptions.deletable && onDeleteClick && !isReadOnlyUser) {
      return {
        key: 'delete',
        label: `${t('share.reports.delete')}`,
        icon: <HiOutlineTrash color={RED_80} size={20} />,
        onClick: onDeleteClick,
        className: `${classes.menuItem} ${classes.danger}`,
      }
    } else {
      return null
    }
  }

  return <Menu className={classes.menu} items={dropdownOptions} />
}

interface GraphOptionsDropdownProps {
  view: string
  report: Report
  graphDataAvailable: boolean
  disableDelete?: boolean
  onDelete?: () => void
  switchAxis?: () => void
  toggleLegend?: () => void
  chartRef: GraphRefs
  svgChartRef: GraphRefs
  secondaryChartRef: MutableRefObject<Plot<Options> | null>
  secondarySvgChartRef: MutableRefObject<Plot<Options> | null>
}

export const GraphExportDropdown = ({
  view,
  report,
  graphDataAvailable,
  disableDelete,
  onDelete,
  switchAxis,
  toggleLegend,
  chartRef,
  svgChartRef,
  secondaryChartRef,
  secondarySvgChartRef,
}: GraphOptionsDropdownProps): JSX.Element => {
  return (
    <Dropdown
      trigger={['click']}
      placement="bottom"
      overlay={
        <DropdownOptions
          disableDelete={disableDelete}
          view={view}
          graphDataAvailable={graphDataAvailable}
          switchAxis={switchAxis}
          toggleLegend={toggleLegend}
          onDeleteClick={onDelete}
          report={report}
          chartRef={chartRef}
          svgChartRef={svgChartRef}
          secondaryChartRef={secondaryChartRef}
          secondarySvgChartRef={secondarySvgChartRef}
        />
      }
    >
      <HiOutlineDotsHorizontal
        color={CINDER_BLUE_50}
        style={{ cursor: 'pointer', marginRight: 2, alignSelf: 'center' }}
        size={24}
      />
    </Dropdown>
  )
}
