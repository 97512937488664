import { Territory } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_TERRITORIES } from './tags'

const territorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTerritories: builder.query<Territory[], void>({
      query: () => ({
        url: logApiGatewayClient.territories.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<Territory[], typeof TAG_TERRITORIES>(result, TAG_TERRITORIES),
    }),
  }),
})

export const { useGetTerritoriesQuery } = territorySlice

export default territorySlice
