import { LifecycleStepsID } from '@/pages/GenericLifecycleSteps/hooks/steps'

export function formatLifecycleSteps(
  steps: { id: number; value: number }[],
): { id: number; key: string; value: number }[] {
  if (!steps || steps.length === 0) {
    return []
  }

  return steps.map((step) => ({
    id: step.id,
    key: LifecycleStepsID[step.id] as string,
    value: step.value,
  }))
}

export function transformComputedReportKey(input: string | undefined): string | undefined {
  if (input === undefined) {
    return input
  }

  // Split the input string by hyphens
  const parts = input.split('-')

  // Capitalize each part and join with spaces
  return parts.map((part) => part.toUpperCase()).join(' ')
}
