import {
  ChangelogAffectedLog,
  ClosedPeriod,
  GroupedMethodologyChangelog,
  Log,
  Page,
  TableViewProductLog,
} from '@cozero/models'
import { calculationsApiGatewayClient } from '@cozero/uris'

import {
  TAG_CHANGELOG_CLOSED_PERIODS,
  TAG_CHANGELOG_LOGS,
  TAG_CHANGELOG_PRODUCTS,
} from '@/redux/logs/tags'
import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  FIND_CHANGELOG_CLOSED_PERIODS,
  FIND_CHANGELOG_LOGS,
  FIND_CHANGELOG_PRODUCTS,
  FIND_METHODOLOGY_CHANGELOG,
  FIND_ONE_METHODOLOGY_CHANGELOG,
} from './constants'

const methodologyChangelogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_METHODOLOGY_CHANGELOG]: builder.query<
      Page<GroupedMethodologyChangelog>,
      {
        page: number
        pageSize: number
        businessUnitId: number
      }
    >({
      query: (params) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_MANY,
        method: 'GET',
        params,
      }),
    }),
    [FIND_ONE_METHODOLOGY_CHANGELOG]: builder.query<
      GroupedMethodologyChangelog,
      {
        id: number
        businessUnitId: number
        subcategoryId: number
      }
    >({
      query: ({ id, businessUnitId, subcategoryId }) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_ONE.replace(
          ':globalChangelogId',
          id.toString(),
        ),
        method: 'GET',
        params: {
          businessUnitId,
          subcategoryId,
        },
      }),
    }),
    [FIND_CHANGELOG_CLOSED_PERIODS]: builder.query<
      { docs: ClosedPeriod[]; totalRecords: number },
      {
        businessUnitId: number
        globalChangelogId: number | undefined
        activityDataSourceId?: number
        subcategoryId?: number
        page: number
        pageSize: number
        isClosedPeriod?: boolean
      }
    >({
      query: (params) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_CHANGELOG_CLOSED_PERIODS,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesList<ClosedPeriod[], typeof TAG_CHANGELOG_CLOSED_PERIODS>(
          result?.docs,
          TAG_CHANGELOG_CLOSED_PERIODS,
          PARTIAL_LIST,
        ),
    }),
    [FIND_CHANGELOG_LOGS]: builder.query<
      Page<ChangelogAffectedLog>,
      {
        businessUnitId: number
        globalChangelogId: number | undefined
        page: number
        pageSize: number
        activityDataSourceId?: number
        subcategoryId?: number
        isClosedPeriod?: boolean
      }
    >({
      query: (params) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_CHANGELOG_LOGS,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesList<(Log & { closedPeriodIds?: number[] })[], typeof TAG_CHANGELOG_LOGS>(
          result?.data,
          TAG_CHANGELOG_LOGS,
          PARTIAL_LIST,
        ),
    }),
    [FIND_CHANGELOG_PRODUCTS]: builder.query<
      Page<TableViewProductLog>,
      {
        globalChangelogId: number | undefined
        page: number
        pageSize: number
        activityDataSourceId?: number
        subcategoryId?: number
        businessUnitId: number
      }
    >({
      query: (params) => ({
        url: calculationsApiGatewayClient.methodologyChangelog.GET_CHANGELOG_PRODUCTS,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesList<TableViewProductLog[], typeof TAG_CHANGELOG_PRODUCTS>(
          result?.data,
          TAG_CHANGELOG_PRODUCTS,
          PARTIAL_LIST,
        ),
    }),
  }),
})

export const {
  useFindMethodologyChangelogsQuery,
  useFindOneChangelogLogQuery,
  useFindChangelogLogsQuery,
  useFindChangelogClosedPeriodsQuery,
  useFindChangelogProductsQuery,
  usePrefetch,
} = methodologyChangelogApiSlice

export default methodologyChangelogApiSlice
