/* eslint react-hooks/exhaustive-deps: 2 */

import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectLastUpdateDate } from '@/redux/logEntriesOverview/selectors'
import { setLastUpdateDate } from '@/redux/logEntriesOverview/slice'

import { formatTime, getEstimatedLastCronUpdate } from '../utils/time'

export const useSetLastUpdateDate = (isFetching: boolean): void => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (typeof isFetching !== 'boolean') {
      return
    }

    if (isFetching) {
      dispatch(setLastUpdateDate(undefined))
    } else {
      dispatch(setLastUpdateDate(new Date()))
    }
  }, [dispatch, isFetching])
}

export const useLastUpdatedTime = (): string | null => {
  const user = useAppSelector(selectUser)
  const lastUpdateDate = useAppSelector(selectLastUpdateDate)

  if (!lastUpdateDate) {
    return null
  }

  return formatTime(
    getEstimatedLastCronUpdate(lastUpdateDate, {
      frequency: 10,
      executionTime: 4,
    }),
    user?.locale,
  )
}
