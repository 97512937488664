import { RootState } from '..'

import { OrganizationCarboonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import { SelectedRows } from './constants'

export const selectOrganizationEmissionsFilters = (
  state: RootState,
): OrganizationCarboonFootprintFormValues => {
  return state.logEntriesOverview.filters
}

export const selectLastUpdateDate = (state: RootState): Date | undefined => {
  return state.logEntriesOverview.lastUpdateDate
}

export const selectOrganizationEmissionsSelectedRows = (state: RootState): SelectedRows => {
  return state.logEntriesOverview.selectedRows
}
