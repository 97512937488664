import { Scope } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_SCOPES } from './tags'

export const GET_SCOPES = 'getScopes'

const scopesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_SCOPES]: builder.query<Scope[], void>({
      query: () => ({
        url: logApiGatewayClient.scopes.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) => providesList<Scope[], typeof TAG_SCOPES>(result, TAG_SCOPES),
    }),
  }),
})

export const { useGetScopesQuery, useLazyGetScopesQuery, usePrefetch } = scopesSlice

export default scopesSlice
