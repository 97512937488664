import React, { memo } from 'react'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { Link } from 'react-router-dom'

import { routes } from '@cozero/utils'

import LoadingSpinner from '@/atoms/LoadingSpinner'

import useImageState from '@/hooks/useImageState'

import classes from './NavCompanyHeader.module.less'

type Props = {
  logoSrc?: string | null
}
const Logo = ({ logoSrc }: Props): ReactElement => {
  const { error, loading, setImgRef } = useImageState()

  return (
    <div className={classes.logoWrapper}>
      <Link to={routes.overview.dashboard}>
        <img
          ref={setImgRef}
          src={logoSrc ?? ''}
          className={`${!error && !loading && classes.loaded}`}
        />
        {(error || !logoSrc) && <HiOutlineOfficeBuilding className={classes.logoIcon} />}
        {loading && !error && <LoadingSpinner size="2xs" />}
      </Link>
    </div>
  )
}

export default memo(Logo)
