import { TFunction } from 'react-i18next'

import { BusinessUnit } from '@cozero/models'

export const getBusinessUnitOptions = ({
  businessUnits,
  t,
}: {
  businessUnits?: BusinessUnit[]
  t: TFunction<'common'>
}): {
  key: string
  value: number
  label: string
  sublabel?: string
}[] => {
  return (businessUnits || []).map((d) => ({
    key: d.key,
    value: d.id,
    label: d.title,
    sublabel: d.parentUnit?.title
      ? t('business-unit.in', { parentUnit: d.parentUnit.title })
      : undefined,
  }))
}
