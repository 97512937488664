import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFiltersCount = <T extends Record<string, any>>({
  filters,
}: {
  filters: T
}): { count: number } => {
  const count = useMemo(() => {
    if (!filters) {
      return 0
    }

    return Object.values(filters).reduce((acc, filter) => {
      // Handle arrays (of any type)
      if (Array.isArray(filter)) {
        return acc + filter.length
      }

      // Handle strings (Count non-empty strings)
      if (typeof filter === 'string') {
        return acc + (filter.length > 0 ? 1 : 0)
      }

      // Handle numbers
      if (typeof filter === 'number') {
        return acc + 1
      }

      // Handle Date objects
      if (filter instanceof Date) {
        return acc + 1
      }

      // For all other types, count truthy values as 1
      if (filter) {
        return acc + 1
      }

      return acc
    }, 0)
  }, [filters])

  return {
    count,
  }
}
