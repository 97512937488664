import React, { useContext } from 'react'

import { RcFile } from 'antd/es/upload/interface'

import { CalculationType } from '@prisma/client'

import { CompanyEmissionOverviewDTO } from '@cozero/dtos'
import {
  ActivityDataSource,
  Assumption,
  BulkImport,
  BulkImportErrors,
  BulkImportResponse,
  CalculationMethod,
  Category,
  ClientUserOption,
  CreateBulkLog,
  Customer,
  ImportDetails,
  Input,
  Log,
  LogEntry,
  LogEntryTag,
  Page,
  PageFilter,
  PreviewCalculation,
  Product,
  ProductLogEntry,
  ProductTag,
  PutSignedUrl,
  Scope,
  Subcategory,
  Unit,
} from '@cozero/models'

import { CreateProductLogEntry } from '@/pages/GenericLifecycleSteps/GenericStep'

import { ParsedProductFormValues } from '@/organisms/FactorRequestForm/types'

import useLogHandler from '../hooks/useLog'

export interface LogContextInterface {
  error?: Error | string
  loading: boolean
  log?: Log
  units: Unit[]
  logs?: CreateBulkLog[]
  submitLogEntry: (
    logEntry: Omit<Partial<LogEntry>, 'inputs' | 'tags'> & {
      inputs: Partial<Input>[]
      tags: Partial<LogEntryTag>[]
    },
  ) => Promise<void>
  submitUpdateLogEntry: (
    logEntry: Omit<Partial<LogEntry>, 'inputs' | 'tags'> & {
      inputs: Partial<Input>[]
      tags: Partial<LogEntryTag>[]
    },
  ) => Promise<void>
  deleteLogEntry: (logId: number, logEntryId: number) => Promise<void>

  getProduct: (id: number) => Promise<Product | void>
  getProducts: () => Promise<Product[] | void>
  product?: Product
  uploadSubcategoryFiles: ({
    logId,
    subcategoryId,
    files,
  }: {
    logId: number
    subcategoryId?: number | null
    files: {
      name: string
      fileObj?: RcFile
      signedUrl: string
      path: string
    }[]
  }) => Promise<void>
  deleteSubcategoryFile: (fileId: number, logId: number) => Promise<void>
  resetLog: () => void
  scopes: Scope[]
  getScopes: () => Promise<void>
  customers: Customer[]
  getCustomers: () => Promise<Customer[] | void>
  createCustomer: (body: Partial<Customer>) => Promise<Customer | void>
  editCustomer: (body: Partial<Customer>) => Promise<void>
  deleteCustomer: (id: number) => Promise<void>
  getUserOptionsForLogCreation: (
    subcategoryId: number,
    calculationMethodId: number,
    activityDataSourceId: number,
  ) => Promise<ClientUserOption[]>
  updateProduct: (product: Partial<Product>) => Promise<void>
  validateBulkImport: (
    bulkImportFile: {
      name: string
      fileObj?: RcFile
      signedUrl: string
      path: string
    },
    categoryId: number,
    preprocessor?: CalculationType,
  ) => Promise<undefined | BulkImportResponse>
  previewBulkImportLogs: (importKey: string, page: number, pageSize: number) => Promise<void>
  uploadBulkImport: (importKey: string) => Promise<BulkImport | void>
  getTemplate: (category: Category) => Promise<void>
  getCalculationMethods: (
    subcategoryId: number,
    activityDataSourceId?: number,
  ) => Promise<CalculationMethod[] | undefined>
  getActivityDataSources: (
    subcategoryId: number,
    calculationMethodId?: number,
  ) => Promise<ActivityDataSource[] | undefined>
  createScopedActivityDataSource: (body: ParsedProductFormValues) => Promise<ActivityDataSource>
  getAssumptions: (
    calculationMethodId: number,
    lifecycleStepKey: string,
  ) => Promise<Assumption[] | undefined>
  getBulkImports: (pageNumber: number) => Promise<Page<ImportDetails> | void>
  deleteBulkImport: (bulkImportId?: number) => Promise<void>
  setLocationModalOpen: (state: boolean) => void
  locationModalOpen: boolean
  showProductModal: boolean
  setShowProductModal: (state: boolean) => void
  isDatePickerOpen?: boolean
  openDatePicker: (state?: boolean) => void
  getCurrencies: () => Promise<void>
  currencies: Unit[]
  /** @deprecated use RTK query `useGetSubcategoriesQuery` */
  getSubcategories: (
    categoryId?: number,
    onlyWithLogsAssociated?: boolean,
    businessUnitId?: number,
    activityDataSourceIds?: number[],
  ) => Promise<void>
  subcategories: Subcategory[]
  getLogEntry: (id: number) => Promise<LogEntry | void>
  getProductLogEntry: (id: number) => Promise<ProductLogEntry | void>
  createManyProductLogEntries: (body: CreateProductLogEntry[]) => Promise<ProductLogEntry[] | void>
  updateProductLogEntry: (
    productId: number,
    id: number,
    body: CreateProductLogEntry,
  ) => Promise<ProductLogEntry[] | void>
  deleteProductLogEntry: (productId: number, id: number) => Promise<ProductLogEntry[] | void>
  getHasProductLogEntries: () => Promise<void>
  hasProductLogEntries: boolean
  reset: () => void
  getBulkImportsPutUrl: (filesNames: string[]) => Promise<PutSignedUrl[]>
  getLogFilesPutUrl: (filesNames: string[]) => Promise<PutSignedUrl[]>
  /** @deprecated use RTK Query version */
  getTags: (subcategoryId?: number | undefined) => Promise<LogEntryTag[]>
  getProductTags: (pattern?: string) => Promise<ProductTag[]>
  /** @deprecated use RTK Query version */
  createTag: (payload: Partial<LogEntryTag>) => Promise<LogEntryTag | undefined>
  updateTag: (id: number, payload: Partial<LogEntryTag>) => Promise<LogEntryTag | undefined>
  /** @deprecated use RTK Query version */
  deleteTag: (tagId: string | number) => Promise<void>
  createProductTag: (name: string) => Promise<ProductTag | undefined>
  deleteProductTag: (tagId: string | number) => Promise<void>
  getUnits: () => Promise<void>
  checkValidateBulkImport: (id: number) => Promise<boolean>
  bulkImportId: number | undefined
  templateErrors?: BulkImportErrors
  setValidating: (state: boolean) => void
  validating: boolean
  importKey?: string
  totalResults: number
  tags: LogEntryTag[]
  productTags: ProductTag[]
  previewProductLogEntryCalculations: (logEntryId: number) => Promise<PreviewCalculation[] | void>
  getProductEmissions: (productId: number) => Promise<{ _sum: { value: number } } | void>
  getDenominatorFromEf: (activityDataSourceId: number) => Promise<void>
  getActivityDataSourcesWithoutStructure: (
    subcategoryId?: number,
    calculationMethodId?: number,
    parentId?: number,
  ) => Promise<ActivityDataSource[] | undefined>
  getActivityDataSourcePathStructure: (adsId: number) => Promise<{ adsStructure: number[] }>
  getBusinessUnitOverview(
    businessUnitId: number,
    filters?: Omit<PageFilter, 'options'>[],
  ): Promise<CompanyEmissionOverviewDTO | undefined>
  uploadBulkImportFile: (bulkImportFile: {
    name: string
    fileObj?: RcFile
    signedUrl: string
    path: string
  }) => Promise<{ path: string }>
}

export const logContext = React.createContext<LogContextInterface | undefined>(undefined)

const { Provider } = logContext

export function useLogContext(): LogContextInterface {
  const contextValue = useContext(logContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const LogProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const logAccessors = useLogHandler()
  return <Provider value={logAccessors}>{children}</Provider>
}

export default LogProvider
