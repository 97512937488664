import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

const POLLING_RATE = 2e3 //2 SECONDS

const usePollingEffect = (
  asyncCallback: () => Promise<void>,
  dependencies: unknown[] = [],
  {
    interval = POLLING_RATE,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCleanUp = (): void => {},
  }: {
    interval?: number
    isPollingEnabled?: boolean
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCleanUp?: () => void
  },
): { isPollingEnabled: boolean; setIsPollingEnabled: Dispatch<SetStateAction<boolean>> } => {
  const timeoutIdRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const [isPollingEnabled, setIsPollingEnabled] = useState(false)

  useEffect(() => {
    if (isPollingEnabled) {
      let _stopped = false
      // Side note: preceding semicolon needed for IIFEs.
      ;(async function pollingCallback() {
        try {
          await asyncCallback()
        } finally {
          // Set timeout after it finished, unless stopped
          timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval)
        }
      })()
      // Clean up if dependencies change
      return () => {
        _stopped = true // prevent racing conditions
        clearTimeout(timeoutIdRef.current as ReturnType<typeof setInterval>)
        onCleanUp()
      }
    }
  }, [...dependencies, isPollingEnabled, interval])

  return {
    isPollingEnabled,
    setIsPollingEnabled,
  }
}

export default usePollingEffect
