import React, { ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import classNames from 'classnames'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './LayoutFooter.module.less'

type Props = {
  onSaveClick: () => void
  onCancelClick: () => void
  hideCancelButton?: boolean
  saveButtonDisabled: boolean
  saveButtonText?: string
  cancelButtonText?: string
  className?: string
  isLoading?: boolean
  categoryCancelButton: AnalyticsCategories
  categoryOkButton: AnalyticsCategories
  isDataValid?: boolean
  isFormEmpty?: boolean
  okButtonIcon?: JSX.Element
}
const LayoutFooter = ({
  saveButtonText,
  saveButtonDisabled,
  categoryCancelButton,
  categoryOkButton,
  hideCancelButton,
  onSaveClick,
  onCancelClick,
  cancelButtonText,
  className,
  isDataValid,
  isLoading,
  okButtonIcon,
}: Props): ReactElement => {
  const { t } = useTranslation()

  return (
    <footer className={classNames(classes.footer, className)}>
      <Row>
        <Col span={12}>
          <Row justify="start">
            {!hideCancelButton && (
              <Button
                className={classes.cancelButton}
                category={categoryCancelButton}
                action={'back'}
                type="secondary"
                onClick={onCancelClick}
                size="lg"
              >
                {cancelButtonText ?? t('actions.cancel')}
              </Button>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            {isDataValid ? (
              <Button
                category={categoryOkButton}
                action={'ok'}
                type="primary"
                prefixIcon={okButtonIcon}
                onClick={onSaveClick}
                data-cy="ok-modal-button"
                color={'blue'}
                className={classes.okButton}
                disabled={saveButtonDisabled}
                loading={isLoading}
                size="lg"
              >
                {saveButtonText ?? t('actions.save-continue')}
              </Button>
            ) : (
              <Button
                category={categoryOkButton}
                action={'ok'}
                type="secondary"
                prefixIcon={okButtonIcon}
                onClick={onSaveClick}
                data-cy="ok-modal-button"
                color={'blue'}
                className={classes.saveButton}
                disabled={saveButtonDisabled}
                size="lg"
                loading={isLoading}
              >
                {saveButtonText ?? t('actions.continue')}
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </footer>
  )
}

export default memo(LayoutFooter)
