import React, { PropsWithChildren } from 'react'
import { HiOutlineExclamation } from 'react-icons/hi'

import { Skeleton } from 'antd'
import { Col, Row } from 'antd/es'

import Text from '@/atoms/Text'

import { CINDER_BLUE_60 } from '@/styles/variables'

import classes from './StrategyBuilderOverviewCard.module.less'

export interface Props {
  title?: string
  isLoading?: boolean
  isError?: boolean
  errorMessage?: string
}

export const StrategyBuilderOverviewCard = ({
  title,
  isLoading,
  isError,
  errorMessage,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  if (isLoading) {
    return (
      <div className={classes.overviewContainer}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={classes.overviewContainer}>
        <Row className={classes.header}>
          <Col xs={24} className={classes.noValuePlaceholder}>
            <Text mode="title" fontWeight="medium" size="lg">
              ---
            </Text>
          </Col>

          <Col span={24} className={classes.warningInfo}>
            <HiOutlineExclamation className={classes.icon} size={14} />

            <Text fontWeight="medium" size="lg">
              {errorMessage}
            </Text>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className={classes.overviewContainer}>
      <Row className={classes.header}>
        <Col>
          {title && (
            <Text
              className={classes.truncate}
              size="xl"
              fontWeight="medium"
              style={{ color: CINDER_BLUE_60 }}
            >
              {title}
            </Text>
          )}

          {children}
        </Col>
      </Row>
    </div>
  )
}
