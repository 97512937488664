/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { Col, Form, Row } from 'antd/es'

import moment from 'moment'

import { SearchSelect } from '@/molecules/FiltersDrawer/SearchSelect'

import DatePicker from '@/atoms/DatePicker'

import { FilterDrawerFormProps } from './FilterDrawerForm'
import classes from './FilterDrawerForm.module.less'
import { LogicOperatorInput } from './inputs/LogicOperatorInput'
import { FilterValueType, FomInputType, FormInput } from './types'

export const FilterDrawerFormInput = <
  TSearchSelectFiltersValuesKey extends string,
  TSearchLogEntryDataQualitySelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
>({
  value: formValue,
  input,
  onChange,
  onSearch,
  isLoading,
  options,
}: {
  input: FormInput<
    TSearchSelectFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey
  >
  onChange: (value: FilterValueType) => void
} & Pick<
  FilterDrawerFormProps<
    TSearchSelectFiltersValuesKey,
    TSearchLogEntryDataQualitySelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey
  >,
  'value' | 'onSearch' | 'isLoading' | 'options'
>): React.ReactElement => {
  const { t } = useTranslation()

  const label = input.icon ? (
    <div className={classes.labelWithIcon}>
      {input.icon}
      <span>{input.label}</span>
    </div>
  ) : (
    input.label
  )

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label={label}>
          {input.type === FomInputType.SEARCH_SELECT && (
            <SearchSelect
              multipleAllowed
              size="large"
              value={formValue[input.key]}
              optionFilterProp="label"
              placeholder={t('log.log-entries-drawer.select-and-search.placeholder')}
              onSearch={onSearch?.[input.key]}
              loading={isLoading?.[input.key]}
              options={options?.[input.key]}
              onChange={(value) => {
                onChange(value)
              }}
              preloadedOptionsMode={true}
            />
          )}
          {input.type === FomInputType.TEXT && (
            <Input
              className={classes.textInput}
              size="large"
              style={{ width: '100%' }}
              value={formValue[input.key]}
              placeholder={input.placeholder}
              onChange={(el) => {
                onChange(el.target.value)
              }}
            ></Input>
          )}
          {input.type === FomInputType.DATE_PICKER && (
            <DatePicker
              className={classes.datePickerInput}
              picker="date"
              value={formValue[input.key] ? moment(formValue[input.key]) : null}
              onChange={(value) => {
                onChange(value?.toDate() ?? null)
              }}
              allowClear={true}
              style={{ width: '100%' }}
              suffixIcon={null}
            />
          )}
          {input.type === FomInputType.DATE_PICKER_YEAR && (
            <DatePicker
              className={classes.datePickerInput}
              picker="year"
              value={formValue[input.key] ? moment(formValue[input.key]) : null}
              onChange={(value) => {
                onChange(value?.toDate() ?? null)
              }}
              allowClear={true}
              style={{ width: '100%' }}
              suffixIcon={null}
            />
          )}
          {input.type === FomInputType.LOGIC_OPERATOR && (
            <LogicOperatorInput
              value={formValue[input.key]}
              onChange={(value) => {
                onChange(value)
              }}
              placeholder={t('log.log-entries-drawer.logical-operator-input.placeholder')}
              options={input.options}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}
