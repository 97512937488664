import React from 'react'

import { IconProps } from '@/types/general'

export const ConsoleOutlineIcon = ({
  size = 14,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.16602 3.5C1.16602 2.5335 1.94952 1.75 2.91602 1.75H11.0827C12.0492 1.75 12.8327 2.5335 12.8327 3.5V10.5C12.8327 11.4665 12.0492 12.25 11.0827 12.25H2.91602C1.94952 12.25 1.16602 11.4665 1.16602 10.5V3.5ZM2.91602 2.91667C2.59385 2.91667 2.33268 3.17783 2.33268 3.5V10.5C2.33268 10.8222 2.59385 11.0833 2.91602 11.0833H11.0827C11.4048 11.0833 11.666 10.8222 11.666 10.5V3.5C11.666 3.17783 11.4048 2.91667 11.0827 2.91667H2.91602ZM4.25354 4.83752C4.48134 4.60972 4.85069 4.60972 5.07849 4.83752L6.82849 6.58752C7.0563 6.81533 7.0563 7.18467 6.82849 7.41248L5.07849 9.16248C4.85069 9.39028 4.48134 9.39028 4.25354 9.16248C4.02573 8.93467 4.02573 8.56533 4.25354 8.33752L5.59106 7L4.25354 5.66248C4.02573 5.43467 4.02573 5.06533 4.25354 4.83752ZM6.99935 8.75C6.99935 8.42783 7.26052 8.16667 7.58268 8.16667H9.33268C9.65485 8.16667 9.91602 8.42783 9.91602 8.75C9.91602 9.07217 9.65485 9.33333 9.33268 9.33333H7.58268C7.26052 9.33333 6.99935 9.07217 6.99935 8.75Z"
        fill={color}
      />
    </svg>
  )
}
