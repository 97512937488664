import { ClosedPeriod } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

export const TAG_ACT_CLOSED_PERIOD = 'TAG_ACT_CLOSED_PERIOD'
export const GET_CLOSED_PERIOD = 'getClosedPeriodFromForecast'

const actClosedPeriodsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_CLOSED_PERIOD]: builder.query<
      ClosedPeriod,
      { organizationId: number; businessUnitId: number }
    >({
      query: ({ organizationId, businessUnitId }) => ({
        url: actApiGatewayClient.forecasts.GET_CLOSED_PERIOD,
        method: 'GET',
        params: {
          organizationId,
          businessUnitId,
        },
      }),
      providesTags: [TAG_ACT_CLOSED_PERIOD],
    }),
  }),
})

export const { useGetClosedPeriodFromForecastQuery } = actClosedPeriodsApiSlice

export default actClosedPeriodsApiSlice
