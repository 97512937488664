import React from 'react'

import { IconProps } from '@/types/general'

export const ScopeOutlineIcon = ({
  size = 12,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="8" r="2.5" fill={color} />
      <circle cx="8.5" cy="8" r="2.5" fill={color} />
      <circle cx="5.5" cy="2.5" r="2.5" fill={color} />
    </svg>
  )
}
