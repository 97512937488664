import React from 'react'
import { useOutletContext } from 'react-router'

import { FactorRequest, Product, ProductTag } from '@cozero/models'

import { UploadProductConfigurationParams } from '@/hooks/useProducts'

import { Step } from './steps'

export interface SupplierProduct {
  denominatorUnitId: number
  productName: string
}

export interface ProductConfigurationValues {
  id?: number
  photo: string | UploadProductConfigurationParams | null
  name: string
  startDate?: Date
  endDate?: Date
  productType: number | string
  functionalUnit: number
  mass: number
  massUnit: number
  tags?: string[] | ProductTag[]
  responsibleId?: number
  territory?: number
  businessUnitId?: number
  code?: string
  version?: string
  selectedSteps?: string[]
}

type ProductContextType = {
  STEPS: Step[]
  product: Product
  supplierProduct?: SupplierProduct
  factorRequest?: FactorRequest | undefined
  childRef: React.MutableRefObject<{
    onNext: () => void
    onReturn: () => void
    onClearData: () => void
  }>
  updateProduct: (productId: string, data: Partial<ProductConfigurationValues>) => Promise<void>
  createProductConfiguration: (data: ProductConfigurationValues) => Promise<Product | undefined>
  onHasFinished: () => void
}

export const useProductOutletContext = (): ProductContextType => {
  return useOutletContext<ProductContextType>()
}
