import React from 'react'

import { IconProps } from '@/types/general'

const CubeOutline = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5528 2.10557C11.8343 1.96481 12.1657 1.96481 12.4472 2.10557L20.4472 6.10557C20.786 6.27496 21 6.62123 21 7V17C21 17.3788 20.786 17.725 20.4472 17.8944L12.4472 21.8944C12.1657 22.0352 11.8343 22.0352 11.5528 21.8944L3.55279 17.8944C3.214 17.725 3 17.3788 3 17V7C3 6.62123 3.214 6.27496 3.55279 6.10557L11.5528 2.10557ZM5 8.61803L11 11.618V19.382L5 16.382V8.61803ZM13 19.382L19 16.382V8.61803L13 11.618V19.382ZM12 9.88197L17.7639 7L12 4.11803L6.23607 7L12 9.88197Z"
        fill={color}
      />
    </svg>
  )
}

export default CubeOutline
