import React from 'react'
import { HiArrowLeft, HiOutlineX } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './CustomModalHeader.module.less'

interface CustomHeaderProps {
  onClose: () => void
  goBack: () => void
  title: string
  showArrow?: boolean
}

const CustomHeader = ({
  onClose,
  goBack,
  title,
  showArrow = true,
}: CustomHeaderProps): JSX.Element => {
  return (
    <Row className={classes.modalHeader}>
      <Col span={1}>
        {showArrow && (
          <Row>
            <Button
              category={AnalyticsCategories.PRODUCTS}
              action={'add'}
              type="text"
              onClick={goBack}
              size="md"
            >
              <h2>
                <HiArrowLeft />
              </h2>
            </Button>
          </Row>
        )}
      </Col>
      <Col span={18}>
        <Row>
          <h2>{title}</h2>
        </Row>
      </Col>
      <Col span={4}>
        <Row justify={'end'}>
          <Button
            category={AnalyticsCategories.PRODUCTS}
            action={'add'}
            type="text"
            onClick={onClose}
            size="md"
          >
            <h2>
              <HiOutlineX size={32} />
            </h2>
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default CustomHeader
