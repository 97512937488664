import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import FactorRequestPage from '@/pages/Log/FactorRequest'
import FactorReviewPage from '@/pages/Log/FactorReview'

import LogProvider from '@/contexts/log'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <LogProvider>
      <PrivateRoute>{children}</PrivateRoute>
    </LogProvider>
  </LogProvider>
)

const getFactorRequestRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.factors.request,
    element: (
      <Wrapper>
        <FactorRequestPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
  },
  {
    path: routes.log.factors.review,
    element: (
      <Wrapper>
        <FactorReviewPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
  },
]

export default getFactorRequestRoutes
