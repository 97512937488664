import { ReactNode, useState } from 'react'

import { FixedType } from 'antd/es'
import { ColumnsType } from 'antd/lib/table'
import { SortOrder } from 'antd/lib/table/interface'

import { LogSorter } from '@cozero/models'

type RenderText = string | string[] | { tags: string[] } | number

export interface GenerateColumn<T> {
  title?: string | (() => JSX.Element)
  key: string
  render?: (_: RenderText, record: T) => JSX.Element | ReactNode | string
  sorter?: boolean | ((a: T, b: T) => number)
  dataIndex?: string | string[]
  sortOrder?: SortOrder
  fixed?: FixedType
  width?: number
  ellipsis?: boolean
}

const useGenerateData = (): {
  generateColumns: <T>(columns: (GenerateColumn<T> | null)[]) => void
  columns: ColumnsType<unknown> | undefined
  getInitialOrder: (key: string, _sorters?: LogSorter[]) => SortOrder | undefined
} => {
  const [columns, setColumns] = useState<ColumnsType | undefined>([])

  const getInitialOrder = (key: string, _sorters?: LogSorter[]): SortOrder | undefined => {
    const foundSorter = _sorters?.find((x) => x.key === key)
    if (!foundSorter) {
      return foundSorter
    }
    return foundSorter.selectedSort === 'desc' ? 'descend' : 'ascend'
  }

  const generateColumns = <T,>(columns: (GenerateColumn<T> | null)[]): void => {
    const filteredCols = columns.filter((el) => !!el) as GenerateColumn<T>[]
    const aux = filteredCols.map(
      ({ title, dataIndex, key, sorter, fixed, width, sortOrder, render, ...props }) => ({
        ...props,
        title,
        dataIndex,
        key,
        width: width ? width : 200,
        sorter,
        sortOrder,
        render,
        fixed,
      }),
    ) as ColumnsType | undefined

    setColumns(aux)
  }

  return { generateColumns, columns, getInitialOrder }
}

export default useGenerateData
