import React from 'react'
import { useTranslation } from 'react-i18next'

import { Form, Input, Select } from 'antd'

import { MAX_VALUE } from '@/constants/numericPrecision'

import classes from './UnitDropdownCombinedInput.module.less'

const { Option } = Select

export interface Unit {
  id: number
  name: string
}

interface OptionType {
  value: string
  key: string
}

interface UnitDropdownCombinedInputProps {
  amount?: number
  minAmount?: number
  amountDisabled?: boolean
  units: Unit[]
  unit?: string
  unitsDisabled?: boolean
  width?: string
  required: boolean
  requiredMessage?: string
  touched?: boolean
  onAmountChange?: (amount: number) => void
  onUnitChange?: (unit: Unit) => void
}

const UnitDropdownCombinedInput: React.FC<UnitDropdownCombinedInputProps> = ({
  amount,
  onAmountChange,
  minAmount = 0,
  amountDisabled = false,
  units,
  unit,
  onUnitChange,
  unitsDisabled = false,
  width = '200px',
  required = false,
  requiredMessage = '',
  touched = true,
}) => {
  const { t } = useTranslation('common')

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newAmount = parseFloat(event.target.value)
    if (!isNaN(newAmount) && onAmountChange) {
      onAmountChange(newAmount)
    }
  }

  const handleUnitChange = (value: string, option: OptionType | OptionType[]): void => {
    if (!Array.isArray(option)) {
      option = [option]
    }

    if (onUnitChange) {
      option.forEach((opt) => {
        onUnitChange({ name: opt.value, id: Number(opt.key) })
      })
    }
  }

  return (
    <div>
      <Form.Item
        name="mass"
        label={t('product.lifecycle-steps.configuration.product-measurement-unit.label')}
        rules={[{ required: required, message: requiredMessage }]}
      >
        <Input
          type="number"
          onChange={handleAmountChange}
          value={amount}
          min={minAmount}
          max={MAX_VALUE}
          disabled={amountDisabled}
          className={classes.amountInput}
          placeholder={t(
            'product.lifecycle-steps.configuration.product-measurement-unit.placeholder-amount',
          )}
          addonAfter={
            <Select
              status={!unit && touched ? 'error' : undefined}
              onChange={handleUnitChange}
              disabled={unitsDisabled}
              value={unit}
              className={classes.unitSelect}
              style={{ maxWidth: width }}
              showSearch
              placeholder={t(
                'product.lifecycle-steps.configuration.product-measurement-unit.placeholder-unit',
              )}
            >
              {units.map((unit) => (
                <Option key={unit.id} value={unit.name}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          }
        />
      </Form.Item>
    </div>
  )
}

export default UnitDropdownCombinedInput
