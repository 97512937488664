export type PARTIAL_LIST_TYPE = 'PARTIAL-LIST'
export type LIST_TYPE = 'LIST'

export const LIST = 'LIST'
export const PARTIAL_LIST = 'PARTIAL-LIST'

export interface BaseMetaResponse {
  response: {
    headers: Headers
  }
}
