import React, { ReactElement } from 'react'

import { Col, Divider, Image, Row } from 'antd/es'

import classes from './classes.module.less'

interface LifecycleModalTitleProps {
  title: string | ReactElement
  description: string | ReactElement
  image?: string
  fitParent?: boolean
}

const LifecycleModalTitle = ({
  title,
  description,
  image,
  fitParent,
}: LifecycleModalTitleProps): JSX.Element => {
  return (
    <>
      <Row gutter={[24, 0]}>
        {image && (
          <Col>
            <Image
              preview={false}
              className={`${classes.image}` + (fitParent ? ` ${classes.imageFill}` : '')}
              src={image}
              wrapperStyle={
                fitParent
                  ? {
                      height: '100%',
                    }
                  : {}
              }
            />
          </Col>
        )}
        <Col flex="1">
          <Row className={classes.title}>
            <Col>{title}</Col>
          </Row>
          <Row className={classes.description}>
            <Col>{description}</Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default LifecycleModalTitle
