import React, { PropsWithChildren, ReactElement, useMemo } from 'react'

import { MenuItemProps } from 'antd'

import classNames from 'classnames'

import MenuItem from '@/atoms/Menu/MenuItem'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './DotsMenuItem.module.less'

interface DotsMenuProps {
  action: string
  category: AnalyticsCategories
}

export const DotsMenuItem = ({
  action,
  category,
  children,
  disabled,
  ...rest
}: PropsWithChildren<DotsMenuProps & MenuItemProps>): ReactElement => {
  const activeClasses = useMemo(
    () => classNames(classes.dotsMenuItem, { [classes.disabledItem]: disabled }),
    [disabled],
  )

  return (
    <MenuItem
      {...rest}
      className={activeClasses}
      category={category}
      action={action}
      disabled={disabled}
    >
      {children}
    </MenuItem>
  )
}
