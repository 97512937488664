import React, { ReactElement, useMemo } from 'react'
import { HiOutlineChartBar, HiOutlineChartPie, HiOutlineTrendingUp, HiTable } from 'react-icons/hi'

import Bars3CenterLeftIcon from '@heroicons/react/20/solid/Bars3CenterLeftIcon'
import { t } from 'i18next'

import { Report, ReportType } from '@cozero/models'

import Sankey from '@/atoms/Icons/Sankey'
import StackedBar from '@/atoms/Icons/StackedBar'
import Sunburst from '@/atoms/Icons/Sunburst'
import Select, { SelectOptionsProperties } from '@/atoms/Select'

import classes from './GraphSelectorDropdown.module.less'

interface Props {
  setView: (view: string) => void
  view: string
  report?: Report
}

export const GraphSelectorDropdown = ({ report, view, setView }: Props): ReactElement | null => {
  const dropdownOptions: SelectOptionsProperties[] = useMemo(() => {
    const primaryGraphs: SelectOptionsProperties[] = graphSelectorAttributes.primary
      .filter((chart) => chart.name === report?.type)
      .map((primary) => {
        return {
          value: primary.type,
          label: primary.label,
          key: primary.type,
          icon: primary.icon,
          className: classes.menuItem,
        }
      })
    const secondaryGraphs = graphSelectorAttributes.secondary.map((secondary) => {
      return {
        value: secondary.name,
        label: secondary.label,
        key: secondary.name,
        icon: secondary.icon,
        className: classes.menuItem,
      }
    })
    const mainGraphWithAlternative = graphSelectorAttributes.primary.find(
      (graph) =>
        !report?.disableAlternativeGraph && graph.alternativeGraph && graph.name === report?.type,
    )

    if (mainGraphWithAlternative) {
      const alternativeGraph = graphSelectorAttributes.primary
        .filter((graph) => graph.name === mainGraphWithAlternative.alternativeGraph)
        .map((alternativeGraph) => {
          return {
            value: alternativeGraph.name,
            label: alternativeGraph.label,
            key: alternativeGraph.name,
            icon: alternativeGraph.icon,
            className: classes.menuItem,
          }
        })
      return [...primaryGraphs, ...alternativeGraph, ...secondaryGraphs]
    }
    return [...primaryGraphs, ...secondaryGraphs]
  }, [])

  if (!report) {
    return null
  }

  if (!graphSelectorAttributes.primary.map((graph) => graph.name).includes(report.type)) {
    return null
  }

  return (
    <Select
      placement="bottomRight"
      dropdownClassName={classes.customDropdownDimensions}
      variant="borderless"
      defaultValue={view}
      onChange={setView}
      optionLabelProp="label"
      options={dropdownOptions}
    />
  )
}

export const graphSelectorAttributes = {
  primary: [
    {
      name: ReportType.LINE,
      icon: <HiOutlineTrendingUp size={20} />,
      label: t('reports.menu.line'),
      type: 'primary',
    },
    {
      name: ReportType.BAR,
      icon: <HiOutlineChartBar size={20} />,
      label: t('reports.menu.bar'),
      type: 'primary',
      alternativeGraph: ReportType.PIE,
    },
    {
      name: ReportType.HORIZONTAL_BAR,
      icon: <HiOutlineChartBar size={20} />,
      label: t('reports.menu.horizontal-bar'),
      type: 'primary',
    },
    {
      name: ReportType.PIE,
      icon: <HiOutlineChartPie size={20} />,
      label: t('reports.menu.pie'),
      type: 'primary',
      alternativeGraph: ReportType.BAR,
    },
    {
      name: ReportType.SUNBURST,
      icon: <Sunburst width={20} height={20} />,
      label: t('reports.menu.sunburst'),
      type: 'primary',
    },
    {
      name: ReportType.SANKEY,
      icon: <Sankey width={20} height={20} />,
      label: t('reports.menu.sankey'),
      type: 'primary',
    },
    {
      name: ReportType.STACKED_BAR,
      icon: <StackedBar width={20} height={20} />,
      label: t('reports.menu.bar'),
      type: 'primary',
    },
    {
      name: ReportType.TREE_MAP,
      icon: <HiTable size={20} />,
      label: t('reports.menu.treemap'),
      type: 'primary',
    },
    {
      name: ReportType.FACET_STACKED_BAR,
      icon: <StackedBar width={20} height={20} />,
      label: t('reports.menu.bar'),
      type: 'primary',
    },
    {
      name: ReportType.STATISTIC,
      icon: <Bars3CenterLeftIcon width={20} height={20} />,
      type: 'primary',
    },
  ],
  secondary: [
    {
      name: ReportType.TABLE,
      icon: <HiTable size={20} />,
      label: t('reports.menu.table'),
      type: 'secondary',
    },
  ],
}
