import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Spin } from 'antd/es'
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface'

import { omit } from 'lodash-es'

import { EmissionFactorWithIncludes, LogSorter, PageFilter } from '@cozero/models'

import Table from '@/molecules/Table'

import useEmissionFactorsData from '@/hooks/useEmissionFactorsData'
import useGenerateData from '@/hooks/useGenerateData'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import classes from './classes.module.less'
import useColumns from './columns'

interface Props {
  featuresAllowed: string[]
  filters?: PageFilter[]
  sorters?: LogSorter[]
  currentPage: number
  pageSize: number
  defaultPageSize?: number
  activityDataSourceId: string
  used: boolean
  custom: boolean
  setSort: (sort: LogSorter[]) => void
  setCurrentPage: (size: number) => void
  setPageSize: (size: number) => void
  setSelectedFactor: (factor: EmissionFactorWithIncludes) => void
  childRef?: React.MutableRefObject<{
    onRefreshTable: () => void
  }>
  triggerUpdateTimestamp?: number
}

const EmissionFactorsDataTable = ({
  childRef,
  featuresAllowed,
  filters,
  sorters,
  pageSize,
  defaultPageSize = 15,
  currentPage,
  activityDataSourceId,
  used,
  custom,
  setPageSize,
  setCurrentPage,
  setSelectedFactor,
  setSort,
  triggerUpdateTimestamp,
}: Props): JSX.Element => {
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const [tableSorter, setTableSorter] = useState<LogSorter[]>([])
  const { data, mutate, loading } = useEmissionFactorsData({
    requestedBusinessUnitId: selectedBusinessUnit?.id?.toString() as string,
    activityDataSourceId,
    used,
    custom,
    page: currentPage || 1,
    pageSize,
    filters: filters?.map((obj) => omit(obj, 'options')) || [],
    sorters: sorters,
  })
  const { generateColumns } = useGenerateData()
  const { columnsData, deletedProduct } = useColumns({
    tableSorter,
    businessUnitsAllowed: featuresAllowed.includes('business-units') || false,
    setSelectedFactor,
  })

  useEffect(() => {
    if (triggerUpdateTimestamp) {
      mutate()
    }
  }, [triggerUpdateTimestamp])

  useEffect(() => {
    if (deletedProduct) {
      mutate()
    }
  }, [deletedProduct])

  useEffect(() => {
    generateColumns(columnsData)
  }, [tableSorter])

  useImperativeHandle(childRef, () => ({
    onRefreshTable: () => {
      mutate()
    },
  }))

  const changeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<EmissionFactorWithIncludes> | SorterResult<EmissionFactorWithIncludes>[],
  ): void => {
    if (pagination.current && pagination.current !== currentPage) {
      setCurrentPage(pagination.current)
    } else {
      if (Array.isArray(sorter)) {
        const sortersAux: LogSorter[] = sorter.map((x) => ({
          id: new Date().getTime().toString(),
          key: (x.column?.key || 'createdAt') as string,
          label: x.column?.key?.toString() || 'createdAt',
          selectedSort: x.order === 'descend' ? 'desc' : 'asc',
        }))
        setSort(sortersAux)
        setTableSorter(sortersAux)
      } else {
        if (sorter && Object.keys(sorter).length) {
          const aux = {
            id: new Date().getTime().toString(),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            key: (sorter.column?.key || 'createdAt') as any,
            label: sorter.column?.key?.toString() || 'createdAt',
            selectedSort: sorter.order === 'descend' ? 'desc' : 'asc',
          }
          setSort([aux as LogSorter])
          setTableSorter([aux as LogSorter])
        }
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <Table
        scroll={{ x: 1200 }}
        columns={columnsData as ColumnsType<EmissionFactorWithIncludes>}
        dataSource={data?.data as EmissionFactorWithIncludes[]}
        rowKey="id"
        className={classes.table}
        rowClassName={classes.tableRow}
        onChange={changeTable}
        pagination={{
          current: currentPage,
          defaultPageSize,
          pageSize,
          total: data?.total,
          onShowSizeChange: (_current, size) => setPageSize(size),
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        showWrapper={false}
      />
    </Spin>
  )
}

export default forwardRef(EmissionFactorsDataTable)
