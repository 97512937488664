/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

import { map } from 'lodash-es'

import { LogEntriesTableColumnKey } from '@cozero/models'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'

import { ColumnManagerModal } from '../components/ColumnManagerModal/ColumnManagerModal'

import { TableColumns, useTableColumns } from './useTableColumns'

type ManagedColumns = {
  visibleColumns: LogEntriesTableColumnKey[]
  hiddenColumns: LogEntriesTableColumnKey[]
}

const getPersistedVisibleKeys = (): ManagedColumns['visibleColumns'] | undefined => {
  const rawItem = localStorage.getItem('log-entries-visible-column-keys')
  if (!rawItem) {
    return undefined
  }

  try {
    return JSON.parse(rawItem)
  } catch (error) {
    return
  }
}

const persistVisibleKeys = (value: ManagedColumns['visibleColumns']): void => {
  localStorage.setItem('log-entries-visible-column-keys', JSON.stringify(value))
}

export const useColumnManager = (): {
  visibleKeys: LogEntriesTableColumnKey[]
  columns: TableColumns
  modal: React.ReactElement
  showModal: (analyticsKey: string) => void
  hideColumn: (key: LogEntriesTableColumnKey) => void
} => {
  const { trackAction } = useMixpanel()
  const { tableColumns } = useTableColumns()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const getInitialVisibleKeys = React.useCallback((): ManagedColumns['visibleColumns'] => {
    const persistedKeys = getPersistedVisibleKeys()

    if (!persistedKeys?.length) {
      const initiallyVisibleKeys = [
        LogEntriesTableColumnKey.LOG_ID,
        LogEntriesTableColumnKey.BUSINESS_UNIT,
        LogEntriesTableColumnKey.LOCATION,
        LogEntriesTableColumnKey.START_DATE,
        LogEntriesTableColumnKey.END_DATE,
        LogEntriesTableColumnKey.TITLE,
        LogEntriesTableColumnKey.CATEGORY,
        LogEntriesTableColumnKey.SUBCATEGORY,
        LogEntriesTableColumnKey.CALCULATION_METHOD,
        LogEntriesTableColumnKey.ACTIVTY_DATA_SOURCE,
        LogEntriesTableColumnKey.SCOPE,
        LogEntriesTableColumnKey.DATA_QUALITY,
        LogEntriesTableColumnKey.ORGANIZATION_EMISSIONS,
      ]
      if (featuresAllowed.includes('cbam')) {
        initiallyVisibleKeys.splice(
          initiallyVisibleKeys.indexOf(LogEntriesTableColumnKey.ORGANIZATION_EMISSIONS),
          0,
          LogEntriesTableColumnKey.CBAM_EMISSIONS_SUM,
        )
      }
      return initiallyVisibleKeys
    }

    return persistedKeys.filter((key) => map(tableColumns, 'key').includes(key))
  }, [featuresAllowed, tableColumns])

  const [visibleKeys, setVisibleKeys] = React.useState<ManagedColumns['visibleColumns']>(
    getInitialVisibleKeys(),
  )
  const [hiddenKeys, setHiddenKeys] = React.useState<ManagedColumns['hiddenColumns']>(
    tableColumns.map((column) => column.key).filter((key) => !visibleKeys.includes(key)),
  )

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const showModal = React.useCallback(
    (analyticsKey: string) => {
      setIsModalOpen(true)
      trackAction(AnalyticsCategories.LOG_ENTRY_OVERVIEW, analyticsKey)
    },
    [trackAction],
  )

  const hideModal = React.useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const onOk = React.useCallback(
    (managedColumns: ManagedColumns) => {
      setVisibleKeys(managedColumns.visibleColumns)
      setHiddenKeys(managedColumns.hiddenColumns)

      hideModal()
    },
    [hideModal],
  )

  const hideColumn = React.useCallback((key: LogEntriesTableColumnKey) => {
    setVisibleKeys((prevVisible) => prevVisible.filter((columnKey) => columnKey !== key))
    setHiddenKeys((prevHidden) => [...prevHidden, key])
  }, [])

  const modal = React.useMemo(
    () => (
      <ColumnManagerModal
        open={isModalOpen}
        onOk={onOk}
        onCancel={hideModal}
        visibleKeys={visibleKeys}
        hiddenKeys={hiddenKeys}
      />
    ),
    [hiddenKeys, hideModal, isModalOpen, onOk, visibleKeys],
  )

  const columns = React.useMemo(
    () =>
      tableColumns
        .filter((column) => visibleKeys.includes(column.key))
        .sort((a, b) => visibleKeys.indexOf(a.key) - visibleKeys.indexOf(b.key)),
    [tableColumns, visibleKeys],
  )

  React.useEffect(() => {
    persistVisibleKeys(visibleKeys)
  }, [visibleKeys])

  return {
    visibleKeys,
    columns,
    hideColumn,
    modal,
    showModal,
  }
}
