/* eslint react-hooks/exhaustive-deps: 2 */
import { useMemo } from 'react'

import {
  SearchSelectFiltersOptions,
  SearchSelectFiltersOptionsLoading,
  SearchSelectFiltersOptionsOnSearch,
} from '@/molecules/FilterDrawerForm/types'
import { useActivityDataSourceSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useActivityDataSourceSearch'
import { useBusinessUnitSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useBusinessUnitSearch'
import { useCalculationMethodsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useCalculationMethodsSearch'
import { useCategoriesSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useCategoriesSearch'
import { useDataQualitySearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useDataQualitySearch'
import { useFactorOriginsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useFactorOriginsSearch'
import { useGetOrganizationUsersSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useGetOrganizationUsersSearch'
import { useLocationsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useLocationsSearch'
import { useLogEntriesTagsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useLogEntriesTagsSearch'
import { useModeledImpactsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useModeledImpactsSearch'
import { useScopesSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useScopesSearch'
import { useSuppliersSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useSuppliersSearch'
import { useTerritoriesSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useTerritoriesSearch'
import { useUnitsSearch } from '@/molecules/FilterDrawerForm/useSearchHooks/useUnitsSearch'

import {
  OrganizationCarboonFootprintFormValues,
  SearchSelectFiltersValuesKey,
} from './useOrganizationCarbonFootprintFormConfiguration'

type FormSearchSelectFilterOptions = SearchSelectFiltersOptions<SearchSelectFiltersValuesKey>
type FormSearchSelectFilterOptionsLoading =
  SearchSelectFiltersOptionsLoading<SearchSelectFiltersValuesKey>
type SearchSelectFiltersOptionsOnSearchCallbacks =
  SearchSelectFiltersOptionsOnSearch<SearchSelectFiltersValuesKey>

export const useOrganizationCarboonFootprintFormSearchOptions = (
  values: OrganizationCarboonFootprintFormValues,
): {
  options: FormSearchSelectFilterOptions
  isLoading: FormSearchSelectFilterOptionsLoading
  onSearch: SearchSelectFiltersOptionsOnSearchCallbacks
} => {
  const {
    options: businessUnits,
    isFetching: isFetchingBusinessUnits,
    onSearch: onBusinessUnitsSearch,
  } = useBusinessUnitSearch()

  const {
    options: locations,
    isFetching: isFetchingLocations,
    onSearch: onLocationsSearch,
  } = useLocationsSearch()

  const {
    categories: {
      options: categories,
      isFetching: isFetchingCategories,
      onSearch: onCategoriesSearch,
    },
    subcategories: {
      options: subcategories,
      isFetching: isFetchingSubcategories,
      onSearch: onSubcategoriesSearch,
    },
  } = useCategoriesSearch({
    selectedCategoriesIDs: values?.['category'],
    selectedSubCategoriesIDs: values?.['subcategory'],
  })

  const {
    options: users,
    isFetching: isFetchingUsers,
    onSearch: onUsersSearch,
  } = useGetOrganizationUsersSearch()

  const {
    options: calculationMethods,
    isFetching: isFetchingCalculationMethods,
    onSearch: onCalculationMethodsSearch,
  } = useCalculationMethodsSearch()

  const {
    options: territories,
    isFetching: isFetchingTerritories,
    onSearch: onTerritoriesSearch,
  } = useTerritoriesSearch()

  const {
    options: scopes,
    isFetching: isFetchingScopes,
    onSearch: onScopesSearch,
  } = useScopesSearch()

  const {
    options: dataQualityOptions,
    isFetching: isFetchingDataQuality,
    onSearch: onDataQualitySearch,
  } = useDataQualitySearch()

  const {
    options: modeledImpacts,
    isFetching: isFetchingModeledImpacts,
    onSearch: onModeledImpactsSearch,
  } = useModeledImpactsSearch()

  const {
    options: logEntriesTags,
    isFetching: isFetchingLogEntriesTags,
    onSearch: onLogEntriesTagsSearch,
  } = useLogEntriesTagsSearch()

  const {
    options: activityDataSources,
    isFetching: isFetchingActivityDataSources,
    onSearch: onActivityDataSourcesSearch,
  } = useActivityDataSourceSearch()

  const { options: units, isFetching: isFetchingUnits, onSearch: onUnitsSearch } = useUnitsSearch()

  const {
    options: factorOrigins,
    isFetching: isFetchingFactorOrigins,
    onSearch: onFactorOriginsSearch,
  } = useFactorOriginsSearch()

  const {
    options: suppliers,
    isFetching: isFetchingSuppliers,
    onSearch: onSuppliersSearch,
  } = useSuppliersSearch()

  // Memoized options to prevent unnecessary re-renders
  const options: FormSearchSelectFilterOptions = useMemo(
    () => ({
      businessUnit: businessUnits,
      location: locations,
      supplier: suppliers,
      responsible: users,
      category: categories,
      subcategory: subcategories,
      calculationMethod: calculationMethods,
      territory: territories,
      scope: scopes,
      organizationTags: logEntriesTags,
      activityDataSource: activityDataSources,
      emissionFactorSource: factorOrigins,
      emissionFactorUnit: units,
      emissionFactorTerritory: territories,
      modeledImpact: modeledImpacts,
      dataQuality: dataQualityOptions,
    }),
    [
      businessUnits,
      locations,
      users,
      categories,
      subcategories,
      calculationMethods,
      territories,
      scopes,
      logEntriesTags,
      activityDataSources,
      units,
      factorOrigins,
      modeledImpacts,
      suppliers,
      dataQualityOptions,
    ],
  )

  const isLoading: FormSearchSelectFilterOptionsLoading = useMemo(
    () => ({
      businessUnit: isFetchingBusinessUnits,
      location: isFetchingLocations,
      supplier: isFetchingSuppliers,
      responsible: isFetchingUsers,
      category: isFetchingCategories,
      subcategory: isFetchingSubcategories,
      calculationMethod: isFetchingCalculationMethods,
      territory: isFetchingTerritories,
      scope: isFetchingScopes,
      organizationTags: isFetchingLogEntriesTags,
      activityDataSource: isFetchingActivityDataSources,
      emissionFactorSource: isFetchingFactorOrigins,
      emissionFactorUnit: isFetchingUnits,
      emissionFactorTerritory: isFetchingTerritories,
      modeledImpact: isFetchingModeledImpacts,
      dataQuality: isFetchingDataQuality,
    }),
    [
      isFetchingBusinessUnits,
      isFetchingLocations,
      isFetchingUsers,
      isFetchingCategories,
      isFetchingSubcategories,
      isFetchingCalculationMethods,
      isFetchingTerritories,
      isFetchingScopes,
      isFetchingLogEntriesTags,
      isFetchingActivityDataSources,
      isFetchingUnits,
      isFetchingFactorOrigins,
      isFetchingModeledImpacts,
      isFetchingSuppliers,
      isFetchingDataQuality,
    ],
  )

  const onSearch: SearchSelectFiltersOptionsOnSearchCallbacks = useMemo(
    () => ({
      businessUnit: onBusinessUnitsSearch,
      location: onLocationsSearch,
      supplier: onSuppliersSearch,
      responsible: onUsersSearch,
      category: onCategoriesSearch,
      subcategory: onSubcategoriesSearch,
      calculationMethod: onCalculationMethodsSearch,
      territory: onTerritoriesSearch,
      scope: onScopesSearch,
      organizationTags: onLogEntriesTagsSearch,
      activityDataSource: onActivityDataSourcesSearch,
      emissionFactorSource: onFactorOriginsSearch,
      emissionFactorUnit: onUnitsSearch,
      emissionFactorTerritory: onTerritoriesSearch,
      modeledImpact: onModeledImpactsSearch,
      dataQuality: onDataQualitySearch,
    }),
    [
      onBusinessUnitsSearch,
      onLocationsSearch,
      onUsersSearch,
      onCategoriesSearch,
      onSubcategoriesSearch,
      onCalculationMethodsSearch,
      onTerritoriesSearch,
      onScopesSearch,
      onLogEntriesTagsSearch,
      onActivityDataSourcesSearch,
      onUnitsSearch,
      onFactorOriginsSearch,
      onModeledImpactsSearch,
      onSuppliersSearch,
      onDataQualitySearch,
    ],
  )

  return {
    options,
    isLoading,
    onSearch,
  }
}
