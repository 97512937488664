import { useCallback } from 'react'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, selectUser } from '@/redux/auth'

import { MenuItem } from './NavSidebar'

type MenuItemPartial = Pick<MenuItem, 'feature' | 'excludeRoles'>

export const useMenuFeature = (): {
  canUseMenuFeature: (item: MenuItemPartial) => boolean
} => {
  const user = useAppSelector(selectUser)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const canUseMenuFeature = useCallback((item: MenuItemPartial): boolean => {
    return (
      !!featuresAllowed.includes(item.feature) &&
      !item.excludeRoles?.includes(user?.role?.type || '')
    )
  }, [])

  return { canUseMenuFeature }
}
