import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'

import { Col, Row, Spin } from 'antd/es'

import { LifecycleStepsID, LifecycleStepsKey } from '@/pages/GenericLifecycleSteps/hooks/steps'
import { useProductOutletContext } from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useLifecycleContext } from '@/contexts/lifecycle'

import LifecycleModalTitle from '../LifecycleModalTitle'
import { useGenericStep } from '../hooks/useGenericStep'

import classes from './classes.module.less'

interface Props {
  step: LifecycleStepsKey
  stepId: LifecycleStepsID
}

const GenericStepSelector = ({ step, stepId }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const bottomRef = useRef<HTMLDivElement>(null)
  const { setIsUnique, setCurrentStep, currentStep } = useLifecycleContext()
  const { childRef, onHasFinished, product } = useProductOutletContext()
  const {
    stepMetadata: { image, translationKey },
    loading,
    genericSteps,
    addNewLogEntryForm,
    fetchData,
    resetStep,
    clearState,
    hasAssumptions,
    hasEntries,
  } = useGenericStep({
    product,
    step,
    stepId,
    bottomRef,
  })

  useEffect(() => {
    resetStep()
  }, [step, currentStep])

  useImperativeHandle(childRef, () => ({
    onClearData: () => {
      clearState()
    },
    onNext: async () => {
      clearState()
      onHasFinished()
    },
    onReturn: () => {
      clearState()
      setCurrentStep(currentStep - 1)
    },
  }))

  useEffect(() => {
    if (product && step) {
      fetchData()
    }

    return () => {
      clearState()
    }
  }, [product, step])

  return (
    <Spin spinning={loading}>
      {!loading && translationKey && (
        <Row>
          <LifecycleModalTitle
            image={image}
            title={t(`product.lifecycle-steps.${translationKey}.title`)}
            description={t(`product.lifecycle-steps.${translationKey}.subtitle`)}
          />
          {hasAssumptions && !hasEntries && (
            <Alert type="info">{t('product.assumptions-info')} </Alert>
          )}
          {genericSteps.map((step, i) => {
            if (!step.element) {
              return ''
            }

            return (
              <Col key={i} span={24} style={{ marginTop: '16px' }}>
                {step && step.element && (
                  <Row className={classes.inputsContainer}>
                    {
                      <step.element.type
                        {...step.element.props}
                        key={i}
                        index={i}
                        hasAssumptions={hasAssumptions}
                        bottomRef={bottomRef}
                      />
                    }
                  </Row>
                )}
              </Col>
            )
          })}
          <Col span={24} style={{ marginTop: '16px' }}>
            <Button
              category={AnalyticsCategories.LOGS}
              action="go-to-bulk-import"
              type="secondary"
              className={classes.bulkImportButton}
              onClick={() => {
                setIsUnique(false)
                addNewLogEntryForm()
              }}
              data-cy="bulk-import-btn"
              prefixIcon={<HiOutlinePlus />}
            >
              {t(`product.lifecycle-steps.${translationKey}.add-new-btn`)}
            </Button>
            <div ref={bottomRef} />
          </Col>
        </Row>
      )}
    </Spin>
  )
}

export default GenericStepSelector
