import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Col, Form, Row, Spin } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'
import { debounce } from 'lodash-es'

import { PageFilter, ProductTag, QuantityTag } from '@cozero/models'

import ProductTypeSelect from '@/molecules/ProductTypeSelect'
import TagInput from '@/molecules/TagInput'

import Select, { Option } from '@/atoms/Select'

import { useLogContext } from '@/contexts/log'
import useProducts from '@/hooks/useProducts'
import useQuantityTags from '@/hooks/useQuantityTags'
import {
  selectSelectedBusinessUnit,
  selectSelectedBusinessUnitKey,
  useGetActiveBusinessUnitsQuery,
} from '@/redux/businessUnits'
import { useGetActiveProductsQuery } from '@/redux/products'
import { getBusinessUnitOptions } from '@/utils/business-units'

import classes from './../FiltersDrawer.module.less'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}

interface Props {
  featuresAllowed: string[]
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  values: {
    [FilterKey.PRODUCT_TAGS]: string[]
    [FilterKey.QUANTITY_TAGS]: string[]
    [FilterKey.PRODUCT_TYPE]: number[]
    [FilterKey.LOCATION]: number[]
    [FilterKey.BUSINESS_UNIT]: number[]
    [FilterKey.PRODUCT]: number[]
    [FilterKey.RESPONSIBLE]: number[]
    [FilterKey.DESCRIPTION]: string
    [FilterKey.CUSTOMER]: number[]
    [FilterKey.SUPPLIER]: number[]
    [FilterKey.EXTERNAL_ID]: string[]
    [FilterKey.SCOPE]: number[]
    [FilterKey.ACTION_AREA]: number[]
  }
  onChange: (
    filter: PageFilter & { key: FilterKey },
    value: Select.value | string | string[],
  ) => void
}

const ProductFiltersForm = ({
  filterOptions,
  values,
  onChange,
  featuresAllowed,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const { getProductTags } = useLogContext()
  const { getProductTypes, productTypes } = useProducts()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const multipleAllowed = featuresAllowed.includes('multiple-filtering')

  const [tagOptions, setTagOptions] = useState<ProductTag[]>([])
  const [quantitiyTagsOptions, setQuantitiyTagsOptions] = useState<QuantityTag[]>([])

  const { getQuantityTags } = useQuantityTags()

  const [searchTerms, setSearchTerms] = useState({
    [FilterKey.LOCATION]: '',
    [FilterKey.BUSINESS_UNIT]: '',
    [FilterKey.PRODUCT]: ' ',
  })

  const { data: paginatedProducts, isLoading: productLoading } = useGetActiveProductsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
      search: searchTerms[FilterKey.PRODUCT],
    },
    { skip: !selectedBusinessUnit || !searchTerms[FilterKey.PRODUCT] },
  )
  const { data: paginatedBusinessUnits, isLoading: businessUnitLoading } =
    useGetActiveBusinessUnitsQuery(
      {
        businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
        search: searchTerms[FilterKey.BUSINESS_UNIT],
        populate: true,
      },
      { skip: !selectedBusinessUnit || !searchTerms[FilterKey.BUSINESS_UNIT] },
    )

  const handleChange = (value: Select.value | string | string[], key: string): void => {
    const selectedFilter = filterOptions?.find((x) => x.key === key)
    if (selectedFilter) {
      onChange(selectedFilter as PageFilter & { key: FilterKey }, value)
    }
  }

  const {
    ownerId: createdByOptions,
    productId: productOptions,
    businessUnit: businessUnitOptions,
  } = useMemo(() => {
    const options = filterOptions.reduce(
      (acc, value) => {
        return {
          ...acc,
          [value.key as string]:
            value.options?.map((x) => ({
              ...x,
              value: +x.value,
              key: x.key ? +x.key : x.key,
            })) || [],
        }
      },
      {
        [FilterKey.CATEGORY]: [],
        [FilterKey.TAG]: [],
        [FilterKey.CUSTOMER]: [],
        [FilterKey.SUPPLIER]: [],
        [FilterKey.RESPONSIBLE]: [],
        [FilterKey.SCOPE]: [],
        [FilterKey.ACTION_AREA]: [],
      },
    )
    return {
      ...options,
      [FilterKey.PRODUCT]:
        paginatedProducts?.map((d) => (
          <Option key={d.id} value={d.id} label={d.name}>
            {d.name}
          </Option>
        )) || [],
      [FilterKey.BUSINESS_UNIT]: getBusinessUnitOptions({
        businessUnits: paginatedBusinessUnits,
        t,
      }),
    }
  }, [filterOptions, paginatedBusinessUnits, paginatedProducts])

  const onSearch = (value: string, key: string): void => {
    setSearchTerms({ ...searchTerms, [key]: value })
  }

  useEffect(() => {
    const fetchTags = async (): Promise<void> => {
      const quantitiyTags = await getQuantityTags()
      setQuantitiyTagsOptions(quantitiyTags)
      const tags = await getProductTags('')
      setTagOptions(tags)
    }
    if (!productTypes?.length) {
      getProductTypes()
    }

    if (!quantitiyTagsOptions?.length) {
      fetchTags()
    }
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const quantitiyTags = await getQuantityTags()
      const tags = await getProductTags('')
      if (!productTypes?.length) {
        await getProductTypes()
      }
      setTagOptions(tags)
      setQuantitiyTagsOptions(quantitiyTags)
    }
    fetchData()
  }, [])

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('product.productName')}>
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={false}
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.search')}
                value={values.productId}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.PRODUCT)
                }}
                onSearch={debounce((term) => onSearch(term, FilterKey.PRODUCT), 500)}
                style={{ width: '100%' }}
                dropdownRender={(menu) => {
                  if (productLoading) {
                    return (
                      <span className={classes.dropdownContainer}>
                        <Spin />
                      </span>
                    )
                  }
                  return productOptions?.length ? (
                    menu
                  ) : (
                    <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                  )
                }}
              >
                {productOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('product.productType')}>
              <ProductTypeSelect
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                productTypes={productTypes}
                defaultValue={values.productTypeId}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.PRODUCT_TYPE)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="tags" label={t('product.tags')}>
              <TagInput
                options={tagOptions.map(({ name }) => name)}
                tags={values.tags}
                onChange={(value) => {
                  handleChange(value, FilterKey.PRODUCT_TAGS)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="quantityTags"
              label={t('product.lifecycle-steps.quantity.quantity-tags.label')}
            >
              <TagInput
                options={quantitiyTagsOptions.map(({ name }) => name)}
                tags={values.quantityTags}
                onChange={(value) => {
                  handleChange(value, FilterKey.QUANTITY_TAGS)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {featuresAllowed.includes('business-units') && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('business-unit.name')}>
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.search')}
                  value={values.businessUnit}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.BUSINESS_UNIT)
                  }}
                  onSearch={debounce((term) => onSearch(term, FilterKey.BUSINESS_UNIT), 500)}
                  style={{ width: '100%' }}
                  dropdownRender={(menu) => {
                    if (businessUnitLoading) {
                      return (
                        <span className={classes.dropdownContainer}>
                          <Spin />
                        </span>
                      )
                    }
                    return businessUnitOptions?.length ? (
                      menu
                    ) : (
                      <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                    )
                  }}
                  options={businessUnitOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t('log.owner')}>
              <Select
                mode={multipleAllowed ? 'multiple' : undefined}
                size="large"
                placeholder={t('log.filter.placeholder')}
                fieldNames={{ label: 'label', value: 'value' }}
                optionFilterProp="label"
                value={values.ownerId}
                options={createdByOptions}
                onChange={(value) => {
                  const cleanValue = multipleAllowed ? value : [value]
                  handleChange(cleanValue, FilterKey.RESPONSIBLE)
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default React.memo(ProductFiltersForm)
