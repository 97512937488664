import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import { FactorRequest } from '@cozero/models'

import Text from '@/atoms/Text'

import { formatCompact } from '@/utils/number'
import { calculateEmissionValue } from '@/utils/recalculations'

import classes from './classes.module.less'

interface Props {
  factorRequest: FactorRequest
  sharedLifeCycles?: Array<{
    id: number
    key: string
    value: number
  }>
  totalEmissionValue?: number | null
}

function EmissionPerLifecycleTable({
  factorRequest,
  sharedLifeCycles,
  totalEmissionValue,
}: Props): JSX.Element {
  const { t } = useTranslation('common')

  return (
    <Row>
      <div className={classes.lifeCycleDetailsTable}>
        <Row className={classes.tableHeader}>
          <Col>
            <Text fontWeight="medium" size="xl">
              {t('customer-requests.review-modal.emission-lifecycle-step')}
            </Text>
          </Col>
        </Row>
        <Row className={classes.subHeader}>
          <Col>
            <Text color="secondary" fontWeight="medium">
              {t('customer-requests.review-modal.lifecycle-steps')}
            </Text>
          </Col>
          <Col>
            <Text color="secondary" fontWeight="medium">
              {t('product.lifecycle-steps.quantity.footprint')} {`(${factorRequest?.unit?.name})`}
            </Text>
          </Col>
        </Row>
        <div className={classes.tableContent}>
          {sharedLifeCycles?.map((step) => {
            const displayValue = calculateEmissionValue(factorRequest.product?.mass, step.value)
            return (
              <Row className={classes.tableData} key={step.id}>
                <Col>
                  <Text fontWeight="medium">
                    {t(
                      'product.lifecycle-steps.' +
                        (step.key.replace('product-', '') ?? 'product-configuration') +
                        '.title',
                    )}
                  </Text>
                </Col>
                <Col>
                  <Text fontWeight="medium">
                    {displayValue ? formatCompact(displayValue * 1000) : '--'}
                  </Text>
                </Col>
              </Row>
            )
          })}
        </div>
        <Row className={classes.tableFooter}>
          <Col>
            <Text fontWeight="semibold" size="xl">
              {totalEmissionValue ? formatCompact(totalEmissionValue) : '--'}{' '}
              {factorRequest?.unit?.name as string}
            </Text>
          </Col>
        </Row>
      </div>
    </Row>
  )
}

export default EmissionPerLifecycleTable
