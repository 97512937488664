/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiDownload,
  HiInformationCircle,
  HiOutlineCheckCircle,
  HiOutlineDotsHorizontal,
  HiOutlineViewBoards,
} from 'react-icons/hi'

import { Dropdown, MenuProps, message } from 'antd'

import i18n from 'i18next'

import { LogEntriesTableColumnKey } from '@cozero/models/src'

import { SpinnerElement } from '@/atoms/LoadingSpinner'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { SearchDto, useLazyExportLogEntriesQuery } from '@/redux/logEntries'
import { COZERO_BLUE_80, COZERO_GREEN_60 } from '@/styles/variables'

import classes from './ExportDropdown.module.less'

export const MAX_ROWS_EXPORT_CAN_HANDLE = 500000

const LabelWithTooltip = ({
  label,
  tooManyRows,
}: {
  label: string
  tooManyRows: boolean
}): JSX.Element | null => {
  const { t } = useTranslation('common')
  return (
    <span className={classes.item}>
      {label}
      {tooManyRows && (
        <Tooltip
          className={classes.itemInner}
          content={t('log.log-entries-overview.export.tooltip', {
            max_rows: MAX_ROWS_EXPORT_CAN_HANDLE.toLocaleString(i18n.language),
          })}
          showArrow
        >
          <HiInformationCircle className={classes.tooltip} size={18} color={COZERO_BLUE_80} />
        </Tooltip>
      )}
    </span>
  )
}

type Props = {
  searchQuery: SearchDto
  visibleColumns?: LogEntriesTableColumnKey[]
  manageColumns: () => void
  isLoadingTableData: boolean
  numberOfRows: number | undefined
  unfilteredNumberOfRows: number | undefined
}

const ExportDropdown = ({
  searchQuery,
  visibleColumns,
  manageColumns,
  isLoadingTableData,
  numberOfRows,
  unfilteredNumberOfRows,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('common')
  const { trackAction } = useMixpanel()
  const [runningExport, setRunningExport] = React.useState<string>()
  const [open, setOpen] = React.useState(false)
  const tooManyRowsForExport = numberOfRows ? numberOfRows >= MAX_ROWS_EXPORT_CAN_HANDLE : false
  const tooManyRowsForUnfilteredExport = unfilteredNumberOfRows
    ? unfilteredNumberOfRows >= MAX_ROWS_EXPORT_CAN_HANDLE
    : false

  const [exportLogEntries] = useLazyExportLogEntriesQuery()

  const onExport = React.useCallback(
    async (exportKey: string, type: 'csv' | 'xlsx', filtered: boolean) => {
      setRunningExport(exportKey)

      trackAction(
        AnalyticsCategories.LOG_ENTRY_OVERVIEW,
        filtered ? 'filtered-view-exported' : 'entire-view-exported',
      )

      try {
        if (!searchQuery.selectedBusinessUnitId) {
          throw new Error('No business unit selected')
        }

        const { filters, ...query } = searchQuery

        exportLogEntries({
          ...query,
          type,
          filters: filtered ? filters : {},
          visibleColumns: filtered ? visibleColumns : undefined,
        })

        message.success({
          content: t('log.log-entries-overview.export.success'),
          className: classes.exportSuccess,
          icon: <HiOutlineCheckCircle size={18} color={COZERO_GREEN_60} />,
        })
      } catch (error) {
        message.error(t('general-errors.app-error'))
      }

      setRunningExport(undefined)
      setOpen(false)
    },
    [trackAction, searchQuery, exportLogEntries, visibleColumns, t],
  )

  const exportItems: MenuProps['items'] = React.useMemo(
    () =>
      [
        {
          key: 'full-view-csv',
          icon: <HiDownload />,
          label: (
            <LabelWithTooltip
              label={t('log.log-entries-overview.export.full-csv')}
              tooManyRows={tooManyRowsForUnfilteredExport}
            />
          ),
          disabled: tooManyRowsForUnfilteredExport,
          onClick: () => onExport('full-view-csv', 'csv', false),
        },
        {
          key: 'full-view-xslx',
          icon: <HiDownload />,
          label: (
            <LabelWithTooltip
              label={t('log.log-entries-overview.export.full-xlsx')}
              tooManyRows={tooManyRowsForUnfilteredExport}
            />
          ),
          disabled: tooManyRowsForUnfilteredExport,
          onClick: () => onExport('full-view-xslx', 'xlsx', false),
        },
        {
          key: 'filtered-view-csv',
          icon: <HiDownload />,
          label: (
            <LabelWithTooltip
              label={t('log.log-entries-overview.export.filtered-csv')}
              tooManyRows={tooManyRowsForExport}
            />
          ),
          disabled: tooManyRowsForExport,
          onClick: () => onExport('filtered-view-csv', 'csv', true),
        },
        {
          key: 'filtered-view-xslx',
          icon: <HiDownload />,
          label: (
            <LabelWithTooltip
              label={t('log.log-entries-overview.export.filtered-xlsx')}
              tooManyRows={tooManyRowsForExport}
            />
          ),
          disabled: tooManyRowsForExport,
          onClick: () => onExport('filtered-view-xslx', 'xlsx', true),
        },
      ].map((item) => ({
        ...item,
        icon:
          runningExport === item.key ? (
            <SpinnerElement size="2xs" color="grey" className={classes.spinner} />
          ) : (
            item.icon
          ),
        disabled: Boolean(item.disabled || runningExport || isLoadingTableData),
      })),
    [
      t,
      tooManyRowsForUnfilteredExport,
      tooManyRowsForExport,
      onExport,
      runningExport,
      isLoadingTableData,
    ],
  )

  const items: MenuProps['items'] = React.useMemo(
    () => [
      ...exportItems,
      {
        key: `manage-columns`,
        icon: <HiOutlineViewBoards />,
        label: t('log.log-entries-overview.manage-columns.label'),
        disabled: isLoadingTableData,
        onClick: () => {
          setOpen(false)
          manageColumns()
        },
      },
    ],
    [exportItems, manageColumns, t, isLoadingTableData],
  )

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
      overlayClassName={classes.dotsMenu}
      onOpenChange={setOpen}
      open={open}
    >
      <HiOutlineDotsHorizontal className={classes.dotsIcon} size={16} />
    </Dropdown>
  )
}

export default ExportDropdown
