import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Popover, Row, Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import Button from '@/atoms/Button'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed, getIsAdmin, getIsManager } from '@/redux/auth'
import { selectUserOrganization } from '@/redux/auth'
import { config } from '@/utils/config'

import classes from './AdminSettings.module.less'

const AdminSettingsBase = (): ReactElement => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const organization = useAppSelector(selectUserOrganization)
  const isManager = useAppSelector(getIsManager)
  const isAdmin = useAppSelector(getIsAdmin)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const MENU_ITEMS = useMemo(() => {
    const items: { uri: string; title: string }[] = []

    if (isAdmin) {
      items.push({
        uri: routes.settings.organization,
        title: t('settings.organization.title'),
      })
      items.push({
        uri: routes.settings.subscription,
        title: t('layout.subscription'),
      })
      items.push({
        uri: routes.settings.integrations,
        title: t('layout.integrations'),
      })
    }

    items.push({
      uri: routes.settings.users,
      title: t('settings.users.title'),
    })

    if (isAdmin && featuresAllowed.includes('api')) {
      items.push({
        uri: routes.settings.api,
        title: t('layout.api'),
      })
    }

    if (isAdmin && featuresAllowed.includes('data-backup')) {
      items.push({
        uri: routes.settings.data,
        title: t('layout.data'),
      })
    }
    if (isAdmin && organization?.pricing?.type !== 'enterprise') {
      items.push({
        uri: routes.settings.plans,
        title: t('layout.plans'),
      })
    }

    if ((isManager || isAdmin) && featuresAllowed.includes('user-functions')) {
      items.push({
        uri: routes.settings.functions,
        title: t('layout.user-functions'),
      })
    }

    return items
  }, [])

  const [currentTab, setCurrentTab] = useState<{ uri: string; title: string }>(MENU_ITEMS[0])

  const onTabClickHandler = useCallback((key: string): void => {
    setCurrentTab(MENU_ITEMS.find((item) => item.uri === key) ?? MENU_ITEMS[0])
    return navigate(key)
  }, [])

  const TabsItems = useMemo(() => {
    return MENU_ITEMS.map(({ uri, title }) => (
      <TabPane key={uri} tab={title}>
        <Outlet />
      </TabPane>
    ))
  }, [MENU_ITEMS])
  const commitHash = config.COMMIT_HASH

  useEffect(() => {
    if (location) {
      setCurrentTab(
        MENU_ITEMS.find((item) => location.pathname.startsWith(item.uri)) ?? MENU_ITEMS[0],
      )
    }
  }, [pathname])

  return (
    <Row justify="center" className={classes.tabSection}>
      <Col span={22}>
        <Tabs
          activeKey={currentTab.uri}
          defaultActiveKey={MENU_ITEMS[0].uri}
          onTabClick={onTabClickHandler}
        >
          {TabsItems}
        </Tabs>
      </Col>
      {commitHash && (
        <Col span={1}>
          <Popover content={commitHash}>
            <Button>i</Button>
          </Popover>
        </Col>
      )}
    </Row>
  )
}

export default AdminSettingsBase
