/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useContext } from 'react'
import { Layout } from 'react-grid-layout'

import { Store } from 'antd/es/form/interface'

import {
  Board,
  GetReportWithDataBase,
  PageFilter,
  Product,
  Report,
  ReportCategory,
  ReportSetting,
  ReportToBoard,
  User,
} from '@cozero/models'

import useBoardsHandler from '../hooks/useBoards'

export interface BoardsContextInterface {
  error: string
  loading: boolean
  loadingBoards: boolean
  boards: Board[]
  getBoards: () => Promise<void>
  getAvailableReports: () => Promise<void>
  setSelectedBoard: React.Dispatch<React.SetStateAction<Board | undefined>>
  selectedBoard?: Board
  getBoard: ({
    boardId,
    user,
    closedPeriodId,
  }: {
    boardId: number
    user?: User
    closedPeriodId?: number
  }) => Promise<Board | undefined>
  loadingGetBoard: boolean
  setLoadingGetBoard: (loading: boolean) => void
  selectedProduct?: Product
  setSelectedProduct: (product?: Product) => void
  setLoading: (loading: boolean) => void
  reports?: Report[]
  updateReportToBoard: (
    board: Board,
    id: Report['id'],
    newValues: Partial<ReportToBoard>,
  ) => Promise<void>
  editReportsOfBoard: (
    reports: Store,
    boardId: number,
    sharePageId?: number,
    closedPeriodId?: number,
  ) => Promise<void>
  removeReportFromSelectedBoard: (
    id: number,
    userId?: number,
    sharePageId?: number,
  ) => Promise<void>
  editingBoard: boolean
  setEditingBoard: React.Dispatch<React.SetStateAction<boolean>>
  clearUpdatedLayout: () => void
  updateBoardLayout: (board: Board, layout: Layout[], sharePageId?: number) => void
  updateBoardWidth: (board: Board, boardId: number) => void
  reportSettings: ReportSetting[]
  setReportSettings: (reportSettings: ReportSetting[]) => void
  searchReportCategories: (searchText: string) => Promise<ReportCategory[]>
  getReportWithData: ({
    key,
    currentFilters,
    persistedBoardSettingsFilters,
    setting,
    abortSignal,
    includeChildrenBUsData,
  }: {
    key: string
    currentFilters?: PageFilter[]
    persistedBoardSettingsFilters?: PageFilter[]
    setting?: ReportSetting
    abortSignal?: AbortSignal
    includeChildrenBUsData?: boolean
  }) => Promise<GetReportWithDataBase | undefined>
  lastDeletedReportId: number | undefined
  handleReportData: (report: GetReportWithDataBase) => {
    isLoading: boolean
    isPollingEnabled: boolean
    hasError: boolean
    reportData?: Report
  }
}

export const boardsContext = React.createContext<BoardsContextInterface | undefined>(undefined)

const { Provider } = boardsContext

export function useBoardsContext(): BoardsContextInterface {
  const contextValue = useContext(boardsContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const BoardsProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const boardAccessors = useBoardsHandler()
  return <Provider value={boardAccessors}>{children}</Provider>
}

export default BoardsProvider
