/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { uniqBy } from 'lodash-es'

import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { getBusinessUnitOptions } from '@/utils/business-units'

import { SearchHookProps } from './types'
import { checkIfSearchMatchesItemProperty } from './utils'

export const useBusinessUnitSearch = (): SearchHookProps => {
  const { t } = useTranslation('common')

  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { data, isFetching } = useGetActiveBusinessUnitsQuery(
    {
      businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
      populate: true,
    },
    { skip: !selectSelectedBusinessUnit },
  )
  const [search, setSearch] = React.useState<string>('')

  const options = React.useMemo(
    () =>
      getBusinessUnitOptions({
        businessUnits: uniqBy(data, 'id').filter((item) =>
          checkIfSearchMatchesItemProperty(item, 'title', search),
        ),
        t,
      }),
    [data, search, t],
  )

  return {
    onSearch: setSearch,
    options,
    isFetching,
  }
}
