import React from 'react'

import Tooltip from '@/atoms/Tooltip'

import classes from './Avatar.module.less'

export const AvatarContainer = ({
  children,
  showTooltip = true,
  tooltipTitle,
}: {
  children: React.ReactElement[] | React.ReactElement
  showTooltip: boolean
  tooltipTitle?: string
}): React.ReactElement => (
  <Tooltip
    title={tooltipTitle}
    trigger={showTooltip ? ['hover'] : []}
    className={classes.avatarContainer}
  >
    {children}
  </Tooltip>
)
