import React from 'react'

import { IconProps } from '@/types/general'

const Globe = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25203 8H3C4.65685 8 6 9.34315 6 11V12C6 12.5523 6.44772 13 7 13C8.65685 13 10 14.3431 10 16V18C10.6906 18 11.3608 17.9125 12 17.748V16C12 14.3431 13.3431 13 15 13H17.4185C17.7935 12.0736 18 11.0609 18 10C18 8.9391 17.7935 7.92643 17.4185 7L17 7C16.4477 7 16 7.44772 16 8C16 9.65685 14.6569 11 13 11C11.3431 11 10 9.65685 10 8C10 7.44772 9.55228 7 9 7H8.5C6.65029 7 5.13571 5.56512 5.00863 3.74772C3.67527 4.81357 2.69034 6.29706 2.25203 8ZM5.55564 1.03942C2.26289 2.67574 0 6.07361 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 8.40387 19.6261 6.89509 18.961 5.55647C18.9607 5.55586 18.9604 5.55525 18.9601 5.55464C17.3236 2.26243 13.926 0 10 0C8.40355 0 6.89447 0.374101 5.55564 1.03942ZM7 2.58152V3.5C7 4.32843 7.67157 5 8.5 5H9C10.6569 5 12 6.34315 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8C14 6.58106 14.9851 5.39221 16.3087 5.08004C14.8446 3.20531 12.563 2 10 2C8.9391 2 7.92643 2.20651 7 2.58152ZM16.2454 15H15C14.4477 15 14 15.4477 14 16V16.9297C14.8634 16.4303 15.6249 15.7741 16.2454 15ZM8 17.748C4.54955 16.8599 2 13.7277 2 10H3C3.55228 10 4 10.4477 4 11V12C4 13.6569 5.34315 15 7 15C7.55228 15 8 15.4477 8 16V17.748Z"
        fill={color}
      />
    </svg>
  )
}

export default Globe
