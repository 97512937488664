import { useEffect } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'

import jwtDecode from 'jwt-decode'

interface UseRedirectArgs {
  fallback: (payload: unknown, navigate: NavigateFunction) => void
}

export const useRedirect = (args?: UseRedirectArgs): void => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')
  const token = searchParams.get('token')

  useEffect(() => {
    if (redirect) {
      navigate(redirect)
    } else if (args?.fallback && token) {
      const tokenPayload = jwtDecode(token)
      if (!tokenPayload) {
        return
      }

      args?.fallback(tokenPayload, navigate)
    }
  }, [redirect, token])
}
