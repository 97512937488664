import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row, Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'

import classes from './Organization.module.less'

const OrganizationBase = (): ReactElement => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const menuItems = [
    {
      uri: `${routes.organization.locations.base}`,
      title: t('layout.locations'),
    },
  ]
  if (featuresAllowed.includes('business-units')) {
    menuItems.push({
      uri: `${routes.organization.businessUnits}`,
      title: t('layout.business-units'),
    })
  }
  menuItems.push({
    uri: `${routes.organization.organigram}`,
    title: t('layout.organigram'),
  })

  const [currentTab, setCurrentTab] = useState<{ uri: string; title: string }>(menuItems[0])

  const onTabClickHandler = useCallback((key: string): void => {
    setCurrentTab(menuItems.find((item) => item.uri === key) ?? menuItems[0])
    return navigate(key)
  }, [])

  const TabsItems = menuItems.map(({ uri, title }) => (
    <TabPane key={uri} tab={title}>
      {pathname === uri && <Outlet />}
    </TabPane>
  ))

  useEffect(() => {
    if (location) {
      setCurrentTab(menuItems.find((item) => item.uri === location.pathname) ?? menuItems[0])
    }
  }, [pathname])

  return (
    <Row justify="center" className={classes.tabSection}>
      <Col span={24}>
        <Tabs
          activeKey={currentTab.uri}
          defaultActiveKey={menuItems[0].uri}
          onTabClick={onTabClickHandler}
          data-cy="business-unit-tab"
        >
          {TabsItems}
        </Tabs>
      </Col>
    </Row>
  )
}

export default OrganizationBase
