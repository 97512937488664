export enum AnalyticsCategories {
  ACT = 'act',
  ACT_ACTION_SIMULATOR = 'act-action-simulator',
  ACT_CLIMATE_ACTIONS = 'act-climate-actions',
  ACT_FORECAST = 'act-forecast',
  ACT_HOTSPOT_ANALYSIS = 'act-hotspot-analysis',
  ACT_ONBOARDING = 'act-onboarding',
  ACT_REDUCTION_EFFORT_GRAPH = 'act-reduction-effort-graph',
  ACT_TARGETS = 'act-targets',
  ACT_TRACKING_CATEGORY = 'act-hotspot-analysis',
  ACT_YEAR_ON_YEAR_GRAPH = 'act-year-on-year-graph',
  ACT_YEAR_ON_YEAR_TABLE = 'act-year-on-year-graph',
  API_INTEGRATIONS = 'api-integrations',
  API_KEYS = 'api-keys',
  BULK_IMPORT = 'bulk-import',
  BUSINESS_UNIT = 'business-unit',
  CALCULATION_CHANGES = 'calculations-changes',
  CARBON_FOOTPRINT = 'carbon-footprint',
  CERTIFICATES = 'certificates',
  CHANGELOG = 'changelog',
  CLOSED_PERIODS = 'closed-periods',
  COMMENT = 'comment',
  CUSTOMER_REQUESTS = 'customer-requests',
  CUSTOM_REPORTS = 'custom-reports',
  CUSTOMERS = 'customers',
  ERROR = 'error',
  FACTOR_REQUESTS = 'factor-requests',
  FACTORS = 'factors',
  FILES = 'files',
  FILTERS = 'filters',
  FORECAST_SETTINGS = 'forecast-settings',
  HOME = 'home',
  INTEGRATIONS = 'integrations',
  LOCATIONS = 'locations',
  LOG_ENTRY = 'log-entry',
  LOG_ENTRY_OVERVIEW = 'log-entry-overview',
  LOGIN = 'login',
  LOGS = 'logs',
  MARKETPLACE = 'marketplace',
  MENU = 'menu',
  NAVIGATION_SIDEBAR = 'navigation-sidebar',
  ONBOARDING = 'onboarding',
  ORGANIGRAM = 'organigram',
  ORGANISATION = 'organisation',
  PAYMENT = 'payment',
  PLANS = 'plans',
  PRODUCT_INFO = 'product-info',
  PRODUCT_LOGS = 'product-logs',
  PRODUCTS = 'products',
  QUANTITIES = 'quantities',
  QUANTITIES_LOGS = 'quantities-logs',
  REGISTER = 'register',
  REPORTS = 'reports',
  SHARE_PAGE = 'share-page',
  SIGNUP = 'signup',
  SOLUTIONS = 'solutions',
  SUBSCRIPTIONS = 'subscriptions',
  SUCCESS_STORY = 'success-story',
  SUPPLIER = 'supplier',
  SUSTAINABILITY_REPORT = 'sustainability-report',
  TAG = 'tag',
  UNKNOWN = 'unknown',
  USER_FUNCTIONS = 'user-functions',
  USERS = 'users',
  VIEW = 'view',
  WALKTHROUGH = 'walkthrough',
}
