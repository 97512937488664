import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight, HiCheck, HiOutlineBookOpen } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd/es'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Tag from '@/atoms/Tag'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './OnboardingStepCard.module.less'

export enum ONBOARDING_STATUS {
  TODO = 'TODO',
  WAITING = 'WAITING',
  DONE = 'DONE',
}

interface Props {
  title: string
  subtitle: string
  cardNumber: number
  wikiLink?: string
  wikiLinkText?: string
  actionLink: string
  actionButton?: React.ReactNode
  open: boolean
  className?: string
  status: ONBOARDING_STATUS
}

const OnboardingStepCard = ({
  title,
  subtitle,
  cardNumber,
  wikiLink,
  wikiLinkText,
  actionLink,
  open,
  actionButton,
  status,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const completed = useMemo(() => status === ONBOARDING_STATUS.DONE, [status])

  return (
    <>
      {status === ONBOARDING_STATUS.WAITING ? <div className={classes.disableLayer}></div> : <></>}
      <Card shadow="none">
        <Row>
          <Col span={1}>
            <div className={`${classes.circleIcon} ${completed && classes.completed}`}>
              {completed ? <HiCheck /> : <span>{cardNumber}</span>}
            </div>
          </Col>
          <Col xs={21} xxl={22}>
            <div className={classes.header}>
              <Title size="xs" className={classes.title}>
                {title}
              </Title>
              <p className={classes.subtitle}>{subtitle}</p>
            </div>
          </Col>
          <Col xs={2} xxl={1}>
            <Tag className={classes.tag} type={completed ? 'success' : 'info'}>
              {completed
                ? t('onboarding.complete')
                : status === ONBOARDING_STATUS.WAITING
                ? t('onboarding.demo.waiting')
                : t('onboarding.demo.tag')}
            </Tag>
          </Col>
        </Row>
        {(completed || open) && wikiLink && wikiLinkText && (
          <Row>
            <Col span={16} offset={1}>
              <a href={wikiLink} className={classes.supportText} target="_blank" rel="noreferrer">
                <HiOutlineBookOpen className={classes.icon} />
                <span>{wikiLinkText}</span>
              </a>
            </Col>
          </Row>
        )}
        {([ONBOARDING_STATUS.DONE, ONBOARDING_STATUS.TODO] || open) && (
          <Row justify="end">
            <Col>
              {actionButton ? (
                actionButton
              ) : (
                <Button
                  type={completed ? 'secondary' : 'primary'}
                  category={AnalyticsCategories.ONBOARDING}
                  action="start-onboarding"
                  className={classes.cardBtn}
                  onClick={() => navigate(actionLink)}
                  fillParent
                  suffixIcon={<HiArrowRight />}
                >
                  {t('onboarding.demo.start')}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Card>
    </>
  )
}

export default OnboardingStepCard
