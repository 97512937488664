import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiChevronDown, HiOutlineTag, HiOutlineTrash } from 'react-icons/hi'

import { Col, Dropdown, MenuProps, Row } from 'antd'

import classNames from 'classnames'

import { RowSelectionResult } from '../../hooks/useRowsSelection'

import { LogEntryAddTagsModal } from './LogEntryAddTagsModal/LogEntryAddTagsModal'
import { LogEntryDeletionModal } from './LogEntryDeletionModal/LogEntryDeletionModal'
import classes from './LogEntryOverviewActionsDropdown.module.less'

type ActionsDropdownProps = Pick<RowSelectionResult, 'selectedLogEntriesIds' | 'clearSelection'>

export const LogEntryOverviewActionsDropdown = ({
  selectedLogEntriesIds = [],
  clearSelection,
}: ActionsDropdownProps): React.ReactElement => {
  const { t } = useTranslation('common')
  const [openDropdown, setOpenDropdown] = React.useState(false)
  const [openDeletionModal, setOpenDeletionModal] = React.useState(false)
  const [openAddTagsModal, setOpenAddTagsModal] = React.useState(false)

  const items: MenuProps['items'] = React.useMemo(
    () => [
      {
        key: `add-tags`,
        icon: <HiOutlineTag className={classNames(classes.clickable)} size={16} />,
        label: (
          <span className={classNames(classes.clickable)}>
            {t('log.log-entries-overview.actions.add-tags.label')}
          </span>
        ),
        onClick: () => {
          setOpenAddTagsModal(true)
          setOpenDropdown(false)
        },
      },
      {
        key: `delete-selection`,
        icon: (
          <HiOutlineTrash className={classNames(classes.danger, classes.clickable)} size={16} />
        ),
        label: (
          <span className={classNames(classes.danger, classes.clickable)}>
            {t('log.log-entries-overview.actions.delete-selection.label')}
          </span>
        ),
        onClick: () => {
          setOpenDeletionModal(true)
          setOpenDropdown(false)
        },
      },
    ],
    [],
  )

  const disabled = selectedLogEntriesIds.length === 0

  return (
    <>
      <LogEntryDeletionModal
        selectedLogEntriesIds={selectedLogEntriesIds}
        open={openDeletionModal}
        onCancel={() => setOpenDeletionModal(false)}
        onSuccess={() => {
          clearSelection()
          setOpenDeletionModal(false)
        }}
      />
      <LogEntryAddTagsModal
        selectedLogEntriesIds={selectedLogEntriesIds}
        open={openAddTagsModal}
        onCancel={() => setOpenAddTagsModal(false)}
        onSuccess={() => {
          clearSelection()
          setOpenAddTagsModal(false)
        }}
      />
      <Dropdown
        trigger={['click']}
        disabled={disabled}
        placement="bottomRight"
        menu={{ items }}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
        className={classNames(classes.dropdown, {
          [classes.disabled]: disabled,
        })}
        overlayClassName={classes.menu}
        onOpenChange={setOpenDropdown}
        open={openDropdown}
      >
        <Row gutter={[6, 6]}>
          <Col>
            <HiChevronDown className={classes.icon} size={18} />
          </Col>
          <Col>
            <span>{t('log.log-entries-overview.actions.label')}</span>
          </Col>
        </Row>
      </Dropdown>
    </>
  )
}
