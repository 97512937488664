import _ from 'lodash'
import moment from 'moment'

import {
  LogEntry,
  OrganizationEmission,
  OrganizationEmissionsCbam,
  ProductLogEntry,
} from '@cozero/models'

const TOLERANCE_AMOUNT = 1
const TOLERANCE_UNIT = 'minutes'

export function checkLogEntryIssues(
  logEntry: LogEntry | ProductLogEntry,
  emissions: OrganizationEmission[],
  emissionsCbam: OrganizationEmissionsCbam[],
): boolean {
  const hasEmissionEntry = !!_.find(emissions, { logEntryId: logEntry.id })
  const hasEmissionCbamEntry = !!_.find(emissionsCbam, { logEntryId: logEntry.id })
  return (
    !logEntry.calculating &&
    !(hasEmissionEntry || hasEmissionCbamEntry) &&
    isPastCalculationTolerancePeriod(logEntry.updatedAt)
  )
}

export function isPastCalculationTolerancePeriod(date: Date | null): boolean {
  if (!date) {
    return true
  }

  const parsed = moment(date)
  const now = moment().subtract(TOLERANCE_AMOUNT, TOLERANCE_UNIT)
  return parsed > now
}

export function isJson(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
