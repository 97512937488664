import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Space } from 'antd'
import { Collapse } from 'antd/es'

import Card from '../../../../atoms/Card'
import Text from '../../../../atoms/Text'

import classes from './FaqSection.module.less'

const { Panel } = Collapse

const FaqSection = (): JSX.Element => {
  const { t } = useTranslation('common')

  const questions = useMemo(
    () => [
      {
        title: t('onboarding.suppliers.faq.0.title'),
        text: t('onboarding.suppliers.faq.0.text'),
      },
      {
        title: t('onboarding.suppliers.faq.1.title'),
        text: t('onboarding.suppliers.faq.1.text'),
      },
      {
        title: t('onboarding.suppliers.faq.2.title'),
        text: t('onboarding.suppliers.faq.2.text'),
      },
      {
        title: t('onboarding.suppliers.faq.3.title'),
        text: t('onboarding.suppliers.faq.3.text'),
      },
    ],
    [],
  )
  return (
    <Card bgColor="default" shadow="none" className={classes.mainWrapper}>
      <Collapse bordered={false} ghost expandIconPosition="end" defaultActiveKey={1}>
        <Panel
          header={
            <Text fontWeight="semibold" size="sm" mode="title">
              {t('onboarding.suppliers.faq.title')}
            </Text>
          }
          key="1"
        >
          <Space direction="vertical" size={24} className={classes.questionsWrapper}>
            {questions.map(({ title, text }) => (
              <Space key={title} direction="vertical" size={0}>
                <Space size={12}>
                  <div className={classes.questionPill}>
                    <Text color="blue" fontWeight="semibold" size="lg" mode="text">
                      <div>?</div>
                    </Text>
                  </div>
                  <Text fontWeight="medium" size="xs" mode="title">
                    {title}
                  </Text>
                </Space>

                <Space size={12}>
                  <div style={{ width: '24px' }} />
                  <Text mode="paragraph" size="xl">
                    {text}
                  </Text>
                </Space>
              </Space>
            ))}
          </Space>
        </Panel>
      </Collapse>
    </Card>
  )
}

export default FaqSection
