import { MutableRefObject, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

export const useScrollToTop = (): {
  scrollElementRef: MutableRefObject<HTMLElement | null>
} => {
  const scrollElementRef = useRef<HTMLElement>(null)
  const location = useLocation()

  useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollTo(0, 0)
    }
  }, [location.pathname])

  return { scrollElementRef }
}
