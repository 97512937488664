/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'

import { uniqBy } from 'lodash-es'

import { useGetCalculationMethodsQuery } from '@/redux/calculationMethods'

import { SearchHookProps } from './types'
import { checkIfSearchMatchesItemProperty } from './utils'

export const useCalculationMethodsSearch = (): SearchHookProps => {
  const { data, isFetching } = useGetCalculationMethodsQuery({})
  const [search, setSearch] = React.useState<string>('')

  const options = React.useMemo(
    () =>
      uniqBy(data, 'id')
        .filter((item) => checkIfSearchMatchesItemProperty(item, 'name', search))
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
    [data, search],
  )

  return {
    onSearch: setSearch,
    options,
    isFetching,
  }
}
