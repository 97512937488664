import React, { ReactNode, Suspense, lazy } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { RouteObject } from 'react-router-dom'

import { routes } from '@cozero/utils'

import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const ClimateAction = lazy(() => import('@/pages/Act/ClimateActions'))
const CreateOrEditTarget = lazy(() => import('@/pages/Act/Targets/CreateOrEditTarget'))
const ActionSimulator = lazy(() => import('@/pages/Act/Analytics/ActionSimulator'))

const Wrappers = ({ children }: { children: ReactNode }): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature="act">{children}</FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getCustomActRoutes = (): RouteObject[] => [
  {
    path: routes.act.createClimateAction,
    element: (
      <Wrappers>
        <Suspense fallback={<SuspenseSpinner />}>
          <ClimateAction />
        </Suspense>
      </Wrappers>
    ),
  },
  {
    path: routes.act.editClimateAction,
    element: (
      <Wrappers>
        <Suspense fallback={<SuspenseSpinner />}>
          <ClimateAction />
        </Suspense>
      </Wrappers>
    ),
  },
  {
    path: routes.act.newTarget,
    element: (
      <Wrappers>
        <Suspense fallback={<SuspenseSpinner />}>
          <CreateOrEditTarget />
        </Suspense>
      </Wrappers>
    ),
  },
  {
    path: routes.act.editTarget,
    element: (
      <Wrappers>
        <Suspense fallback={<SuspenseSpinner />}>
          <CreateOrEditTarget />
        </Suspense>
      </Wrappers>
    ),
  },
  {
    path: routes.act.actionSimulator,
    element: (
      <Wrappers>
        <Suspense fallback={<SuspenseSpinner />}>
          <ActionSimulator />
        </Suspense>
      </Wrappers>
    ),
  },
]

export default getCustomActRoutes
