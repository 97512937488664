import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import { BusinessUnitDetails } from '@/pages/Organization/UpsertBusinessUnit'
import { LocationDetails } from '@/pages/Organization/UpsertLocation'

import Modal from '@/molecules/Modal'

import Title from '@/atoms/Title'

import classes from './ConfirmationModal.module.less'

type ConfirmationModalProps = {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  title: string
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  businessUnitDetails?: BusinessUnitDetails
  confirmationText: string
  userOrLocationDetails?: LocationDetails
}

const ConfirmationModal = ({
  openModal,
  setOpenModal,
  title,
  onOk,
  businessUnitDetails,
  confirmationText,
  userOrLocationDetails,
}: ConfirmationModalProps): ReactElement => {
  const { t } = useTranslation()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  return (
    <Modal
      title={title}
      visible={openModal}
      onOk={(e) => {
        setButtonDisabled(true)
        onOk(e)
      }}
      onCancel={() => {
        setOpenModal(!openModal)
        setButtonDisabled(false)
      }}
      okText={t('actions.save')}
      okButtonProps={{ disabled: buttonDisabled }}
    >
      <Title size="xs">{confirmationText} </Title>
      {businessUnitDetails && (
        <>
          <Row className={classes.details}>
            <Col span={10}>{t('settings.bu.title-field')}:</Col>
            <Col span={14}>{businessUnitDetails.title}</Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('layout.tags')}:</Col>
            <Col span={14}>
              {businessUnitDetails.tags.length > 0 ? businessUnitDetails.tags.join(', ') : '-'}
            </Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('settings.bu.parent-field')}:</Col>
            <Col span={14}>
              {businessUnitDetails.parentUnit
                ? businessUnitDetails.parentUnit
                : t('business-unit.modal.no-parent-unit')}
            </Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('settings.bu.active.title')}:</Col>
            <Col span={14}>{t(businessUnitDetails.active ? 'yes' : 'no')}</Col>
          </Row>
        </>
      )}
      {userOrLocationDetails && (
        <>
          <Row className={classes.details}>
            <Col span={10}>{t('name')}:</Col>
            <Col span={14}>{userOrLocationDetails.name}</Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('email')}:</Col>
            <Col span={14}>{userOrLocationDetails.email}</Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('business-unit.name')}:</Col>
            <Col span={14}>{userOrLocationDetails.businessUnitName}</Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('location.responsible-user')}:</Col>
            <Col span={14}>{userOrLocationDetails.responsibleUser}</Col>
          </Row>
          <Row className={classes.details}>
            <Col span={10}>{t('settings.users.role')}:</Col>
            <Col span={14}>{userOrLocationDetails.role}</Col>
          </Row>
        </>
      )}
    </Modal>
  )
}

export default ConfirmationModal
