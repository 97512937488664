import React from 'react'

import { IconProps } from '@/types/general'

const ClipboardList = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17071 2C4.58254 0.834808 5.69378 0 7 0H9C10.3062 0 11.4175 0.834808 11.8293 2H13C14.6569 2 16 3.34315 16 5V17C16 18.6569 14.6569 20 13 20H3C1.34315 20 0 18.6569 0 17V5C0 3.34315 1.34315 2 3 2H4.17071ZM4.17071 4H3C2.44772 4 2 4.44772 2 5V17C2 17.5523 2.44772 18 3 18H13C13.5523 18 14 17.5523 14 17V5C14 4.44772 13.5523 4 13 4H11.8293C11.4175 5.16519 10.3062 6 9 6H7C5.69378 6 4.58254 5.16519 4.17071 4ZM7 2C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4H9C9.55228 4 10 3.55228 10 3C10 2.44772 9.55228 2 9 2H7ZM4 10C4 9.44771 4.44772 9 5 9H5.01C5.56228 9 6.01 9.44771 6.01 10C6.01 10.5523 5.56228 11 5.01 11H5C4.44772 11 4 10.5523 4 10ZM7 10C7 9.44771 7.44772 9 8 9H11C11.5523 9 12 9.44771 12 10C12 10.5523 11.5523 11 11 11H8C7.44772 11 7 10.5523 7 10ZM4 14C4 13.4477 4.44772 13 5 13H5.01C5.56228 13 6.01 13.4477 6.01 14C6.01 14.5523 5.56228 15 5.01 15H5C4.44772 15 4 14.5523 4 14ZM7 14C7 13.4477 7.44772 13 8 13H11C11.5523 13 12 13.4477 12 14C12 14.5523 11.5523 15 11 15H8C7.44772 15 7 14.5523 7 14Z"
        fill={color}
      />
    </svg>
  )
}

export default ClipboardList
