import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { RootState } from '@/redux'

export interface Emissions {
  scope1: number
  scope2: number
  scope3: number
  total: number
}

export type NodeToEmissionsMapType = {
  locations: { [key: number]: Emissions }
  businessUnits: { [key: number]: Emissions }
}

export const selectNodeToEmissionsMap = (state: RootState): NodeToEmissionsMapType =>
  state.organigram.nodeToEmissionsMap

export const selectEmissionsForNode = createSelector(
  [
    selectNodeToEmissionsMap,
    (_state, { nodeId, isLocation }) => {
      return {
        nodeId,
        isLocation,
      }
    },
  ],
  (nodeToEmissionsMap, { nodeId, isLocation }) => {
    const map = isLocation ? nodeToEmissionsMap.locations : nodeToEmissionsMap.businessUnits
    return (
      map[nodeId] ?? {
        total: undefined,
        scope1: undefined,
        scope2: undefined,
        scope3: undefined,
      }
    )
  },
)

export type OrganigramState = {
  nodeToEmissionsMap: NodeToEmissionsMapType
}

const initialState: OrganigramState = {
  nodeToEmissionsMap: {
    locations: {},
    businessUnits: {},
  },
}

const organigramSlice = createSlice({
  name: 'organigram',
  initialState: initialState,
  reducers: {
    setNodeToEmissionsMap: (state, { payload }: PayloadAction<NodeToEmissionsMapType>) => {
      return {
        ...state,
        nodeToEmissionsMap: payload,
      }
    },
  },
})

export const { setNodeToEmissionsMap } = organigramSlice.actions

export default organigramSlice
