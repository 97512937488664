import { HotspotAnalysisViewDto } from '@cozero/dtos'

export const SLICE_NAME_ACT_AN = 'actAnalytics'

export const DEFAULT_ANALYTICS_STATE: {
  expandedRowKeys: React.Key[]
  selectHotspotAnalysisView: HotspotAnalysisViewDto | undefined
} = {
  expandedRowKeys: [],
  selectHotspotAnalysisView: undefined,
}

// Hotspot Analysis
export const TAG_EMISSION_HOTSPOTS_DATA = 'TAG_EMISSION_HOTSPOTS_DATA'
export const TAG_HOTSPOTS_VIEWS = 'TAG_HOTSPOTS_VIEWS'
export const TAG_HOTSPOTS_PINS = 'TAG_HOTSPOTS_PINS'
export const TAG_REDUCTION_EFFORT_GRAPH = 'TAG_REDUCTION_EFFORT_GRAPH'
export const TAG_YEAR_ON_YEAR_DATA = 'TAG_YEAR_ON_YEAR_DATA'

// Action Simulator
export const TAG_ACTION_SIMULATOR_ACTIVITY_DATA = 'TAG_ACTION_SIMULATOR_ACTIVITY_DATA'

export default [
  TAG_EMISSION_HOTSPOTS_DATA,
  TAG_HOTSPOTS_VIEWS,
  TAG_REDUCTION_EFFORT_GRAPH,
  TAG_YEAR_ON_YEAR_DATA,
]
