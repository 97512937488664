import React from 'react'

import { IconProps } from '@/types/general'

const OutgoingDocument = ({
  width = 16,
  height = 16,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66675 2.66671C4.29856 2.66671 4.00008 2.96518 4.00008 3.33337V12.6667C4.00008 13.0349 4.29856 13.3334 4.66675 13.3334H11.3334C11.7016 13.3334 12.0001 13.0349 12.0001 12.6667V6.27618L8.39061 2.66671H4.66675ZM2.66675 3.33337C2.66675 2.2288 3.56218 1.33337 4.66675 1.33337H8.39061C8.74423 1.33337 9.08337 1.47385 9.33342 1.7239L12.9429 5.33337C13.1929 5.58342 13.3334 5.92256 13.3334 6.27618V12.6667C13.3334 13.7713 12.438 14.6667 11.3334 14.6667H4.66675C3.56218 14.6667 2.66675 13.7713 2.66675 12.6667V3.33337Z"
        fill={color}
      />
      <path
        d="M8.19518 5.52864C7.93483 5.78899 7.93483 6.2111 8.19518 6.47145L9.05711 7.33337L7.99992 7.33337C6.15897 7.33337 4.66658 8.82576 4.66658 10.6667L4.66658 11.3334C4.66658 11.7016 4.96506 12 5.33325 12C5.70144 12 5.99992 11.7016 5.99992 11.3334L5.99992 10.6667C5.99992 9.56214 6.89535 8.66671 7.99992 8.66671L9.05711 8.66671L8.19518 9.52864C7.93483 9.78898 7.93483 10.2111 8.19518 10.4714C8.45553 10.7318 8.87764 10.7318 9.13799 10.4714L11.138 8.47145C11.3983 8.2111 11.3983 7.78899 11.138 7.52864L9.13799 5.52864C8.87764 5.26829 8.45553 5.26829 8.19518 5.52864Z"
        fill={color}
      />
    </svg>
  )
}

export default OutgoingDocument
