import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { OrganizationCarboonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import { useAppSelector } from '@/redux'
import { selectOrganizationEmissionsFilters } from '@/redux/logEntriesOverview/selectors'
import { setOrganizationEmissionsFilters } from '@/redux/logEntriesOverview/slice'

type SetFiltersCallback = (value: OrganizationCarboonFootprintFormValues) => void

export const useOrganizationEmissionsFiltersState = (): [
  OrganizationCarboonFootprintFormValues,
  SetFiltersCallback,
] => {
  const dispatch = useDispatch()
  const filters = useAppSelector(selectOrganizationEmissionsFilters)

  const setFilters: SetFiltersCallback = useCallback(
    (filters: OrganizationCarboonFootprintFormValues) => {
      if (!filters) {
        return
      }

      dispatch(setOrganizationEmissionsFilters(filters))
    },
    [],
  )

  return [filters, setFilters]
}
