import { LogEntryTag } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { TAG_LOG_ENTRY_TAGS } from './tags'

const logEntryTagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogEntryTags: builder.query<
      LogEntryTag[],
      { selectedBusinessUnitId: number; subcategoryId?: number }
    >({
      query: ({ selectedBusinessUnitId, subcategoryId }) => ({
        url: logApiGatewayClient.tags.GET_MANY,
        method: 'GET',
        params: {
          businessUnitId: selectedBusinessUnitId,
          ...(subcategoryId ? { subcategoryId } : {}),
        },
      }),
      providesTags: () => [TAG_LOG_ENTRY_TAGS],
    }),
    createLogEntryTag: builder.mutation<LogEntryTag, Partial<LogEntryTag>>({
      query: (data) => ({
        url: logApiGatewayClient.tags.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG_LOG_ENTRY_TAGS],
    }),
    deleteLogEntryTag: builder.mutation<LogEntryTag[], { tagId: number }>({
      query: ({ tagId }) => ({
        url: logApiGatewayClient.tags.DELETE_ONE.replace(':id', `${tagId}`),
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_LOG_ENTRY_TAGS],
    }),
  }),
})

export const {
  useGetLogEntryTagsQuery,
  useCreateLogEntryTagMutation,
  useDeleteLogEntryTagMutation,
} = logEntryTagsApiSlice

export default logEntryTagsApiSlice
