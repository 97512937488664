import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamation } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import classNames from 'classnames'

import LoadingSpinner from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { CINDER_BLUE_60, CINDER_BLUE_80 } from '@/styles/variables'

import classes from './OverviewCard.module.less'

export interface OverviewCardProps {
  headerColor?: string
  headerTitle: string
  headerWeight?: 'bold' | 'medium' | 'semibold' | 'regular' | 'light'
  tooltip?: {
    triggerElement: ReactNode
    subtitle: string
  }
  content: ReactNode
  isVisible?: boolean
  style?: React.CSSProperties | undefined
  loading?: boolean
  className?: string
  truncateHeader?: boolean
  isError?: boolean
  icon?: ReactElement
}

const OverviewCard = ({
  headerTitle,
  headerColor = CINDER_BLUE_60,
  headerWeight = 'medium',
  tooltip,
  content,
  loading,
  className,
  truncateHeader = true,
  isError,
  icon,
  ...rest
}: OverviewCardProps): JSX.Element => {
  const { t } = useTranslation('common')

  if (isError) {
    return (
      <div
        className={classNames(classes.overviewContainer, { [classes[className || '']]: className })}
        {...rest}
      >
        <Row className={classes.header}>
          <Col xs={24} className={classes.noValuePlaceholder}>
            <Text mode="title" fontWeight="medium" size="lg">
              ---
            </Text>
          </Col>

          <Col span={24} className={classes.warningInfo}>
            <HiOutlineExclamation className={classes.icon} size={14} />

            <Text fontWeight="medium" size="lg">
              {t('act.climateActions.overview-stats.refresh-page')}
            </Text>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <div
      className={classNames(classes.overviewContainer, { [classes[className || '']]: className })}
      {...rest}
    >
      <Row className={classes.header}>
        <Col className={classes.titleWrapper}>
          {icon}
          <Text
            className={classNames({ [classes.truncate]: truncateHeader })}
            size="xl"
            fontWeight={headerWeight}
            style={{ color: headerColor }}
            title={headerTitle}
          >
            {headerTitle}
          </Text>
        </Col>
        {tooltip && (
          <Col className={classes.tooltipIcon}>
            <Tooltip showArrow color={CINDER_BLUE_80} content={tooltip?.subtitle}>
              {tooltip?.triggerElement}
            </Tooltip>
          </Col>
        )}
      </Row>
      {loading ? (
        <div className={classes.spinner}>
          <LoadingSpinner size={'sm'} />
        </div>
      ) : (
        content
      )}
    </div>
  )
}

export default OverviewCard
