import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'
import { Navigate } from 'react-router'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import {
  ComputedReportNameCrumb,
  ComputedReportTypeCrumb,
} from '@/molecules/Breadcrumbs/ComputedReportCrumb'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const Home = lazy(() => import('@/pages/Home'))
const OverviewWrapperPage = lazy(() => import('@/pages/Overview/OverviewWrapperPage'))
const NotFoundPage = lazy(() => import('@/pages/404'))
const AvailableReports = lazy(() => import('@/pages/Overview/Reports/AvailableReports'))
const ComputedReport = lazy(() => import('@/pages/Overview/Reports/ComputedReports/'))
const YourReports = lazy(() => import('@/pages/Overview/Reports/YourReports'))
const ReportsPage = lazy(() => import('@/pages/Overview/Reports/ReportsPage'))
const ViewReport = lazy(() => import('@/pages/Overview/Reports/PresetReports/ViewReport'))
const BalanceSheetReport = lazy(
  () => import('@/pages/Overview/Reports/PresetReports/BalanceSheetReport'),
)

const Wrapper = ({
  feature = 'reports',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
  </PrivateRoute>
)

/**
 * All routes in the /share/ namespace
 */
const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.overview.base,
    element: (
      <Wrapper>
        <Suspense fallback={<SuspenseSpinner />}>
          <OverviewWrapperPage />
        </Suspense>
      </Wrapper>
    ),
    children: [
      {
        path: routes.overview.dashboard,
        element: (
          <Wrapper feature="reports">
            <LogProvider>
              <FiltersProvider saveQueryString={true}>
                <Home />
              </FiltersProvider>
            </LogProvider>
          </Wrapper>
        ),
        breadcrumb: 'Home',
      },
      {
        path: routes.overview.reports.base,
        element: (
          <Wrapper feature="reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <ReportsPage />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.reports'),
        children: [
          {
            index: true,
            element: (
              <Wrapper>
                <Navigate to={{ pathname: routes.overview.reports.availableReports }} />
              </Wrapper>
            ),
          },
          {
            path: routes.overview.reports.yourReports,
            element: (
              <Wrapper feature="computed-reports">
                <Suspense fallback={<SuspenseSpinner />}>
                  <YourReports />
                </Suspense>
              </Wrapper>
            ),
            breadcrumb: t('share.your-reports.tab-title'),
          },
          {
            path: routes.overview.reports.availableReports,
            element: (
              <Wrapper feature="reports">
                <Suspense fallback={<SuspenseSpinner />}>
                  <AvailableReports />
                </Suspense>
              </Wrapper>
            ),
            breadcrumb: t('share.available-reports.tab-title'),
          },
        ],
      },
      {
        path: routes.overview.reports.computedReport.base,
        props: { preventNavigation: true },
        breadcrumb: t('reports.computed-reports'),
        children: [
          {
            path: routes.overview.reports.computedReport.view,
            element: (
              <Wrapper feature="computed-reports">
                <ComputedReport />
              </Wrapper>
            ),
            breadcrumb: ComputedReportTypeCrumb,
          },
          {
            path: routes.overview.reports.computedReport.details,
            element: (
              <Wrapper feature="computed-reports">
                <ComputedReport />
              </Wrapper>
            ),
            breadcrumb: ComputedReportNameCrumb,
          },
        ],
      },
      {
        path: routes.overview.reports.viewBalanceSheetReport,
        element: (
          <Wrapper feature="reports">
            <LogProvider>
              <Suspense fallback={<SuspenseSpinner />}>
                <BalanceSheetReport />
              </Suspense>
            </LogProvider>
          </Wrapper>
        ),
        breadcrumb: t('reports.balanceSheetReport.title'),
      },
      {
        path: routes.overview.reports.viewReport,
        element: (
          <Wrapper feature="reports">
            <LogProvider>
              <FiltersProvider>
                <Suspense fallback={<SuspenseSpinner />}>
                  <ViewReport />
                </Suspense>
              </FiltersProvider>
            </LogProvider>
          </Wrapper>
        ),
      },
      {
        path: `${routes.overview.base}/*`,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <NotFoundPage />
            </Suspense>
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getOverviewRoutes
