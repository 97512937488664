import { Moment } from 'moment'

import { Product } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '../utils/axios'
import { getUrl } from '../utils/url'

export async function getProducts({
  selectedBusinessUnitKey,
  root,
  search,
}: {
  selectedBusinessUnitKey?: string
  root?: boolean
  search?: string
}): Promise<Product[]> {
  const url = getUrl(centralApiGatewayClient.products.SEARCH)

  if (root) {
    url.searchParams.append('root', 'true')
  }

  if (selectedBusinessUnitKey) {
    url.searchParams.set('businessUnit', selectedBusinessUnitKey)
  }
  if (search) {
    url.searchParams.set('search', search)
  }
  const productsResponse = await axios.post(url.toString())

  return productsResponse.data as Product[]
}

export async function getProduct(id: number): Promise<Product> {
  const url = getUrl(centralApiGatewayClient.products.GET_ONE.replace(':id', `${id}`))

  const getProductResponse = await axios.get(url.toString())

  return getProductResponse.data
}

export async function updateProduct(product: Partial<Product>): Promise<Product> {
  const url = getUrl(centralApiGatewayClient.products.UPDATE_ONE.replace(':id', `${product.id}`))
  delete product.id
  const editProductResponse = await axios.put(url.toString(), product)

  return editProductResponse.data
}
