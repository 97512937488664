import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import classNames from 'classnames'

import OverviewCard, { OverviewCardProps } from '@/molecules/OverviewCard'

import classes from './OverviewRow.module.less'

interface Props {
  loadingOverview: boolean
  marginBetween: number
  overviewData: OverviewCardProps[]
  hideTitle?: boolean
  classNameTitle?: string
}
const OverviewRow = ({
  loadingOverview,
  marginBetween,
  overviewData,
  hideTitle,
  classNameTitle,
}: Props): JSX.Element => {
  const { t } = useTranslation('common')
  return (
    <div className={classes.overviewStatscontainer}>
      {!hideTitle && (
        <Row className={classNames(classes.overviewTitle, classNameTitle)}>
          {t('log.overview-stats.title')}
        </Row>
      )}
      <Row gutter={[marginBetween, marginBetween]}>
        {overviewData.map((data) => (
          <Col key={data.headerTitle} className={classes.overviewCardContainer}>
            <OverviewCard
              icon={data.icon}
              loading={loadingOverview}
              content={data.content}
              headerTitle={data.headerTitle}
              headerColor={data.headerColor}
              tooltip={data.tooltip}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default OverviewRow
