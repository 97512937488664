import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import LifecycleStepCard from '@/molecules/LifecycleStepCard'

import LifecycleModalTitle from '../LifecycleModalTitle'
import { Step } from '../hooks/steps'

export interface SelectedStep {
  title: string
  index: number
  component: JSX.Element
  isValid: boolean
  key: string
}
interface LifecycleStepsProps {
  initialSteps: Step[]
}

const LifecycleSteps = ({ initialSteps }: LifecycleStepsProps): JSX.Element => {
  const { t } = useTranslation('common')
  const [steps, setSteps] = useState<Step[]>([])

  const lifecycleStepCards = useMemo(() => {
    return steps
      .filter((s) => s.isVisible)
      .map(
        (
          {
            description,
            imageSrc,
            title,
            selectedLifecycle,
            initialValue,
            isDisabled,
            stepKey,
            component,
          },
          i,
        ) => (
          <Col span={6} key={stepKey}>
            <LifecycleStepCard
              component={component}
              description={description}
              imageSrc={imageSrc}
              title={title}
              selectedLifecycle={selectedLifecycle}
              initialValue={initialValue}
              isDisabled={isDisabled}
              index={i}
            />
          </Col>
        ),
      )
  }, [steps])

  useEffect(() => {
    setSteps([...initialSteps])
  }, [initialSteps])

  return (
    <Row>
      <LifecycleModalTitle
        title={t('product.lifecycle-steps.title')}
        description={t('product.lifecycle-steps.description')}
      />
      <Row gutter={[16, 24]}>{lifecycleStepCards}</Row>
    </Row>
  )
}

export default LifecycleSteps
