import React from 'react'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import {
  useGetComputedReportByIdQuery,
  useGetComputedReportTypesQuery,
} from '@/redux/computedReports'

export const ComputedReportTypeCrumb = (): React.ReactElement => {
  const { key } = useParams()

  const { data: computedReportTypes = [], isLoading } = useGetComputedReportTypesQuery()
  const computedReportTypeName = React.useMemo(
    () => computedReportTypes.find((type) => type.key === key)?.name,
    [key, computedReportTypes],
  )

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }

  return <>{computedReportTypeName}</>
}

export const ComputedReportNameCrumb = (): React.ReactElement => {
  const { id } = useParams()
  const { data: report, isLoading } = useGetComputedReportByIdQuery(id!)

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }

  return <>{report?.name}</>
}
