import React from 'react'

import { IconProps } from '@/types/general'

const PaperClipOutline = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.883 4.87439L6.46453 11.4646C4.51191 13.4172 4.51184 16.5829 6.46447 18.5356C8.41709 20.4882 11.5829 20.4882 13.5355 18.5356L13.5364 18.5347L19.7937 12.2921C20.1847 11.902 20.8179 11.9028 21.2079 12.2938C21.598 12.6847 21.5973 13.3179 21.2063 13.708L14.9497 19.9498C14.9496 19.9499 14.9499 19.9496 14.9497 19.9498C12.216 22.683 7.78377 22.6833 5.05025 19.9498C2.3182 17.2177 2.31658 12.7892 5.0454 10.0551L11.4644 3.46444C13.417 1.51181 16.5829 1.51188 18.5355 3.4645C20.4865 5.41548 20.4882 8.57764 18.5404 10.5307L12.1214 17.1214C10.9498 18.293 9.05025 18.2929 7.87868 17.1214C6.70711 15.9498 6.70711 14.0503 7.87868 12.8787L14.4645 6.29292C14.855 5.9024 15.4882 5.9024 15.8787 6.29292C16.2692 6.68345 16.2692 7.31661 15.8787 7.70714L9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071C9.68212 16.0964 10.3124 16.0977 10.7032 15.711L17.1213 9.12129C18.2928 7.94972 18.2929 6.05028 17.1213 4.87871C15.9512 3.70858 14.0549 3.70714 12.883 4.87439Z"
        fill={color}
      />
    </svg>
  )
}

export default PaperClipOutline
