import React, { ReactElement, memo } from 'react'

import { Cascader as _Cascader } from 'antd'

import classnames from 'classnames'

import classes from './Cascader.module.less'

const Cascader = (props: React.ComponentProps<typeof _Cascader>): ReactElement => {
  const classNames = classnames(props.className, classes.cascader)

  return <_Cascader {...props} className={classNames} />
}

export default memo(Cascader)
