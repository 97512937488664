/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useState } from 'react'

import { cloneDeep, omit } from 'lodash-es'

import { LogSorter, PageFilter } from '@cozero/models'

import { FiltersContextInterface } from '../contexts/filters'
import { SearchFilters } from '../types/general'

const defaultFilters: PageFilter[] = []
const defaultSorters: LogSorter[] = [
  {
    id: '3uzmyzm291',
    key: 'createdAt',
    label: 'createdAt',
    selectedSort: 'desc',
    logType: 'location',
  },
  {
    id: '3uzmyzm294',
    key: 'id',
    label: 'id',
    selectedSort: 'desc',
    logType: 'product',
  },
]

function createDefaultFilters(initialState: SearchFilters): SearchFilters {
  const searchState = cloneDeep(initialState || {})
  if (!searchState.filters?.length) {
    searchState.filters = defaultFilters
  }
  if (!searchState.sorters?.length) {
    searchState.sorters = defaultSorters
  }

  return searchState
}

const useFiltersContext = (initialState: SearchFilters): FiltersContextInterface => {
  const [search, setSearchState] = useState<SearchFilters>(createDefaultFilters(initialState))

  const savePageNumber = React.useCallback((pageNumber: number): void => {
    setSearchState((prevSearch) => ({ ...prevSearch, pageNumber }))
  }, [])

  const saveFilters = React.useCallback((filters: PageFilter[]): void => {
    setSearchState((prevSearch) => ({
      ...prevSearch,
      filters: filters?.map(
        (x) =>
          ({
            ...omit(x, ['options', 'conditions']),
            options: [],
            conditions: [],
          } as PageFilter),
      ),
      pageNumber: 1,
    }))
  }, [])

  const saveSorters = React.useCallback((sorters: LogSorter[]): void => {
    setSearchState((prevSearch) => ({ ...prevSearch, sorters, pageNumber: 1 }))
  }, [])

  const resetSearch = React.useCallback((): void => {
    setSearchState((prevSearch) => createDefaultFilters({ pageNumber: prevSearch.pageNumber ?? 1 }))
  }, [])

  const saveConfig = React.useCallback(
    ({ filters, sorters }: { filters?: PageFilter[]; sorters?: LogSorter[] }): void => {
      setSearchState((prevSearch) => ({ ...prevSearch, filters, sorters }))
    },
    [],
  )

  return React.useMemo(
    () => ({
      filters: search.filters || [],
      sorters: search.sorters || [],
      pageNumber: search.pageNumber,
      saveFilters,
      saveSorters,
      savePageNumber,
      resetSearch,
      saveConfig,
    }),
    [search, saveFilters, saveSorters, savePageNumber, resetSearch, saveConfig],
  )
}

export default useFiltersContext
