import React from 'react'

import { FactorRequest } from '@cozero/models'

import Tag from '@/atoms/Tag'

import { formatNumber } from '@/utils/number'
import { calculateEmissionValue } from '@/utils/recalculations'

interface EmissionTagProps {
  mass?: number | null
  value?: number
  factorRequest: FactorRequest | undefined | null
  noBg?: boolean
}

const EmissionTag = ({ mass, value, factorRequest, noBg }: EmissionTagProps): JSX.Element => {
  const kgCO2e = 'kgCO2e'
  const unitName = factorRequest?.denominatorUnit?.name?.toString() || ''
  const displayUnit = unitName.startsWith(kgCO2e) ? unitName : `${kgCO2e}/${unitName}`
  const displayValue = calculateEmissionValue(mass, value)

  if (noBg) {
    return (
      <span>
        {displayValue ? formatNumber(displayValue * 1000) : '--'} {displayUnit}
      </span>
    )
  }

  return (
    <Tag size="sm" type="default">
      {displayValue ? formatNumber(displayValue * 1000) : '--'} {displayUnit}
    </Tag>
  )
}

export default EmissionTag
