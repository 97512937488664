import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Col, Row } from 'antd'

import _ from 'lodash'
import moment from 'moment'

import { GroupedMethodologyChangelog } from '@cozero/models'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'
import InfoBanner from '@/pages/Log/Factors/CalculationView/InfoBanner'

import CustomHeader from '@/organisms/CustomModalHeader'
import ImpactedClosedPeriodsTable from '@/organisms/ImpactedClosedPeriodsTable'
import OrganisationImpactedLogsTable from '@/organisms/OrganisationImpactedLogsTable'
import OrganisationImpactedProductsTable from '@/organisms/OrganisationImpactedProductsTable'
import OverviewRow from '@/organisms/OverviewRow'

import LayoutFooter from '@/molecules/LayoutFooter'
import { OverviewCardProps } from '@/molecules/OverviewCard'

import HighlightValue from '@/atoms/HighlightValue'
import Pill from '@/atoms/Pill'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectLocale } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  selectCalculationChangelogRecord,
  useFindOneChangelogLogQuery,
} from '@/redux/methodologyChangelogs'
import { setSelectedCalculationChangelogRecord } from '@/redux/methodologyChangelogs/slice'
import { languages } from '@/utils/config'
import { formatNumber } from '@/utils/number'
import { formatChangePercentage, getChangeOfEmissions } from '@/utils/recalculations'

import classes from './CalculationView.module.less'

interface ContentData {
  oldEmissions: number
  newEmissions: number
  affectedProductCount: number
  affectedLogCount: number
  percentageChange: number
  years: number[]
  title: string
}

const CalculationView = (): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const selectedCalculationChangelogRecord = useAppSelector(selectCalculationChangelogRecord)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const locale = useAppSelector(selectLocale)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: methodologyChangelog } = useFindOneChangelogLogQuery(
    {
      id: Number(id),
      businessUnitId: selectedBusinessUnit!.id,
      subcategoryId: parseInt(searchParams.get('subcategoryId') as string),
    },
    {
      skip: !selectedBusinessUnit,
    },
  )

  const generateContentData = useCallback(
    (
      selectedCalculationChangelogRecord: GroupedMethodologyChangelog | undefined,
      type: 'organization' | 'product',
    ): ContentData | undefined => {
      if (!selectedCalculationChangelogRecord) {
        return
      }

      const { oldEmissions, newEmissions, percentageChange, years } = getChangeOfEmissions(
        selectedCalculationChangelogRecord,
        type,
      )
      if (oldEmissions && newEmissions) {
        return {
          oldEmissions,
          newEmissions,
          percentageChange,
          years,
          affectedLogCount: selectedCalculationChangelogRecord.affectedLogCount,
          affectedProductCount: selectedCalculationChangelogRecord.affectedProductCount,
          title: t(`calculation-changes.impact-modal.${type}-logs-card-title`),
        } as ContentData
      }
    },
    [],
  )

  const title = useMemo(() => {
    if (selectedCalculationChangelogRecord) {
      const subcategoryName = selectedCalculationChangelogRecord?.subcategory.name
      const changelogChangeSource =
        selectedCalculationChangelogRecord?.methodologyChangelogGlobal.changeSource
      const createdDate = moment(selectedCalculationChangelogRecord?.createdAt)
        .locale(locale ?? languages[0].id)
        .format('ll')
      return `${subcategoryName} • ${createdDate}`
    }
    return ''
  }, [selectedCalculationChangelogRecord])

  const navigateBack = useCallback((): void => {
    navigate(-1)
  }, [])

  const overviewData: OverviewCardProps[] = useMemo(() => {
    const organizationContentData = generateContentData(
      selectedCalculationChangelogRecord,
      'organization',
    )
    const productContentData = generateContentData(selectedCalculationChangelogRecord, 'product')
    const contentData = _.compact([organizationContentData, productContentData])

    return contentData.map(
      ({
        oldEmissions,
        newEmissions,
        percentageChange,
        years,
        title,
        affectedLogCount,
        affectedProductCount,
      }) => {
        return {
          content: (
            <div>
              <Row align={'middle'} gutter={12}>
                <Col>
                  <HighlightValue striked value={formatNumber(oldEmissions)} />
                </Col>
                <Col>
                  <HighlightValue value="→" />
                </Col>
                <Col>
                  <HighlightValue value={formatNumber(newEmissions)} unit={t('co2-tonnes')} />
                </Col>
              </Row>
              <Row>
                <Pill color={percentageChange <= 0 ? 'green' : 'orange'}>
                  <strong>{percentageChange < 0 ? '-' : percentageChange > 0 ? '+' : ''}</strong>
                  <strong>
                    {' '}
                    {formatChangePercentage({
                      changePercentage: percentageChange,
                      affectedLogCount,
                      affectedProductCount,
                    })}
                  </strong>
                  <span>
                    {' '}
                    {t('targets.in')} {years.join(', ')}
                  </span>
                </Pill>
              </Row>
            </div>
          ),
          headerTitle: title,
        }
      },
    )
  }, [selectedCalculationChangelogRecord])

  useEffect(() => {
    if (methodologyChangelog) {
      dispatch(setSelectedCalculationChangelogRecord(methodologyChangelog))
    }
  }, [methodologyChangelog])

  return (
    <>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('calculation-changes.impact-modal.title')}
            onClose={navigateBack}
            goBack={navigateBack}
          />
        </div>
        <Row className={classes.content}>
          <LifecycleModalTitle
            image={selectedCalculationChangelogRecord?.subcategory?.category?.image?.url ?? ''}
            title={title}
            description={
              <>
                {t('calculation-changes.impact-modal.subtitle-first')}
                <br />
                {t('calculation-changes.impact-modal.subtitle-second')}
              </>
            }
            fitParent={true}
          />
          {selectedCalculationChangelogRecord?.methodologyChangelogGlobal
            .changeReasonDescription && (
            <InfoBanner
              description={
                typeof selectedCalculationChangelogRecord?.methodologyChangelogGlobal
                  .changeReasonDescription === 'string'
                  ? selectedCalculationChangelogRecord?.methodologyChangelogGlobal
                      .changeReasonDescription
                  : ''
              }
            />
          )}
          <Col span={24}>
            <OverviewRow
              loadingOverview={false}
              marginBetween={14}
              overviewData={overviewData}
              hideTitle={true}
            />
          </Col>
          <OrganisationImpactedLogsTable />
          <OrganisationImpactedProductsTable />
          <ImpactedClosedPeriodsTable />
        </Row>
        <LayoutFooter
          hideCancelButton
          categoryOkButton={AnalyticsCategories.FACTOR_REQUESTS}
          isDataValid={true}
          onCancelClick={navigateBack}
          onSaveClick={navigateBack}
          saveButtonDisabled={false}
          categoryCancelButton={AnalyticsCategories.CALCULATION_CHANGES}
          saveButtonText={t('calculation-changes.impact-modal.done-btn')}
        />
      </div>
    </>
  )
}

export default CalculationView
