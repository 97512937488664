import React, { ReactNode, createContext, useContext } from 'react'
import { ReactElement } from 'react'

import { CreateProductLogEntry } from '@/pages/GenericLifecycleSteps/GenericStep'

import useLifecycleHandler from '../hooks/useLifecycle'
import { LifecycleStepsKey } from '../pages/GenericLifecycleSteps/hooks/steps'

export interface ILifecycleContext {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  stepsToDisplay: { title: string; description?: string }[]
  setStepsToDisplay: React.Dispatch<React.SetStateAction<{ title: string; description?: string }[]>>
  selectedSteps: { index: number; isActive: boolean }[]
  setSelectedSteps: React.Dispatch<React.SetStateAction<{ index: number; isActive: boolean }[]>>
  stepData: (CreateProductLogEntry & {
    inputs: {
      inputKey: string
      value: number
      unitId: number
    }[]
    productlifecycleStep: LifecycleStepsKey
  })[]

  setStepData: React.Dispatch<
    React.SetStateAction<
      (CreateProductLogEntry & {
        inputs: {
          inputKey: string
          value: number
          unitId: number
        }[]
        productlifecycleStep: LifecycleStepsKey
      })[]
    >
  >
  isUnique: boolean
  setIsUnique: React.Dispatch<React.SetStateAction<boolean>>
  isDataValid: boolean
  setIsDataValid: React.Dispatch<React.SetStateAction<boolean>>
  totalEmissionFactor: number
  setTotalEmissionFactor: React.Dispatch<React.SetStateAction<number>>
  isNewProduct: boolean
  setIsNewProduct: React.Dispatch<React.SetStateAction<boolean>>
  from: string | undefined
  setFrom: React.Dispatch<React.SetStateAction<string | undefined>>
}

const LifecycleContext = createContext<ILifecycleContext | undefined>(undefined)

const LifecycleProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const appAccessors = useLifecycleHandler()
  return <LifecycleContext.Provider value={appAccessors}>{children}</LifecycleContext.Provider>
}
const useLifecycleContext = (): ILifecycleContext => {
  try {
    const contextValue = useContext(LifecycleContext)
    if (!contextValue) {
      throw new Error('useLifecycleContext must be used within an LifecycleProvider')
    }
    return contextValue
  } catch (e) {
    throw new Error(e)
  }
}

export { useLifecycleContext, LifecycleContext }

export default LifecycleProvider
