import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ClosedPeriodStatus } from '@cozero/constants'

import Pill from '@/atoms/Pill'

interface Props {
  status: string | undefined
}

const ClosedPeriodStatusPill = ({ status }: Props): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <Pill color={status === ClosedPeriodStatus.DRAFT ? 'cinder-blue' : 'blue'}>
      {t(`custom-reports.${status}`)}
    </Pill>
  )
}

export default ClosedPeriodStatusPill
