import { ActivityDataSource, ActivityDataSourceWithChildren } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_ACTIVITY_DATA_SOURCES, TAG_ACTIVITY_DATA_SOURCES_FIND_MANY } from './tags'

export const GET_ACTIVITY_DATA_SOURCES_TREE = 'getActivityDataSourcesTree'

const activityDataSourceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_ACTIVITY_DATA_SOURCES_TREE]: builder.query<
      ActivityDataSourceWithChildren[],
      { subcategoryId: number; calculationMethodId?: number }
    >({
      query: ({ subcategoryId, calculationMethodId }) => ({
        url: logApiGatewayClient.activity_data_sources.GET_MANY,
        method: 'GET',
        params: { subcategoryId, calculationMethodId },
      }),
      providesTags: (result) =>
        providesList<ActivityDataSourceWithChildren[], typeof TAG_ACTIVITY_DATA_SOURCES>(
          result,
          TAG_ACTIVITY_DATA_SOURCES,
        ),
    }),
    getActivityDataSourceFindMany: builder.query<ActivityDataSource[], void>({
      query: () => ({
        url: logApiGatewayClient.activity_data_sources.FIND_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<ActivityDataSource[], typeof TAG_ACTIVITY_DATA_SOURCES_FIND_MANY>(
          result,
          TAG_ACTIVITY_DATA_SOURCES_FIND_MANY,
        ),
    }),
  }),
})

export const { useGetActivityDataSourcesTreeQuery, useGetActivityDataSourceFindManyQuery } =
  activityDataSourceSlice

export default activityDataSourceSlice
