import { ClimateActionWithIncludes } from '@cozero/models'

export const SLICE_NAME = 'actActionSimulator'

export type ActionSimulatorState = {
  pendingClimateActionId: number | undefined
  calculatedClimateAction: ClimateActionWithIncludes | undefined
}

export const DEFAULT_SLICE_VALUES: ActionSimulatorState = {
  pendingClimateActionId: undefined,
  calculatedClimateAction: undefined,
}
