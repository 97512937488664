/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'

import moment, { Moment } from 'moment'

const DEFAULT_DATE_FILTER: [null, null] = [null, null]

const getPersistedFilter = <T extends object>(key: string): T | undefined => {
  const rawItem = localStorage.getItem(key)
  if (!rawItem) {
    return
  }

  try {
    return JSON.parse(rawItem)
  } catch (error) {
    return
  }
}

const persistFilter = (key: string, value: [string | null, string | null] | undefined): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

type UseDateFilterReturn = {
  onOpenChange: (value: [Moment | null, Moment | null] | null) => void
  dateFilter: [string | null, string | null]
  ranges: {
    [key: string]: [moment.Moment | null, moment.Moment | null]
  }
}

export const useDateFilter = (): UseDateFilterReturn => {
  const [dateFilter, setDateFilter] = React.useState<[string | null, string | null] | undefined>(
    getPersistedFilter('date'),
  )

  React.useEffect(() => {
    persistFilter('date', dateFilter)
  }, [dateFilter])

  const onOpenChange = React.useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const startDate = value?.[0] ? value[0].tz(timezone).format('YYYY-MM-DD') : null
      const endDate = value?.[1] ? value[1].tz(timezone).format('YYYY-MM-DD') : null

      setDateFilter([startDate, endDate])
    },
    [setDateFilter],
  )

  const ranges = React.useMemo(() => {
    const rangesAux: { [key: string]: [Moment | null, Moment | null] } = {}
    rangesAux['Year-to-Date'] = [moment().startOf('year'), moment()]
    rangesAux['Last 12 months'] = [moment().subtract(12, 'months'), moment()]
    for (let i = 3; i >= 0; i--) {
      rangesAux[moment().subtract(i, 'year').year()] = [
        moment().subtract(i, 'year').startOf('year'),
        moment().subtract(i, 'year').endOf('year'),
      ]
    }
    return rangesAux
  }, [])

  return {
    onOpenChange,
    dateFilter: dateFilter ?? DEFAULT_DATE_FILTER,
    ranges,
  }
}
