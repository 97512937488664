import React, { ReactElement, memo, useMemo, useState } from 'react'
import { HiUser } from 'react-icons/hi'

import { Avatar as AntAvatar } from 'antd/es'

import { AvatarPictureProps } from '.'
import classnames from 'classnames'

import classes from './Avatar.module.less'

/**
 * A basic avatar representing a user
 * @arg props.user the user the avatar represents
 * @arg [props.size='sm] the size of the avatar
 * @component
 */
const _AvatarPicture = ({
  image,
  firstName,
  lastName,
  size = 'sm',
  outlined = false,
  className,
}: AvatarPictureProps): ReactElement => {
  const [imgHasError, setImgHasError] = useState(false)

  const classNames = useMemo(
    () => classnames([classes.avatar, classes[size], className], { [classes.outlined]: outlined }),
    [className, size],
  )

  const initials = useMemo(
    () =>
      firstName || lastName ? `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase() : null,
    [firstName, lastName],
  )

  if (image && image.url && !imgHasError) {
    return (
      <AntAvatar
        className={classNames}
        src={<img src={image.url} onError={() => setImgHasError(true)} />}
      />
    )
  }

  if (initials) {
    return <AntAvatar className={classNames}>{initials}</AntAvatar>
  }

  return <AntAvatar className={classNames} icon={<HiUser className={classes.icon} />} />
}

export const AvatarPicture = memo(_AvatarPicture)
