/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { uniqBy } from 'lodash-es'

import { Scope } from '@cozero/models'

import { useGetScopesQuery } from '@/redux/scopes'

import { SearchHookProps } from './types'

const validateScopeSearch = (item: Scope, search: string): boolean => {
  if (!search?.length) {
    // defaults to true if no search value (show all)
    return true
  }

  // Ensure the value exists and is a string before proceeding
  return getScopeName(item).toLowerCase().includes(search.toLowerCase())
}

const getScopeName = (item: Scope): string => {
  return `${
    item.categoryNumber
      ? `${item.categoryNumber}${item.subcategoryNumber ? `.${item.subcategoryNumber}:` : ':'}`
      : ''
  } ${item.title}`
}

export const useScopesSearch = (): SearchHookProps => {
  const { t } = useTranslation()

  const { data, isFetching } = useGetScopesQuery()
  const [search, setSearch] = React.useState<string>('')

  const options = React.useMemo(
    () =>
      uniqBy(data, 'id')
        .filter((scope) => validateScopeSearch(scope, search))
        .map((scope) => ({
          value: scope.id,
          label: `${t('log.scope')} ${getScopeName(scope)}`,
        })),
    [data, search, t],
  )

  return {
    onSearch: setSearch,
    options,
    isFetching,
  }
}
