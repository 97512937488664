import React from 'react'

import { IconProps } from '@/types/general'

const UserGroup = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2C9.89543 2 9 2.89543 9 4C9 5.10457 9.89543 6 11 6C12.1046 6 13 5.10457 13 4C13 2.89543 12.1046 2 11 2ZM7 4C7 1.79086 8.79086 0 11 0C13.2091 0 15 1.79086 15 4C15 6.20914 13.2091 8 11 8C8.79086 8 7 6.20914 7 4ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6ZM1 7C1 5.34315 2.34315 4 4 4C5.65685 4 7 5.34315 7 7C7 8.65685 5.65685 10 4 10C2.34315 10 1 8.65685 1 7ZM18 6C17.4477 6 17 6.44772 17 7C17 7.55228 17.4477 8 18 8C18.5523 8 19 7.55228 19 7C19 6.44772 18.5523 6 18 6ZM15 7C15 5.34315 16.3431 4 18 4C19.6569 4 21 5.34315 21 7C21 8.65685 19.6569 10 18 10C16.3431 10 15 8.65685 15 7ZM11 11C9.31743 11 7.87516 12.0392 7.28463 13.5145C7.10133 13.9725 7 14.4732 7 15V16H15V15C15 14.4732 14.8987 13.9725 14.7154 13.5145C14.1248 12.0392 12.6826 11 11 11ZM17 16H20V15C20 13.8954 19.1046 13 18 13C17.5439 13 17.1237 13.1521 16.7867 13.4097C16.9259 13.917 17 14.4504 17 15V16ZM15.9286 11.5777C14.8455 10.0206 13.0427 9 11 9C8.9573 9 7.15455 10.0206 6.07138 11.5777C5.46741 11.2114 4.75829 11 4 11C1.79086 11 0 12.7909 0 15V17C0 17.5523 0.447715 18 1 18H21C21.5523 18 22 17.5523 22 17V15C22 12.7909 20.2091 11 18 11C17.2417 11 16.5326 11.2114 15.9286 11.5777ZM5.21328 13.4097C4.87632 13.1521 4.45607 13 4 13C2.89543 13 2 13.8954 2 15V16H5V15C5 14.4504 5.07414 13.917 5.21328 13.4097Z"
        fill={color}
      />
    </svg>
  )
}

export default UserGroup
