import { CalculationType } from '@prisma/client'

import { BulkImport, BulkImportResponse, ImportDetails, Page } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import axios from '../utils/axios'
import { getUrl } from '../utils/url'

export async function submitBulkImport(
  importKey: string,
  bulkImportId: number,
): Promise<BulkImport> {
  const url = getUrl(logApiGatewayClient.bulkImport.CREATE_ONE)
  url.searchParams.append('importKey', importKey)
  url.searchParams.append('bulkImportId', `${bulkImportId}`)
  const uploadResponse = await axios.post(url.toString())

  return (await uploadResponse.data) as BulkImport
}

export async function validateLogs(
  filePath: string,
  fileName: string,
  categoryId: number,
  preprocessor: CalculationType,
): Promise<BulkImportResponse> {
  return (
    await axios.post<BulkImportResponse>(
      `${logApiGatewayClient.bulkImport.VALIDATE}?categoryId=${categoryId}`,
      {
        filePath,
        fileName,
        preprocessor,
      },
    )
  )?.data
}

export async function previewBulkImportLogs(
  importKey: string,
  page: number,
  pageSize: number,
): Promise<BulkImportResponse> {
  return (
    await axios.get<BulkImportResponse>(
      `${logApiGatewayClient.bulkImport.PREVIEW}?page=${page}&importKey=${importKey}&pageSize=${pageSize}`,
    )
  )?.data
}

export async function getBulkImports(pageNumber: number): Promise<Page<ImportDetails>> {
  const url = getUrl(logApiGatewayClient.bulkImport.GET_MANY)

  const bulkImports = await axios.get<Page<ImportDetails>>(url.toString(), {
    params: {
      pageNumber,
    },
  })

  return bulkImports.data
}

export async function deleteBulkImport(bulkImportId: number): Promise<string> {
  const url = getUrl(
    logApiGatewayClient.bulkImport.DELETE_ONE.replace(':id', bulkImportId.toString()),
  )

  const bulkImports = await axios.delete(url.toString())

  return await bulkImports.data
}
