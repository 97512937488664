import React from 'react'

import { IconProps } from '@/types/general'

const Tag = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.34315 2 2 3.34315 2 5V10C2 10.2985 2.12956 10.5656 2.33882 10.7503L2.36217 10.7709L9.35844 17.7671C9.53256 17.9131 9.755 18 10 18C10.245 18 10.4674 17.9131 10.6416 17.7671L17.6158 10.7929C17.6305 10.7782 17.6456 10.764 17.6612 10.7503C17.8704 10.5656 18 10.2985 18 10C18 9.755 17.9131 9.53256 17.7671 9.35844L10.7929 2.38419C10.7782 2.36952 10.764 2.35438 10.7503 2.33882C10.5656 2.12956 10.2985 2 10 2H5ZM0 5C0 2.23858 2.23858 0 5 0H10C10.8838 0 11.6793 0.383396 12.2269 0.989777L19.2071 7.96998C19.2218 7.98466 19.236 7.99979 19.2497 8.01535C19.716 8.5436 20 9.23985 20 10C20 10.8838 19.6166 11.6793 19.0102 12.2269L12.03 19.2071C12.0153 19.2218 12.0002 19.236 11.9846 19.2497C11.4564 19.716 10.7602 20 10 20C9.23985 20 8.5436 19.716 8.01535 19.2497C7.99979 19.236 7.98466 19.2218 7.96998 19.2071L0.989777 12.2269C0.383396 11.6793 0 10.8838 0 10V5ZM4 5C4 4.44772 4.44772 4 5 4H5.01C5.56228 4 6.01 4.44772 6.01 5C6.01 5.55228 5.56228 6 5.01 6H5C4.44772 6 4 5.55228 4 5Z"
        fill={color}
      />
    </svg>
  )
}

export default Tag
