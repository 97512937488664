import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, message } from 'antd/es'
import { useForm } from 'antd/es/form/Form'
import { Store } from 'antd/es/form/interface'

import { CloseOutlined } from '@ant-design/icons'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { BusinessUnit, Supplier } from '@cozero/models'

import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'
import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'

import classes from './SuppliersModal.module.less'
import { FormValues } from './types'
import { isSupplierFormValid } from './validation'

interface SupplierProps {
  supplier?: Supplier
  isVisible: boolean
  onClose: () => void
  createSupplier: (values: Supplier) => void
  featuresAllowed: string[]
}

function SuppliersModal({
  isVisible,
  onClose,
  supplier,
  createSupplier,
  featuresAllowed,
}: SupplierProps): JSX.Element {
  const { t } = useTranslation('common')
  const businessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: businessUnit?.id ?? -1 },
    { skip: !businessUnit?.id },
  )
  const [form] = useForm()
  const [isOkDisable, setIsOkDisable] = useState(true)
  const [internalSupplierId, setInternalSupplierId] = useState<number | undefined | null>(
    supplier?.internalSupplierId,
  )
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState<number | undefined | null>(
    supplier?.businessUnitId,
  )

  const onSubmit = (values: Store): void => {
    if (values) {
      const supplier = {
        ...values,
        internalSupplierId,
        businessUnitId: selectedBusinessUnitId,
      } as Supplier

      createSupplier(supplier)
    }
  }

  useEffect((): void => {
    if (supplier) {
      form.resetFields()
      form.setFieldsValue({
        ...supplier,
        businessUnit: (supplier.businessUnit as BusinessUnit)?.id,
      })
      setInternalSupplierId(supplier.internalSupplierId)
    } else {
      form.resetFields()
      form.setFieldsValue({ contact: [{ email: undefined }] })
    }
  }, [supplier])

  const onError = async (store: ValidateErrorEntity<Store>): Promise<void> => {
    await message.error(store.errorFields.flatMap((errorField) => errorField.errors).join(', '))
  }

  const onValueChanges = (_: Partial<FormValues>, allFields: FormValues): void => {
    setIsOkDisable(!isSupplierFormValid(allFields))
  }

  return (
    <Modal
      title={t('suppliers.create')}
      visible={isVisible}
      onOk={() => form.submit()}
      okText={t('suppliers.save')}
      okButtonProps={{ disabled: isOkDisable }}
      onCancel={onClose}
      className={classes.modalContainer}
    >
      <Form
        category={AnalyticsCategories.SUPPLIER}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onError}
        onValuesChange={onValueChanges}
      >
        <Text size="xl" strong>
          {t('suppliers.company-information')}
        </Text>

        <Form.Item
          rules={[
            { required: true, message: `${t('suppliers.name')} ${t('suppliers.is-mandatory')}` },
          ]}
          label={t('suppliers.name')}
          name={'name'}
        >
          <InputField />
        </Form.Item>

        <Form.Item label={t('suppliers.address')} name={'address'}>
          <InputField />
        </Form.Item>

        {featuresAllowed.includes('business-units') && (
          <>
            <Form.Item
              label={t('suppliers.internalSupplier.description')}
              id="internalSupplier"
              name="internalSupplier"
              initialValue={internalSupplierId}
            >
              <BusinessUnitsDropdown
                businessUnits={businessUnits}
                showNoParent={false}
                allowClear
                onClear={() => {
                  setInternalSupplierId(undefined)
                  form.setFieldValue('internalSupplier', undefined)
                }}
                onChange={(value) =>
                  setInternalSupplierId(!isNaN(parseInt(value)) ? parseInt(value) : undefined)
                }
              />
            </Form.Item>

            <Form.Item
              label={t('suppliers.businessUnitInput')}
              id="businessUnit"
              name="businessUnit"
              initialValue={selectedBusinessUnitId}
            >
              <BusinessUnitsDropdown
                businessUnits={businessUnits}
                allowClear
                showNoParent={false}
                onClear={() => {
                  setSelectedBusinessUnitId(undefined)
                  form.setFieldValue('businessUnit', undefined)
                }}
                onChange={(value) =>
                  setSelectedBusinessUnitId(!isNaN(parseInt(value)) ? parseInt(value) : undefined)
                }
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label={t('suppliers.externalReferenceInput')}
          id="externalReference"
          name="externalReference"
        >
          <InputField />
        </Form.Item>

        <Form.Item label={t('suppliers.typeInput')} id="type" name="type">
          <InputField />
        </Form.Item>

        <Text size="xl" strong>
          {t('suppliers.contact-list')}
        </Text>

        <Form.List name="contact">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => {
                return (
                  <Row gutter={[16, 16]} key={`container_${field.key}`}>
                    <Col span={6}>
                      <Form.Item label={t('suppliers.contact_name')} name={[field.name, 'name']}>
                        <InputField />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t('suppliers.position')} name={[field.name, 'position']}>
                        <InputField />
                      </Form.Item>
                    </Col>

                    <Col span={10}>
                      <Form.Item
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: `${t('suppliers.email')} ${t('suppliers.is-mandatory')}`,
                          },
                        ]}
                        label={t('suppliers.email')}
                        name={[field.name, 'email']}
                      >
                        <InputField type={'email'} />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      {fields.length > 1 ? (
                        <CloseOutlined onClick={() => remove(field.name)} />
                      ) : null}
                    </Col>
                  </Row>
                )
              })}

              <Row gutter={[16, 16]}>
                <Col>
                  <Button
                    color="blue"
                    category={AnalyticsCategories.SUPPLIER}
                    action="add-contact"
                    type="text"
                    onClick={() => add()}
                  >
                    {t('suppliers.add-contact')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default SuppliersModal
