/* eslint react-hooks/exhaustive-deps: 2 */

import { useCallback, useMemo, useState } from 'react'
import { Layout } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'

import { Store } from 'antd/es/form/interface'

import { debounce, isEqual, pick } from 'lodash-es'

import {
  Board,
  GetReportWithDataBase,
  PageFilter,
  Product,
  Report,
  ReportCategory,
  ReportSetting,
  ReportToBoard,
} from '@cozero/models'
import { reportsApiGatewayClient } from '@cozero/uris'

import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import { BoardsContextInterface } from '../contexts/boards'
import { useAppSelector } from '../redux'
import { selectUser } from '../redux/auth'
import axios from '../utils/axios'

type UpdateReportToBoardProps = {
  xCoord?: ReportToBoard['xCoord']
  yCoord?: ReportToBoard['yCoord']
  width?: ReportToBoard['width']
}

/**
 * A hook providing the boards and the state for editing these
 */
const useBoards = (): BoardsContextInterface => {
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { t } = useTranslation('common')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingBoards, setLoadingBoards] = useState<boolean>(false)
  const [loadingGetBoard, setLoadingGetBoard] = useState<boolean>(false)
  const [boards, setBoards] = useState<Board[]>([])
  const [selectedBoard, setSelectedBoard] = useState<Board>()
  const [initialSelectedBoard, setInitialSelectedBoard] = useState<Board>()
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [reports, setReports] = useState<Report[]>([])
  const [editingBoard, setEditingBoard] = useState<boolean>(false)
  const [reportSettings, setReportSettings] = useState<ReportSetting[]>([])
  const [lastDeletedReportId, setLastDeletedReportId] = useState<number>()
  const user = useAppSelector(selectUser)

  const getBoards = useCallback(async (): Promise<void> => {
    try {
      setLoadingBoards(true)
      const { data } = await axios.get<Board[]>(reportsApiGatewayClient.boards.GET_MANY)
      setBoards(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoadingBoards(false)
    }
  }, [])

  /**
   * getBoard.
   *
   * Fetch the board, set as the selected board
   * @param dateRange the date range for the data in the board
   * @param args.board the board to fetch
   * @param args.selectedLocation
   * @param args.selectedProduct
   */
  const getBoard = useCallback(
    async ({
      boardId,
      closedPeriodId,
    }: {
      boardId: number
      closedPeriodId?: number
    }): Promise<Board | undefined> => {
      try {
        setError('')
        setLoadingGetBoard(true)
        if (boardId == null) {
          throw new Error(t('share.reports.errors.no-board'))
        }
        if (!selectedBusinessUnit) {
          throw new Error(t('share.reports.errors.no-board'))
        }

        const { data: fetchedBoard } = await axios.get<Board>(
          reportsApiGatewayClient.boards.GET_ONE.replace(':id', boardId.toString()),
          {
            params: {
              businessUnitId: selectedBusinessUnit?.id,
              closedPeriodId,
            },
          },
        )

        setSelectedBoard(fetchedBoard)
        setInitialSelectedBoard(fetchedBoard)
        return fetchedBoard
      } catch (e) {
        setError(e.message)
      } finally {
        setLoadingGetBoard(false)
      }
    },
    [selectedBusinessUnit, t],
  )

  /**
   * saveBoardLayout.
   *
   * Save the Board layout to the backend
   *
   * @param board the board to save
   * @returns fetchedBoard the current board from the backend
   */
  const saveBoardLayout = useCallback(
    async (board: Board): Promise<Board | void> => {
      try {
        if (!board) {
          throw new Error(t('share.reports.errors.no-board'))
        }

        setLoading(true)

        const { data: fetchedBoard } = await axios.post<Board>(
          reportsApiGatewayClient.boards.UPDATE_ONE.replace(':id', board.id.toString()),
          board,
        )

        return fetchedBoard
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    },
    [t],
  )

  /**
   * getAvailableReports.
   * Fetch all available reports for the org.
   */
  const getAvailableReports = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: reports } = await axios.get<Report[]>(reportsApiGatewayClient.reports.GET_MANY)

      if (reports) {
        setReports(reports)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const searchReportCategories = useCallback(
    async (searchText: string): Promise<ReportCategory[]> => {
      let categories: ReportCategory[] = []
      try {
        setLoading(true)
        const { data } = await axios.get<ReportCategory[]>(
          reportsApiGatewayClient.reportCategories.SEARCH,
          {
            params: {
              searchText,
            },
          },
        )
        categories = data
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
      return categories
    },
    [],
  )

  /**
   * updateReportToBoard.
   *
   * Update the grid data
   * @param board
   * @param reportId the id of the report
   * @param newValues the new layout data for the grid data
   * @returns
   */
  const updateReportToBoard = useCallback(
    async (
      board: Board,
      reportId: number,
      newValues: UpdateReportToBoardProps,
      sharePageId?: number,
    ): Promise<void> => {
      try {
        if (board) {
          await axios.put(
            reportsApiGatewayClient.reports.UPDATE_GRID_DATA.replace(':id', reportId.toString()),
            newValues,
            {
              params: { boardId: board.id, sharePageId },
            },
          )
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    [],
  )

  const updateBoardLayout = useCallback(
    (board: Board, boardLayout?: Layout[], sharePageId?: number): void => {
      if (board && boardLayout && boardLayout.length > 0) {
        board.reportToBoards?.forEach((gridData) => {
          const newGrid = boardLayout.find(
            (layout) => gridData.id.toString() === layout.i.toString(),
          )
          if (newGrid) {
            const newValues = {
              xCoord: newGrid.x,
              yCoord: newGrid.y,
            }
            if (!isEqual(newValues, pick(gridData, ['xCoord', 'yCoord']))) {
              updateReportToBoard(
                board,
                (gridData.report as Report).id,
                {
                  xCoord: newGrid.x,
                  yCoord: newGrid.y,
                },
                sharePageId,
              )
            }
          }
        })
      }
    },
    [updateReportToBoard],
  )

  const updateBoardWidth = useCallback(
    (board: Board, boardId: number): void => {
      const boardToUpdate = board.reportToBoards.find((current) => current.id === boardId)
      if (boardToUpdate && boardToUpdate.reportId) {
        updateReportToBoard(board, boardToUpdate.reportId, { width: boardToUpdate.width })
      }
    },
    [updateReportToBoard],
  )

  const editReportsOfBoard = useCallback(
    async (
      reports: Store,
      id: number,
      sharePageId?: number,
      closedPeriodId?: number,
    ): Promise<void> => {
      try {
        await axios.put(
          reportsApiGatewayClient.boards.UPDATE_REPORT_GRID_DATA.replace(':id', id.toString()),
          reports,
          {
            params: { sharePageId, closedPeriodId },
          },
        )
      } catch (error) {
        throw new Error(`${t('share.reports.errors.add')}: ${error.message ?? ''}`)
      }
    },
    [t],
  )

  /**
   * removeReportFromSelectedBoard.
   * Remove a specific report from the currently selected board
   * @param reportId
   * @param userId?
   * @param sharePageId?
   */
  const removeReportFromSelectedBoard = useCallback(
    async (reportId: number, userId?: number, sharePageId?: number): Promise<void> => {
      try {
        if (selectedBoard) {
          await axios.delete(
            reportsApiGatewayClient.reports.DELETE_REPORT_GRID_DATA.replace(
              ':id',
              reportId.toString(),
            ),
            {
              params: {
                boardId: selectedBoard.id,
                userId,
                boardDocLevel: selectedBoard.level,
                ...(selectedBoard.type === 'share' ? { sharePageId } : {}),
              },
            },
          )
          setLastDeletedReportId(reportId)
        }
      } catch (error) {
        throw new Error(`${t('share.reports.errors.remove')}: ${error.message ?? ''}`)
      }
    },
    [selectedBoard, t],
  )

  /**
   * updateBoard.
   * Save the board layout to the api, debounce the save by 250 ms.
   */
  const updateBoard = useCallback(
    (board: Board): void => {
      const debouncedSave = debounce(saveBoardLayout, 250)
      debouncedSave(board)
      setSelectedBoard(board)
    },
    [saveBoardLayout],
  )

  /**
   * clearUpdatedLayout.
   * Reset the board to its initial state and persist
   */
  const clearUpdatedLayout = useCallback((): void => {
    if (initialSelectedBoard) {
      updateBoard(initialSelectedBoard)
    }
  }, [initialSelectedBoard, updateBoard])

  /**
   * Get a report with the relevant data
   */
  const getReportWithData = useCallback(
    async ({
      key,
      currentFilters,
      persistedBoardSettingsFilters,
      setting,
      abortSignal,
      includeChildrenBUsData,
    }: {
      key: string
      currentFilters?: PageFilter[]
      persistedBoardSettingsFilters?: PageFilter[]
      setting?: ReportSetting
      abortSignal?: AbortSignal
      includeChildrenBUsData?: boolean
    }): Promise<GetReportWithDataBase | undefined> => {
      if (!selectedBusinessUnit) {
        return
      }
      const { data: report } = await axios.post(
        reportsApiGatewayClient.reports.GET_REPORT_WITH_DATA.replace(':key', key),
        {
          requestedBusinessUnitId: selectedBusinessUnit.id,
          filters: persistedBoardSettingsFilters ?? currentFilters,
          setting,
          locale: user?.locale || 'en',
          includeChildrenBUsData,
        },
        { signal: abortSignal },
      )
      return report
    },
    [selectedBusinessUnit, user?.locale],
  )

  const handleReportData = useCallback(
    (
      report: GetReportWithDataBase,
    ): {
      isLoading: boolean
      isPollingEnabled: boolean
      hasError: boolean
      reportData?: Report
    } => {
      if (report.error) {
        return {
          isPollingEnabled: false,
          hasError: true,
          isLoading: false,
        }
      }
      if (report.data && report.data?.id > -1) {
        return {
          isLoading: false,
          isPollingEnabled: false,
          hasError: false,
          reportData: report.data,
        }
      }
      return {
        isPollingEnabled: true,
        isLoading: false,
        hasError: false,
      }
    },
    [],
  )

  return useMemo(
    () => ({
      error,
      loading,
      loadingBoards,
      getBoards,
      getBoard,
      loadingGetBoard,
      setLoadingGetBoard,
      boards,
      selectedBoard,
      setSelectedBoard,
      setLoading,
      selectedProduct,
      setSelectedProduct,
      getAvailableReports,
      reports,
      editReportsOfBoard,
      updateReportToBoard,
      updateBoardWidth,
      removeReportFromSelectedBoard,
      editingBoard,
      setEditingBoard,
      clearUpdatedLayout,
      updateBoardLayout,
      reportSettings,
      setReportSettings,
      getReportWithData,
      searchReportCategories,
      lastDeletedReportId,
      handleReportData,
    }),
    [
      error,
      loading,
      loadingBoards,
      getBoards,
      getBoard,
      loadingGetBoard,
      boards,
      selectedBoard,
      selectedProduct,
      reports,
      editReportsOfBoard,
      updateReportToBoard,
      updateBoardWidth,
      removeReportFromSelectedBoard,
      editingBoard,
      clearUpdatedLayout,
      updateBoardLayout,
      reportSettings,
      getReportWithData,
      searchReportCategories,
      lastDeletedReportId,
      handleReportData,
      getAvailableReports,
    ],
  )
}

export default useBoards
