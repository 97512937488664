import React from 'react'

import { OrganizationCarboonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

export const SLICE_NAME = 'logEntriesOverview'

export type SelectedRows = { keys: React.Key[]; ids: number[] }

export const DEFAULT_STATE: {
  lastUpdateDate?: Date
  filters: OrganizationCarboonFootprintFormValues
  selectedRows: SelectedRows
} = {
  filters: {},
  selectedRows: {
    keys: [],
    ids: [],
  },
}
