import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'

const UserCrumb = (): JSX.Element => {
  const businessUnit = useSelector(selectSelectedBusinessUnit)
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnitId: businessUnit?.id },
    { skip: !businessUnit?.id },
  )
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const userName = useMemo(() => {
    const user = users?.find((user) => user.id === parseInt(id ?? ''))
    let name = ''
    if (user?.firstName || user?.lastName) {
      name = `${user.firstName ?? ''} ${user.lastName ?? ''}`
    } else if (!user?.firstName) {
      name = user?.id.toString() ?? 'User'
    }

    setLoading(false)
    return name
  }, [id, users])

  if (loading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (!userName) {
    return <>{id}</>
  }
  return <>{userName}</>
}

export default UserCrumb
