import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Layout, Row, Space } from 'antd/es'

import { FallbackErrorType, FallbackProps } from '@/templates/ErrorBoundary/types'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import cozeroLogo from '@/assets/COZERO_LOGO.svg'
import generalErrorImage from '@/assets/error-general.svg'
import updateErrorImage from '@/assets/error-update.svg'
import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './AppErrorFallback.module.less'

const LAYOUTS: Record<
  FallbackErrorType,
  {
    image: string
    title: string
    description: string
    showContactSupport?: boolean
  }
> = {
  [FallbackErrorType.GENERAL]: {
    image: generalErrorImage,
    title: 'fallback.general.title',
    description: 'fallback.general.description',
    showContactSupport: true,
  },
  [FallbackErrorType.UPDATE]: {
    image: updateErrorImage,
    title: 'fallback.update.title',
    description: 'fallback.update.description',
  },
}

export const AppErrorFallback = ({ type }: FallbackProps): ReactElement => {
  const { t } = useTranslation('common')
  const layout = LAYOUTS[type]

  const onRefresh = (): void => {
    window.location.reload()
  }

  return (
    <Layout.Content className={classes.layoutContent}>
      <Row justify="center" align="middle" className={classes.mainRow}>
        <Col>
          <Row justify={'center'} align={'middle'}>
            <Col span={24} className={classes.centered}>
              <img src={cozeroLogo} alt="" className={classes.logo} />
            </Col>
            <Col span={24} className={classes.centered}>
              <img src={layout.image} alt="" className={classes.errorImage} />
            </Col>
            <Col span={24} className={classes.content}>
              <Title size="xl" className={classes.title}>
                {t(layout.title)}
              </Title>
              <Text size="xl" className={classes.description}>
                {t(layout.description)}
              </Text>
              <Space dir="horizontal" size={'small'} className={classes.buttons}>
                <Button
                  size="lg"
                  type={layout?.showContactSupport ? 'secondary' : 'primary'}
                  action="reload-page"
                  category={AnalyticsCategories.ERROR}
                  onClick={onRefresh}
                >
                  {t('fallback.reload-page')}
                </Button>
                {layout?.showContactSupport && (
                  <a id="intercom_launcher" href="mailto:support@cozero.io">
                    <Button
                      size="lg"
                      type={'primary'}
                      action="contact-support"
                      category={AnalyticsCategories.ERROR}
                    >
                      {t('fallback.contact-support')}
                    </Button>
                  </a>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  )
}
