import React, { HTMLAttributes, ReactElement, ReactNode, memo, useMemo } from 'react'
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineStar,
  HiOutlineX,
  HiOutlineXCircle,
} from 'react-icons/hi'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons'

import Title from '../Title'

import classes from './Alert.module.less'

export interface AlertProps {
  type?: 'default' | 'info' | 'danger' | 'error' | 'warning' | 'success' | 'loading' | 'star'
  children?: ReactNode
  title?: string
  message?: string | ReactElement
  textColor?: string
  icon?: 'info' | 'exclamation' | 'times' | 'no-icon' | 'star'
  className?: string
  onClose?: () => void
  action?: ReactNode
}

const Icon = memo(
  ({
    className,
    type,
    icon,
  }: {
    className: string
    icon: AlertProps['icon']
    type: AlertProps['type']
  }): JSX.Element => {
    switch (type) {
      case 'star':
      case 'info':
        if (icon === 'star') {
          return <HiOutlineStar className={className} />
        }
        return <HiOutlineInformationCircle className={className} />
      case 'loading':
        return <SyncOutlined className={className} />
      case 'warning':
        return icon === 'times' ? (
          <WarningOutlined className={className} />
        ) : (
          <HiOutlineExclamationCircle className={className} />
        )
      case 'danger':
        return icon === 'times' ? (
          <HiOutlineXCircle className={className} />
        ) : (
          <CloseCircleOutlined className={className} />
        )
      case 'success':
        return <CheckCircleOutlined />
      default:
        return icon === 'no-icon' ? <></> : <HiOutlineInformationCircle className={className} />
    }
  },
)

Icon.displayName = 'AlertIcon'

const Alert = ({
  type = 'default',
  children,
  message,
  textColor,
  icon,
  title,
  onClose,
  className,
  action,
  ...rest
}: AlertProps & HTMLAttributes<HTMLDivElement>): ReactElement | null => {
  const classNames = useMemo(
    () => [classes.alert, classes[type], className].join(' '),
    [type, className],
  )
  if (title) {
    return (
      <div className={classNames} role="alert" {...rest}>
        <div className={`${classes.iconWrapper} ${icon === 'star' ? classes.star : ''}`}>
          <Icon className={classes.icon} type={type} icon={icon} />
        </div>
        <div>
          <Title className={classes.title} size="sm">
            {title}
          </Title>
          <div style={{ color: textColor }}>{message}</div>
        </div>
        {action && <div className={classes.action}>{action}</div>}
        {onClose && (
          <div className={classes.buttonWrapper}>
            <button className={classes.button} onClick={onClose}>
              <HiOutlineX className={classes.closable} />
            </button>
          </div>
        )}
      </div>
    )
  }
  if (!title && (message || children)) {
    return (
      <div className={classNames} role="alert" {...rest}>
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} type={type} icon={icon} />
        </div>

        <div style={{ color: textColor }}>{children || message}</div>
        {action && <div className={classes.action}>{action}</div>}

        {onClose && (
          <div className={classes.buttonWrapper}>
            <button className={classes.button} onClick={onClose}>
              <HiOutlineX className={classes.closable} />
            </button>
          </div>
        )}
      </div>
    )
  }
  throw new Error('Set an alert message, please')
}

export default memo(Alert)
