import React from 'react'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { HotspotAnalysisViewDto } from '@cozero/dtos'
import { HotspotAnalysisFilterValues } from '@cozero/models'

import { DEFAULT_ANALYTICS_STATE, SLICE_NAME_ACT_AN } from './constants'

const actAnalyticsSlice = createSlice({
  name: SLICE_NAME_ACT_AN,
  initialState: DEFAULT_ANALYTICS_STATE,
  reducers: {
    setHotspotAnalysisDateFilter: (state, action: PayloadAction<[Date, Date]>) => {
      const [startDate, endDate] = action.payload
      if (state.selectHotspotAnalysisView) {
        state.selectHotspotAnalysisView.startDate = startDate
        state.selectHotspotAnalysisView.endDate = endDate
      }
    },
    setHotspotAnalysisFilters: (state, action: PayloadAction<HotspotAnalysisFilterValues>) => {
      if (state.selectHotspotAnalysisView) {
        state.selectHotspotAnalysisView.filters = action.payload
      }
    },
    setHotspotAnalysisView: (state, action: PayloadAction<HotspotAnalysisViewDto | undefined>) => {
      state.selectHotspotAnalysisView = action.payload
    },
    setHotspotExpandedRowKeys: (state, action: PayloadAction<React.Key[]>) => {
      state.expandedRowKeys = action.payload
    },
    resetHotspotExpandedRowKeys: (state) => {
      state.expandedRowKeys = DEFAULT_ANALYTICS_STATE.expandedRowKeys
    },
  },
})

export const {
  setHotspotAnalysisDateFilter,
  setHotspotAnalysisFilters,
  setHotspotAnalysisView,
  setHotspotExpandedRowKeys,
  resetHotspotExpandedRowKeys,
} = actAnalyticsSlice.actions

export default actAnalyticsSlice
