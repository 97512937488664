import React, { ReactElement, memo } from 'react'
import { HiOutlineX } from 'react-icons/hi'
import { IconBaseProps } from 'react-icons/lib'

import classes from './CloseIcon.module.less'

const CloseIcon = (props: IconBaseProps): ReactElement => (
  <div className={classes.closeIcon}>
    <HiOutlineX {...props} />
  </div>
)

export default memo(CloseIcon)
