import React from 'react'

import { ConfigProvider } from 'antd/es'
import localeDe from 'antd/es/locale/de_DE'
import localeEn from 'antd/es/locale/en_US'
import localeJa from 'antd/es/locale/ja_JP'

import { useAppSelector } from '../redux'
import { selectUser } from '../redux/auth'

const AntDesignConfigProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const user = useAppSelector(selectUser) || { locale: 'en' }
  const locale = {
    en: localeEn,
    de: localeDe,
    ja: localeJa,
  }

  return (
    <ConfigProvider locale={locale[user.locale as 'en' | 'de' | 'ja']}>{children}</ConfigProvider>
  )
}
export default AntDesignConfigProvider
