/* eslint react-hooks/exhaustive-deps: 2 */

import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowsExpand, HiOutlineFilter, HiOutlineInformationCircle } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { SizeMe } from 'react-sizeme'

import { Options, Plot } from '@antv/g2plot'
import ArrowsPointingInIcon from '@heroicons/react/20/solid/ArrowsPointingInIcon'
import ArrowsPointingOutIcon from '@heroicons/react/20/solid/ArrowsPointingOutIcon'
import classNames from 'classnames'
import { flatten, omit, uniqBy } from 'lodash-es'

import {
  ClosedPeriod,
  ConfigFilter,
  Location,
  PageFilter,
  PageType,
  Report,
  ReportSetting,
  ReportToBoard,
  ReportType,
} from '@cozero/models'

import FiltersDrawer from '@/molecules/FiltersDrawer'
import { GraphExportDropdown } from '@/molecules/GraphExportDropdown'
import { GraphSelectorDropdown } from '@/molecules/GraphSelectorDropdown'

import Divider from '@/atoms/Divider'
import SixDotsVertical from '@/atoms/Icons/SixDotsVertical'
import LoadingSpinner from '@/atoms/LoadingSpinner'
import Pill from '@/atoms/Pill'
import Select from '@/atoms/Select'
import Title from '@/atoms/Title'
import Tooltip from '@/atoms/Tooltip'

import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import usePollingEffect from '@/hooks/usePollingEffect'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import {
  useDeletePresetFilterMutation,
  useGetPresetFiltersQuery,
  useSavePresetFilterMutation,
  useUpdatePresetFilterMutation,
} from '@/redux/presetFilters'
import { CINDER_BLUE_50, COZERO_BLUE_80 } from '@/styles/variables'
import { PersistedBoardSettings } from '@/types/general'

import { ChartContainer } from '../ChartContainer'
import EmptyGraphView from '../EmptyGraphView'
import ErrorGraphView from '../ErrorGraphView'

import classes from './ReportContainer.module.less'
import { useFilters } from './ReportContainer.useFilters'

const DEFAULT_REPORT_LIMIT = 10
const LIMIT_OPTIONS = [
  {
    key: 10,
    label: '10',
    value: 10,
  },
  {
    key: 25,
    label: '25',
    value: 25,
  },
  {
    key: 50,
    label: '50',
    value: 50,
  },
  {
    key: 10000,
    label: 'All',
    value: -1,
  },
]

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}

interface IReportContainer {
  report: Report
  draggingGraphId: number | null
  reportToBoard?: ReportToBoard & {
    report: Report | null
  }
  loading?: boolean
  disableDelete?: boolean
  showFilters?: boolean | null
  showColumns?: boolean | null
  updateGraphWidth?: (
    report: ReportToBoard & {
      report: Report | null
    },
  ) => void
  location?: Location
  hasDatePicker?: boolean
  editable?: boolean
  includeChildrenBUsData?: boolean
  persistedBoardSettings?: PersistedBoardSettings
  closedPeriod?: ClosedPeriod & { locations?: { id: number }[] }
  presetView?: string
  disableViewChange?: boolean
}

const ReportContainer = ({
  includeChildrenBUsData = false,
  report,
  editable,
  draggingGraphId,
  reportToBoard,
  showFilters = false,
  showColumns = true,
  disableDelete,
  updateGraphWidth,
  persistedBoardSettings,
  closedPeriod,
  presetView,
  disableViewChange,
}: IReportContainer): ReactElement => {
  const abortControllerRef = useRef<AbortController>()
  const { t, i18n } = useTranslation('common')
  const [view, setView] = useState<string>(presetView || 'primary')
  const user = useAppSelector(selectUser)
  const {
    reportSettings,
    getReportWithData,
    selectedBoard,
    removeReportFromSelectedBoard,
    getBoard,
    loadingGetBoard,
    handleReportData,
  } = useBoardsContext()
  const { filters } = useFiltersContext()
  const { filterOptions, featuresAllowed } = useFilters()
  const { data: presetFilters } = useGetPresetFiltersQuery()
  const [graphFilters, setGraphFilters] = useState<PageFilter[]>(filters)
  const [savePresetFilter] = useSavePresetFilterMutation()
  const [updatePresetFilter] = useUpdatePresetFilterMutation()
  const [deletePresetFilter] = useDeletePresetFilterMutation()
  const [reportSetting, setReportSetting] = useState<ReportSetting | undefined>(
    reportSettings.find((setting) => setting.reportId.toString() === report.id.toString()),
  )
  const [selectorOptions, setSelectorOptions] = useState<typeof LIMIT_OPTIONS>([])
  const [switchAxis, setSwitchAxis] = useState<boolean>(false)
  const [drawerOpened, setDrawerOpened] = useState(false)
  const isSwitchedAxisRef = useRef(switchAxis)

  const chartRef = useRef<Plot<Options> | null>(null)
  const svgChartRef = useRef<Plot<Options> | null>(null)
  const secondaryChartRef = useRef<Plot<Options> | null>(null)
  const secondarySvgChartRef = useRef<Plot<Options> | null>(null)

  const [hoveringOverGraph, setHoveringOverGraph] = useState<boolean>(false)
  const [isDraggingGraph, setIsDraggingGraph] = useState<boolean>(
    reportToBoard?.id === draggingGraphId,
  )
  const [resizeTooltipVisible, setResizeTooltipVisible] = useState<boolean>(false)
  const [showLegend, setShowLegend] = useState<boolean>(true)
  const isShowLegendToggled = useRef(showColumns)
  const [reportLoading, setReportLoading] = useState<boolean>(false)
  const [currentReportData, setCurrentReportData] = useState<Report>(report)
  const [graphError, setGraphError] = useState<boolean>(false)

  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const [graphDataAvailable, setGraphDataAvailable] = useState<boolean>(false)
  const [dragTooltipVisible, setDragTooltipVisible] = useState<boolean>(false)

  const { isPollingEnabled, setIsPollingEnabled } = usePollingEffect(
    async () => {
      fetchReportData()
    },
    [],
    {},
  )

  const dateRangeFilterIsSet = (filters: PageFilter[]): boolean =>
    filters && !!filters.find((f) => f.type === 'dateRange')

  const saveView = (view: string): void => {
    setView(view)
  }

  useEffect(() => {
    setIsDraggingGraph(reportToBoard?.id === draggingGraphId)
  }, [draggingGraphId, reportToBoard?.id])

  useEffect(() => {
    setReportSetting(
      reportSettings.find((setting) => setting.reportId.toString() === report.id.toString()),
    )
  }, [report.id, reportSettings])

  useEffect(() => {
    if (currentReportData.limitSelector && !reportSetting?.limit && currentReportData.data) {
      updateLimitSelector(currentReportData.data.chartPivot.length || DEFAULT_REPORT_LIMIT)
    }
  }, [currentReportData.limitSelector, currentReportData.data, reportSetting?.limit])

  useEffect(() => {
    const hasData = !graphError && currentReportData.data?.seriesNames?.length > 0
    setGraphDataAvailable(hasData)
  }, [currentReportData, graphError])

  useEffect(() => {
    setCurrentReportData(report)
  }, [report])

  // Whenever we have changes on the preset filters make sure they're reflected on the filters currently being shown
  useEffect(() => {
    const presetGraphFilters = flatten(
      presetFilters
        ?.filter((presetFilter) => presetFilter.reportKey == report.key)
        .map((presetFilter) => presetFilter.filters),
    )
    const newGraphFilters = uniqBy([...(presetGraphFilters as PageFilter[]), ...filters], 'key')
    setGraphFilters(newGraphFilters)
  }, [presetFilters, filters, report.key])

  const onSaveFilters = useCallback(
    async (filters: PageFilter[]) => {
      const filtersToSave = (
        filters?.map(
          (obj) =>
            ({
              ...omit(obj, ['id', 'options', 'conditions']),
              conditions: [],
            } as PageFilter),
        ) as ConfigFilter[]
      ).filter(({ type }) => type != 'dateRange')

      const presetFilter = presetFilters?.find(
        (presetFilter) =>
          presetFilter.reportKey == report.key && presetFilter.pageType == PageType.DASHBOARD,
      )

      // If we have a filter for this report already either delete them (if all are removed) or update them.
      if (presetFilter) {
        if (!filters.length) {
          await deletePresetFilter(presetFilter.id)
        } else {
          await updatePresetFilter({
            id: presetFilter.id,
            filters: filtersToSave,
          })
        }
      } else {
        await savePresetFilter({
          pageType: PageType.DASHBOARD,
          filters: filtersToSave,
          reportKey: report.key,
        }).unwrap()
      }
    },
    [presetFilters, report, deletePresetFilter, savePresetFilter, updatePresetFilter],
  )

  const updateReportStates = useCallback(
    ({
      isLoading,
      isPollingEnabled,
      hasError,
      reportData,
    }: ReturnType<typeof handleReportData>) => {
      setGraphError(hasError)
      setReportLoading(isLoading)
      setIsPollingEnabled(isPollingEnabled)
      if (reportData) {
        setCurrentReportData(reportData)
      }
    },
    [setIsPollingEnabled],
  )

  const fetchReportData = React.useCallback(async (): Promise<void> => {
    setReportLoading(true)
    try {
      const reportResponse = await getReportWithData({
        key: report.key,
        currentFilters: graphFilters,
        persistedBoardSettingsFilters: persistedBoardSettings?.filters,
        setting: reportSetting ?? {
          reportId: report.id,
          ...(report.limitSelector ? { limit: DEFAULT_REPORT_LIMIT } : {}),
        },
        abortSignal: abortControllerRef.current?.signal,
        includeChildrenBUsData: includeChildrenBUsData,
      })
      if (reportResponse) {
        updateReportStates(handleReportData(reportResponse))
      }
    } catch (error) {
      setGraphError(true)
      setReportLoading(false)
      setIsPollingEnabled(false)
    }
  }, [
    graphFilters,
    getReportWithData,
    handleReportData,
    includeChildrenBUsData,
    persistedBoardSettings?.filters,
    report.id,
    report.key,
    report.limitSelector,
    reportSetting,
    setIsPollingEnabled,
    updateReportStates,
  ])

  const deleteReport = React.useCallback(async (): Promise<void> => {
    setReportLoading(true)
    if (user && currentReportData && selectedBoard) {
      await removeReportFromSelectedBoard(report.id, user.id)
      if (persistedBoardSettings?.board) {
        await getBoard({
          boardId: persistedBoardSettings.board.id,
          user: user,
          closedPeriodId: closedPeriod?.id,
        })
      } else {
        await getBoard({ boardId: selectedBoard.id, closedPeriodId: closedPeriod?.id })
      }
    }
    setReportLoading(false)
  }, [
    closedPeriod?.id,
    currentReportData,
    getBoard,
    persistedBoardSettings?.board,
    removeReportFromSelectedBoard,
    report.id,
    selectedBoard,
    user,
  ])

  const handleTooltipWhileDragging = useCallback(
    (visible?: boolean) => {
      const currentVisibleStatus = visible !== undefined ? visible : dragTooltipVisible
      setDragTooltipVisible(currentVisibleStatus && !isDraggingGraph)
    },
    [dragTooltipVisible, isDraggingGraph],
  )

  useEffect(() => {
    handleTooltipWhileDragging()
  }, [handleTooltipWhileDragging, isDraggingGraph])

  const onDragTooltipToggle = useCallback(
    (visible: boolean): void => {
      handleTooltipWhileDragging(visible)
    },
    [handleTooltipWhileDragging],
  )

  const updateDimension = (dimension: ReportSetting['dimension']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, dimension })
    } else {
      setReportSetting({ reportId: currentReportData.id, dimension })
    }
  }

  const updateMeasure = (measure: ReportSetting['measure']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, measure })
    } else {
      setReportSetting({ reportId: currentReportData.id, measure })
    }
  }

  const updateTimeGranularity = (timeGranularity: ReportSetting['timeGranularity']): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, timeGranularity })
    } else {
      setReportSetting({ reportId: currentReportData.id, timeGranularity })
    }
  }

  const updateLimit = (limit: number): void => {
    if (reportSetting) {
      setReportSetting({ ...reportSetting, limit })
    } else {
      setReportSetting({ reportId: currentReportData.id, limit })
    }
  }

  const updateLimitSelector = (maxItems: number): void => {
    const filteredOptions = LIMIT_OPTIONS.filter((option) => {
      return option.value <= maxItems
    })
    setSelectorOptions(filteredOptions)
  }

  const handleHover: MouseEventHandler<HTMLDivElement> | undefined = (e) => {
    switch (e.type) {
      case 'mouseenter':
        setHoveringOverGraph(true)
        break
      case 'mouseleave':
        setHoveringOverGraph(false)
        break
      default:
        break
    }
  }

  const handleSwitchAxis = useCallback(() => {
    setSwitchAxis(!isSwitchedAxisRef.current)
  }, [setSwitchAxis])

  useEffect(() => {
    isSwitchedAxisRef.current = switchAxis
  }, [switchAxis])

  const handleToggleLegend = useCallback(() => {
    setShowLegend(!isShowLegendToggled.current)
  }, [setShowLegend])

  useEffect(() => {
    isShowLegendToggled.current = showLegend
  }, [showLegend])

  const handleGraphWidthUpdate = useCallback(() => {
    if (updateGraphWidth && reportToBoard) {
      updateGraphWidth(reportToBoard)
      setHoveringOverGraph(false)
      setResizeTooltipVisible(false)
    }
  }, [updateGraphWidth, reportToBoard])

  const resizeIconProps = useMemo(() => {
    return {
      onClick: handleGraphWidthUpdate,
      width: 16,
      height: 16,
      color: CINDER_BLUE_50,
      className: classes.resizeIcon,
    }
  }, [handleGraphWidthUpdate])

  useEffect(() => {
    if (selectedBusinessUnit && !loadingGetBoard && dateRangeFilterIsSet(graphFilters)) {
      setIsPollingEnabled(false)
      fetchReportData()
    }
  }, [
    loadingGetBoard,
    reportSetting,
    selectedBusinessUnit,
    graphFilters,
    setIsPollingEnabled,
    fetchReportData,
  ])

  const count = useMemo(() => {
    return graphFilters?.filter((filter) => filter.type !== 'dateRange').length
  }, [graphFilters])

  return (
    <div
      key={currentReportData.id}
      className={classNames(classes.wrapper, { [classes.shadow]: isDraggingGraph })}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {editable && (
        <div style={{ width: 24 }}>
          <Tooltip
            title={t('reports.reorder-tooltip-title')}
            subTitle={t('reports.reorder-tooltip-content')}
            showArrow
            open={dragTooltipVisible}
            onOpenChange={onDragTooltipToggle}
          >
            <div
              className={classNames('drag', classes.graphDragIconWrapper, {
                [classes.active]: hoveringOverGraph,
                [classes.dragging]: isDraggingGraph,
              })}
            >
              <SixDotsVertical width={16} height={16} color={CINDER_BLUE_50} />
            </div>
          </Tooltip>
        </div>
      )}
      <div className={classes.reportContainer}>
        <div className={classes.reportWrapper}>
          <div className={classes.header}>
            <div className={classes.headerContainer}>
              <Title size="sm" className={classes.title}>
                {currentReportData.title || ''}
                {currentReportData.description && (
                  <Tooltip title={currentReportData.description}>
                    <HiOutlineInformationCircle
                      className={classes.infoIcon}
                      color={COZERO_BLUE_80}
                    />
                  </Tooltip>
                )}
              </Title>
            </div>
            <div className={classes.dropdownSelectorsContainer}>
              {!reportLoading && (
                <GraphExportDropdown
                  disableDelete={disableDelete || !editable}
                  view={view}
                  chartRef={chartRef}
                  svgChartRef={svgChartRef}
                  secondaryChartRef={secondaryChartRef}
                  secondarySvgChartRef={secondarySvgChartRef}
                  switchAxis={handleSwitchAxis}
                  toggleLegend={handleToggleLegend}
                  onDelete={selectedBoard?.type !== 'reports' ? deleteReport : undefined}
                  report={currentReportData}
                  graphDataAvailable={graphDataAvailable}
                />
              )}
              {updateGraphWidth &&
                reportToBoard &&
                currentReportData.type !== ReportType.CARBON_ACTIONS && (
                  <Tooltip
                    title={t('reports.resize-tooltip.title')}
                    subTitle={
                      reportToBoard?.width === 1
                        ? t('reports.resize-tooltip.expand')
                        : t('reports.resize-tooltip.shrink')
                    }
                    showArrow
                    open={resizeTooltipVisible}
                    onOpenChange={setResizeTooltipVisible}
                  >
                    {reportToBoard?.width === 2 ? (
                      <ArrowsPointingInIcon {...resizeIconProps} />
                    ) : (
                      <ArrowsPointingOutIcon {...resizeIconProps} />
                    )}
                  </Tooltip>
                )}
              <div
                {...resizeIconProps}
                onClick={() => setDrawerOpened(true)}
                className={classNames(classes.button, classes.buttonFilter, {
                  [classes.active]: count > 0,
                })}
              >
                <span className={classes.filterIconWrapper}>
                  <HiOutlineFilter height={34} width={34} />
                </span>

                {count > 0 && (
                  <span className={classes.count} data-cy="dashboard-filters-count">
                    {count}
                  </span>
                )}
              </div>
              {graphDataAvailable && !disableViewChange && (
                <GraphSelectorDropdown setView={saveView} view={view} report={currentReportData} />
              )}
              {currentReportData.limitSelector && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={selectorOptions}
                  value={
                    reportSetting?.limit ??
                    currentReportData.cubeJSQuery?.limit ??
                    DEFAULT_REPORT_LIMIT
                  }
                  onChange={(limit) => updateLimit(limit)}
                />
              )}
              {currentReportData.timeGranularity && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={[
                    {
                      key: 'month',
                      label: t('reports.timeDimensions.month'),
                      value: 'month',
                    },
                    {
                      key: 'quarter',
                      label: t('reports.timeDimensions.quarter'),
                      value: 'quarter',
                    },
                    {
                      key: 'year',
                      label: t('reports.timeDimensions.year'),
                      value: 'year',
                    },
                  ]}
                  value={
                    reportSetting?.timeGranularity ??
                    currentReportData.cubeJSQuery?.timeDimensions?.[0]?.granularity
                  }
                  onChange={(granularity) => updateTimeGranularity(granularity)}
                />
              )}
              {currentReportData.dimensions?.length > 0 && (
                <Select
                  variant="borderless"
                  options={currentReportData.dimensions?.map((dimension) => ({
                    label: i18n.exists(`common:reports.keys.${dimension}`)
                      ? t(`reports.keys.${dimension}`)
                      : dimension,
                    value: dimension,
                    key: dimension,
                  }))}
                  value={
                    (reportSetting?.dimension
                      ? reportSetting?.dimension
                      : currentReportData.cubeJSQuery.dimensions?.[0]
                      ? currentReportData.cubeJSQuery.dimensions[0]
                      : Array.isArray(currentReportData.dimensions) &&
                        currentReportData.dimensions[0]) ?? ''
                  }
                  onChange={(dimension) => updateDimension(dimension)}
                />
              )}
              {currentReportData.measures?.length > 0 && graphDataAvailable && (
                <Select
                  variant="borderless"
                  options={currentReportData.measures?.map((measure) => ({
                    label: i18n.exists(`common:reports.legend.${measure}`)
                      ? t(`reports.legend.${measure}`)
                      : i18n.exists(`common:reports.keys.${measure}`)
                      ? t(`reports.keys.${measure}`)
                      : measure,
                    value: measure,
                    key: measure,
                  }))}
                  value={
                    reportSetting?.measure ??
                    (Array.isArray(currentReportData.measures) && currentReportData.measures[0])
                  }
                  onChange={(measure) => updateMeasure(measure)}
                />
              )}
            </div>
          </div>
          <Divider color="light" />
          {isDraggingGraph && (
            <div className={classes.draggingPillLabel}>
              <Pill icon={<HiArrowsExpand size={16} />} color="cinder-blue-80">
                {t('reports.drag-label')}
              </Pill>
            </div>
          )}
        </div>
        <SizeMe key={currentReportData.id} monitorWidth monitorHeight refreshMode="throttle">
          {({ size }) => {
            return (
              <div
                className={classNames(classes.chartWrapper, {
                  [classes.dragging]: isDraggingGraph,
                  [classes.overFlowYScroll]: currentReportData?.type === 'table',
                })}
              >
                {isPollingEnabled ? (
                  <LoadingSpinner
                    className={classes.calculatingSpinner}
                    title={t('share.reports.calculating.title')}
                    text={t('share.reports.calculating.text', { joinArrays: '\n' })}
                  />
                ) : reportLoading ? (
                  <LoadingSpinner title={t('share.reports.loading')} />
                ) : graphError ? (
                  <ErrorGraphView />
                ) : !graphDataAvailable && currentReportData.type !== ReportType.FORECAST ? (
                  <EmptyGraphView />
                ) : (
                  <ChartContainer
                    chartRef={chartRef}
                    svgChartRef={svgChartRef}
                    secondaryChartRef={secondaryChartRef}
                    secondarySvgChartRef={secondarySvgChartRef}
                    height={size.height}
                    report={currentReportData}
                    view={view}
                    reportSetting={reportSetting}
                    showColumns={showColumns ?? true}
                    showFilters={showFilters ?? false}
                    showLegend={showLegend ?? true}
                    switchAxis={switchAxis}
                  />
                )}
              </div>
            )
          }}
        </SizeMe>
      </div>
      <FiltersDrawer
        search={onSaveFilters}
        pageType={PageType.DASHBOARD}
        filters={[...graphFilters] as (PageFilter & { key: FilterKey })[]}
        visible={drawerOpened}
        filterOptions={
          [...filterOptions] as (PageFilter & {
            options: {
              key?: string
              value: string
              label: string
            }[]
          })[]
        }
        onClose={() => setDrawerOpened(false)}
        featuresAllowed={featuresAllowed}
        isGraphLevel={true}
      />
    </div>
  )
}

export default ReportContainer
