/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'

import { Space } from 'antd/es'

import Icon from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'

import SixDotsVertical from '@/atoms/Icons/SixDotsVertical'
import Text from '@/atoms/Text'

import { CINDER_BLUE_80 } from '@/styles/variables'

import classes from './ColumnManagerModal.module.less'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const TableColumnItem = ({
  tableColumnKey,
  tableColumnTitle,
  presentational,
  disabled,
}: {
  tableColumnKey: string
  tableColumnTitle: string | ReactElement
  presentational?: boolean
  disabled?: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: presentational ? 'presentational' : tableColumnKey,
    disabled: presentational || disabled,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Space
        size="small"
        align="center"
        className={classNames(
          classes.tableColumn,
          disabled && classes.disabled,
          (active?.id === tableColumnKey || presentational) && classes.active,
        )}
      >
        <Icon component={SixDotsVertical} color={CINDER_BLUE_80} />
        <Text size="lg" fontWeight="medium" color={disabled ? 'disabled' : undefined}>
          {tableColumnTitle}
        </Text>
      </Space>
    </div>
  )
}
