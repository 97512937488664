import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineEye } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { truncate } from '@/utils/string'

import classes from './Onboarding.module.less'

type FeaturedArticleType = {
  title: string
  subtitle: string
  url: string
}

const FeaturedArticle = ({ title, subtitle, url }: FeaturedArticleType): ReactElement => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  return (
    <Col span={24}>
      <Card type="flat" shadow="none">
        <Row className={classes.pillSection}>
          <Col span={24}>
            <Tag type="info" shape="pill" showDot>
              {t('onboarding.demo.one-minute-read')}
            </Tag>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Title size="xs">{title}</Title>
          </Col>

          <Col span={24}>
            <Text size="xl">{!expanded ? truncate(subtitle, 110) : subtitle}</Text>
            <span className={classes.readMore} onClick={(): void => setExpanded(!expanded)}>
              {!expanded ? t('actions.read-more') : t('actions.read-less')}
            </span>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Button
              type="text"
              prefixIcon={<HiOutlineEye />}
              onClick={() => window.open(url, '_blank')}
              category={AnalyticsCategories.ONBOARDING}
              action={`read more:${title}`}
            >
              {t('actions.to-wiki')}
            </Button>
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

const ResourceOverview = (): ReactElement => {
  const { t } = useTranslation()

  const featuredArticles: FeaturedArticleType[] = [
    {
      title: t('onboarding.featured-articles.1.title'),
      subtitle: t('onboarding.featured-articles.1.subtitle'),
      url: t('intercom.onboarding.businessUnits'),
    },
    {
      title: t('onboarding.featured-articles.2.title'),
      subtitle: t('onboarding.featured-articles.2.subtitle'),
      url: t('intercom.onboarding.users'),
    },
    {
      title: t('onboarding.featured-articles.3.title'),
      subtitle: t('onboarding.featured-articles.3.subtitle'),
      url: t('intercom.resourceCards.emissionCategories'),
    },
  ]

  return (
    <Row className={classes.resourceOverview} gutter={16}>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <p className={classes.sectionTitle}>{t('onboarding.demo.featured-articles')}</p>
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          {featuredArticles.map(({ title, subtitle, url }) => (
            <FeaturedArticle key={url} title={title} subtitle={subtitle} url={url} />
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default ResourceOverview
