import { useMemo } from 'react'

import { jsonFieldHasPropertyValue } from '@cozero/utils'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'

export const useOrgIsSupplier = (): boolean => {
  const user = useAppSelector(selectUser)

  return useMemo(
    () =>
      jsonFieldHasPropertyValue({
        field: user?.organization?.metadata,
        property: 'mode',
        value: 'supplier',
      }),
    [user],
  )
}
