import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ProductLifecycleStep } from '@prisma/client'
import _ from 'lodash'
import moment, { Moment } from 'moment'

import { Product, Supplier } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import { LifecycleStepsKey } from '@/pages/GenericLifecycleSteps/hooks/steps'

import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import { getProducts as fetchProducts } from '../backend/products'
import { AppContextInterface } from '../contexts/app'
import { useAppSelector } from '../redux'
import { selectUserOrganization } from '../redux/auth'
import axios from '../utils/axios'

const useAppContext = (): AppContextInterface => {
  const organization = useAppSelector(selectUserOrganization)
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [activeProducts, setActiveProducts] = useState<Product[]>()
  const [error, setError] = useState<Error | undefined>()
  const [lifecycleSteps, setLifecycleSteps] = useState<ProductLifecycleStep[]>([])
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([
    moment('2018-01-01').startOf('day'),
    moment('2020-12-31').endOf('day'),
  ])
  const [dateError, setDateError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [organizationSuppliers, setOrganizationSuppliers] = useState<Supplier[]>([])

  const changeDateRange = (values: [Moment | null, Moment | null]): void => {
    const startDate = values[0]?.startOf('day') || null
    const endDate = values[1]?.endOf('day') || null
    setDateRange([startDate, endDate])
  }

  const getProducts = async (root?: boolean): Promise<void> => {
    try {
      setLoading(true)
      const data = await fetchProducts({
        root,
        selectedBusinessUnitKey: selectedBusinessUnit?.key,
      })
      const active = data.filter((prod) => prod.active)
      setActiveProducts(active)
      setProducts(data)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const onSearch = async (key: string, value: string): Promise<void> => {
    try {
      setLoading(true)
      if (key === 'product') {
        const fetchedProducts = await fetchProducts({
          search: value,
          ...(selectedBusinessUnit ? { selectedBusinessUnitKey: selectedBusinessUnit?.key } : {}),
        })
        setProducts(fetchedProducts)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  /** @Deprecated Use RTK query hook instead (`useGetSuppliersQuery`) */
  const getSuppliers = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: fetchedSuppliers } = await axios.get<Supplier[]>(
        centralApiGatewayClient.suppliers.GET_MANY,
        {
          params: {
            businessUnitId: selectedBusinessUnit?.id,
          },
        },
      )
      setSuppliers(fetchedSuppliers)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const createSupplier = async (supplier: Supplier): Promise<Supplier | null | undefined> => {
    try {
      setLoading(true)
      const { data } = await axios.post<Supplier>(centralApiGatewayClient.suppliers.CREATE, {
        ...supplier,
        businessUnitId: supplier.businessUnit,
      })
      setOrganizationSuppliers((suppliers) =>
        _.orderBy([...suppliers, data], [(user) => user.name.toLowerCase()], 'asc'),
      )
      return data
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const updateSupplier = async (id: number, supplier: Supplier): Promise<void> => {
    try {
      setLoading(true)
      await axios.put<Supplier>(
        centralApiGatewayClient.suppliers.UPDATE_ONE.replace(':id', id.toString()),
        supplier,
      )
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const deleteSupplier = async (id: number): Promise<void> => {
    try {
      setLoading(true)
      await axios.delete(centralApiGatewayClient.suppliers.DELETE_ONE.replace(':id', id.toString()))
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const getOrganizationSuppliers = async (): Promise<Supplier[] | void> => {
    try {
      setLoading(true)
      const { data: fetchedSuppliers } = await axios.get<Supplier[]>(
        centralApiGatewayClient.suppliers.GET_MANY,
        { params: { organization: organization?.id, businessUnitId: selectedBusinessUnit?.id } },
      )

      setOrganizationSuppliers(fetchedSuppliers)
      return fetchedSuppliers
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const getLifecycleSteps = async (
    lifecycleSteps?: LifecycleStepsKey[],
  ): Promise<ProductLifecycleStep[]> => {
    try {
      setLoading(true)
      let lifecycleStepsParams = ''
      if (lifecycleSteps) {
        lifecycleStepsParams = `?keys=${lifecycleSteps}`
      }
      const { data: fetchedLifecycleSteps } = await axios.get<ProductLifecycleStep[]>(
        `${logApiGatewayClient.lifecycleSteps.GET_MANY}${lifecycleStepsParams}`,
      )

      setLifecycleSteps(fetchedLifecycleSteps)
      return fetchedLifecycleSteps
    } catch (e) {
      setError(e.message)
      return []
    } finally {
      setLoading(false)
    }
  }

  return {
    products,
    setDateRange: changeDateRange,
    error,
    dateRange,
    getProducts,
    dateError,
    setDateError,
    activeProducts,
    loading,
    onSearch,
    getSuppliers,
    suppliers,
    createSupplier,
    updateSupplier,
    deleteSupplier,
    getOrganizationSuppliers,
    organizationSuppliers,
    getLifecycleSteps,
    lifecycleSteps,
  }
}

export default useAppContext
