/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Space } from 'antd/es'

import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { arrayMove } from '@dnd-kit/sortable'
import { keyBy, uniq } from 'lodash-es'

import { LogEntriesTableColumnKey } from '@cozero/models'

import Modal, { ModalProps } from '@/molecules/Modal'

import Text from '@/atoms/Text'

import { SPACING_XL } from '@/styles/variables'

import { useTableColumns } from '../../hooks/useTableColumns'

import classes from './ColumnManagerModal.module.less'
import { ManagedColumn, ManagedColumns } from './ManagedColumn'
import { TableColumnItem } from './TableColumnItem'

type Props = Omit<ModalProps, 'onOk'> & {
  visibleKeys: LogEntriesTableColumnKey[]
  hiddenKeys: LogEntriesTableColumnKey[]
  onOk: (managedColumns: ManagedColumns) => void
}

export const ColumnManagerModal = ({
  visibleKeys,
  hiddenKeys,
  onCancel,
  onOk,
  ...props
}: Props): React.ReactElement | null => {
  const { t } = useTranslation()
  const [managedColumns, setManagedColumns] = React.useState<ManagedColumns>({
    visibleColumns: visibleKeys,
    hiddenColumns: hiddenKeys,
  })
  const [activeId, setActiveId] = React.useState<LogEntriesTableColumnKey | null>(null)

  const { tableColumns } = useTableColumns()

  const tableColumnsDict = React.useMemo(() => keyBy(tableColumns, 'key'), [tableColumns])

  const sensors = useSensors(useSensor(PointerSensor))

  const findContainer = React.useCallback(
    (id?: LogEntriesTableColumnKey | keyof typeof managedColumns) => {
      if (!id) {
        return
      }

      if (id in managedColumns) {
        return id as keyof typeof managedColumns
      }

      return Object.keys(managedColumns).find((key) =>
        managedColumns[key as keyof typeof managedColumns].includes(id as LogEntriesTableColumnKey),
      ) as keyof typeof managedColumns | undefined
    },
    [managedColumns],
  )

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      const activeId = active.id as LogEntriesTableColumnKey
      const overId = over?.id as LogEntriesTableColumnKey

      const activeContainer = findContainer(activeId)
      const overContainer = findContainer(overId)

      if (!activeContainer || !overContainer || activeContainer !== overContainer) {
        return
      }

      const activeIndex = managedColumns[activeContainer].indexOf(activeId)
      const overIndex = managedColumns[overContainer].indexOf(overId)

      if (
        overContainer === 'visibleColumns' &&
        (overIndex <= 0 || overIndex === managedColumns[overContainer].length - 1)
      ) {
        return
      }

      if (activeIndex !== overIndex) {
        setManagedColumns((prevManagedColumns) => ({
          ...prevManagedColumns,
          [overContainer]: uniq(
            arrayMove(prevManagedColumns[overContainer], activeIndex, overIndex),
          ),
        }))
      }
      setActiveId(null)
    },
    [findContainer, managedColumns],
  )

  const handleDragOver = React.useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event
      const activeId = active.id as LogEntriesTableColumnKey
      const overId = over?.id as LogEntriesTableColumnKey

      const activeContainer = findContainer(activeId)
      const overContainer = findContainer(overId)

      if (!activeContainer || !overContainer || activeContainer === overContainer) {
        return
      }

      // Insert the item into the new container at the index it overs, or as the second item otherwise (to be placed
      // between first and last items that are locked)
      const index = over?.data.current?.sortable.index || 1

      setManagedColumns((prevManagedColumns) => ({
        ...prevManagedColumns,
        [activeContainer]: prevManagedColumns[activeContainer].filter((id) => id !== activeId),
        [overContainer]: [
          ...prevManagedColumns[overContainer].slice(0, index),
          activeId,
          ...prevManagedColumns[overContainer].slice(index),
        ],
      }))
    },
    [findContainer],
  )

  const onModalOk = React.useCallback(() => {
    onOk(managedColumns)
  }, [managedColumns, onOk])

  const onModalCancel = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setManagedColumns({
        visibleColumns: visibleKeys,
        hiddenColumns: hiddenKeys,
      })

      onCancel?.(e)
    },
    [hiddenKeys, onCancel, visibleKeys],
  )

  React.useEffect(() => {
    // Update local values when the parent values changed
    setManagedColumns({
      visibleColumns: visibleKeys,
      hiddenColumns: hiddenKeys,
    })
  }, [hiddenKeys, visibleKeys])

  return (
    <Modal
      destroyOnClose
      {...props}
      onOk={onModalOk}
      onCancel={onModalCancel}
      zIndex={9999}
      title={t('log.log-entries-overview.manage-columns.label')}
      okText={t('log.log-entries-overview.manage-columns.action')}
      cancelText={t('actions.cancel')}
      className={classes.modalWrapper}
      style={{ top: 0, maxWidth: 540, paddingBottom: 0, userSelect: 'none' }}
      bodyStyle={{ paddingRight: SPACING_XL, paddingLeft: SPACING_XL }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={(event) => setActiveId(event.active.id as LogEntriesTableColumnKey)}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        collisionDetection={rectIntersection}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <DragOverlay>
          {activeId ? (
            <TableColumnItem
              tableColumnKey={tableColumnsDict[activeId].key}
              tableColumnTitle={
                tableColumnsDict[activeId].columnManagerTitle ?? tableColumnsDict[activeId].title
              }
            />
          ) : null}
        </DragOverlay>
        <Space className={classes.space}>
          <Space direction="vertical" className={classes.space} size={24}>
            <Text fontWeight="medium" color="secondary" size="lg">
              {t('log.log-entries-overview.manage-columns.description')}
            </Text>
            <Space size={24} className={classes.spaceColumn}>
              <ManagedColumn
                id="visibleColumns"
                title={t('log.log-entries-overview.manage-columns.shown-label')}
                columnKeys={managedColumns.visibleColumns}
                fixFirstAndLast
              />
              <Divider type="vertical" style={{ height: '100%' }} />
              <ManagedColumn
                id="hiddenColumns"
                title={t('log.log-entries-overview.manage-columns.hidden-label')}
                columnKeys={managedColumns.hiddenColumns}
              />
            </Space>
          </Space>
        </Space>
      </DndContext>
    </Modal>
  )
}
