import React, { ReactElement, ReactNode, memo } from 'react'

import { Typography } from 'antd/es'
import { BlockProps } from 'antd/es/typography/Base'

import classNames from 'classnames'

import classes from './Text.module.less'

interface Props extends BlockProps {
  children: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  color?: 'default' | 'secondary' | 'disabled' | 'danger' | 'orange' | 'blue' | 'white'
  fontWeight?: 'bold' | 'medium' | 'semibold' | 'regular' | 'light'
  mode?: 'paragraph' | 'text' | 'title'
}

/**
 * Basic text component
 * @param {('xs' | 'sm' | 'md' | 'lg' | 'xl')} props.size The size of the text
 * @param {string} [props.className=""] The optional classes added to the text
 * @param {ReactNode} props.children the content of the text component
 * @param {('default' | 'secondary' | 'disabled' | 'danger' | 'orange' | 'blue')} [props.color] The color of the text
 * @param {('body' | 'heading' | 'button')} [props.variant] The type of the text
 * @returns {ReactElement}
 */
const Text = ({
  size = 'md',
  color = 'default',
  className = '',
  fontWeight = 'regular',
  children,
  mode = 'text',
  ...rest
}: Props): ReactElement => {
  const rootClasses = classNames([
    classes.text,
    classes[color],
    classes[size],
    classes[fontWeight],
    classes[mode],
    className,
  ])

  if (mode === 'paragraph') {
    return (
      <Typography.Paragraph className={rootClasses} {...rest}>
        {children}
      </Typography.Paragraph>
    )
  }

  if (mode === 'title') {
    return (
      <Typography.Title className={rootClasses} {...rest}>
        {children}
      </Typography.Title>
    )
  }
  return (
    <Typography.Text className={rootClasses} {...rest}>
      {children}
    </Typography.Text>
  )
}

export default memo(Text)
