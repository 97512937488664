import React from 'react'

import { IconProps } from '@/types/general'

export const TotalEmissionsOutlineIcon = ({
  size = 14,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02044 1.67088C4.54266 1.67088 3.34469 2.86886 3.34469 4.34663C3.34469 4.54924 3.36709 4.74581 3.40928 4.93431C3.48995 5.29472 3.26328 5.65231 2.90291 5.73315C2.00692 5.93413 1.33788 6.73539 1.33788 7.69132C1.33788 8.79965 2.23636 9.69813 3.34469 9.69813H9.36513C10.8429 9.69813 12.0409 8.50016 12.0409 7.02238C12.0409 5.54461 10.8429 4.34663 9.36513 4.34663C9.3472 4.34663 9.32931 4.34681 9.31145 4.34716C8.98838 4.35348 8.70697 4.12797 8.64276 3.81129C8.3951 2.58997 7.31435 1.67088 6.02044 1.67088ZM2.00681 4.34663C2.00681 2.12997 3.80377 0.333008 6.02044 0.333008C7.77773 0.333008 9.27015 1.46181 9.81417 3.0336C11.8195 3.25684 13.3788 4.95751 13.3788 7.02238C13.3788 9.23905 11.5818 11.036 9.36513 11.036H3.34469C1.49747 11.036 0 9.53854 0 7.69132C0 6.3157 0.82997 5.13511 2.01608 4.62113C2.00993 4.53035 2.00681 4.43882 2.00681 4.34663Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69822 6.83139C8.88294 6.83139 9.03269 6.98114 9.03269 7.16586L9.03269 12.5908L10.1341 11.4894C10.2647 11.3588 10.4765 11.3588 10.6071 11.4894C10.7377 11.62 10.7377 11.8318 10.6071 11.9624L8.93472 13.6347C8.872 13.6975 8.78693 13.7327 8.69822 13.7327C8.60951 13.7327 8.52444 13.6975 8.46171 13.6347L6.78937 11.9624C6.65875 11.8318 6.65875 11.62 6.78937 11.4894C6.91999 11.3588 7.13176 11.3588 7.26238 11.4894L8.36375 12.5908L8.36375 7.16586C8.36375 6.98114 8.5135 6.83139 8.69822 6.83139Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11228 7.97815C4.297 7.97815 4.44675 8.1279 4.44675 8.31262V13.1911L5.54812 12.0897C5.67874 11.9591 5.89051 11.9591 6.02113 12.0897C6.15175 12.2204 6.15175 12.4321 6.02113 12.5627L4.34879 14.2351C4.28606 14.2978 4.20099 14.3331 4.11228 14.3331C4.02357 14.3331 3.9385 14.2978 3.87578 14.2351L2.20343 12.5627C2.07281 12.4321 2.07281 12.2204 2.20343 12.0897C2.33405 11.9591 2.54582 11.9591 2.67644 12.0897L3.77781 13.1911L3.77781 8.31262C3.77781 8.1279 3.92756 7.97815 4.11228 7.97815Z"
        fill={color}
      />
    </svg>
  )
}
