import { useTranslation } from 'react-i18next'

export const useGetGraphMeasureUnitTitle = (): {
  getGraphMeasureUnitTitle: (measureUnitKey?: string) => string
} => {
  const { t, i18n } = useTranslation('common')

  const getGraphMeasureUnitTitle = (measureUnitKey?: string): string => {
    const lastKey = measureUnitKey?.split(',').at(-1)
    if (lastKey && i18n.exists(`common:reports.legend.${lastKey}`)) {
      return t(`reports.legend.${lastKey}`)
    }
    if (i18n.exists(`common:reports.legend.${measureUnitKey}`)) {
      return t(`reports.legend.${measureUnitKey}`)
    }
    return t('tonnes-emitted')
  }

  return {
    getGraphMeasureUnitTitle,
  }
}
