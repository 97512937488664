export const checkIfSearchMatchesItemProperty = <T>(
  item: T,
  key: keyof T,
  search: string,
): boolean => {
  const value = item[key]

  if (!search?.length) {
    // defaults to true if no search value (show all)
    return true
  }

  // Ensure the value exists and is a string before proceeding
  if (typeof value === 'string' && search) {
    return value.toLowerCase().includes(search.toLowerCase())
  }

  return false
}
