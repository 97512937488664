import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ClimateActionWithIncludes } from '@cozero/models'

import { DEFAULT_SLICE_VALUES, SLICE_NAME } from './constants'

const actActionSimulatorSlice = createSlice({
  name: SLICE_NAME,
  initialState: DEFAULT_SLICE_VALUES,
  reducers: {
    setPendingClimateActionId: (state, action: PayloadAction<number | undefined>) => {
      state.pendingClimateActionId = action.payload
    },
    setCalculatedClimateAction: (
      state,
      action: PayloadAction<ClimateActionWithIncludes | undefined>,
    ) => {
      state.calculatedClimateAction = action.payload
    },
  },
})

export const { setPendingClimateActionId, setCalculatedClimateAction } =
  actActionSimulatorSlice.actions

export default actActionSimulatorSlice
