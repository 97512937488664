import { User } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import i18n from '@/i18n'

import apiSlice from '../api'
import { TAG_USER } from '../auth'

import {
  CHANGE_USER_PASSWORD,
  REQUEST_RESET_PASSWORD,
  SUBMIT_RESET_PASSWORD,
  UPDATE_CURRENT_USER,
} from './constants'

const userProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [UPDATE_CURRENT_USER]: builder.mutation<
      User,
      {
        user: Partial<User>
        image?: string
      }
    >({
      query: ({ user, image }) => ({
        url: centralApiGatewayClient.users.UPDATE_ME,
        method: 'PUT',
        data: { ...user, image },
      }),
      invalidatesTags: (res) => [{ type: TAG_USER, id: res?.id }],
    }),
    [CHANGE_USER_PASSWORD]: builder.mutation<
      boolean,
      { currentPassword: string; newPassword: string }
    >({
      query: (data) => ({
        url: centralApiGatewayClient.users.CHANGE_PASSWORD,
        method: 'POST',
        data,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    [REQUEST_RESET_PASSWORD]: builder.mutation<void, string>({
      query: (email) => ({
        url: centralApiGatewayClient.users.REQUEST_PASSWORD,
        method: 'POST',
        data: { email },
      }),
    }),
    [SUBMIT_RESET_PASSWORD]: builder.mutation<User, { token: string; password: string }>({
      query: (data) => ({
        url: centralApiGatewayClient.users.PASSWORD_RESET,
        method: 'POST',
        data,
      }),
      extraOptions: { maxRetries: 0 },
      transformErrorResponse: (res) => {
        const isArrayMessage = Array.isArray(res?.data?.message)

        if (isArrayMessage) {
          // Message from dto validation follow this structure:
          // field: errorMessage1, errorMessage2
          const [, messageKeys] = (res?.data?.message?.[0] || '').split(': ')
          const keys = messageKeys.split(', ') as string[]
          const messageKey = keys.find((key) => i18n.exists(`general-errors.${key}`)) || 'app-error'

          return {
            ...res,
            message: i18n.exists(`general-errors.${messageKey}`)
              ? i18n.t(`general-errors.${messageKey}`)
              : '',
          }
        } else {
          const message = res.data.message

          return {
            ...res,
            message: i18n.exists(`general-errors.${message}`)
              ? i18n.t(`general-errors.${message}`)
              : JSON.parse(message),
          }
        }
      },
    }),
  }),
})

export const {
  useUpdateCurrentUserMutation,
  useChangePasswordMutation,
  useRequestResetPasswordMutation,
  useSubmitResetPasswordMutation,
} = userProfileApiSlice
export default userProfileApiSlice
