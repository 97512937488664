import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'

import { Options, PieOptions, Plot } from '@antv/g2plot'
import { ChartPivotRow } from '@cubejs-client/core'
import { PieChart, PieChartProps } from '@opd/g2plot-react'
import moment from 'moment'

import { ReportType } from '@cozero/models'

import useGraphs from '@/hooks/useGraphs'
import { legendStyles } from '@/styles/graphs'
import { CINDER_BLUE_50 } from '@/styles/variables'

const config: Omit<PieChartProps, 'data' | 'angleField'> = {
  colorField: 'x',
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  },
  yAxis: {
    label: null,
  },
  radius: 0.75,
  label: false,
}

const Pie = React.memo(
  ({
    height,
    chartData,
    valueKey,
    chartRef,
    svgChartRef,
    visible,
    showLegend,
    dimensionType,
  }: {
    height: number | null
    chartData: ChartPivotRow[]
    valueKey: string
    chartRef?: MutableRefObject<Plot<Options> | null>
    svgChartRef?: MutableRefObject<Plot<Options> | null>
    dimensionType?: string
    visible: boolean
    showLegend: boolean
  }): ReactElement | null => {
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.PIE,
      dimensionType,
      valueUnitKey: valueKey,
    })
    const [pieProps, setPieProps] = useState<PieOptions>({
      angleField: valueKey,
      colorField: 'x',
      data: [],
    })

    useEffect(() => {
      const props: PieOptions = {
        ...graphStyles,
        pieStyle: { lineWidth: 2, stroke: 'white' },
        angleField: valueKey,
        data: chartData,
        tooltip: {
          ...customTooltip,
          formatter: (item) => {
            return {
              name: `${
                moment(item.x, moment.ISO_8601, true).isValid() ? moment(item.x).year() : item.x
              }`,
              value: item[valueKey],
            }
          },
        },
        legend: showLegend
          ? {
              ...legendStyles,
              itemName: {
                style: legendStyles.itemName?.style,
                formatter: (name) =>
                  moment(name, moment.ISO_8601).isValid() ? moment(name).format('YYYY') : name,
              },
            }
          : false,
        ...config,
        label: {
          type: 'spider',
          labelHeight: 18,
          style: {
            lineWidth: 1.5,
            fontWeight: 500,
            color: CINDER_BLUE_50,
          },
          callback: (item) => {
            return {
              labelLine: {
                style: {
                  lineWidth: 1.4,
                  opacity: item > 0 ? 1 : 0,
                },
              },
            }
          },
          formatter: (item) => {
            const name = item.x
            const legend = moment(name, moment.ISO_8601).isValid()
              ? moment(name).format('YYYY')
              : name
            return parseFloat(item.percent) > 0
              ? `${legend} ${(parseFloat(item.percent) * 100).toFixed(0)}%`
              : ` `
          },
        },
      }
      setPieProps(props)
    }, [chartData, valueKey, showLegend, dimensionType, graphStyles])

    if (!height) {
      return null
    }

    return (
      <>
        <PieChart
          {...pieProps}
          height={height}
          style={{ display: visible ? 'block' : 'none' }}
          renderer={'canvas'}
          chartRef={(ref) => {
            if (ref && chartRef) {
              chartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
        <PieChart
          {...pieProps}
          height={height}
          style={{ display: 'none' }}
          renderer={'svg'}
          width={1000}
          chartRef={(ref) => {
            if (ref && svgChartRef) {
              svgChartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
      </>
    )
  },
)

Pie.displayName = 'Pie'

export default Pie
