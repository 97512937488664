/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { message } from 'antd/es'

import moment from 'moment'

import { ClosedPeriodStatus } from '@cozero/constants'
import {
  CheckClosedPeriod,
  ClosedPeriod,
  ClosedPeriodOrganizationLevel,
  CreatedClosedPeriod,
  PreviewClosedPeriod,
} from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'
import { routes } from '@cozero/utils'

import { ClosedPeriodTableSorter } from '@/pages/ClosedPeriods'

import apiSlice from '@/redux/api'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { TAG_GET_FULL_CALENDAR_YEAR_PERIODS } from '@/redux/closedPeriods/tags'
import { TAG_GET_STRATEGY_BUILDER_STATE } from '@/redux/strategyBuilder'
import { LIST } from '@/redux/types'
import { getUrl } from '@/utils/url'

import axios from '../utils/axios'

export interface ClosedPeriodForm {
  id?: number
  name: string
  timePeriod: Date[]
  description?: string
  organizationLevel: ClosedPeriodOrganizationLevel
  businessUnitId: number
  disabled: boolean
  status: boolean
}
interface ClosedPeriodContext {
  closedPeriod: (ClosedPeriod & { locations?: { id: number }[] }) | undefined
  keyStats: PreviewClosedPeriod | undefined
  error: Error | string | undefined
  minimumDataToPreview: (keyof ClosedPeriodForm)[]
  loadingPreview: boolean
  loadingCreate: boolean
  isPeriodClosed: boolean
  checkClosePeriod: (
    body: {
      startDate: CreatedClosedPeriod['startDate']
      endDate: CreatedClosedPeriod['endDate']
    },
    businessUnitId: number | undefined,
  ) => Promise<void>
  getManyClosedPeriods: (data: {
    page: number
    pageSize: number
    sorter: ClosedPeriodTableSorter
    startDate?: Date
    endDate?: Date
    onlyClosed?: boolean
    closedPeriodIds?: number[]
  }) => Promise<{ docs: ClosedPeriod[]; totalRecords: number }>
  getClosedPeriod: (id: string) => Promise<void>
  createClosedPeriod: (
    newclosedPeriod: ClosedPeriod,
    navigateToClosedPeriodDetails?: boolean,
  ) => Promise<ClosedPeriod | undefined>
  updateClosedPeriod: (
    id: string,
    closedPeriod: Partial<ClosedPeriod>,
  ) => Promise<ClosedPeriod | undefined>
  previewKeyStats: (newclosedPeriod: Partial<ClosedPeriod>) => Promise<void>
  resetKeyStats: () => void
  parseForm: (formValues: ClosedPeriodForm) => ClosedPeriod
  deleteClosedPeriod: (id: string) => Promise<void>
}

export const useClosedPeriod = (): ClosedPeriodContext => {
  const { t } = useTranslation('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState<Error | string | undefined>()
  const [closedPeriod, setclosedPeriod] = useState<
    ClosedPeriod & { locations?: { id: number }[] }
  >()
  const [keyStats, setKeyStats] = useState<PreviewClosedPeriod>()
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)
  const [isPeriodClosed, setIsPeriodClosed] = useState<boolean>(false)
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const createClosedPeriod = React.useCallback(
    async (
      newClosedPeriod: ClosedPeriod,
      navigateToClosedPeriodDetails = false,
    ): Promise<ClosedPeriod | undefined> => {
      try {
        setError(undefined)
        setLoadingCreate(true)
        const { data } = await axios.post(logApiGatewayClient.closedPeriods.CREATE, newClosedPeriod)
        if (navigateToClosedPeriodDetails) {
          navigate(routes.log.customReport.replace(':id', data.id))
        }
        message.success(t('log.closed-period.create-success'))
        setclosedPeriod(data)

        dispatch(
          apiSlice.util.invalidateTags([
            { type: TAG_GET_FULL_CALENDAR_YEAR_PERIODS, id: LIST },
            TAG_GET_STRATEGY_BUILDER_STATE,
          ]),
        )

        return data
      } catch (e) {
        message.error(t('log.closed-period.error'))
        setError(e.message)
      } finally {
        setLoadingCreate(false)
      }
    },
    [dispatch, navigate, t],
  )

  const updateClosedPeriod = React.useCallback(
    async (id: string, closedPeriod: Partial<ClosedPeriod>): Promise<ClosedPeriod | undefined> => {
      try {
        setError(undefined)
        setLoadingCreate(true)
        const { data } = await axios.put(
          logApiGatewayClient.closedPeriods.UPDATE.replace(':id', id.toString()),
          closedPeriod,
        )
        setLoadingCreate(false)
        navigate(routes.log.customReport.replace(':id', data.id))
        message.success(t('log.closed-period.update-success'))
        setclosedPeriod(data)

        dispatch(
          apiSlice.util.invalidateTags([
            { type: TAG_GET_FULL_CALENDAR_YEAR_PERIODS, id: LIST },
            TAG_GET_STRATEGY_BUILDER_STATE,
          ]),
        )

        return data
      } catch (e) {
        setLoadingCreate(false)
        message.error(t('log.closed-period.error'))
        setError(e.message)
      }
    },
    [dispatch, navigate, t],
  )

  const getManyClosedPeriods = React.useCallback(
    async ({
      page,
      pageSize,
      closedPeriodIds,
      sorter,
      startDate,
      endDate,
      onlyClosed,
    }: {
      page: number
      pageSize: number
      sorter?: unknown
      startDate?: Date
      endDate?: Date
      onlyClosed?: boolean
      closedPeriodIds?: number[]
    }): Promise<{ docs: ClosedPeriod[]; totalRecords: number }> => {
      try {
        const url = getUrl(logApiGatewayClient.closedPeriods.GET_MANY)
        url.searchParams.append('page', `${page}`)
        url.searchParams.append('pageSize', `${pageSize}`)
        url.searchParams.append('businessUnitId', `${selectedBusinessUnit?.id}`)
        url.searchParams.append('onlyClosed', `${onlyClosed}`)
        if (startDate && !isNaN(startDate.valueOf()) && endDate && !isNaN(endDate.valueOf())) {
          url.searchParams.append('startDate', `${startDate.toISOString()}`)
          url.searchParams.append('endDate', `${endDate.toISOString()}`)
        }
        if (sorter) {
          url.searchParams.append('sorter', `${JSON.stringify(sorter)}`)
        }
        if (closedPeriodIds) {
          url.searchParams.append('closedPeriodIds', `${closedPeriodIds}`)
        }
        const { data } = await axios.get<{ docs: ClosedPeriod[]; totalRecords: number }>(
          url.toString(),
        )
        return data
      } catch (e) {
        message.error(t('log.closed-period.error'))
        setError(e.message)
        return { docs: [], totalRecords: 0 }
      }
    },
    [selectedBusinessUnit?.id, t],
  )

  const minimumDataToPreview = React.useMemo<(keyof ClosedPeriodForm)[]>(
    () => ['organizationLevel', 'timePeriod'],
    [],
  )

  const previewKeyStats = React.useCallback(async (newClosedPeriod: Partial<ClosedPeriod>) => {
    try {
      setLoadingPreview(true)
      setError(undefined)
      const { data } = await axios.post(
        logApiGatewayClient.closedPeriods.PREVIEW_KEY_STATS,
        newClosedPeriod,
      )
      setLoadingPreview(false)
      setKeyStats(data)
    } catch (e) {
      setError(e.message)
    }
  }, [])

  const getClosedPeriod = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        setError(undefined)
        const { data } = await axios.get(
          logApiGatewayClient.closedPeriods.GET_ONE.replace(':id', id.toString()),
        )
        setclosedPeriod(data)
      } catch (e) {
        message.error(t('log.closed-period.error'))
        setError(e.message)
      }
    },
    [t],
  )

  const resetKeyStats = React.useCallback(() => {
    setKeyStats(undefined)
  }, [])

  const parseForm = React.useCallback((formValues: ClosedPeriodForm): ClosedPeriod => {
    return Object.entries(formValues).reduce(
      (acc, [key, value]) =>
        value === undefined
          ? { ...acc }
          : key === 'timePeriod'
          ? {
              ...acc,
              startDate: moment(value[0]).utc().toDate(),
              endDate: moment(value[1]).utc().toDate(),
            }
          : key === 'status'
          ? { ...acc, status: value ? ClosedPeriodStatus.DRAFT : ClosedPeriodStatus.CLOSED }
          : { ...acc, [key]: value },
      {},
    ) as ClosedPeriod
  }, [])

  const checkClosePeriod = React.useCallback(
    async (
      body: {
        startDate: CreatedClosedPeriod['startDate'] | null
        endDate: CreatedClosedPeriod['endDate'] | null
      },
      businessUnitId?: number | undefined,
    ): Promise<void> => {
      if (body.startDate && body.endDate) {
        const { data } = await axios.post<CheckClosedPeriod>(
          logApiGatewayClient.closedPeriods.CHECK_CLOSED_PERIOD,
          body,
          {
            params: { businessUnitId },
          },
        )
        setIsPeriodClosed(data.isPeriodClosed)
      }
    },
    [],
  )
  const deleteClosedPeriod = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        setError(undefined)
        const { data } = await axios.delete(
          logApiGatewayClient.closedPeriods.DELETE.replace(':id', id.toString()),
        )
        message.success(t('log.closed-period.deleted'))
        setclosedPeriod(data)
      } catch (e) {
        message.error(t('log.closed-period.error'))
        setError(e.message)
      }
    },
    [t],
  )

  return {
    closedPeriod,
    keyStats,
    error,
    minimumDataToPreview,
    loadingPreview,
    loadingCreate,
    isPeriodClosed,
    getManyClosedPeriods,
    getClosedPeriod,
    createClosedPeriod,
    previewKeyStats,
    resetKeyStats,
    parseForm,
    checkClosePeriod,
    updateClosedPeriod,
    deleteClosedPeriod,
  }
}
