/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

import Alert from '@/atoms/Alert'
import Text from '@/atoms/Text'

import { CINDER_BLUE_80 } from '@/styles/variables'

import classes from './LogEntryDeletionModal.module.less'
import { LogEntryDeletionModalType } from './LogEntryDeletionModal.types'

export const LogEntryDeletionModalContent = ({
  logEntriesCount,
  modalType,
}: {
  logEntriesCount: number
  modalType: LogEntryDeletionModalType
}): React.ReactElement => {
  const { t } = useTranslation()

  if (modalType === LogEntryDeletionModalType.CONFIRMATION) {
    return (
      <div className={classes.warningText}>
        <HiOutlineExclamationCircle size={18} />
        <Text size="xl" style={{ textAlign: 'center' }}>
          <Trans
            i18nKey="log.log-entries-overview.actions.delete-selection.confirmation-text"
            values={{ count: logEntriesCount }}
            components={{ bold: <strong /> }}
          />
        </Text>
      </div>
    )
  }

  return (
    <>
      <Alert type="warning" textColor={CINDER_BLUE_80} className={classes.alert}>
        {t(
          modalType === LogEntryDeletionModalType.CANNOT_DELETE_SELECTION
            ? 'log.log-entries-overview.actions.delete-selection.alert-unable-to-delete'
            : 'log.log-entries-overview.actions.delete-selection.alert-with-conflict',
        )}
      </Alert>
      {modalType === LogEntryDeletionModalType.PARTIAL_CONFIRMATION && (
        <Text size="xl" style={{ textAlign: 'center' }} className={classes.text}>
          <Trans
            i18nKey="log.log-entries-overview.actions.delete-selection.confirmation-text-with-conflict"
            values={{ count: logEntriesCount }}
            components={{ bold: <strong /> }}
          />
        </Text>
      )}
    </>
  )
}
