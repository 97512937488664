import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'

import { Options, Plot, SankeyOptions } from '@antv/g2plot'
import { ChartPivotRow } from '@cubejs-client/core'
import { SankeyChart } from '@opd/g2plot-react'

import { ReportType } from '@cozero/models'

import useGraphs from '@/hooks/useGraphs'

const Sankey = React.memo(
  ({
    height,
    chartData,
    valueKey,
    chartRef,
    svgChartRef,
    visible,
    dimensionType,
  }: {
    height: number | null
    chartData: ChartPivotRow[]
    valueKey: string
    chartRef?: MutableRefObject<Plot<Options> | null>
    svgChartRef?: MutableRefObject<Plot<Options> | null>
    visible: boolean
    dimensionType?: string
  }): ReactElement | null => {
    const [props, setProps] = useState<SankeyOptions>({ data: [] })
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.SANKEY,
      dimensionType,
      valueUnitKey: valueKey,
    })

    useEffect(() => {
      const props: SankeyOptions = {
        ...graphStyles,
        data: chartData.map((chartDatum) => ({
          source: chartDatum.xValues[0],
          target: chartDatum.xValues[1],
          value: chartDatum[valueKey],
        })),
        nodeWidthRatio: 0.003,
        tooltip: {
          ...customTooltip,
          formatter: (item) => {
            return {
              name: `${item.source} -> ${item.target}`,
              value: item.value,
            }
          },
        },
      }
      setProps(props)
    }, [graphStyles, chartData, dimensionType])

    if (!height) {
      return null
    }

    return (
      <>
        <SankeyChart
          sourceField={'source'}
          targetField={'target'}
          weightField={'value'}
          height={height}
          {...props}
          style={{ display: visible ? 'block' : 'none' }}
          renderer={'canvas'}
          chartRef={(ref) => {
            if (ref && chartRef) {
              chartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
        <SankeyChart
          sourceField={'source'}
          targetField={'target'}
          weightField={'value'}
          height={height}
          {...props}
          style={{ display: 'none' }}
          renderer={'svg'}
          width={1000}
          chartRef={(ref) => {
            if (ref && svgChartRef) {
              svgChartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
      </>
    )
  },
)

Sankey.displayName = 'Sankey'

export default Sankey
