import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineLockClosed } from 'react-icons/hi'

import { Form, message } from 'antd/es'

import Modal from '@/molecules/Modal'

import InputField from '@/atoms/InputField'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useChangePasswordMutation } from '@/redux/userProfile'

type Props = {
  visible: boolean
  toggleVisibility: (state: boolean) => void
}

type FormFields = {
  currentPassword: string
  newPassword: string
}

const ChangePasswordModal = ({ visible, toggleVisibility }: Props): JSX.Element => {
  const { t } = useTranslation()
  const [form] = Form.useForm<FormFields>()
  const [changePassword] = useChangePasswordMutation()

  const onSubmit = async (): Promise<void> => {
    try {
      const values = form.getFieldsValue()
      const result = await changePassword(values).unwrap()

      if (result) {
        message.success(t('password.change-success'))
        form.resetFields()
        return toggleVisibility(false)
      }
    } catch (error) {
      message.error(error.message, 30)
    }
  }

  return (
    <Modal
      visible={visible}
      title={t('password.change')}
      okButtonProps={{
        htmlType: 'submit',
        action: 'update user',
        category: AnalyticsCategories.USERS,
      }}
      okText={t('password.send-btn')}
      onOk={() => form.submit()}
      onCancel={() => toggleVisibility(false)}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="currentPassword">
          <InputField
            prefix={<HiOutlineLockClosed />}
            type="password"
            placeholder={t('password.current')}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[
            { required: true, message: t('password.required-msg') },
            { min: 8, message: t('password.too-short') },
          ]}
        >
          <InputField
            prefix={<HiOutlineLockClosed />}
            type="password"
            placeholder={t('password.new')}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="confirm-password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('password.confirm-new'),
              validator: async (_, value): Promise<void> => {
                if (!value || form.getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(t('password.no-match'))
              },
            },
          ]}
        >
          <InputField
            prefix={<HiOutlineLockClosed />}
            type="password"
            placeholder={t('password.placeholder-confirm')}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePasswordModal
