import React from 'react'

import { IconProps } from '@/types/general'

export const SubcategoryOutlineIcon = ({
  size = 15,
  color = 'currentColor',
  ...rest
}: Omit<IconProps, 'width' | 'height'> & { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.75C0 0.783502 0.783502 0 1.75 0H7.58333C8.54983 0 9.33333 0.783502 9.33333 1.75V4.3335C9.33333 4.53567 9.16602 4.3335 9.16602 4.3335C5.16602 4.3335 5.66602 5.8335 5.66602 10.1395L4.66667 9.69385L0.844208 11.6051C0.663381 11.6955 0.448633 11.6858 0.276657 11.5795C0.104681 11.4733 0 11.2855 0 11.0833V1.75ZM1.75 1.16667C1.42783 1.16667 1.16667 1.42783 1.16667 1.75V10.1395L4.40579 8.51992C4.57002 8.43781 5.00179 8.75138 5.16602 8.8335L5.66602 5.3335L8.16602 4.3335L8.16667 1.75C8.16667 1.42783 7.9055 1.16667 7.58333 1.16667H1.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.75C5 4.7835 5.7835 4 6.75 4H12.5833C13.5498 4 14.3333 4.7835 14.3333 5.75V15.0833C14.3333 15.2855 14.2287 15.4733 14.0567 15.5795C13.8847 15.6858 13.67 15.6955 13.4891 15.6051L9.66667 13.6939L5.84421 15.6051C5.66338 15.6955 5.44863 15.6858 5.27666 15.5795C5.10468 15.4733 5 15.2855 5 15.0833V5.75ZM6.75 5.16667C6.42783 5.16667 6.16667 5.42783 6.16667 5.75V14.1395L9.40579 12.5199C9.57002 12.4378 9.76332 12.4378 9.92754 12.5199L13.1667 14.1395V5.75C13.1667 5.42783 12.9055 5.16667 12.5833 5.16667H6.75Z"
        fill={color}
      />
    </svg>
  )
}
