import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineCheckCircle, HiOutlineEye, HiOutlineXCircle } from 'react-icons/hi'

import { FactorRequestTableRowDto } from '@cozero/dtos'

import Tag, { TagType } from '@/atoms/Tag'

import classes from '../FactorRequests.module.less'

interface Props {
  status: FactorRequestTableRowDto['status']
}

export const FactorStatusTag = ({ status }: Props): React.ReactElement => {
  const { t } = useTranslation()

  let type: TagType
  let icon: React.ReactNode = null

  switch (status) {
    case 'ACCEPTED':
      type = 'success'
      icon = <HiOutlineCheckCircle />
      break
    case 'SUBMITTED':
      type = 'info'
      icon = <HiOutlineEye />
      break
    case 'REJECTED':
      type = 'danger'
      icon = <HiOutlineXCircle />
      break
    case 'REQUESTED':
    default:
      type = 'default'
  }

  return (
    <Tag type={type} icon={icon} className={classes.tag}>
      {t(`customer-requests.table.status-tags.${status}`)}
    </Tag>
  )
}
