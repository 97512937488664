import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popconfirm, Tag } from 'antd/es'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'

import { omit } from 'lodash-es'
import truncate from 'lodash/truncate'
import moment from 'moment'

import {
  ActivityDataSource,
  EmissionFactor,
  EmissionFactorWithIncludes,
  Page,
  Unit,
} from '@cozero/models'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFactorContext } from '@/contexts/factor'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUserOrganization } from '@/redux/auth'
import { createActivityDataSourceName } from '@/utils/factors'
import { formatNumber, isNumeric } from '@/utils/number'

import classes from './classes.module.less'

interface FactorProps {
  isPeriodClosed?: boolean
  showDeletedColumn?: boolean
  page: number
  pageSize: number
  factors?: Page<EmissionFactorWithIncludes>
  setPageSize: (size: number) => void
  setPage: (page: number) => void
  setSelectedFactor: (factor: EmissionFactorWithIncludes) => void
}

function FactorsTable({
  showDeletedColumn,
  isPeriodClosed,
  factors,
  page,
  pageSize,
  setPageSize,
  setPage,
  setSelectedFactor,
}: FactorProps): JSX.Element {
  const { t } = useTranslation('common')
  const organization = useAppSelector(selectUserOrganization)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const cleanedFactors = factors?.data?.map((obj) => omit(obj, 'children')) || []
  const { deleteCustomFactor } = useFactorContext()

  const columns: ColumnsType<EmissionFactor> = [
    {
      title: t('factors.activity-data'),
      key: 'activityDataSource',
      render(factor: EmissionFactorWithIncludes) {
        return createActivityDataSourceName(factor.activityDataSource as ActivityDataSource)
      },
    },
    {
      title: t('factors.value'),
      key: 'value',
      render(factor: EmissionFactorWithIncludes) {
        return isNumeric(factor.value) ? (
          <Tooltip title={factor.value}>{formatNumber(factor.value)}</Tooltip>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('factors.unit'),
      dataIndex: 'unit',
      key: 'unit',
      render(unit: Unit) {
        return unit ? unit.name : 'EmissionFactor'
      },
    },
    {
      title: t('factors.source'),
      key: 'origin',
      render(factor: EmissionFactorWithIncludes) {
        const originName: string | undefined = factor?.origin?.name
        if (originName) {
          return originName
        }

        const organizationName: string | undefined = factor.organizationId
          ? organization?.name
          : undefined
        if (organizationName) {
          return organizationName
        }

        return 'Cozero'
      },
    },
    {
      title: t('factors.region'),
      key: 'territory',
      render(factor: EmissionFactorWithIncludes) {
        return factor.territory.name as string
      },
    },
    {
      title: t('factors.startDate'),
      key: 'validityStartDate',
      render(factor: EmissionFactorWithIncludes) {
        return moment(factor.validityStartDate).year()
      },
    },
    ...(showDeletedColumn
      ? [
          {
            title: t('factors.deleted'),
            render(factor: EmissionFactorWithIncludes) {
              return factor.deleted ? <Tag>{t('yes')}</Tag> : <Tag>{t('no')}</Tag>
            },
          },
        ]
      : []),
    {
      title: t('factors.type'),
      render(factor: EmissionFactorWithIncludes) {
        return factor.modeledImpact
          ? `${t('factors.modeled-impact')} - ${factor.modeledImpact.name as string}`
          : t('factors.direct')
      },
    },
    {
      title: t('factors.supplier'),
      key: 'name',
      dataIndex: ['supplier', 'name'],
    },
    {
      title: t('factors.description'),
      key: 'description',
      render(factor: EmissionFactorWithIncludes) {
        return (
          <Tooltip title={factor?.description as string}>
            {truncate(factor?.description as string, { length: 40 })}
          </Tooltip>
        )
      },
    },
    {
      title: t('actions.title-plural'),
      key: 'action',
      render(record: EmissionFactorWithIncludes) {
        if (!isManagerOrAdmin || isPeriodClosed || !record.editable) {
          return
        }
        return (
          <div>
            <Button
              action={'edit'}
              category={AnalyticsCategories.FACTORS}
              onClick={() => {
                setSelectedFactor(record)
              }}
              className={classes.editButton}
            >
              {record.organizationId ? t('actions.edit.title') : t('factors.create-factor')}
            </Button>
            {record.organizationId && (
              <Popconfirm
                title={t('factors.delete-warning')}
                onConfirm={async () => {
                  await deleteCustomFactor(record.id)
                }}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Button
                  action={'delete'}
                  category={AnalyticsCategories.FACTORS}
                  type="primary"
                  color="danger"
                  className={classes.editButton}
                >
                  {t('actions.delete')}
                </Button>
              </Popconfirm>
            )}
          </div>
        )
      },
    },
  ]

  const changeTable = (pagination: TablePaginationConfig): void => {
    if (pagination.current && pagination.current !== page) {
      setPage(pagination.current)
    }
  }

  return (
    <Table
      rowKey="id"
      dataSource={cleanedFactors || []}
      columns={columns}
      scroll={{ x: 1000 }}
      emptyText={t('factors.no-custom-factors')}
      onChange={changeTable}
      pagination={{
        current: page,
        defaultPageSize: pageSize,
        pageSize,
        total: factors?.total,
        hideOnSinglePage: true,
        onShowSizeChange: (_current, size) => {
          setPageSize(size)
        },
      }}
      showWrapper={false}
    />
  )
}

export default FactorsTable
