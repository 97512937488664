import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Select, Typography } from 'antd/es'
import { SelectValue } from 'antd/es/select'

import moment from 'moment'

import { Customer, Product, Quantity, Unit } from '@cozero/models'

import Modal from '@/molecules/Modal'

import CustomInputNumber from '@/atoms/CustomInputNumber'
import DatePicker from '@/atoms/DatePicker'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { MODAL_SIZE_MD } from '@/styles/variables'

import classes from './CreateQuantityModal.module.less'

interface Props {
  visible: boolean
  handleCancel: () => void
  submitQuantity: (body: Partial<Quantity> & { customerIds: number[] }) => Promise<void>
  quantity?: Partial<Quantity>
  customers: Customer[]
  products: Product[]
  currencies: Unit[]
}

export function CreateQuantityModal({
  visible,
  handleCancel,
  submitQuantity,
  quantity: initialQuantity,
  customers,
  products,
  currencies,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()
  const [quantity, setQuantity] = useState<Partial<Quantity> | undefined>(initialQuantity)

  useEffect(() => {
    form.setFieldsValue(initialQuantity)
    setQuantity(initialQuantity)
  }, [visible, initialQuantity])

  function editQuantity(key: string, value: unknown): void {
    const formValues = form.getFieldsValue()
    setQuantity({
      ...quantity,
      ...formValues,
      [key]: value,
    })
  }

  function selectProduct(value: SelectValue): void {
    const formValues = form.getFieldsValue()
    const foundProduct = products?.find((obj) => obj.id === value)
    setQuantity({
      ...quantity,
      ...formValues,
      product: foundProduct,
    })
  }

  function selectCustomer(values: string[]): void {
    const newCustomers = values.map((obj) => {
      return customers.find((customer) => customer.id.toString() === obj)
    })
    const formValues = form.getFieldsValue()
    setQuantity({
      ...quantity,
      ...formValues,
      customers: newCustomers,
    })
  }

  async function onSubmit(): Promise<void> {
    const formValues = form.getFieldsValue()
    await submitQuantity({
      ...quantity,
      ...formValues,
    })
  }

  function validQuantity(): boolean {
    return (
      !quantity || !quantity.startDate || !quantity.endDate || !quantity.unit || !quantity.product
    )
  }

  return (
    <Modal
      visible={visible}
      title={`${initialQuantity ? `${t('actions.edit.title')}` : t('actions.create')} ${t(
        'quantity.quantity',
      )}`}
      onCancel={handleCancel}
      footer={null}
      okButtonProps={{
        htmlType: 'submit',
        category: AnalyticsCategories.QUANTITIES,
        className: classes.submitButton,
        disabled: validQuantity(),
        action: 'create-quantity',
      }}
      onOk={onSubmit}
      data-cy="quantities-modal"
    >
      <Form
        category={AnalyticsCategories.QUANTITIES}
        layout="vertical"
        initialValues={quantity}
        form={form}
        onFinish={onSubmit}
        className={classes.formContainer}
      >
        <Typography className={classes.title}>
          {initialQuantity ? `${t('actions.edit.title')}` : t('actions.create')}{' '}
          {t('quantity.quantity')}
        </Typography>
        <Form.Item label="ID" name="id" hidden />
        <div className={classes.row}>
          <Form.Item label={t('quantity.price')} name="price">
            <CustomInputNumber data-cy="quantity-price-per-unit" className={classes.input} />
          </Form.Item>
          <Form.Item
            className={classes.currency}
            label={t('quantity.currency')}
            name={['currency', 'id']}
          >
            <Select data-cy="quantity-currency" showSearch>
              {currencies.map((currency) => (
                <Select.Option key={currency.id} value={currency.id}>
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={classes.row}>
          <Form.Item
            label={t('quantity.volume')}
            name="volume"
            rules={[{ required: true, message: t('required') }]}
          >
            <CustomInputNumber data-cy="quantity-split" className={classes.input} />
          </Form.Item>
          <Form.Item
            label={t('quantity.unit')}
            name="unit"
            rules={[{ required: true, message: t('required') }]}
          >
            <InputField data-cy="quantity-unit" className={classes.input} />
          </Form.Item>
        </div>
        <div className={classes.row}>
          <Form.Item
            className={classes.startDate}
            label={t('quantity.startDate')}
            rules={[{ required: true, message: t('required') }]}
          >
            <DatePicker
              data-cy="quantity-start-date"
              value={quantity?.startDate ? moment(quantity?.startDate) : undefined}
              onChange={(date) => date && editQuantity('startDate', date)}
              format="YYYY-MM-DD"
              picker="date"
            />
          </Form.Item>
          <Form.Item label={t('quantity.endDate')}>
            <DatePicker
              data-cy="quantity-end-date"
              value={quantity?.endDate ? moment(quantity?.endDate) : undefined}
              onChange={(date) => date && editQuantity('endDate', date)}
              format="YYYY-MM-DD"
              picker="date"
            />
          </Form.Item>
        </div>
        <Form.Item
          label={t('quantity.product')}
          rules={[{ required: true, message: t('required') }]}
        >
          <Select<string, { children: string }>
            showSearch
            onChange={selectProduct}
            value={(quantity?.product as Product)?.id.toString()}
            className={classes.productSelect}
            placeholder={t('log.product')}
            notFoundContent={null}
            filterOption={(input, option) => {
              if (!option || !option.children) {
                return false
              }
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            data-cy="quantity-product"
          >
            {products.map((product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('quantity.customers')}>
          <Select
            onChange={selectCustomer}
            mode="multiple"
            value={quantity?.customers?.map((obj) => obj?.id.toString()) || []}
            data-cy="quantity-customers"
          >
            {customers.map((customer) => (
              <Select.Option data-cy={customer.name} key={customer.id} value={`${customer.id}`}>
                {customer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t('quantity.description')} name="description">
          <Input.TextArea data-cy="quantity-description" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
