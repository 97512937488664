/* eslint react-hooks/exhaustive-deps: 2 */

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LogEntryDataQuality } from '@prisma/client'
import { noop } from 'lodash-es'

import { SearchHookProps } from './types'

export const useDataQualitySearch = (): Omit<SearchHookProps, 'options'> & {
  options: { value: LogEntryDataQuality; label: string }[]
} => {
  const { t } = useTranslation('common')

  const options = useMemo(
    () => [
      {
        value: LogEntryDataQuality.UNKNOWN as LogEntryDataQuality,
        label: t('data-quality.options.UNKNOWN.title'),
      },
      {
        value: LogEntryDataQuality.PROXY as LogEntryDataQuality,
        label: t('data-quality.options.PROXY.title'),
      },
      {
        value: LogEntryDataQuality.SECONDARY as LogEntryDataQuality,
        label: t('data-quality.options.SECONDARY.title'),
      },
      {
        value: LogEntryDataQuality.PRIMARY as LogEntryDataQuality,
        label: t('data-quality.options.PRIMARY.title'),
      },
    ],
    [t],
  )

  return {
    onSearch: noop,
    options,
    isFetching: false,
  }
}
