/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row, message } from 'antd/es'

import Modal from '@/molecules/Modal'
import TagInput from '@/molecules/TagInput'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useAddTagsToLogEntriesMutation } from '@/redux/logEntries'
import {
  useCreateLogEntryTagMutation,
  useDeleteLogEntryTagMutation,
  useGetLogEntryTagsQuery,
} from '@/redux/logEntriesTags'

import { RowSelectionResult } from '../../../hooks/useRowsSelection'

import classes from './LogEntryAddTagsModal.module.less'

type LogEntryAddTagsModalProps = {
  open: boolean
  onSuccess: () => void
  onCancel: () => void
  selectedLogEntriesIds: RowSelectionResult['selectedLogEntriesIds']
}

export const LogEntryAddTagsModal = ({
  selectedLogEntriesIds = [],
  open,
  onCancel,
  onSuccess,
}: LogEntryAddTagsModalProps): React.ReactElement => {
  const { t } = useTranslation()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  const [deleteLogEntryTag, { isLoading: isLoadingDeleteLogEntryTag }] =
    useDeleteLogEntryTagMutation()
  const [createLogEntryTag, { isLoading: isLoadingCreateLogEntryTag }] =
    useCreateLogEntryTagMutation()
  const { data: tagOptions, isLoading: isLoadingGetLogEntryTags } = useGetLogEntryTagsQuery(
    {
      selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
    },
    {
      skip: !selectedBusinessUnit?.id,
    },
  )
  const [addTagsToLogEntries, { isLoading: isLoadingAddTags }] = useAddTagsToLogEntriesMutation()

  const [form] = Form.useForm<{ tags: string[] }>()

  const tags = Form.useWatch('tags', form)
  const isLoading =
    isLoadingGetLogEntryTags ||
    isLoadingAddTags ||
    isLoadingCreateLogEntryTag ||
    isLoadingDeleteLogEntryTag

  const onDeleteTag = React.useCallback(
    async (name: string) => {
      const id = tagOptions?.find((x) => x.name === name)?.id
      id && (await deleteLogEntryTag({ tagId: id }))
    },
    [deleteLogEntryTag, tagOptions],
  )

  const onCreateTag = React.useCallback(
    async (name: string) => {
      if (selectedBusinessUnit) {
        await createLogEntryTag({
          name: name,
          businessUnitId: selectedBusinessUnit.id,
        })
      }
    },
    [createLogEntryTag, selectedBusinessUnit],
  )

  const addTagsToSelection = React.useCallback(async (): Promise<void> => {
    const tagIds = tagOptions?.filter(({ name }) => tags.includes(name)).map(({ id }) => id) ?? []

    const response = await addTagsToLogEntries({
      logEntriesIds: selectedLogEntriesIds,
      tagIds,
    })
    if ('data' in response && response.data.success === true) {
      message.success(t('log.log-entries-overview.actions.add-tags.success-message'))
      onSuccess()
    } else {
      message.warning(t('log.log-entries-overview.actions.add-tags.error-message'))
    }
  }, [tagOptions, addTagsToLogEntries, selectedLogEntriesIds, tags, t, onSuccess])

  // On modal open, fetch the data and reset forms
  React.useEffect(() => {
    if (open) {
      form.resetFields()
    }
  }, [open, form])

  return (
    <Modal
      className={classes.modal}
      noBodyPadding
      open={open}
      centered
      onCancel={onCancel}
      title={t('log.log-entries-overview.actions.add-tags.title')}
      okText={t('log.log-entries-overview.actions.add-tags.ok-text')}
      okButtonProps={{
        disabled: !tags?.length,
      }}
      confirmLoading={isLoading}
      onOk={addTagsToSelection}
      maskClosable={false}
    >
      <div className={classes.wrapper}>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="tags" label={t('location.tags')} className={classes.formItem}>
                <TagInput
                  options={(tagOptions?.map((x) => x.name) as string[]) ?? []}
                  onCreateTag={onCreateTag}
                  onDeleteTag={onDeleteTag}
                  placeholder={t('log.log-entries-overview.actions.add-tags.tag-input.placehoder')}
                  reservedOptions={
                    (tagOptions
                      ?.filter((tag) => !!tag.subcategoryId)
                      .map((x) => x.name) as string[]) ?? []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
