import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LOGIN, LOGOUT, authApiSlice } from '../auth'

import { SLICE_NAME_STRATEGY_BUILDER } from './constants'

export type StrategyBuilderState = {
  forecastDrawerOpen: boolean
  isAlertHidden: boolean
}

const initialState: StrategyBuilderState = {
  forecastDrawerOpen: false,
  isAlertHidden: false,
}

const strategyBuilderSlice = createSlice({
  name: SLICE_NAME_STRATEGY_BUILDER,
  initialState,
  reducers: {
    setForecastDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.forecastDrawerOpen = payload
    },
    setIsAlertHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.isAlertHidden = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApiSlice.endpoints[LOGIN].matchFulfilled, (state) => {
        state.isAlertHidden = false
      })
      .addMatcher(authApiSlice.endpoints[LOGOUT].matchFulfilled, () => initialState)
  },
})

export const { setForecastDrawerOpen, setIsAlertHidden } = strategyBuilderSlice.actions

export default strategyBuilderSlice
