import React from 'react'

import { IconProps } from '@/types/general'

const Cube = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55279 0.105573C8.83431 -0.0351909 9.16569 -0.0351909 9.44721 0.105573L17.4472 4.10557C17.786 4.27496 18 4.62123 18 5V15C18 15.3788 17.786 15.725 17.4472 15.8944L9.44721 19.8944C9.16569 20.0352 8.83431 20.0352 8.55279 19.8944L0.552786 15.8944C0.214002 15.725 0 15.3788 0 15V5C0 4.62123 0.214002 4.27496 0.552786 4.10557L8.55279 0.105573ZM2 6.61803L8 9.61803V17.382L2 14.382V6.61803ZM10 17.382L16 14.382V6.61803L10 9.61803V17.382ZM9 7.88197L14.7639 5L9 2.11803L3.23607 5L9 7.88197Z"
        fill={color}
      />
    </svg>
  )
}

export default Cube
